import Head from 'next/head';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {css, Global} from '@emotion/react';

import {getLanguage} from '@app/i18n/utils';

const FontOverrider = (): JSX.Element | null => {
  const {i18n} = useTranslation();

  const [lang, setLang] = React.useState('');

  React.useEffect(() => {
    setLang(i18n.language);
  }, [i18n, setLang]);

  React.useEffect(() => {
    i18n.on('languageChanged', setLang);
    return () => {
      i18n.off('languageChanged', setLang);
    };
  }, [i18n, setLang]);

  const normalizedLang = React.useMemo(() => {
    return getLanguage(lang);
  }, [lang]);

  switch (normalizedLang) {
    case 'ja': {
      return (
        <>
          <Head>
            <link
              key="link-stylesheet-noto-sans-jp"
              href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;700&display=block"
              rel="stylesheet"
            />
          </Head>
          <Global
            styles={css`
              * {
                font-family: 'Spoqa Han Sans', 'Noto Sans JP', sans-serif;
              }
            `}
          />
        </>
      );
    }
    case 'th': {
      return (
        <>
          <Head>
            <link
              key="link-stylesheet-noto-sans-th"
              href="https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;300;400;700&display=swap"
              rel="stylesheet"
            />
          </Head>
          <Global
            styles={css`
              * {
                font-family: 'Spoqa Han Sans', 'Noto Sans Thai', sans-serif;
              }
            `}
          />
        </>
      );
    }
  }
  return null;
};

export default React.memo(FontOverrider);
