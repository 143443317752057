function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { ToeicSpeakingError, TOEIC_SPEAKING_ERROR } from "./error";
export var getToeicSpeakingPartContentsFromUrls = function() {
    var _ref = _async_to_generator(function(param) {
        var partContentUrls, getAudioResourceUrlFromId, fetchedContents, partContents;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    partContentUrls = param.partContentUrls, getAudioResourceUrlFromId = param.getAudioResourceUrlFromId;
                    return [
                        4,
                        Promise.all(partContentUrls.map(function() {
                            var _ref = _async_to_generator(function(partContentUrl) {
                                var response, content;
                                return _ts_generator(this, function(_state) {
                                    switch(_state.label){
                                        case 0:
                                            return [
                                                4,
                                                fetch(partContentUrl)
                                            ];
                                        case 1:
                                            response = _state.sent();
                                            return [
                                                4,
                                                response.json()
                                            ];
                                        case 2:
                                            content = _state.sent();
                                            if (!content.oneOfDomainContent.toeicSpeakingPartContent) {
                                                throw new ToeicSpeakingError(TOEIC_SPEAKING_ERROR.SERVER.INVALID_CONTENT);
                                            }
                                            return [
                                                2,
                                                content
                                            ];
                                    }
                                });
                            });
                            return function(partContentUrl) {
                                return _ref.apply(this, arguments);
                            };
                        }()))
                    ];
                case 1:
                    fetchedContents = _state.sent();
                    return [
                        4,
                        Promise.all(fetchedContents.map(function() {
                            var _ref = _async_to_generator(function(content) {
                                var id, part, audioResourceUrl, _tmp, questions;
                                return _ts_generator(this, function(_state) {
                                    switch(_state.label){
                                        case 0:
                                            id = content.baseContent.contentId;
                                            part = content.oneOfDomainContent.toeicSpeakingPartContent;
                                            if (!part.audioResourceId) return [
                                                3,
                                                2
                                            ];
                                            return [
                                                4,
                                                getAudioResourceUrlFromId(part.audioResourceId)
                                            ];
                                        case 1:
                                            _tmp = _state.sent();
                                            return [
                                                3,
                                                3
                                            ];
                                        case 2:
                                            _tmp = undefined;
                                            _state.label = 3;
                                        case 3:
                                            audioResourceUrl = _tmp;
                                            return [
                                                4,
                                                Promise.all(part.questions.map(function() {
                                                    var _ref = _async_to_generator(function(question) {
                                                        var audioResourceUrl, _tmp, exampleAnswers;
                                                        return _ts_generator(this, function(_state) {
                                                            switch(_state.label){
                                                                case 0:
                                                                    if (!question.audioResourceId) return [
                                                                        3,
                                                                        2
                                                                    ];
                                                                    return [
                                                                        4,
                                                                        getAudioResourceUrlFromId(question.audioResourceId)
                                                                    ];
                                                                case 1:
                                                                    _tmp = _state.sent();
                                                                    return [
                                                                        3,
                                                                        3
                                                                    ];
                                                                case 2:
                                                                    _tmp = undefined;
                                                                    _state.label = 3;
                                                                case 3:
                                                                    audioResourceUrl = _tmp;
                                                                    return [
                                                                        4,
                                                                        Promise.all(question.exampleAnswers.map(function() {
                                                                            var _ref = _async_to_generator(function(exampleAnswer) {
                                                                                var audioResourceUrl, _tmp;
                                                                                return _ts_generator(this, function(_state) {
                                                                                    switch(_state.label){
                                                                                        case 0:
                                                                                            if (!exampleAnswer.audioResourceId) return [
                                                                                                3,
                                                                                                2
                                                                                            ];
                                                                                            return [
                                                                                                4,
                                                                                                getAudioResourceUrlFromId(exampleAnswer.audioResourceId)
                                                                                            ];
                                                                                        case 1:
                                                                                            _tmp = _state.sent();
                                                                                            return [
                                                                                                3,
                                                                                                3
                                                                                            ];
                                                                                        case 2:
                                                                                            _tmp = undefined;
                                                                                            _state.label = 3;
                                                                                        case 3:
                                                                                            audioResourceUrl = _tmp;
                                                                                            return [
                                                                                                2,
                                                                                                _object_spread_props(_object_spread({}, exampleAnswer), {
                                                                                                    audioResourceUrl: audioResourceUrl
                                                                                                })
                                                                                            ];
                                                                                    }
                                                                                });
                                                                            });
                                                                            return function(exampleAnswer) {
                                                                                return _ref.apply(this, arguments);
                                                                            };
                                                                        }()))
                                                                    ];
                                                                case 4:
                                                                    exampleAnswers = _state.sent();
                                                                    return [
                                                                        2,
                                                                        _object_spread_props(_object_spread({}, question), {
                                                                            exampleAnswers: exampleAnswers,
                                                                            audioResourceUrl: audioResourceUrl
                                                                        })
                                                                    ];
                                                            }
                                                        });
                                                    });
                                                    return function(question) {
                                                        return _ref.apply(this, arguments);
                                                    };
                                                }()))
                                            ];
                                        case 4:
                                            questions = _state.sent();
                                            return [
                                                2,
                                                _object_spread_props(_object_spread({}, part), {
                                                    id: id,
                                                    questions: questions,
                                                    audioResourceUrl: audioResourceUrl
                                                })
                                            ];
                                    }
                                });
                            });
                            return function(content) {
                                return _ref.apply(this, arguments);
                            };
                        }()))
                    ];
                case 2:
                    partContents = _state.sent();
                    return [
                        2,
                        partContents
                    ];
            }
        });
    });
    return function getToeicSpeakingPartContentsFromUrls(_) {
        return _ref.apply(this, arguments);
    };
}();
