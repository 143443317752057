/**
 * Check if a given object implements the UserConfig interface.
 */
export function instanceOfUserConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "json" in value;
    return isInstance;
}
export function UserConfigFromJSON(json) {
    return UserConfigFromJSONTyped(json, false);
}
export function UserConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'json': json['json'],
    };
}
export function UserConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'json': value.json,
    };
}
