// @ts-nocheck
import { name2num, num2name, } from "./(Vocabulary)/PoS";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./VocaExample";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./VoiceResourceIdsByAccent";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./VocaPoS";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as Long, } from "../../runtime/Long";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "",
        word: "",
        voiceResourceId: "",
        pos: "NOUN",
        meaning: "",
        tagIds: [],
        examples: [],
        voiceResourceIdsByAccent: undefined,
        relatedVocabularies: [],
        vocaPosSet: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.string(value.id);
    if (value.word !== undefined)
        result.word = tsValueToJsonValueFns.string(value.word);
    if (value.voiceResourceId !== undefined)
        result.voiceResourceId = tsValueToJsonValueFns.string(value.voiceResourceId);
    if (value.pos !== undefined)
        result.pos = tsValueToJsonValueFns.enum(value.pos);
    if (value.meaning !== undefined)
        result.meaning = tsValueToJsonValueFns.string(value.meaning);
    result.tagIds = value.tagIds.map(value => tsValueToJsonValueFns.string(value));
    result.examples = value.examples.map(value => encodeJson_1(value));
    if (value.voiceResourceIdsByAccent !== undefined)
        result.voiceResourceIdsByAccent = encodeJson_2(value.voiceResourceIdsByAccent);
    result.relatedVocabularies = value.relatedVocabularies.map(value => tsValueToJsonValueFns.string(value));
    result.vocaPosSet = value.vocaPosSet.map(value => encodeJson_3(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.string(value.id);
    if (value.word !== undefined)
        result.word = jsonValueToTsValueFns.string(value.word);
    if (value.voiceResourceId !== undefined)
        result.voiceResourceId = jsonValueToTsValueFns.string(value.voiceResourceId);
    if (value.pos !== undefined)
        result.pos = jsonValueToTsValueFns.enum(value.pos);
    if (value.meaning !== undefined)
        result.meaning = jsonValueToTsValueFns.string(value.meaning);
    result.tagIds = (_b = (_a = value.tagIds) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.string(value))) !== null && _b !== void 0 ? _b : [];
    result.examples = (_d = (_c = value.examples) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_1(value))) !== null && _d !== void 0 ? _d : [];
    if (value.voiceResourceIdsByAccent !== undefined)
        result.voiceResourceIdsByAccent = decodeJson_2(value.voiceResourceIdsByAccent);
    result.relatedVocabularies = (_f = (_e = value.relatedVocabularies) === null || _e === void 0 ? void 0 : _e.map((value) => jsonValueToTsValueFns.string(value))) !== null && _f !== void 0 ? _f : [];
    result.vocaPosSet = (_h = (_g = value.vocaPosSet) === null || _g === void 0 ? void 0 : _g.map((value) => decodeJson_3(value))) !== null && _h !== void 0 ? _h : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.word !== undefined) {
        const tsValue = value.word;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.voiceResourceId !== undefined) {
        const tsValue = value.voiceResourceId;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.pos !== undefined) {
        const tsValue = value.pos;
        result.push([4, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.meaning !== undefined) {
        const tsValue = value.meaning;
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.tagIds) {
        result.push([6, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.examples) {
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.voiceResourceIdsByAccent !== undefined) {
        const tsValue = value.voiceResourceIdsByAccent;
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    for (const tsValue of value.relatedVocabularies) {
        result.push([9, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.vocaPosSet) {
        result.push([10, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.word = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.voiceResourceId = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.pos = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.meaning = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 6).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValueToTsValueFns.string(wireValue)).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.tagIds = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 7).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.examples = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.voiceResourceIdsByAccent = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 9).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValueToTsValueFns.string(wireValue)).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.relatedVocabularies = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 10).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.vocaPosSet = value;
    }
    return result;
}
