/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetPurchaseableTicketCountResponseFromJSON, ListTicketsResponseFromJSON, TicketGroupSourceFromJSON, } from '../models';
/**
 *
 */
export class TicketV1Api extends runtime.BaseAPI {
    /**
     * 구매 가능한 응시권의 개수를 가져옵니다.
     */
    async getPurchaseableTicketCountRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/ticket/v1/availability`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetPurchaseableTicketCountResponseFromJSON(jsonValue));
    }
    /**
     * 구매 가능한 응시권의 개수를 가져옵니다.
     */
    async getPurchaseableTicketCount(initOverrides) {
        const response = await this.getPurchaseableTicketCountRaw(initOverrides);
        return await response.value();
    }
    /**
     * TicketGroupSource를 조회합니다.
     */
    async listTicketGroupSourcesRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/ticket/v1/group`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TicketGroupSourceFromJSON));
    }
    /**
     * TicketGroupSource를 조회합니다.
     */
    async listTicketGroupSources(initOverrides) {
        const response = await this.listTicketGroupSourcesRaw(initOverrides);
        return await response.value();
    }
    /**
     * 사용자의 티켓들을 조회합니다.
     */
    async listTicketsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listTickets.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/ticket/v1/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListTicketsResponseFromJSON(jsonValue));
    }
    /**
     * 사용자의 티켓들을 조회합니다.
     */
    async listTickets(requestParameters, initOverrides) {
        const response = await this.listTicketsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
