/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetSkillAnalyticsResponseFromJSON, } from '../models';
/**
 *
 */
export class SkillAnalyticsV1Api extends runtime.BaseAPI {
    /**
     * 최신 Estimation 정보를 토대로 다음과 같은 정보를 반환한다.  1. 예측점수 1. 스킬데이터 1. 유형 태그 별 실력 1. LC 억양 태그 별 실력 (Optional) 1. 유형 별 RC 풀이시간 1. 취약한 개념
     * 실력 분석 정보를 조회한다.
     */
    async getSkillAnalyticsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/analytics/v1/skill-analytics/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetSkillAnalyticsResponseFromJSON(jsonValue));
    }
    /**
     * 최신 Estimation 정보를 토대로 다음과 같은 정보를 반환한다.  1. 예측점수 1. 스킬데이터 1. 유형 태그 별 실력 1. LC 억양 태그 별 실력 (Optional) 1. 유형 별 RC 풀이시간 1. 취약한 개념
     * 실력 분석 정보를 조회한다.
     */
    async getSkillAnalytics(requestParameters, initOverrides) {
        const response = await this.getSkillAnalyticsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
