import React, {Suspense} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useMount} from 'react-use';
import * as Sentry from '@sentry/nextjs';

import {startBrowserServiceServer} from '@santa-web/gen/ssp/server';
import {SantaLearningDomainIdError, SantaToeicLearningDomainNotFoundError} from '@app/atoms/core/learning-domain';
import {SantaUnderSystemCheckError} from '@app/atoms/core/santa-unauthorized-openapi-services';
import SystemCheckView from '@app/components/core/SystemCheckView';
import {SantaBootFailureError} from '@app/utils/error';

/**
 * @description 산타에서 일어나는 비동기적 상태 초기화를 수행하는 컴포넌트. 부팅 로직에 대한 [설명 참조](https://www.notion.so/riiid/Santa-web-application-b423b02415364442b9b5387213a13634?pvs=4)
 */
const Boot: React.FC<{children: React.ReactNode}> = ({children}) => {
  useMount(() => {
    startBrowserServiceServer();
  });

  return (
    <ErrorBoundary
      fallbackRender={({error}) => {
        if (error instanceof SantaBootFailureError) {
          if (error instanceof SantaLearningDomainIdError) {
            Sentry.captureException(error);
          } else if (error instanceof SantaToeicLearningDomainNotFoundError) {
            Sentry.captureException(error);
          } else if (error instanceof SantaUnderSystemCheckError) {
            return <SystemCheckView reference={error.reference} />;
          }
        }
        throw error;
      }}>
      <Suspense fallback={null}>{children}</Suspense>
    </ErrorBoundary>
  );
};

Boot.displayName = 'Boot';

export default React.memo(Boot);
