export const num2name = {
    0: "THUMBNAIL_CONTENT_IMAGE_ALIGNMENT_UNSPECIFIED",
    1: "THUMBNAIL_CONTENT_IMAGE_ALIGNMENT_LEFT",
    2: "THUMBNAIL_CONTENT_IMAGE_ALIGNMENT_CENTER",
};
export const name2num = {
    THUMBNAIL_CONTENT_IMAGE_ALIGNMENT_UNSPECIFIED: 0,
    THUMBNAIL_CONTENT_IMAGE_ALIGNMENT_LEFT: 1,
    THUMBNAIL_CONTENT_IMAGE_ALIGNMENT_CENTER: 2,
};
