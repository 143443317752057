/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetLearningCellReportResponseFromJSON, } from '../models';
/**
 *
 */
export class LearningCellReportV1Api extends runtime.BaseAPI {
    /**
     * 대상 학습 셀에 대한 리포트를 조회한다.
     * 학습 셀 리포트를 조회한다.
     */
    async getLearningCellReportRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling getLearningCellReport.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/analytics/v1/learning-cell-report/{learningCellId}`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetLearningCellReportResponseFromJSON(jsonValue));
    }
    /**
     * 대상 학습 셀에 대한 리포트를 조회한다.
     * 학습 셀 리포트를 조회한다.
     */
    async getLearningCellReport(requestParameters, initOverrides) {
        const response = await this.getLearningCellReportRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
