// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(SendIamportPaymentResultRequest)/Error";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        result: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    switch ((_a = value.result) === null || _a === void 0 ? void 0 : _a.field) {
        case "payload": {
            result.payload = tsValueToJsonValueFns.string(value.result.value);
            break;
        }
        case "error": {
            result.error = encodeJson_1(value.result.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.payload !== undefined)
        result.result = { field: "payload", value: jsonValueToTsValueFns.string(value.payload) };
    if (value.error !== undefined)
        result.result = { field: "error", value: decodeJson_1(value.error) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    switch ((_a = value.result) === null || _a === void 0 ? void 0 : _a.field) {
        case "payload": {
            const tsValue = value.result.value;
            result.push([1, tsValueToWireValueFns.string(tsValue)]);
            break;
        }
        case "error": {
            const tsValue = value.result.value;
            result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    result: new Set([1, 2]),
};
const oneofFieldNamesMap = {
    result: new Map([
        [1, "payload"],
        [2, "error"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.result;
        const oneofFieldNames = oneofFieldNamesMap.result;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [1](wireValue) { return wireValueToTsValueFns.string(wireValue); },
            [2](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.result = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
