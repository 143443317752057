import { TimerBgColorFromJSON, TimerBgColorToJSON, } from './TimerBgColor';
/**
 * Check if a given object implements the TimerConfig interface.
 */
export function instanceOfTimerConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "bgColor" in value;
    isInstance = isInstance && "endMessage" in value;
    isInstance = isInstance && "startsAt" in value;
    return isInstance;
}
export function TimerConfigFromJSON(json) {
    return TimerConfigFromJSONTyped(json, false);
}
export function TimerConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bgColor': TimerBgColorFromJSON(json['bgColor']),
        'endMessage': json['endMessage'],
        'startsAt': (new Date(json['startsAt'])),
    };
}
export function TimerConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bgColor': TimerBgColorToJSON(value.bgColor),
        'endMessage': value.endMessage,
        'startsAt': (value.startsAt.toISOString()),
    };
}
