/**
 * Check if a given object implements the CreateAnonymousUserRequest interface.
 */
export function instanceOfCreateAnonymousUserRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "locale" in value;
    return isInstance;
}
export function CreateAnonymousUserRequestFromJSON(json) {
    return CreateAnonymousUserRequestFromJSONTyped(json, false);
}
export function CreateAnonymousUserRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'locale': json['locale'],
    };
}
export function CreateAnonymousUserRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'locale': value.locale,
    };
}
