import { LearningCellStateFromJSON, LearningCellStateToJSON, } from './LearningCellState';
/**
 * Check if a given object implements the UpdateLearningCellRequest interface.
 */
export function instanceOfUpdateLearningCellRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "state" in value;
    return isInstance;
}
export function UpdateLearningCellRequestFromJSON(json) {
    return UpdateLearningCellRequestFromJSONTyped(json, false);
}
export function UpdateLearningCellRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'state': LearningCellStateFromJSON(json['state']),
    };
}
export function UpdateLearningCellRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'state': LearningCellStateToJSON(value.state),
    };
}
