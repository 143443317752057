export const num2name = {
    0: "TYPE_UNSPECIFIED",
    1: "TYPE_RATE",
    2: "TYPE_AMOUNT",
};
export const name2num = {
    TYPE_UNSPECIFIED: 0,
    TYPE_RATE: 1,
    TYPE_AMOUNT: 2,
};
