/* tslint:disable */
/* eslint-disable */
/**
 * [ISO 언어 코드 참조](https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes)
 * @export
 */
export const Language = {
    EN: 'EN',
    KO: 'KO',
    JA: 'JA',
    ZH: 'ZH',
    TH: 'TH',
    VI: 'VI'
};
export function LanguageFromJSON(json) {
    return LanguageFromJSONTyped(json, false);
}
export function LanguageFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LanguageToJSON(value) {
    return value;
}
