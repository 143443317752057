export var ToeicSpeakingGrade;
(function(ToeicSpeakingGrade) {
    ToeicSpeakingGrade["NL"] = "NL";
    ToeicSpeakingGrade["NM"] = "NM";
    ToeicSpeakingGrade["NH"] = "NH";
    ToeicSpeakingGrade["IL"] = "IL";
    ToeicSpeakingGrade["IM1"] = "IM1";
    ToeicSpeakingGrade["IM2"] = "IM2";
    ToeicSpeakingGrade["IM3"] = "IM3";
    ToeicSpeakingGrade["IH"] = "IH";
    ToeicSpeakingGrade["AL"] = "AL";
    ToeicSpeakingGrade["AM"] = "AM";
    ToeicSpeakingGrade["AH"] = "AH";
})(ToeicSpeakingGrade || (ToeicSpeakingGrade = {}));
export var allToeicSpeakingGrades = [
    "NL",
    "NM",
    "NH",
    "IL",
    "IM1",
    "IM2",
    "IM3",
    "IH",
    "AL",
    "AM",
    "AH"
];
