/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { TicketProductBoardFromJSON, } from '../models';
/**
 *
 */
export class TicketProductBoardV1Api extends runtime.BaseAPI {
    /**
     * 티켓상품보드를 조회합니다. boardCode는 기본적으로 유저의 언어 코드를 uppercase로 보내는 것을 원칙으로 합니다. (ex. KO, JA)
     */
    async getTicketProductBoardRaw(requestParameters, initOverrides) {
        if (requestParameters.boardCode === null || requestParameters.boardCode === undefined) {
            throw new runtime.RequiredError('boardCode', 'Required parameter requestParameters.boardCode was null or undefined when calling getTicketProductBoard.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/ticketProductBoard/v1/{boardCode}`.replace(`{${"boardCode"}}`, encodeURIComponent(String(requestParameters.boardCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketProductBoardFromJSON(jsonValue));
    }
    /**
     * 티켓상품보드를 조회합니다. boardCode는 기본적으로 유저의 언어 코드를 uppercase로 보내는 것을 원칙으로 합니다. (ex. KO, JA)
     */
    async getTicketProductBoard(requestParameters, initOverrides) {
        const response = await this.getTicketProductBoardRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
