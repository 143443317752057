/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ElementInteractionStateFromJSON, ElementInteractionStateToJSON, } from './ElementInteractionState';
import { LanguageFromJSON, LanguageToJSON, } from './Language';
import { LearningDomainKeyFromJSON, LearningDomainKeyToJSON, } from './LearningDomainKey';
/**
 * Check if a given object implements the ContentInteractionState interface.
 */
export function instanceOfContentInteractionState(value) {
    let isInstance = true;
    isInstance = isInstance && "isBookmarked" in value;
    isInstance = isInstance && "contentId" in value;
    isInstance = isInstance && "learningCellId" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "learningDomainKey" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "elementInteractionStates" in value;
    return isInstance;
}
export function ContentInteractionStateFromJSON(json) {
    return ContentInteractionStateFromJSONTyped(json, false);
}
export function ContentInteractionStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isBookmarked': json['isBookmarked'],
        'contentId': json['contentId'],
        'learningCellId': json['learningCellId'],
        'language': LanguageFromJSON(json['language']),
        'startedAtServer': !exists(json, 'startedAtServer') ? undefined : (new Date(json['startedAtServer'])),
        'id': json['id'],
        'completedAtServer': !exists(json, 'completedAtServer') ? undefined : (new Date(json['completedAtServer'])),
        'learningDomainKey': LearningDomainKeyFromJSON(json['learningDomainKey']),
        'userId': json['userId'],
        'elementInteractionStates': (json['elementInteractionStates'].map(ElementInteractionStateFromJSON)),
    };
}
export function ContentInteractionStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isBookmarked': value.isBookmarked,
        'contentId': value.contentId,
        'learningCellId': value.learningCellId,
        'language': LanguageToJSON(value.language),
        'startedAtServer': value.startedAtServer === undefined ? undefined : (value.startedAtServer.toISOString()),
        'id': value.id,
        'completedAtServer': value.completedAtServer === undefined ? undefined : (value.completedAtServer.toISOString()),
        'learningDomainKey': LearningDomainKeyToJSON(value.learningDomainKey),
        'userId': value.userId,
        'elementInteractionStates': (value.elementInteractionStates.map(ElementInteractionStateToJSON)),
    };
}
