/**
 * Check if a given object implements the Period interface.
 */
export function instanceOfPeriod(value) {
    let isInstance = true;
    isInstance = isInstance && "startInclusive" in value;
    isInstance = isInstance && "endExclusive" in value;
    return isInstance;
}
export function PeriodFromJSON(json) {
    return PeriodFromJSONTyped(json, false);
}
export function PeriodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'startInclusive': (new Date(json['startInclusive'])),
        'endExclusive': (new Date(json['endExclusive'])),
    };
}
export function PeriodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'startInclusive': (value.startInclusive.toISOString()),
        'endExclusive': (value.endExclusive.toISOString()),
    };
}
