/**
 * Check if a given object implements the LineRegistrationInfo interface.
 */
export function instanceOfLineRegistrationInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "lineIdToken" in value;
    return isInstance;
}
export function LineRegistrationInfoFromJSON(json) {
    return LineRegistrationInfoFromJSONTyped(json, false);
}
export function LineRegistrationInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lineIdToken': json['lineIdToken'],
    };
}
export function LineRegistrationInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lineIdToken': value.lineIdToken,
    };
}
