import { UserConfigFromJSON, UserConfigToJSON, } from './UserConfig';
/**
 * Check if a given object implements the UpdateMyUserConfigResponse interface.
 */
export function instanceOfUpdateMyUserConfigResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "userConfig" in value;
    return isInstance;
}
export function UpdateMyUserConfigResponseFromJSON(json) {
    return UpdateMyUserConfigResponseFromJSONTyped(json, false);
}
export function UpdateMyUserConfigResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userConfig': UserConfigFromJSON(json['userConfig']),
    };
}
export function UpdateMyUserConfigResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userConfig': UserConfigToJSON(value.userConfig),
    };
}
