/* tslint:disable */
/* eslint-disable */
/**
 * - **ASSISTANT**: 챗봇의 메시지
 * - **USER**: 유저의 메시지
 * @export
 */
export const ChatMessageRole = {
    ASSISTANT: 'ASSISTANT',
    USER: 'USER'
};
export function ChatMessageRoleFromJSON(json) {
    return ChatMessageRoleFromJSONTyped(json, false);
}
export function ChatMessageRoleFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ChatMessageRoleToJSON(value) {
    return value;
}
