// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(AuthorityConfig)/ExtraDownloadable";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(AuthorityConfig)/ExtraGiftCode";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./(AuthorityConfig)/ExtraDeliverable";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "./(AuthorityConfig)/ExtraSelfLearning";
import { encodeJson as encodeJson_5, decodeJson as decodeJson_5, encodeBinary as encodeBinary_6, decodeBinary as decodeBinary_6, } from "./(AuthorityConfig)/ExtraSelfLearningHierarchy";
import { name2num, num2name, } from "./AuthorityType";
import { name2num as name2num_1, num2name as num2name_1, } from "./AuthorityLimit";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Int64Value";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        description: "",
        type: "AUTHORITY_TYPE_UNSPECIFIED",
        limit: "AUTHORITY_LIMIT_UNSPECIFIED",
        limitCount: undefined,
        extra: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.description !== undefined)
        result.description = tsValueToJsonValueFns.string(value.description);
    if (value.type !== undefined)
        result.type = tsValueToJsonValueFns.enum(value.type);
    if (value.limit !== undefined)
        result.limit = tsValueToJsonValueFns.enum(value.limit);
    if (value.limitCount !== undefined)
        result.limitCount = value.limitCount;
    switch ((_a = value.extra) === null || _a === void 0 ? void 0 : _a.field) {
        case "downloadable": {
            result.downloadable = encodeJson_1(value.extra.value);
            break;
        }
        case "giftCode": {
            result.giftCode = encodeJson_2(value.extra.value);
            break;
        }
        case "deliverable": {
            result.deliverable = encodeJson_3(value.extra.value);
            break;
        }
        case "selfLearning": {
            result.selfLearning = encodeJson_4(value.extra.value);
            break;
        }
        case "selfLearningHierarchy": {
            result.selfLearningHierarchy = encodeJson_5(value.extra.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.description !== undefined)
        result.description = jsonValueToTsValueFns.string(value.description);
    if (value.type !== undefined)
        result.type = jsonValueToTsValueFns.enum(value.type);
    if (value.limit !== undefined)
        result.limit = jsonValueToTsValueFns.enum(value.limit);
    if (value.limitCount !== undefined)
        result.limitCount = value.limitCount;
    if (value.downloadable !== undefined)
        result.extra = { field: "downloadable", value: decodeJson_1(value.downloadable) };
    if (value.giftCode !== undefined)
        result.extra = { field: "giftCode", value: decodeJson_2(value.giftCode) };
    if (value.deliverable !== undefined)
        result.extra = { field: "deliverable", value: decodeJson_3(value.deliverable) };
    if (value.selfLearning !== undefined)
        result.extra = { field: "selfLearning", value: decodeJson_4(value.selfLearning) };
    if (value.selfLearningHierarchy !== undefined)
        result.extra = { field: "selfLearningHierarchy", value: decodeJson_5(value.selfLearningHierarchy) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.description !== undefined) {
        const tsValue = value.description;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.type !== undefined) {
        const tsValue = value.type;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.limit !== undefined) {
        const tsValue = value.limit;
        result.push([4, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.limitCount !== undefined) {
        const tsValue = value.limitCount;
        result.push([5, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    switch ((_a = value.extra) === null || _a === void 0 ? void 0 : _a.field) {
        case "downloadable": {
            const tsValue = value.extra.value;
            result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
        case "giftCode": {
            const tsValue = value.extra.value;
            result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
            break;
        }
        case "deliverable": {
            const tsValue = value.extra.value;
            result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
            break;
        }
        case "selfLearning": {
            const tsValue = value.extra.value;
            result.push([12, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
            break;
        }
        case "selfLearningHierarchy": {
            const tsValue = value.extra.value;
            result.push([13, { type: WireType.LengthDelimited, value: encodeBinary_6(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    extra: new Set([6, 7, 8, 12, 13]),
};
const oneofFieldNamesMap = {
    extra: new Map([
        [6, "downloadable"],
        [7, "giftCode"],
        [8, "deliverable"],
        [12, "selfLearning"],
        [13, "selfLearningHierarchy"],
    ]),
};
export function decodeBinary(binary) {
    var _a, _b;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.description = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.type = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.limit = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.limitCount = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.extra;
        const oneofFieldNames = oneofFieldNamesMap.extra;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [6](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
            [7](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined; },
            [8](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined; },
            [12](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined; },
            [13](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_6(wireValue.value) : undefined; },
        };
        const value = (_b = wireValueToTsValueMap[fieldNumber]) === null || _b === void 0 ? void 0 : _b(wireValue);
        if (value === undefined)
            break oneof;
        result.extra = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
