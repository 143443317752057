export const num2name = {
    0: "LESSON_V2_UNSPECIFIED",
    1: "LESSON_V2_STARTER_INTRO",
    2: "LESSON_V2_STARTER_GRAMMAR",
    3: "LESSON_V2_BASIC_LC",
    4: "LESSON_V2_BASIC_RC",
    5: "LESSON_V2_CONCEPT_LC",
    6: "LESSON_V2_CONCEPT_RC",
    7: "LESSON_V2_ADVANCED",
    8: "LESSON_V2_SOLVING_QUESTION_P1",
    9: "LESSON_V2_SOLVING_QUESTION_P2",
    10: "LESSON_V2_SOLVING_QUESTION_P3_P4",
    11: "LESSON_V2_SOLVING_QUESTION_P5_P6",
    12: "LESSON_V2_SOLVING_QUESTION_P7",
};
export const name2num = {
    LESSON_V2_UNSPECIFIED: 0,
    LESSON_V2_STARTER_INTRO: 1,
    LESSON_V2_STARTER_GRAMMAR: 2,
    LESSON_V2_BASIC_LC: 3,
    LESSON_V2_BASIC_RC: 4,
    LESSON_V2_CONCEPT_LC: 5,
    LESSON_V2_CONCEPT_RC: 6,
    LESSON_V2_ADVANCED: 7,
    LESSON_V2_SOLVING_QUESTION_P1: 8,
    LESSON_V2_SOLVING_QUESTION_P2: 9,
    LESSON_V2_SOLVING_QUESTION_P3_P4: 10,
    LESSON_V2_SOLVING_QUESTION_P5_P6: 11,
    LESSON_V2_SOLVING_QUESTION_P7: 12,
};
