import {atom, useAtom} from 'jotai';

import {createMobileServiceClient} from '@santa-web/gen/ssp/client';
import {registerServiceMethodImpl} from '@santa-web/gen/ssp/server';
import {MethodImplMap} from '@santa-web/gen/ssp/server/ServiceMethod';
import {
  createServiceClient as createCommonMobileService,
  methodDescriptors as CommonMobileServiceMethods,
} from '@santa-web/gen/ssp/services/inside/app/CommonMobileService';
import {
  createServiceClient as createToeicMobileService,
  methodDescriptors as ToeicMobileServiceMethods,
} from '@santa-web/gen/ssp/services/toeic/mobile/ToeicMobileService';
import config from '@app/config';

const mobileServiceClient = createMobileServiceClient();

const mobileServiceConfig = {
  devtools: config.env !== 'production' || config.enableProdDevtools === 'true' || undefined,
};
const CommonMobileService = createCommonMobileService(mobileServiceClient, mobileServiceConfig);
const ToeicMobileService = createToeicMobileService(mobileServiceClient, mobileServiceConfig);
export type MobileServices = ReturnType<typeof createMobileServices>;

export const createMobileServices = () => {
  return {
    ToeicMobileService,
    CommonMobileService,
  };
};

/**
 * FIXME: webview 환경이 아닐 시 Error를 던지게 하여 PC Web 환경에서 생성조차 되지 않도록 하기
 */
export const mobileServicesAtom = atom(createMobileServices());

export const useMobileServices = (): MobileServices => {
  const mobileServices = useAtom(mobileServicesAtom)[0];
  return mobileServices;
};

export const registerCommonMobileService = (methodImplMap: MethodImplMap<typeof CommonMobileServiceMethods>) => {
  registerServiceMethodImpl(CommonMobileServiceMethods, methodImplMap);
};

export const registerToeicMobileService = (methodImplMap: MethodImplMap<typeof ToeicMobileServiceMethods>) => {
  registerServiceMethodImpl(ToeicMobileServiceMethods, methodImplMap);
};

mobileServicesAtom.debugPrivate = true;
