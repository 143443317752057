/**
 * Check if a given object implements the LearningCycleCellProgressInfo interface.
 */
export function instanceOfLearningCycleCellProgressInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "progressed" in value;
    isInstance = isInstance && "total" in value;
    return isInstance;
}
export function LearningCycleCellProgressInfoFromJSON(json) {
    return LearningCycleCellProgressInfoFromJSONTyped(json, false);
}
export function LearningCycleCellProgressInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'progressed': json['progressed'],
        'total': json['total'],
    };
}
export function LearningCycleCellProgressInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'progressed': value.progressed,
        'total': value.total,
    };
}
