import { LineRegistrationInfoFromJSON, LineRegistrationInfoToJSON, } from './LineRegistrationInfo';
/**
 * Check if a given object implements the LoginWithLineRequest interface.
 */
export function instanceOfLoginWithLineRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "lineRegistrationInfo" in value;
    return isInstance;
}
export function LoginWithLineRequestFromJSON(json) {
    return LoginWithLineRequestFromJSONTyped(json, false);
}
export function LoginWithLineRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lineRegistrationInfo': LineRegistrationInfoFromJSON(json['lineRegistrationInfo']),
    };
}
export function LoginWithLineRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lineRegistrationInfo': LineRegistrationInfoToJSON(value.lineRegistrationInfo),
    };
}
