import React from 'react';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/nextjs';
import {useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {tokenAtom} from '@app/atoms/core/token-manager';
import {getLearningDomainListQueryKey} from '@app/queryKeys';

/**
 * @description token 변화를 감지하여 Sentry 유저 설정, query removal, visible learning domain 재요청을 진행하는 컴포넌트
 */
const TokenSubscriber = ({children}: {children?: React.ReactNode}) => {
  /**
   * 토큰의 변화만 감지하기 위해 사용하는 ref
   */
  const initialRef = React.useRef(true);
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        userId: token.authId,
      },
    });
    Sentry.setUser({
      id: token.authId,
    });
    if (initialRef.current) {
      initialRef.current = false;
      return;
    }
    /**
     * user에 종속되는 상태를 모두 제거하기 위함.
     */
    queryClient.removeQueries();
    /**
     * 사용자가 바뀌었으므로 그에 맞게 visible learning domain을 다시 불러온다.
     */
    queryClient.refetchQueries(getLearningDomainListQueryKey());
  }, [token.authId, queryClient]);
  return <>{children}</>;
};

/**
 * @description token-manager에서 setToken으로 인해 변경되는 token에 대해 Sentry 유저 설정, query invalidate 등을 진행하는 컴포넌트
 */
export default React.memo(TokenSubscriber);
