/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the LearningInformation interface.
 */
export function instanceOfLearningInformation(value) {
    let isInstance = true;
    isInstance = isInstance && "totalVocabularyCount" in value;
    isInstance = isInstance && "totalQuestionCount" in value;
    return isInstance;
}
export function LearningInformationFromJSON(json) {
    return LearningInformationFromJSONTyped(json, false);
}
export function LearningInformationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalLessonCount': !exists(json, 'totalLessonCount') ? undefined : json['totalLessonCount'],
        'totalVocabularyCount': json['totalVocabularyCount'],
        'totalQuestionCount': json['totalQuestionCount'],
    };
}
export function LearningInformationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totalLessonCount': value.totalLessonCount,
        'totalVocabularyCount': value.totalVocabularyCount,
        'totalQuestionCount': value.totalQuestionCount,
    };
}
