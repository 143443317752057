/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExamFromJSON, ExamToJSON, } from './Exam';
/**
 * Check if a given object implements the GetExamResponse interface.
 */
export function instanceOfGetExamResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "exam" in value;
    return isInstance;
}
export function GetExamResponseFromJSON(json) {
    return GetExamResponseFromJSONTyped(json, false);
}
export function GetExamResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'exam': ExamFromJSON(json['exam']),
    };
}
export function GetExamResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'exam': ExamToJSON(value.exam),
    };
}
