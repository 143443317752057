import { LearningCellConfigFromJSON, LearningCellConfigToJSON, } from './LearningCellConfig';
import { LearningCellExtraInfoFromJSON, LearningCellExtraInfoToJSON, } from './LearningCellExtraInfo';
import { LearningCellProvidedContextFromJSON, LearningCellProvidedContextToJSON, } from './LearningCellProvidedContext';
import { LearningCellStateFromJSON, LearningCellStateToJSON, } from './LearningCellState';
import { LearningDomainKeyFromJSON, LearningDomainKeyToJSON, } from './LearningDomainKey';
/**
 * Check if a given object implements the LearningCell interface.
 */
export function instanceOfLearningCell(value) {
    let isInstance = true;
    isInstance = isInstance && "providedContext" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "config" in value;
    isInstance = isInstance && "learningDomainKey" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "extraInfo" in value;
    isInstance = isInstance && "tags" in value;
    return isInstance;
}
export function LearningCellFromJSON(json) {
    return LearningCellFromJSONTyped(json, false);
}
export function LearningCellFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'providedContext': LearningCellProvidedContextFromJSON(json['providedContext']),
        'id': json['id'],
        'state': LearningCellStateFromJSON(json['state']),
        'config': LearningCellConfigFromJSON(json['config']),
        'learningDomainKey': LearningDomainKeyFromJSON(json['learningDomainKey']),
        'userId': json['userId'],
        'extraInfo': LearningCellExtraInfoFromJSON(json['extraInfo']),
        'tags': json['tags'],
    };
}
export function LearningCellToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'providedContext': LearningCellProvidedContextToJSON(value.providedContext),
        'id': value.id,
        'state': LearningCellStateToJSON(value.state),
        'config': LearningCellConfigToJSON(value.config),
        'learningDomainKey': LearningDomainKeyToJSON(value.learningDomainKey),
        'userId': value.userId,
        'extraInfo': LearningCellExtraInfoToJSON(value.extraInfo),
        'tags': value.tags,
    };
}
