/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the LearningCellConfigQuestionStream interface.
 */
export function instanceOfLearningCellConfigQuestionStream(value) {
    let isInstance = true;
    isInstance = isInstance && "isExam" in value;
    return isInstance;
}
export function LearningCellConfigQuestionStreamFromJSON(json) {
    return LearningCellConfigQuestionStreamFromJSONTyped(json, false);
}
export function LearningCellConfigQuestionStreamFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isExam': json['isExam'],
        'requiredCountToCompleteLearningCell': !exists(json, 'requiredCountToCompleteLearningCell') ? undefined : json['requiredCountToCompleteLearningCell'],
    };
}
export function LearningCellConfigQuestionStreamToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isExam': value.isExam,
        'requiredCountToCompleteLearningCell': value.requiredCountToCompleteLearningCell,
    };
}
