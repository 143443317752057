import produce from 'immer';

import {atomWithDefault} from 'jotai/utils';
import {Configuration, ConfigurationParameters, UserToken} from '@santa-web/gen/open-api/service';
import {OpenAPIServices, createOpenAPIServices} from '@app/api/openapi';

import {santaUnauthorizedOpenapiServicesConfParamsAtom} from './santa-unauthorized-openapi-services';
import tokenManagerAtom from './token-manager';

/**
 * @description santaUnauthorizedOpenapiServicesConfigurationProperties에 더해
 * TokenManager를 이용하여 403 에러가 발생하면 토큰을 갱신하고 재시도하는 로직이 포함됨.
 */
export const santaAuthorizedOpenapiServicesConfParamsAtom = atomWithDefault<Promise<ConfigurationParameters>>(
  async get => {
    const tokenManager = await get(tokenManagerAtom);
    const santaUnauthorizedOpenapiServicesConfParams = get(santaUnauthorizedOpenapiServicesConfParamsAtom);
    return produce(santaUnauthorizedOpenapiServicesConfParams, draft => {
      draft.fetchApi = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
        const retryCount = 1;
        let response;
        let token: UserToken = await tokenManager.getToken();
        for (let i = 0; i <= retryCount; i += 1) {
          response = await fetch(input, {
            ...init,
            headers: {
              Authorization: `Bearer ${token.accessToken}`,
              ...init?.headers,
            },
          });
          if (response.status === 401) {
            token = await tokenManager.refreshToken();
          } else {
            break;
          }
        }
        return response as Response;
      };
    });
  }
);

/**
 * @description TokenManager를 이용하여 Authroization header는 주입해 주나 riiid-learning-domain 헤더는 주입해주지 않는 OpenApi service 호출 객체
 */
const santaAuthorizedOpenapiServicesAtom = atomWithDefault<Promise<OpenAPIServices>>(async get => {
  return createOpenAPIServices(new Configuration(await get(santaAuthorizedOpenapiServicesConfParamsAtom)));
});

export default santaAuthorizedOpenapiServicesAtom;

santaAuthorizedOpenapiServicesConfParamsAtom.debugPrivate = true;
santaAuthorizedOpenapiServicesAtom.debugPrivate = true;
