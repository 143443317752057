import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
import { StripeSubscriptionListItemFromJSON, StripeSubscriptionListItemToJSON, } from './StripeSubscriptionListItem';
/**
 * Check if a given object implements the ListStripeSubscriptionsResponse interface.
 */
export function instanceOfListStripeSubscriptionsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "subscriptions" in value;
    isInstance = isInstance && "page" in value;
    return isInstance;
}
export function ListStripeSubscriptionsResponseFromJSON(json) {
    return ListStripeSubscriptionsResponseFromJSONTyped(json, false);
}
export function ListStripeSubscriptionsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'subscriptions': (json['subscriptions'].map(StripeSubscriptionListItemFromJSON)),
        'page': CursorPageFromJSON(json['page']),
    };
}
export function ListStripeSubscriptionsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'subscriptions': (value.subscriptions.map(StripeSubscriptionListItemToJSON)),
        'page': CursorPageToJSON(value.page),
    };
}
