import { StripeCheckoutAvailabilityFromJSON, StripeCheckoutAvailabilityToJSON, } from './StripeCheckoutAvailability';
/**
 * Check if a given object implements the CheckMyAvailabilityResponse interface.
 */
export function instanceOfCheckMyAvailabilityResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "availability" in value;
    return isInstance;
}
export function CheckMyAvailabilityResponseFromJSON(json) {
    return CheckMyAvailabilityResponseFromJSONTyped(json, false);
}
export function CheckMyAvailabilityResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'availability': StripeCheckoutAvailabilityFromJSON(json['availability']),
    };
}
export function CheckMyAvailabilityResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'availability': StripeCheckoutAvailabilityToJSON(value.availability),
    };
}
