import { ContentInteractionStateWithContentFromJSON, ContentInteractionStateWithContentToJSON, } from './ContentInteractionStateWithContent';
import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
/**
 * Check if a given object implements the ListContentInteractionStatesResponse interface.
 */
export function instanceOfListContentInteractionStatesResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "contentInteractionStates" in value;
    isInstance = isInstance && "page" in value;
    return isInstance;
}
export function ListContentInteractionStatesResponseFromJSON(json) {
    return ListContentInteractionStatesResponseFromJSONTyped(json, false);
}
export function ListContentInteractionStatesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentInteractionStates': (json['contentInteractionStates'].map(ContentInteractionStateWithContentFromJSON)),
        'page': CursorPageFromJSON(json['page']),
    };
}
export function ListContentInteractionStatesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentInteractionStates': (value.contentInteractionStates.map(ContentInteractionStateWithContentToJSON)),
        'page': CursorPageToJSON(value.page),
    };
}
