/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { AIBlockFromJSON, AIBlockToJSON, } from './AIBlock';
import { DurationFromJSON, DurationToJSON, } from './Duration';
import { EstimatedScoreFromJSON, EstimatedScoreToJSON, } from './EstimatedScore';
import { LearningCycleCellInfoFromJSON, LearningCycleCellInfoToJSON, } from './LearningCycleCellInfo';
import { LearningCycleStatusFromJSON, LearningCycleStatusToJSON, } from './LearningCycleStatus';
import { LearningCycleTypeFromJSON, LearningCycleTypeToJSON, } from './LearningCycleType';
import { LearningInformationFromJSON, LearningInformationToJSON, } from './LearningInformation';
/**
 * Check if a given object implements the LearningCycle interface.
 */
export function instanceOfLearningCycle(value) {
    let isInstance = true;
    isInstance = isInstance && "cellInfos" in value;
    isInstance = isInstance && "ordinalNumber" in value;
    isInstance = isInstance && "totalElapsedTime" in value;
    isInstance = isInstance && "aiBlocks" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function LearningCycleFromJSON(json) {
    return LearningCycleFromJSONTyped(json, false);
}
export function LearningCycleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cellInfos': (json['cellInfos'].map(LearningCycleCellInfoFromJSON)),
        'latestEstimatedScore': !exists(json, 'latestEstimatedScore') ? undefined : EstimatedScoreFromJSON(json['latestEstimatedScore']),
        'previousEstimatedScore': !exists(json, 'previousEstimatedScore') ? undefined : EstimatedScoreFromJSON(json['previousEstimatedScore']),
        'ordinalNumber': json['ordinalNumber'],
        'totalElapsedTime': DurationFromJSON(json['totalElapsedTime']),
        'aiBlocks': (json['aiBlocks'].map(AIBlockFromJSON)),
        'learningInformation': !exists(json, 'learningInformation') ? undefined : LearningInformationFromJSON(json['learningInformation']),
        'id': json['id'],
        'type': LearningCycleTypeFromJSON(json['type']),
        'status': LearningCycleStatusFromJSON(json['status']),
    };
}
export function LearningCycleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cellInfos': (value.cellInfos.map(LearningCycleCellInfoToJSON)),
        'latestEstimatedScore': EstimatedScoreToJSON(value.latestEstimatedScore),
        'previousEstimatedScore': EstimatedScoreToJSON(value.previousEstimatedScore),
        'ordinalNumber': value.ordinalNumber,
        'totalElapsedTime': DurationToJSON(value.totalElapsedTime),
        'aiBlocks': (value.aiBlocks.map(AIBlockToJSON)),
        'learningInformation': LearningInformationToJSON(value.learningInformation),
        'id': value.id,
        'type': LearningCycleTypeToJSON(value.type),
        'status': LearningCycleStatusToJSON(value.status),
    };
}
