import useCountry from '@app/hooks/i18n/useCountry';

const useMetaTagData = (): {openGraphImage: string; openGraphLocale: string} => {
  const country = useCountry();
  switch (country) {
    case 'KR':
      return {openGraphImage: '/img/social-thumbnail/meta-default.png', openGraphLocale: 'ko-KR'};
    case 'US':
      return {openGraphImage: '/img/social-thumbnail/meta-default.png', openGraphLocale: 'en-US'};
    case 'JP':
      return {openGraphImage: '/img/social-thumbnail/meta-jp.png', openGraphLocale: 'ja-JP'};
    case 'TH':
      return {openGraphImage: '/img/social-thumbnail/meta-th.png', openGraphLocale: 'th-TH'};
    case 'TW':
      return {openGraphImage: '/img/social-thumbnail/meta-tw.png', openGraphLocale: 'zh-TW'};
    case 'VN':
      return {openGraphImage: '/img/social-thumbnail/meta-default.png', openGraphLocale: 'vi-VN'};
    default:
      return {openGraphImage: '/img/social-thumbnail/meta-default.png', openGraphLocale: 'ko-KR'};
  }
};

export default useMetaTagData;
