/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { ChatMessageResponseFromJSON, ChatMessagesResponseFromJSON, FeedbackChatMessageRequestToJSON, SendChatMessageRequestToJSON, } from '../models';
/**
 *
 */
export class ChatMessageV1Api extends runtime.BaseAPI {
    /**
     * 해당 메시지의 상태를 `DELETED`로 변경한다.  정상적으로 응답을 받았다면, UI 상에서 해당 메시지를 보이지 않게 수정해야 한다.
     * 유저가 보낸 메시지의 `status`가 `ERROR`인 경우 해당 메시지를 삭제한다.
     */
    async deleteChatMessageRaw(requestParameters, initOverrides) {
        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId', 'Required parameter requestParameters.messageId was null or undefined when calling deleteChatMessage.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning-x/v1/chat/messages/{messageId}`.replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 해당 메시지의 상태를 `DELETED`로 변경한다.  정상적으로 응답을 받았다면, UI 상에서 해당 메시지를 보이지 않게 수정해야 한다.
     * 유저가 보낸 메시지의 `status`가 `ERROR`인 경우 해당 메시지를 삭제한다.
     */
    async deleteChatMessage(requestParameters, initOverrides) {
        await this.deleteChatMessageRaw(requestParameters, initOverrides);
    }
    /**
     * 해당 메시지의 롤이 `ASSISTANT`인 경우, AI 서버로 피드백을 보낼 수 있다.
     * 챗봇이 대답한 메시지에 대해 피드백을 AI 서버로 보낸다.
     */
    async feedbackChatMessageRaw(requestParameters, initOverrides) {
        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId', 'Required parameter requestParameters.messageId was null or undefined when calling feedbackChatMessage.');
        }
        if (requestParameters.feedbackChatMessageRequest === null || requestParameters.feedbackChatMessageRequest === undefined) {
            throw new runtime.RequiredError('feedbackChatMessageRequest', 'Required parameter requestParameters.feedbackChatMessageRequest was null or undefined when calling feedbackChatMessage.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/content-learning-x/v1/chat/messages/{messageId}/feedback`.replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: FeedbackChatMessageRequestToJSON(requestParameters.feedbackChatMessageRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageResponseFromJSON(jsonValue));
    }
    /**
     * 해당 메시지의 롤이 `ASSISTANT`인 경우, AI 서버로 피드백을 보낼 수 있다.
     * 챗봇이 대답한 메시지에 대해 피드백을 AI 서버로 보낸다.
     */
    async feedbackChatMessage(requestParameters, initOverrides) {
        const response = await this.feedbackChatMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 채팅방에 존재하는 최신 메시지 최대 100개를 반환한다.\\ `status`가 `DELETED`인 메시지는 내려가지 않는다.
     * 채팅방의 메시지 목록을 가져온다.
     */
    async getChatMessagesRaw(requestParameters, initOverrides) {
        if (requestParameters.roomId === null || requestParameters.roomId === undefined) {
            throw new runtime.RequiredError('roomId', 'Required parameter requestParameters.roomId was null or undefined when calling getChatMessages.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning-x/v1/chat/rooms/{roomId}/messages`.replace(`{${"roomId"}}`, encodeURIComponent(String(requestParameters.roomId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessagesResponseFromJSON(jsonValue));
    }
    /**
     * 채팅방에 존재하는 최신 메시지 최대 100개를 반환한다.\\ `status`가 `DELETED`인 메시지는 내려가지 않는다.
     * 채팅방의 메시지 목록을 가져온다.
     */
    async getChatMessages(requestParameters, initOverrides) {
        const response = await this.getChatMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 해당 ID의 메시지가 `ENQUEUED` 상태라면 최대 대략 1분정도 DB를 폴링한다.  폴링해도 결과가 없으면 `ERROR`인 상태로 변경된다.\\ 1분이 넘었는데 결과가 없다는 것은, AI 서버가 처리할 수 없거나 알 수 없는 에러로 응답을 받을 수 없는 경우이다.  결과가 있다면 유저가 보낸 메시지의 상태를 `NONE`으로 변경하고, 즉시 챗봇의 대답을 반환한다.
     * 유저가 보낸 메시지에 대한 챗봇의 응답을 가져온다.
     */
    async getReplyChatMessageRaw(requestParameters, initOverrides) {
        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId', 'Required parameter requestParameters.messageId was null or undefined when calling getReplyChatMessage.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning-x/v1/chat/messages/{messageId}/reply`.replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageResponseFromJSON(jsonValue));
    }
    /**
     * 해당 ID의 메시지가 `ENQUEUED` 상태라면 최대 대략 1분정도 DB를 폴링한다.  폴링해도 결과가 없으면 `ERROR`인 상태로 변경된다.\\ 1분이 넘었는데 결과가 없다는 것은, AI 서버가 처리할 수 없거나 알 수 없는 에러로 응답을 받을 수 없는 경우이다.  결과가 있다면 유저가 보낸 메시지의 상태를 `NONE`으로 변경하고, 즉시 챗봇의 대답을 반환한다.
     * 유저가 보낸 메시지에 대한 챗봇의 응답을 가져온다.
     */
    async getReplyChatMessage(requestParameters, initOverrides) {
        const response = await this.getReplyChatMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 내부적으로는 해당 메시지의 `status`를 `DELETED`로 바꾸고, 새로운 메시지를 생성한다.\\ 따라서 정상적으로 응답을 받았다면, UI 상에서 해당 메시지를 보이지 않게 수정해야 한다.  생성 이후의 로직은 `sendChatMessage`<sup>POST /rooms/{roomId}/messages</sup>와 동일하다.
     * 유저가 보낸 메시지의 `status`가 `ERROR`인 경우 해당 메시지를 재전송한다.
     */
    async resendChatMessageRaw(requestParameters, initOverrides) {
        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId', 'Required parameter requestParameters.messageId was null or undefined when calling resendChatMessage.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning-x/v1/chat/messages/{messageId}`.replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageResponseFromJSON(jsonValue));
    }
    /**
     * 내부적으로는 해당 메시지의 `status`를 `DELETED`로 바꾸고, 새로운 메시지를 생성한다.\\ 따라서 정상적으로 응답을 받았다면, UI 상에서 해당 메시지를 보이지 않게 수정해야 한다.  생성 이후의 로직은 `sendChatMessage`<sup>POST /rooms/{roomId}/messages</sup>와 동일하다.
     * 유저가 보낸 메시지의 `status`가 `ERROR`인 경우 해당 메시지를 재전송한다.
     */
    async resendChatMessage(requestParameters, initOverrides) {
        const response = await this.resendChatMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 메시지를 `ENQUEUED` 혹은 `ERROR` 상태로 trim 하여 DB에 저장한다.  AI 서버로 메시지를 보내기 위해 큐에 작업을 넣어두고 유저의 메시지를 DB에 저장한다.\\ 큐를 이용하는 이유는, 챗봇의 응답을 받기까지 오래 걸릴 수 있기 때문이다.  큐에 잘 들어갔다면 유저의 메시지가 `ENQUEUED` 상태로 저장되고, 에러가 발생했다면 상태를 `ERROR`로 저장된다.  유저 메시지에 대한 챗봇의 응답을 얻기 위해서는 `getReplyChatMessage`<sup>GET /messages/{messageId}/reply</sup>를 호출하면 된다(자세한 사항은 해당 API 문서를 참고).
     * 유저는 메시지를 AI 서버로 보낸다.
     */
    async sendChatMessageRaw(requestParameters, initOverrides) {
        if (requestParameters.roomId === null || requestParameters.roomId === undefined) {
            throw new runtime.RequiredError('roomId', 'Required parameter requestParameters.roomId was null or undefined when calling sendChatMessage.');
        }
        if (requestParameters.sendChatMessageRequest === null || requestParameters.sendChatMessageRequest === undefined) {
            throw new runtime.RequiredError('sendChatMessageRequest', 'Required parameter requestParameters.sendChatMessageRequest was null or undefined when calling sendChatMessage.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/content-learning-x/v1/chat/rooms/{roomId}/messages`.replace(`{${"roomId"}}`, encodeURIComponent(String(requestParameters.roomId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendChatMessageRequestToJSON(requestParameters.sendChatMessageRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageResponseFromJSON(jsonValue));
    }
    /**
     * 메시지를 `ENQUEUED` 혹은 `ERROR` 상태로 trim 하여 DB에 저장한다.  AI 서버로 메시지를 보내기 위해 큐에 작업을 넣어두고 유저의 메시지를 DB에 저장한다.\\ 큐를 이용하는 이유는, 챗봇의 응답을 받기까지 오래 걸릴 수 있기 때문이다.  큐에 잘 들어갔다면 유저의 메시지가 `ENQUEUED` 상태로 저장되고, 에러가 발생했다면 상태를 `ERROR`로 저장된다.  유저 메시지에 대한 챗봇의 응답을 얻기 위해서는 `getReplyChatMessage`<sup>GET /messages/{messageId}/reply</sup>를 호출하면 된다(자세한 사항은 해당 API 문서를 참고).
     * 유저는 메시지를 AI 서버로 보낸다.
     */
    async sendChatMessage(requestParameters, initOverrides) {
        const response = await this.sendChatMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
