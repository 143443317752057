/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const DevicePlatform = {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
    WEB: 'WEB'
};
export function DevicePlatformFromJSON(json) {
    return DevicePlatformFromJSONTyped(json, false);
}
export function DevicePlatformFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function DevicePlatformToJSON(value) {
    return value;
}
