import * as ds from "@riiid/design-system";
import { URL_GIF_LOADING_CIRCLE_WHITE1, URL_GIF_LOADING_CIRCLE_GRAY6 } from "@riiid/design-system/lotties/santa";
export var createButtonTheme = function() {
    var COLOR_STYLE_SOLID_BLACK = {
        root: {
            background: ds.COLOR_SANTA_M,
            ":not(:disabled)": {
                "@media screen and (hover: hover)": {
                    ":hover": {
                        background: ds.COLOR_SANTA_L
                    }
                },
                ":focus": {
                    background: ds.COLOR_SANTA_N
                }
            }
        },
        disabled: {
            background: ds.COLOR_SANTA_D,
            ".button-typography": {
                color: ds.COLOR_SANTA_F
            }
        },
        typography: {
            color: ds.COLOR_SANTA_A
        },
        loadingAnimationData: URL_GIF_LOADING_CIRCLE_WHITE1,
        loadingAnimationDataDark: URL_GIF_LOADING_CIRCLE_GRAY6
    };
    var COLOR_STYLE_SOLID_BRAND = {
        root: {
            background: ds.COLOR_SANTA_BC,
            ":not(:disabled)": {
                "@media screen and (hover: hover)": {
                    ":hover": {
                        background: ds.COLOR_SANTA_BB
                    }
                },
                ":focus": {
                    background: ds.COLOR_SANTA_BD
                }
            }
        },
        disabled: {
            background: ds.COLOR_SANTA_D,
            ".button-typography": {
                color: ds.COLOR_SANTA_F
            }
        },
        typography: {
            color: ds.COLOR_FIX_SANTA_WHITE_1
        },
        loadingAnimationData: URL_GIF_LOADING_CIRCLE_WHITE1
    };
    var COLOR_STYLE_SOLID_GREY = {
        root: {
            background: ds.COLOR_SANTA_D,
            ":not(:disabled)": {
                "@media screen and (hover: hover)": {
                    ":hover": {
                        background: ds.COLOR_SANTA_C
                    }
                },
                ":focus": {
                    background: ds.COLOR_SANTA_E
                }
            }
        },
        disabled: {
            background: ds.COLOR_SANTA_D,
            ".button-typography": {
                color: ds.COLOR_SANTA_F
            }
        },
        typography: {
            color: ds.COLOR_SANTA_M,
            fontWeight: "regular"
        },
        loadingAnimationData: URL_GIF_LOADING_CIRCLE_GRAY6
    };
    var COLOR_STYLE_SOLID_GREY_FIX = {
        root: {
            background: ds.COLOR_FIX_SANTA_GRAY_1,
            ":not(:disabled)": {
                "@media screen and (hover: hover)": {
                    ":hover": {
                        background: ds.COLOR_FIX_SANTA_WHITE_3
                    }
                },
                ":focus": {
                    background: ds.COLOR_FIX_SANTA_GRAY_2
                }
            }
        },
        disabled: {
            background: ds.COLOR_FIX_SANTA_GRAY_1,
            ".button-typography": {
                color: ds.COLOR_FIX_SANTA_GRAY_3
            }
        },
        typography: {
            color: ds.COLOR_FIX_SANTA_BLACK_3,
            fontWeight: "regular"
        },
        loadingAnimationData: URL_GIF_LOADING_CIRCLE_GRAY6
    };
    var COLOR_STYLE_SOLID_TRANSPARENT = {
        root: {
            background: "transparent",
            ":not(:disabled)": {
                "@media screen and (hover: hover)": {
                    ":hover": {
                        background: ds.COLOR_SANTA_C
                    }
                },
                ":focus": {
                    background: "transparent",
                    ".button-typography": {
                        color: ds.COLOR_SANTA_O
                    }
                }
            }
        },
        disabled: {
            ".button-typography": {
                color: ds.COLOR_SANTA_F
            }
        },
        typography: {
            color: ds.COLOR_SANTA_L,
            fontWeight: "regular"
        },
        loadingAnimationData: URL_GIF_LOADING_CIRCLE_GRAY6
    };
    var COLOR_STYLE_BOX_LINE_BLACK = {
        root: {
            background: "none",
            borderColor: ds.COLOR_SANTA_N,
            borderStyle: "solid",
            borderWidth: 1,
            ":not(:disabled)": {
                "@media screen and (hover: hover)": {
                    ":hover": {
                        background: ds.COLOR_SANTA_N_ALPHA(0.1)
                    }
                },
                ":focus": {
                    background: ds.COLOR_SANTA_N_ALPHA(0.2),
                    borderWidth: 2
                }
            }
        },
        disabled: {
            borderColor: ds.COLOR_SANTA_M,
            opacity: 0.4
        },
        typography: {
            color: ds.COLOR_SANTA_M
        },
        loadingAnimationData: URL_GIF_LOADING_CIRCLE_GRAY6
    };
    var COLOR_STYLE_BOX_LINE_WHITE_FIX = {
        root: {
            background: "none",
            borderColor: ds.COLOR_FIX_SANTA_WHITE_3,
            borderStyle: "solid",
            borderWidth: 1,
            ":not(:disabled)": {
                "@media screen and (hover: hover)": {
                    ":hover": {
                        background: ds.COLOR_FIX_SANTA_WHITE_2_ALPHA(0.1)
                    }
                },
                ":focus": {
                    background: ds.COLOR_FIX_SANTA_WHITE_2_ALPHA(0.2),
                    borderWidth: 2
                }
            }
        },
        disabled: {
            borderColor: ds.COLOR_FIX_SANTA_WHITE_2,
            opacity: 0.4
        },
        typography: {
            color: ds.COLOR_FIX_SANTA_WHITE_3
        },
        loadingAnimationData: URL_GIF_LOADING_CIRCLE_GRAY6
    };
    var boxLineToggleHoverStyle = {
        borderColor: ds.COLOR_SANTA_I,
        background: ds.COLOR_SANTA_N_ALPHA(0.05),
        ".button-typography": {
            color: ds.COLOR_SANTA_J
        }
    };
    var boxLineToggleSelectedStyle = {
        borderColor: ds.COLOR_SANTA_BD,
        background: ds.COLOR_SANTA_BC_ALPHA(0.2),
        ".button-typography": {
            color: ds.COLOR_SANTA_BE
        }
    };
    var COLOR_STYLE_BOX_LINE_TOGGLE_DEFAULT = {
        root: {
            background: "none",
            borderColor: ds.COLOR_SANTA_H,
            borderStyle: "solid",
            borderWidth: 1,
            ":not(:disabled)": {
                "@media screen and (hover: hover)": {
                    ":hover": boxLineToggleHoverStyle
                },
                ":focus": boxLineToggleHoverStyle
            }
        },
        selected: {
            ":not(:disabled)": {
                "@media screen and (hover: hover)": {
                    ":hover": boxLineToggleSelectedStyle
                },
                "&, &:focus": boxLineToggleSelectedStyle
            }
        },
        disabled: {
            opacity: 0.4
        },
        loading: {
            borderColor: ds.COLOR_SANTA_I,
            background: ds.COLOR_SANTA_N_ALPHA(0.05)
        },
        typography: {
            color: ds.COLOR_SANTA_I
        },
        loadingAnimationData: URL_GIF_LOADING_CIRCLE_GRAY6
    };
    var COLOR_STYLE_SOLID_WHITE_FIX = {
        root: {
            background: ds.COLOR_FIX_SANTA_WHITE_1,
            ":not(:disabled)": {
                "@media screen and (hover: hover)": {
                    ":hover": {
                        background: ds.COLOR_FIX_SANTA_WHITE_3
                    }
                },
                ":focus": {
                    background: ds.COLOR_FIX_SANTA_GRAY_2
                }
            }
        },
        disabled: {
            background: ds.COLOR_FIX_SANTA_GRAY_1,
            ".button-typography": {
                color: ds.COLOR_FIX_SANTA_GRAY_3
            }
        },
        typography: {
            color: ds.COLOR_FIX_SANTA_BLACK_3
        },
        loadingAnimationData: URL_GIF_LOADING_CIRCLE_GRAY6
    };
    var COLOR_STYLE_SOLID = {
        black: COLOR_STYLE_SOLID_BLACK,
        brand: COLOR_STYLE_SOLID_BRAND,
        grey: COLOR_STYLE_SOLID_GREY,
        transparent: COLOR_STYLE_SOLID_TRANSPARENT,
        "grey-fix": COLOR_STYLE_SOLID_GREY_FIX,
        "white-fix": COLOR_STYLE_SOLID_WHITE_FIX
    };
    var COLOR_STYLE_BOX_LINE = {
        black: COLOR_STYLE_BOX_LINE_BLACK,
        brand: COLOR_STYLE_BOX_LINE_BLACK,
        grey: COLOR_STYLE_BOX_LINE_BLACK,
        transparent: COLOR_STYLE_SOLID_TRANSPARENT,
        "grey-fix": {},
        "white-fix": COLOR_STYLE_BOX_LINE_WHITE_FIX
    };
    var COLOR_STYLE_BOX_LINE_TOGGLE = {
        black: COLOR_STYLE_BOX_LINE_TOGGLE_DEFAULT,
        brand: COLOR_STYLE_BOX_LINE_TOGGLE_DEFAULT,
        grey: COLOR_STYLE_BOX_LINE_TOGGLE_DEFAULT,
        transparent: COLOR_STYLE_SOLID_TRANSPARENT,
        "grey-fix": {},
        "white-fix": {}
    };
    var COLOR_STYLES = {
        solid: COLOR_STYLE_SOLID,
        "bottom-fix": COLOR_STYLE_SOLID,
        "box-line": COLOR_STYLE_BOX_LINE,
        "box-line-toggle": COLOR_STYLE_BOX_LINE_TOGGLE
    };
    var STYLE_SMALL_SOLID = {
        root: {
            height: "36px",
            padding: "8px 20px",
            borderRadius: "4px"
        }
    };
    var STYLE_SOLID = {
        root: {
            height: "48px",
            padding: "0 24px",
            borderRadius: "4px"
        }
    };
    var STYLE_BOTTOM_FIX = {
        root: {
            width: "100%",
            height: "58px",
            padding: "0 20px"
        }
    };
    var STYLES = {
        small: {
            solid: STYLE_SMALL_SOLID,
            "bottom-fix": STYLE_BOTTOM_FIX,
            "box-line": STYLE_SOLID,
            "box-line-toggle": STYLE_SOLID
        },
        medium: {
            solid: STYLE_SOLID,
            "bottom-fix": STYLE_BOTTOM_FIX,
            "box-line": STYLE_SOLID,
            "box-line-toggle": STYLE_SOLID
        }
    };
    return {
        colorVariants: COLOR_STYLES,
        variants: STYLES
    };
};
