import React from 'react';
import {Global, css} from '@emotion/react';

const SpoqaHanSans = (): JSX.Element => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Spoqa Han Sans';
          font-style: normal;
          font-weight: 100;
          src: local('Spoqa Han Sans Thin'), local('SpoqaHanSans-Thin'),
            url('/fonts/SpoqaHanSans-Thin.subset.woff') format('woff');
          unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A, U+0030-0039;
          font-display: block;
        }
        @font-face {
          font-family: 'Spoqa Han Sans';
          font-style: normal;
          font-weight: 300;
          src: local('Spoqa Han Sans Light'), local('SpoqaHanSans-Light'),
            url('/fonts/SpoqaHanSans-Light.subset.woff') format('woff');
          unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A, U+0030-0039;
          font-display: block;
        }
        @font-face {
          font-family: 'Spoqa Han Sans';
          font-style: normal;
          font-weight: 400;
          src: local('Spoqa Han Sans Regular'), local('SpoqaHanSans-Regular'),
            url('/fonts/SpoqaHanSans-Regular.subset.woff') format('woff');
          unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A, U+0030-0039;
          font-display: block;
        }
        @font-face {
          font-family: 'Spoqa Han Sans';
          font-style: normal;
          font-weight: 700;
          src: local('Spoqa Han Sans Bold'), local('SpoqaHanSans-Bold'),
            url('/fonts/SpoqaHanSans-Bold.subset.woff') format('woff');
          unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A, U+0030-0039;
          font-display: block;
        }
        * {
          font-family: 'Spoqa Han Sans', 'Noto Sans KR', sans-serif;
        }
      `}
    />
  );
};

export default React.memo(SpoqaHanSans);
