import {
  CheckLearningAuthorityRequest,
  GetMyNoteCountRequest,
  ListAvailableSelfCardIdentifiersRequest,
} from '@santa-web/gen/ssp/messages/toeic/app';
import {VirtualExamReportFilter} from '@app/new-structure/entities/virtual-exam/VirtualExamReport';

import {ListSelfLearningCardRequest} from './pages/self-learning/types';
import {PromotionStatus} from './types/promotion';
import {ListReviewRequest} from './types/review';

export const getContentQueryKey = (url: string | null | undefined) => {
  return ['content', url];
};

export const getContentInteractionStatesQueryKey = (sessionId: number | null | undefined) => {
  return ['contentInteractionStates', sessionId];
};

export const getContentInteractionStateQueryKey = (contentInteractionStateId: number | null | undefined) => {
  return ['contentInteractionState', contentInteractionStateId];
};

export const getIsValidResetPasswordTokenQueryKey = (token: string): [string, string] => {
  return ['isValidResetPasswordToken', token];
};

export const getLearningCellQueryKey = (id: number | null | undefined) => {
  return ['learningCell', id];
};

export const getLearningCellReportQueryKey = (id: number | null | undefined) => {
  return ['learningCellReport', id];
};

export const getListAvailableSelfCardIdentifiersQueryKey = (
  request: ListAvailableSelfCardIdentifiersRequest
): [string, ListAvailableSelfCardIdentifiersRequest] => {
  return ['listAvailableSelfCardIdentifiersRequest', request];
};

export const getLearningSelfCardsInfiniteQueryKey = (
  request: ListSelfLearningCardRequest
): [string, string, ListSelfLearningCardRequest] => {
  return ['learningSelfCards', 'infinite', request];
};

export const getLearningSelfCardsQueryKey = (
  request: ListSelfLearningCardRequest
): [string, ListSelfLearningCardRequest] => {
  return ['learningSelfCards', request];
};

export const getSelfLearningSummaryQueryKey = (): [string] => ['selfLearningSummary'];

export const getResourceQueryKey = (id: string | null | undefined) => {
  return ['resource', id];
};

export const getResourcesQueryKey = (ids: string[]) => {
  return ['resources', ids];
};

export const getStripeProductBoardQueryKey = (boardCode?: string): [string, string | undefined] => {
  return ['getStripeProductBoardQueryKey', boardCode];
};
export const getSubscriptionBoardQueryKey = (boardCode?: string): [string, string | undefined] => {
  return ['getSubscriptionBoardQueryKey', boardCode];
};

export const getProductBoardQueryKey = (boardCode?: string): [string, string | undefined] => {
  return ['getProductBoardQueryKey', boardCode];
};

export const getProductQueryKey = (productId: string): [string, string] => {
  return ['getProductQueryKey', productId];
};

export const getSubscriptionProductQueryKey = (productId: string): [string, string] => {
  return ['getProductQueryKey', productId];
};

export const getStripeProductQueryKey = (productId: number) => {
  return ['getProductQueryKey', productId] as const;
};

export const getOrderQueryKey = (orderId: string): [string, string] => {
  return ['getOrderQueryKey', orderId];
};

export const getSubscriptionOrderQueryKey = (orderId: string): [string, string] => {
  return ['getSubscriptionOrderQueryKey', orderId];
};

export const getIsSubscriptionOrderAvailableQueryKey = (): [string] => {
  return ['getIsSubscriptionOrderAvailable'];
};

export const getIsOrderAvailableQueryKey = (): [string] => {
  return ['getIsOrderAvailableQueryKey'];
};

export const getStripeSubscriptionListQueryKey = () => {
  return ['getStripeSubscriptionQueryKey'] as const;
};

export const getStripeSubscriptionDetailQueryKey = (id: number) => {
  return ['getStripeSubscriptionDetailQueryKey', id] as const;
};

export const getSubscriptionQueryKey = (): ['getSubscriptionQueryKey'] => {
  return ['getSubscriptionQueryKey'];
};

export const getStripeSubscriptionAvailabilityQueryKey = () => ['getStripeSubscriptionAvailabilityQueryKey'] as const;

export const getListFulfillmentByOrderQueryKey = (orderId: string): ['ListFulfillmentByOrder', string] => {
  return ['ListFulfillmentByOrder', orderId];
};

export const getEnhancedOrderQueryKey = (orderId: string): [string, string] => {
  return ['getEnhancedOrderQueryKey', orderId];
};

export const getOrderFulfillmentsQueryKey = (orderId: string): ['getOrderFulfillments', string] => {
  return ['getOrderFulfillments', orderId];
};

export const getAccessGiftCodeQueryKey = (fulfillmentId: string) => {
  return ['getAccessGiftCodeQueryKey', fulfillmentId];
};

export const getUserQueryKey = (): [string] => ['user'];

export const getUserProfileQueryKey = (): [string] => ['profile'];

export const getOrdersQueryKey = (): [string] => {
  return ['listOrders'];
};

export const listCouponQueryKeyPrefix = ['listCoupon'];
export type GetListCouponsQueryKey = [typeof listCouponQueryKeyPrefix[0], {isAvailableOnly: boolean}];
export const getListCouponsQueryKey = (isAvailableOnly: boolean): GetListCouponsQueryKey => {
  return [listCouponQueryKeyPrefix[0], {isAvailableOnly}];
};

export const getLearningCycleQueryKey = (id?: number) => ['learningCycle', id];

export const getLearningCycleReportQueryKey = (id: number | undefined) => ['learningCycleReport', id];
export const getExamDatesQueryKey = (): [string] => {
  return ['examDates'];
};

export const getDiagnosisReportQueryKey = () => ['DiagnosisReport'];
export const getDiagnosisQueryKey = () => ['Diagnosis'];

export const getListReviewsQueryKey = (request: ListReviewRequest): [string, ListReviewRequest] => ['reviews', request];
export const getCountReviewsQueryKey = (request: ListReviewRequest): [string, ListReviewRequest] => [
  'reviewsCount',
  request,
];

export const getOngoingReviewQuizQueryKey = () => ['OngoingReviewQuiz'] as const;
export const getReviewQuizCountQueryKey = (request: GetMyNoteCountRequest) => ['ReviewQuizCount', request] as const;
export const getReviewQuizInMemoryFilterQueryKey = () => ['ReviewQuizInMemoryFilter'] as const;

export const getCheckLearningAuthorityQueryKey = (
  request: CheckLearningAuthorityRequest
): [string, CheckLearningAuthorityRequest] => {
  return ['checkLearningAuthority', request];
};

export const getGetActiveLearningAuthorityInfoQueryKey = (): [string] => ['getActiveLearningAuthorityInfo'];

export const getCouponBoxSummaryKey = () => ['CouponBoxSummary'];

export const getCISReviewsQueryKey = () => ['CISReviews'];

export const getInboxNotificationQueryKey = () => ['inboxNotification'];

export const getCheckInboxNewNotificationQueryKey = () => ['checkInboxNewNotification'];

export const getMarketingBannerQueryKey = () => ['marketingBanner'];

export const getB2bClassInfoKey = (orgId: string) => ['B2bClassInfo', orgId];

export const getMainPopupBannerQueryKey = (eventId: string) => ['mainPopupBanner', eventId];

export const getPromotionListQueryKey = (status: PromotionStatus) => ['promotionList', status];

export const getPromotionDetailQueryKey = (id: string) => ['promotionDetail', id];

export const getMainPopupBannerQueryLey = (eventId: string) => ['mainPopupBanner', eventId];

export const getVirtualExamInstanceQueryKey = (sessionId: string | null | undefined) => [
  'virtualExamInstance',
  sessionId,
];

export const getVirtualExamReportQueryKey = (sessionId: string): ['VirtualExamReport', string] => [
  'VirtualExamReport',
  sessionId,
];

export const getVirtualExamUnansweredProblemsQueryKey = (
  sessionId: string
): ['VirtualExamUnansweredProblems', string] => ['VirtualExamUnansweredProblems', sessionId];

export const getVirtualExamReviewQueryKey = (
  sessionId: string,
  filter: VirtualExamReportFilter
): ['VirtualExamReview', string, VirtualExamReportFilter] => ['VirtualExamReview', sessionId, filter];

export const getGtelpStatusQueryKey = () => ['gtelpStatus'];

export const getGtelpProfileQueryKey = () => ['gtelpProfile'];

export const getGtelpContentInfosQueryKey = () => ['gtelpContentInfos'];

export const getGtelpContentAnswerStatesQueryKey = () => ['gtelpContentAnswerStates'];

export const getGtelpResultQueryKey = () => ['gtelpResult'];

export const getKakaoLandingResourceQueryKey = () => ['KakaoLandingResource'];

export const getListHighlightInfosQueryKey = (contentInteractionStateId: string) =>
  ['ListHighlightInfosQueryKey', contentInteractionStateId] as const;

export const getGetSentenceStructureV2QueryKey = (contentId: string) =>
  ['GetSentenceStructureQueryKey', contentId] as const;

export const getKnowledgeComponentQueryKey = (contentId: string | undefined | null) =>
  ['KnowledgeComponent', contentId] as const;

export const getOrCreateKnowledgeComponentLessonQueryKey = (contentId: string) =>
  ['KnowledgeComponentLesson', contentId] as const;

export const getHasSubmittedFeedbackQueryKey = () => ['HasSubmittedFeedbackQueryKey'] as const;

export const getGetUserConfigQueryKey = () => ['GetUserConfigQueryKey'] as const;

export const getSelfLearningRootNodesQueryKey = () => ['selfLearningRootNodes'] as const;

export const getSelfLearningNodeQueryKey = (nodeId: string | undefined) => ['selfLearningNode', nodeId] as const;

export const getSelfLearningHierarchyInfos = () => ['selfLearningHierarchyInfos'] as const;

export const getGetVerbUseKey = (contentId: string) => ['getVerbUse', contentId] as const;

export const getListLearningCycleInfosQueryKey = () => ['learningCycleInfos'] as const;

export const getSummarizeContentInteractionStateKey = (cellId: number | null | undefined) =>
  ['summarizeContentInteractionState', cellId] as const;

export const getUnderlineMarkupQueryKey = (cisId: number | null | undefined) => ['underlineMarkup', cisId];

export const getLearningDomainListQueryKey = () => ['learningDomainList'] as const;

export const getChatRoomQueryKey = (contentInteractionStateId: number) =>
  ['chatRoom', contentInteractionStateId] as const;

export const getChatMessagesQueryKey = (chatRoomId: number) => ['chatMessages', chatRoomId] as const;

export const getReplyChatMessageQueryKey = (chatMessageId: number) => ['replyChatMessage', chatMessageId] as const;

export const getSkillAnalyticsQueryKey = () => ['skillAnalytics'] as const;

export const getContentStatisticsQueryKey = (cisId: number) => ['contentStatistics', cisId] as const;

export const getKeyValueQueryKey = (request: {key: string; type: string}) => ['keyValue', request] as const;

export const getSystemConfigQueryKey = (type: string) => ['systemConfig', type] as const;

export const getAppInfoQueryKey = () => ['appInfo'] as const;

export const getOfferGroupQueryKey = (id: number) => ['getOfferGroup', id] as const;

export const getOfferGroupsQueryKey = (boardCode?: string) => ['getOfferGroups', boardCode] as const;
