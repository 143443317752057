/**
 * Check if a given object implements the ValidateResetPasswordTokenResponse interface.
 */
export function instanceOfValidateResetPasswordTokenResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "isValid" in value;
    return isInstance;
}
export function ValidateResetPasswordTokenResponseFromJSON(json) {
    return ValidateResetPasswordTokenResponseFromJSONTyped(json, false);
}
export function ValidateResetPasswordTokenResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isValid': json['isValid'],
    };
}
export function ValidateResetPasswordTokenResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isValid': value.isValid,
    };
}
