/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - **CREATED**: 티켓 상품이 선택되었고 티켓 주문이 생성된 상태.
 * - **PAYMENT_FAILED**: 결제에 실패한 상태.
 * - **PAYMENT_SUCCEEDED**: 정상적으로 결제된 후 티켓이 부여된 상태.
 * - **REVOKED**: 만료됨
 * - **REFUNDED**: 환불됨
 * @export
 */
export const TicketOrderStatus = {
    CREATED: 'CREATED',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    PAYMENT_SUCCEEDED: 'PAYMENT_SUCCEEDED',
    REVOKED: 'REVOKED',
    REFUNDED: 'REFUNDED'
};
export function TicketOrderStatusFromJSON(json) {
    return TicketOrderStatusFromJSONTyped(json, false);
}
export function TicketOrderStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TicketOrderStatusToJSON(value) {
    return value;
}
