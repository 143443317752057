/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CheckMyAvailabilityResponseFromJSON, CreateStripeCheckoutUrlRequestToJSON, CreateStripeCheckoutUrlResponseFromJSON, InvokeAuthorityFromCheckoutSessionRequestToJSON, InvokeAuthorityFromCheckoutSessionResponseFromJSON, } from '../models';
/**
 *
 */
export class StripeCheckoutV1Api extends runtime.BaseAPI {
    /**
     * 사용자가 상품을 구매할 수 있는 상태인지 확인한다.
     */
    async checkMyAvailabilityRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/commerce/v1/stripe/checkout/availability`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CheckMyAvailabilityResponseFromJSON(jsonValue));
    }
    /**
     * 사용자가 상품을 구매할 수 있는 상태인지 확인한다.
     */
    async checkMyAvailability(requestParameters, initOverrides) {
        const response = await this.checkMyAvailabilityRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 아래의 동작이 순서대로 발생한다. 1. Subscription record(In-App subscription과 다름) 생성 2. Stripe customer가 없으면 생성 3. Stripe checkout session 생성 4. Stripe checkout session url 반환
     */
    async createStripeCheckoutUrlRaw(requestParameters, initOverrides) {
        if (requestParameters.createStripeCheckoutUrlRequest === null || requestParameters.createStripeCheckoutUrlRequest === undefined) {
            throw new runtime.RequiredError('createStripeCheckoutUrlRequest', 'Required parameter requestParameters.createStripeCheckoutUrlRequest was null or undefined when calling createStripeCheckoutUrl.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/commerce/v1/stripe/checkout/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStripeCheckoutUrlRequestToJSON(requestParameters.createStripeCheckoutUrlRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateStripeCheckoutUrlResponseFromJSON(jsonValue));
    }
    /**
     * 아래의 동작이 순서대로 발생한다. 1. Subscription record(In-App subscription과 다름) 생성 2. Stripe customer가 없으면 생성 3. Stripe checkout session 생성 4. Stripe checkout session url 반환
     */
    async createStripeCheckoutUrl(requestParameters, initOverrides) {
        const response = await this.createStripeCheckoutUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 결제완료한 상품의 구독을 요청합니다.
     */
    async invokeAuthorityFromCheckoutSessionRaw(requestParameters, initOverrides) {
        if (requestParameters.invokeAuthorityFromCheckoutSessionRequest === null || requestParameters.invokeAuthorityFromCheckoutSessionRequest === undefined) {
            throw new runtime.RequiredError('invokeAuthorityFromCheckoutSessionRequest', 'Required parameter requestParameters.invokeAuthorityFromCheckoutSessionRequest was null or undefined when calling invokeAuthorityFromCheckoutSession.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/commerce/v1/stripe/checkout/invoke-authority`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvokeAuthorityFromCheckoutSessionRequestToJSON(requestParameters.invokeAuthorityFromCheckoutSessionRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => InvokeAuthorityFromCheckoutSessionResponseFromJSON(jsonValue));
    }
    /**
     * 결제완료한 상품의 구독을 요청합니다.
     */
    async invokeAuthorityFromCheckoutSession(requestParameters, initOverrides) {
        const response = await this.invokeAuthorityFromCheckoutSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
