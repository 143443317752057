import { VocaStateSelfReportFromJSON, VocaStateSelfReportToJSON, } from './VocaStateSelfReport';
/**
 * Check if a given object implements the VocaState interface.
 */
export function instanceOfVocaState(value) {
    let isInstance = true;
    isInstance = isInstance && "selfReports" in value;
    return isInstance;
}
export function VocaStateFromJSON(json) {
    return VocaStateFromJSONTyped(json, false);
}
export function VocaStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'selfReports': (json['selfReports'].map(VocaStateSelfReportFromJSON)),
    };
}
export function VocaStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'selfReports': (value.selfReports.map(VocaStateSelfReportToJSON)),
    };
}
