/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const UserRegistrationType = {
    ANONYMOUS: 'ANONYMOUS',
    EMAIL: 'EMAIL',
    KAKAO: 'KAKAO',
    APPLE: 'APPLE',
    GOOGLE: 'GOOGLE',
    LINE: 'LINE'
};
export function UserRegistrationTypeFromJSON(json) {
    return UserRegistrationTypeFromJSONTyped(json, false);
}
export function UserRegistrationTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UserRegistrationTypeToJSON(value) {
    return value;
}
