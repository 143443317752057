// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        imageUrlX1: "",
        imageUrlX2: "",
        imageUrlX3: "",
        imageX1Id: "0",
        imageX2Id: "0",
        imageX3Id: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.imageUrlX1 !== undefined)
        result.imageUrlX1 = tsValueToJsonValueFns.string(value.imageUrlX1);
    if (value.imageUrlX2 !== undefined)
        result.imageUrlX2 = tsValueToJsonValueFns.string(value.imageUrlX2);
    if (value.imageUrlX3 !== undefined)
        result.imageUrlX3 = tsValueToJsonValueFns.string(value.imageUrlX3);
    if (value.imageX1Id !== undefined)
        result.imageX1Id = tsValueToJsonValueFns.int64(value.imageX1Id);
    if (value.imageX2Id !== undefined)
        result.imageX2Id = tsValueToJsonValueFns.int64(value.imageX2Id);
    if (value.imageX3Id !== undefined)
        result.imageX3Id = tsValueToJsonValueFns.int64(value.imageX3Id);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.imageUrlX1 !== undefined)
        result.imageUrlX1 = jsonValueToTsValueFns.string(value.imageUrlX1);
    if (value.imageUrlX2 !== undefined)
        result.imageUrlX2 = jsonValueToTsValueFns.string(value.imageUrlX2);
    if (value.imageUrlX3 !== undefined)
        result.imageUrlX3 = jsonValueToTsValueFns.string(value.imageUrlX3);
    if (value.imageX1Id !== undefined)
        result.imageX1Id = jsonValueToTsValueFns.int64(value.imageX1Id);
    if (value.imageX2Id !== undefined)
        result.imageX2Id = jsonValueToTsValueFns.int64(value.imageX2Id);
    if (value.imageX3Id !== undefined)
        result.imageX3Id = jsonValueToTsValueFns.int64(value.imageX3Id);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.imageUrlX1 !== undefined) {
        const tsValue = value.imageUrlX1;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.imageUrlX2 !== undefined) {
        const tsValue = value.imageUrlX2;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.imageUrlX3 !== undefined) {
        const tsValue = value.imageUrlX3;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.imageX1Id !== undefined) {
        const tsValue = value.imageX1Id;
        result.push([5, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.imageX2Id !== undefined) {
        const tsValue = value.imageX2Id;
        result.push([6, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.imageX3Id !== undefined) {
        const tsValue = value.imageX3Id;
        result.push([7, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.imageUrlX1 = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.imageUrlX2 = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.imageUrlX3 = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.imageX1Id = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.imageX2Id = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.imageX3Id = value;
    }
    return result;
}
