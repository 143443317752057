/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const ToeicUserSegment = {
    UNKNOWN: 'UNKNOWN',
    BASIC: 'BASIC',
    BASIC_N: 'BASIC_N',
    INTERMEDIATE: 'INTERMEDIATE',
    INTERMEDIATE_N: 'INTERMEDIATE_N',
    ADVANCED: 'ADVANCED',
    ADVANCED_N: 'ADVANCED_N'
};
export function ToeicUserSegmentFromJSON(json) {
    return ToeicUserSegmentFromJSONTyped(json, false);
}
export function ToeicUserSegmentFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ToeicUserSegmentToJSON(value) {
    return value;
}
