export const num2name = {
    0: "DISCOUNT_TYPE_UNSPECIFIED",
    1: "AMOUNT",
    2: "PERCENT",
};
export const name2num = {
    DISCOUNT_TYPE_UNSPECIFIED: 0,
    AMOUNT: 1,
    PERCENT: 2,
};
