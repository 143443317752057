/**
 * Check if a given object implements the GoogleRegistrationInfo interface.
 */
export function instanceOfGoogleRegistrationInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "idToken" in value;
    return isInstance;
}
export function GoogleRegistrationInfoFromJSON(json) {
    return GoogleRegistrationInfoFromJSONTyped(json, false);
}
export function GoogleRegistrationInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idToken': json['idToken'],
    };
}
export function GoogleRegistrationInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'idToken': value.idToken,
    };
}
