/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const UserSegmentCase = {
    TOEIC: 'TOEIC',
    G_TELP: 'G_TELP'
};
export function UserSegmentCaseFromJSON(json) {
    return UserSegmentCaseFromJSONTyped(json, false);
}
export function UserSegmentCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UserSegmentCaseToJSON(value) {
    return value;
}
