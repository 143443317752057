/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the UpdateMyAgreementRequest interface.
 */
export function instanceOfUpdateMyAgreementRequest(value) {
    let isInstance = true;
    return isInstance;
}
export function UpdateMyAgreementRequestFromJSON(json) {
    return UpdateMyAgreementRequestFromJSONTyped(json, false);
}
export function UpdateMyAgreementRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'marketing': !exists(json, 'marketing') ? undefined : json['marketing'],
        'push': !exists(json, 'push') ? undefined : json['push'],
    };
}
export function UpdateMyAgreementRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'marketing': value.marketing,
        'push': value.push,
    };
}
