/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { TicketGroupFromJSON, TicketGroupToJSON, } from './TicketGroup';
/**
 * Check if a given object implements the ExchangeCouponConfig interface.
 */
export function instanceOfExchangeCouponConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "ticketQuantity" in value;
    isInstance = isInstance && "agency" in value;
    isInstance = isInstance && "ticketValidDays" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function ExchangeCouponConfigFromJSON(json) {
    return ExchangeCouponConfigFromJSONTyped(json, false);
}
export function ExchangeCouponConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketQuantity': json['ticketQuantity'],
        'issuedTicketGroup': !exists(json, 'issuedTicketGroup') ? undefined : TicketGroupFromJSON(json['issuedTicketGroup']),
        'agency': json['agency'],
        'ticketValidDays': json['ticketValidDays'],
        'description': json['description'],
        'id': json['id'],
    };
}
export function ExchangeCouponConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ticketQuantity': value.ticketQuantity,
        'issuedTicketGroup': TicketGroupToJSON(value.issuedTicketGroup),
        'agency': value.agency,
        'ticketValidDays': value.ticketValidDays,
        'description': value.description,
        'id': value.id,
    };
}
