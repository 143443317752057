/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const DiscountType = {
    AMOUNT: 'AMOUNT',
    RATE: 'RATE'
};
export function DiscountTypeFromJSON(json) {
    return DiscountTypeFromJSONTyped(json, false);
}
export function DiscountTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function DiscountTypeToJSON(value) {
    return value;
}
