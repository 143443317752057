// @ts-nocheck
import { name2num, num2name, } from "./OrderStatus";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./Product";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(ToeicOrder)/OrderConfig";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(ToeicOrder)/OrderData";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "../../google/protobuf/StringValue";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        userId: "",
        status: "ORDER_STATUS_UNSPECIFIED",
        product: undefined,
        config: undefined,
        derivedData: undefined,
        sourceBoardCode: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.userId !== undefined)
        result.userId = tsValueToJsonValueFns.string(value.userId);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    if (value.product !== undefined)
        result.product = encodeJson_1(value.product);
    if (value.config !== undefined)
        result.config = encodeJson_2(value.config);
    if (value.derivedData !== undefined)
        result.derivedData = encodeJson_3(value.derivedData);
    if (value.sourceBoardCode !== undefined)
        result.sourceBoardCode = value.sourceBoardCode;
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.userId !== undefined)
        result.userId = jsonValueToTsValueFns.string(value.userId);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    if (value.product !== undefined)
        result.product = decodeJson_1(value.product);
    if (value.config !== undefined)
        result.config = decodeJson_2(value.config);
    if (value.derivedData !== undefined)
        result.derivedData = decodeJson_3(value.derivedData);
    if (value.sourceBoardCode !== undefined)
        result.sourceBoardCode = value.sourceBoardCode;
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.userId !== undefined) {
        const tsValue = value.userId;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.product !== undefined) {
        const tsValue = value.product;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.config !== undefined) {
        const tsValue = value.config;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.derivedData !== undefined) {
        const tsValue = value.derivedData;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.sourceBoardCode !== undefined) {
        const tsValue = value.sourceBoardCode;
        result.push([7, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }))({ value: tsValue })]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.userId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.product = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.config = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.derivedData = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.sourceBoardCode = value;
    }
    return result;
}
