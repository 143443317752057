/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ListQuestionSetResponseFromJSON, } from '../models';
/**
 *
 */
export class QuestionSetV1Api extends runtime.BaseAPI {
    /**
     * QuestionSet의 목록을 조회합니다.
     */
    async listQuestionSetsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listQuestionSets.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/questionSet/v1/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListQuestionSetResponseFromJSON(jsonValue));
    }
    /**
     * QuestionSet의 목록을 조회합니다.
     */
    async listQuestionSets(requestParameters, initOverrides) {
        const response = await this.listQuestionSetsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
