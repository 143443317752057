import {useState, useEffect, useCallback, useRef, FormEvent} from 'react';
import {css, SerializedStyles} from '@emotion/react';
import {ReactQueryDevtoolsPanel} from '@tanstack/react-query-devtools';

const DEFAULT_HEIGHT = '300px';

interface ReactQueryDevToolsProps {
  css?: SerializedStyles;
}

export const ReactQueryDevTools = (props: ReactQueryDevToolsProps) => {
  const [isDevOpen, setIsDevOpen] = useState(false);
  const [height, setHeight] = useState(DEFAULT_HEIGHT);
  const heightInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const reactQueryDevToolsToggleKeyDown = (e: globalThis.KeyboardEvent) => {
      if (!isDevelopmentEnv()) return;

      e.stopPropagation();
      if (e.ctrlKey && e.code === 'KeyD') {
        setIsDevOpen(prev => !prev);
      }

      if (e.ctrlKey && e.code === 'KeyH') {
        heightInputRef.current?.focus();
      }
    };

    if (isDevelopmentEnv()) {
      window.addEventListener('keydown', reactQueryDevToolsToggleKeyDown);
    }

    return () => {
      if (isDevelopmentEnv()) {
        window.removeEventListener('keydown', reactQueryDevToolsToggleKeyDown);
      }
    };
  }, []);

  const handleDevToolsButtonClick = useCallback(() => {
    setIsDevOpen(false);
  }, []);

  const handleHeightKeyDown = useCallback((e: FormEvent<HTMLFormElement> & {target: {height: {value: string}}}) => {
    e.preventDefault();
    const height = e.target.height.value as string;
    if (height.match(/[\d]%/)) {
      setHeight(height);
      return;
    }
    if (height.match(/[\d]/)) {
      const heightNumber = Number(height);
      const isHeightOver = heightNumber > window.innerHeight * 0.7;
      setHeight(isHeightOver ? '70%' : `${height}px`);
      return;
    }

    setHeight(DEFAULT_HEIGHT);
  }, []);

  const shouldDevToolsOpen = isDevelopmentEnv() && isDevOpen;

  if (!shouldDevToolsOpen) return null;

  return (
    <div
      css={css`
        position: fixed;
        bottom: 0;
        width: 100%;
        height: ${height};
      `}
      {...props}>
      <div
        css={css`
          top: 25px;
          left: 70px;
          position: absolute;
          z-index: 10;
          color: white;
        `}>
        <form onSubmit={handleHeightKeyDown}>
          <label
            htmlFor="height"
            css={css`
              display: inline-block;
              width: 70px;
            `}>
            Height:
          </label>
          <input
            id="height"
            name="height"
            css={css`
              width: 100px;
            `}
            tabIndex={0}
            ref={heightInputRef}
          />
        </form>
      </div>
      <ReactQueryDevtoolsPanel
        isOpen
        setIsOpen={handleDevToolsButtonClick}
        onDragStart={() => {}}
        css={css`
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          position: absolute;
        `}
      />
    </div>
  );
};

function isDevelopmentEnv() {
  return process.env.NODE_ENV === 'development';
}
