/**
 * Check if a given object implements the UserToken interface.
 */
export function instanceOfUserToken(value) {
    let isInstance = true;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "authId" in value;
    isInstance = isInstance && "refreshToken" in value;
    return isInstance;
}
export function UserTokenFromJSON(json) {
    return UserTokenFromJSONTyped(json, false);
}
export function UserTokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accessToken': json['accessToken'],
        'authId': json['authId'],
        'refreshToken': json['refreshToken'],
    };
}
export function UserTokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accessToken': value.accessToken,
        'authId': value.authId,
        'refreshToken': value.refreshToken,
    };
}
