/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetResourceUrlResponseFromJSON, } from '../models';
/**
 *
 */
export class ResourceV1Api extends runtime.BaseAPI {
    /**
     * 리소스 조회를 위한 url 생성.
     */
    async getResourceUrlRaw(requestParameters, initOverrides) {
        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId', 'Required parameter requestParameters.resourceId was null or undefined when calling getResourceUrl.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/resource/v1/{resourceId}`.replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetResourceUrlResponseFromJSON(jsonValue));
    }
    /**
     * 리소스 조회를 위한 url 생성.
     */
    async getResourceUrl(requestParameters, initOverrides) {
        const response = await this.getResourceUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
