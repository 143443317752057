/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreateMyProfileRequestToJSON, CreateMyProfileResponseFromJSON, GetMyProfileResponseFromJSON, UpdateMyOnboardingInfoRequestToJSON, UpdateMyOnboardingInfoResponseFromJSON, UpdateMyTargetExamDateRequestToJSON, UpdateMyTargetExamDateResponseFromJSON, } from '../models';
/**
 *
 */
export class ProfileV1Api extends runtime.BaseAPI {
    /**
     * riiid-learning-domain 헤더에 대응하는 유저 프로필을 생성합니다.\\ 만약 request body가 지정된 경우 request body의 `targetScore` 및 `hasExperience` 값을 유저 프로필의 온보딩 정보에 반영합니다.
     */
    async createMyProfileRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/profile/me`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMyProfileRequestToJSON(requestParameters.createMyProfileRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateMyProfileResponseFromJSON(jsonValue));
    }
    /**
     * riiid-learning-domain 헤더에 대응하는 유저 프로필을 생성합니다.\\ 만약 request body가 지정된 경우 request body의 `targetScore` 및 `hasExperience` 값을 유저 프로필의 온보딩 정보에 반영합니다.
     */
    async createMyProfile(requestParameters, initOverrides) {
        const response = await this.createMyProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * riiid-learning-domain 헤더에 대응하는 유저 프로필 정보를 가져옵니다.
     */
    async getMyProfileRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/user/v1/profile/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetMyProfileResponseFromJSON(jsonValue));
    }
    /**
     * riiid-learning-domain 헤더에 대응하는 유저 프로필 정보를 가져옵니다.
     */
    async getMyProfile(requestParameters, initOverrides) {
        const response = await this.getMyProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * riiid-learning-domain 헤더에 대응하는 유저 온보딩 정보를 변경합니다.
     */
    async updateMyOnboardingInfoRaw(requestParameters, initOverrides) {
        if (requestParameters.updateMyOnboardingInfoRequest === null || requestParameters.updateMyOnboardingInfoRequest === undefined) {
            throw new runtime.RequiredError('updateMyOnboardingInfoRequest', 'Required parameter requestParameters.updateMyOnboardingInfoRequest was null or undefined when calling updateMyOnboardingInfo.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/profile/me/onboarding-info`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMyOnboardingInfoRequestToJSON(requestParameters.updateMyOnboardingInfoRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateMyOnboardingInfoResponseFromJSON(jsonValue));
    }
    /**
     * riiid-learning-domain 헤더에 대응하는 유저 온보딩 정보를 변경합니다.
     */
    async updateMyOnboardingInfo(requestParameters, initOverrides) {
        const response = await this.updateMyOnboardingInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * riiid-learning-domain 헤더에 대응하는 목표 시험일을 변경합니다.
     */
    async updateMyTargetExamDateRaw(requestParameters, initOverrides) {
        if (requestParameters.updateMyTargetExamDateRequest === null || requestParameters.updateMyTargetExamDateRequest === undefined) {
            throw new runtime.RequiredError('updateMyTargetExamDateRequest', 'Required parameter requestParameters.updateMyTargetExamDateRequest was null or undefined when calling updateMyTargetExamDate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/profile/me/target-exam-date`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMyTargetExamDateRequestToJSON(requestParameters.updateMyTargetExamDateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateMyTargetExamDateResponseFromJSON(jsonValue));
    }
    /**
     * riiid-learning-domain 헤더에 대응하는 목표 시험일을 변경합니다.
     */
    async updateMyTargetExamDate(requestParameters, initOverrides) {
        const response = await this.updateMyTargetExamDateRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
