// https://developer.apple.com/documentation/webkit/wkusercontentcontroller/1537172-add
const getIosMessageHandlers = () => { var _a; return (_a = window === null || window === void 0 ? void 0 : window.webkit) === null || _a === void 0 ? void 0 : _a.messageHandlers; };
const getIosRequestRiiidMobileService = () => { var _a; return (_a = getIosMessageHandlers()) === null || _a === void 0 ? void 0 : _a.requestRiiidMobileService; };
const getIosRespondRiiidBrowserService = () => { var _a; return (_a = getIosMessageHandlers()) === null || _a === void 0 ? void 0 : _a.respondRiiidBrowserService; };
const getIosRespondErrorRiiidBrowserService = () => { var _a; return (_a = getIosMessageHandlers()) === null || _a === void 0 ? void 0 : _a.respondErrorRiiidBrowserService; };
const getIosRequestRiiidMobileServicePostMessage = () => { var _a; return (_a = getIosRequestRiiidMobileService()) === null || _a === void 0 ? void 0 : _a.postMessage; };
const getIosRespondRiiidBrowserServicePostMessage = () => { var _a; return (_a = getIosRespondRiiidBrowserService()) === null || _a === void 0 ? void 0 : _a.postMessage; };
const getIosRespondErrorRiiidBrowserServicePostMessage = () => { var _a; return (_a = getIosRespondErrorRiiidBrowserService()) === null || _a === void 0 ? void 0 : _a.postMessage; };
const iosAppBridge = {
    get available() {
        return (typeof getIosRequestRiiidMobileServicePostMessage() === 'function' &&
            typeof getIosRespondRiiidBrowserServicePostMessage() === 'function');
        // `typeof getIosRespondErrorRiiidBrowserServicePostMessage() === 'function'` 조건도 확인해야 함이 옳으나
        // `respondErrorRiiidBrowserService` 메서드를 제공하지 않는 옛 버전의 네이티브 환경도 지원하기 위해 생략합니다.
    },
    requestMobileService(id, method, message) {
        getIosRequestRiiidMobileServicePostMessage().call(getIosRequestRiiidMobileService(), { id, method, message });
    },
    respondBrowserService(id, message) {
        getIosRespondRiiidBrowserServicePostMessage().call(getIosRespondRiiidBrowserService(), { id, message });
    },
    respondErrorBrowserService(id, errorMessage) {
        getIosRespondErrorRiiidBrowserServicePostMessage().call(getIosRespondErrorRiiidBrowserService(), {
            id,
            errorMessage,
        });
    },
};
export default iosAppBridge;
