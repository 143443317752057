/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CurrencyFromJSON, CurrencyToJSON, } from './Currency';
/**
 * Check if a given object implements the TicketOrderAmountData interface.
 */
export function instanceOfTicketOrderAmountData(value) {
    let isInstance = true;
    isInstance = isInstance && "productDefaultAmount" in value;
    isInstance = isInstance && "totalChargeAmount" in value;
    isInstance = isInstance && "currency" in value;
    return isInstance;
}
export function TicketOrderAmountDataFromJSON(json) {
    return TicketOrderAmountDataFromJSONTyped(json, false);
}
export function TicketOrderAmountDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'productDiscountAmount': !exists(json, 'productDiscountAmount') ? undefined : json['productDiscountAmount'],
        'productDefaultAmount': json['productDefaultAmount'],
        'totalChargeAmount': json['totalChargeAmount'],
        'currency': CurrencyFromJSON(json['currency']),
        'couponDiscountAmount': !exists(json, 'couponDiscountAmount') ? undefined : json['couponDiscountAmount'],
    };
}
export function TicketOrderAmountDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'productDiscountAmount': value.productDiscountAmount,
        'productDefaultAmount': value.productDefaultAmount,
        'totalChargeAmount': value.totalChargeAmount,
        'currency': CurrencyToJSON(value.currency),
        'couponDiscountAmount': value.couponDiscountAmount,
    };
}
