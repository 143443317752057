// @ts-nocheck
import { name2num, num2name, } from "./(GoToProductBoardRequest)/Referred";
import { name2num as name2num_1, num2name as num2name_1, } from "./(GoToProductBoardRequest)/UserPaidStatus";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        referred: "REFERRED_UNSPECIFIED",
        userPaidStatus: "USER_PAID_STATUS_UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.referred !== undefined)
        result.referred = tsValueToJsonValueFns.enum(value.referred);
    if (value.userPaidStatus !== undefined)
        result.userPaidStatus = tsValueToJsonValueFns.enum(value.userPaidStatus);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.referred !== undefined)
        result.referred = jsonValueToTsValueFns.enum(value.referred);
    if (value.userPaidStatus !== undefined)
        result.userPaidStatus = jsonValueToTsValueFns.enum(value.userPaidStatus);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.referred !== undefined) {
        const tsValue = value.referred;
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.userPaidStatus !== undefined) {
        const tsValue = value.userPaidStatus;
        result.push([2, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.referred = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.userPaidStatus = value;
    }
    return result;
}
