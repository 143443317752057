/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { ChatRoomResponseFromJSON, CreateOrGetChatRoomRequestToJSON, } from '../models';
/**
 *
 */
export class ChatRoomV1Api extends runtime.BaseAPI {
    /**
     * `ContentInteractionState.ID` 기반으로 채팅방을 생성하여 반환한다.\\ 이미 생성된 채팅방이 있다면 그대로 반환한다.  채팅방이 생성될 때, 내부적으로 웰컴 메시지를 생성하여 저장해둔다.  채팅방 생성을 성공하면, `getChatMessages`<sup>/rooms/{roomId}/messages</sup>를 호출하여 채팅 메시지 목록을 가져오면 된다.
     * 채팅방을 생성하거나 이미 있다면 그냥 가져온다.
     */
    async createOrGetChatRoomRaw(requestParameters, initOverrides) {
        if (requestParameters.createOrGetChatRoomRequest === null || requestParameters.createOrGetChatRoomRequest === undefined) {
            throw new runtime.RequiredError('createOrGetChatRoomRequest', 'Required parameter requestParameters.createOrGetChatRoomRequest was null or undefined when calling createOrGetChatRoom.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/content-learning-x/v1/chat/rooms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrGetChatRoomRequestToJSON(requestParameters.createOrGetChatRoomRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ChatRoomResponseFromJSON(jsonValue));
    }
    /**
     * `ContentInteractionState.ID` 기반으로 채팅방을 생성하여 반환한다.\\ 이미 생성된 채팅방이 있다면 그대로 반환한다.  채팅방이 생성될 때, 내부적으로 웰컴 메시지를 생성하여 저장해둔다.  채팅방 생성을 성공하면, `getChatMessages`<sup>/rooms/{roomId}/messages</sup>를 호출하여 채팅 메시지 목록을 가져오면 된다.
     * 채팅방을 생성하거나 이미 있다면 그냥 가져온다.
     */
    async createOrGetChatRoom(requestParameters, initOverrides) {
        const response = await this.createOrGetChatRoomRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
