export const num2name = {
    0: "UNSPECIFIED_CONTENT_UPLOAD_VALIDATION_STATUS",
    1: "CONTENT_BODY_NOT_UPLOADED",
    2: "VALIDATION_FAILED",
    3: "VALIDATION_SUCCESS",
};
export const name2num = {
    UNSPECIFIED_CONTENT_UPLOAD_VALIDATION_STATUS: 0,
    CONTENT_BODY_NOT_UPLOADED: 1,
    VALIDATION_FAILED: 2,
    VALIDATION_SUCCESS: 3,
};
