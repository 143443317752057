/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { DiagnosisConfigResponseFromJSON, GetDiagnosisResponseFromJSON, StartDiagnosisResponseFromJSON, } from '../models';
/**
 *
 */
export class DiagnosisV1Api extends runtime.BaseAPI {
    /**
     * 현재 사용자 및 사용자가 선택한 선택한 학습 도메인에 대한 진단고사 `LearningCell`의 정보를 불러온다.
     */
    async getDiagnosisRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/diagnosis/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetDiagnosisResponseFromJSON(jsonValue));
    }
    /**
     * 현재 사용자 및 사용자가 선택한 선택한 학습 도메인에 대한 진단고사 `LearningCell`의 정보를 불러온다.
     */
    async getDiagnosis(requestParameters, initOverrides) {
        const response = await this.getDiagnosisRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 현재 사용자의 언어 및 사용자가 선택한 학습 도메인에 해당하는 진단고사 설정을 조회한다.
     */
    async getDiagnosisConfigRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/diagnosis/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => DiagnosisConfigResponseFromJSON(jsonValue));
    }
    /**
     * 현재 사용자의 언어 및 사용자가 선택한 학습 도메인에 해당하는 진단고사 설정을 조회한다.
     */
    async getDiagnosisConfig(requestParameters, initOverrides) {
        const response = await this.getDiagnosisConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 현재 사용자 및 사용자가 선택한 선택한 학습 도메인에 대해 `READY` 상태의 진단고사 `LearningCell`을 생성한다.\\ 이와 동시에 현재 사용자 프로필의 진단고사 진행 과정이 `ONGOING`으로 변경된다.\\ 이후 진단고사 진행 과정 업데이트는 진단고사 `LearningCell`의 상태 변경으로만 가능하다. - 진단고사 `LearningCell`의 상태를 `READY` 혹은 `ONGOING`으로 변경 시 진단고사 진행 과정이 `ONGOING`으로 변경된다. - 진단고사 `LearningCell`의 상태를 `DONE`으로 변경 시 진단고사 진행 과정이 `DONE`으로 변경된다.
     */
    async startDiagnosisRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.diagnosisContentOption !== undefined) {
            queryParameters['diagnosisContentOption'] = requestParameters.diagnosisContentOption;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/diagnosis/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => StartDiagnosisResponseFromJSON(jsonValue));
    }
    /**
     * 현재 사용자 및 사용자가 선택한 선택한 학습 도메인에 대해 `READY` 상태의 진단고사 `LearningCell`을 생성한다.\\ 이와 동시에 현재 사용자 프로필의 진단고사 진행 과정이 `ONGOING`으로 변경된다.\\ 이후 진단고사 진행 과정 업데이트는 진단고사 `LearningCell`의 상태 변경으로만 가능하다. - 진단고사 `LearningCell`의 상태를 `READY` 혹은 `ONGOING`으로 변경 시 진단고사 진행 과정이 `ONGOING`으로 변경된다. - 진단고사 `LearningCell`의 상태를 `DONE`으로 변경 시 진단고사 진행 과정이 `DONE`으로 변경된다.
     */
    async startDiagnosis(requestParameters, initOverrides) {
        const response = await this.startDiagnosisRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
