export const num2name = {
    0: "USER_PAID_STATUS_UNSPECIFIED",
    1: "USER_PAID_STATUS_FREE",
    2: "USER_PAID_STATUS_PAID",
};
export const name2num = {
    USER_PAID_STATUS_UNSPECIFIED: 0,
    USER_PAID_STATUS_FREE: 1,
    USER_PAID_STATUS_PAID: 2,
};
