/* tslint:disable */
/* eslint-disable */
/**
 * - **PENDING**: OAuth authorization code flow로 가입했으나 약관동의와 회원정보 기입을 하지 않은 상태
 * - **ACTIVE**: 정상 계정
 * @export
 */
export const UserStatus = {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE'
};
export function UserStatusFromJSON(json) {
    return UserStatusFromJSONTyped(json, false);
}
export function UserStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UserStatusToJSON(value) {
    return value;
}
