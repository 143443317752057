/* tslint:disable */
/* eslint-disable */
/**
 * 학습 셀의 현재 상태.
 *
 * - **READY**: 아직 학습을 시작하지 않음
 * - **ONGOING**: 학습 진행 중. `ONGOING` 상태에서만 `ContentInteractionState` 생성이 가능하다.
 * - **DONE**: 학습 완료
 * - **LOCKED**: 학습할 수 있는 조건을 아직 만족하지 않음
 * @export
 */
export const LearningCellState = {
    READY: 'READY',
    ONGOING: 'ONGOING',
    DONE: 'DONE',
    LOCKED: 'LOCKED'
};
export function LearningCellStateFromJSON(json) {
    return LearningCellStateFromJSONTyped(json, false);
}
export function LearningCellStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LearningCellStateToJSON(value) {
    return value;
}
