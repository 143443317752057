import { encodeBinary, decodeBinary, encodeJson, } from "../../../messages/toeic/mobile/CompleteCellRequest";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, encodeJson as encodeJson_1, } from "../../../messages/toeic/mobile/CompleteCellResponse";
import { encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, encodeJson as encodeJson_2, } from "../../../messages/toeic/mobile/ShouldShowTutorialRequest";
import { encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, encodeJson as encodeJson_3, } from "../../../messages/toeic/mobile/ShouldShowTutorialResponse";
import { encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, encodeJson as encodeJson_4, } from "../../../messages/toeic/mobile/ConfirmOrderRequest";
import { encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, encodeJson as encodeJson_5, } from "../../../messages/toeic/mobile/ConfirmOrderResponse";
import { encodeBinary as encodeBinary_6, decodeBinary as decodeBinary_6, encodeJson as encodeJson_6, } from "../../../messages/toeic/mobile/CheckoutPaymentRequest";
import { encodeBinary as encodeBinary_7, decodeBinary as decodeBinary_7, encodeJson as encodeJson_7, } from "../../../messages/toeic/mobile/CheckoutPaymentResponse";
import { encodeBinary as encodeBinary_8, decodeBinary as decodeBinary_8, encodeJson as encodeJson_8, } from "../../../messages/toeic/mobile/CheckoutSubscriptionPaymentRequest";
import { encodeBinary as encodeBinary_9, decodeBinary as decodeBinary_9, encodeJson as encodeJson_9, } from "../../../messages/toeic/mobile/CheckoutSubscriptionPaymentResponse";
import { encodeBinary as encodeBinary_10, decodeBinary as decodeBinary_10, encodeJson as encodeJson_10, } from "../../../messages/toeic/mobile/CheckoutIamportPaymentRequest";
import { encodeBinary as encodeBinary_11, decodeBinary as decodeBinary_11, encodeJson as encodeJson_11, } from "../../../messages/toeic/mobile/CheckoutIamportPaymentResponse";
import { encodeBinary as encodeBinary_12, decodeBinary as decodeBinary_12, encodeJson as encodeJson_12, } from "../../../messages/toeic/mobile/SendIamportPaymentResultRequest";
import { encodeBinary as encodeBinary_13, decodeBinary as decodeBinary_13, encodeJson as encodeJson_13, } from "../../../messages/toeic/mobile/SendIamportPaymentResultResponse";
import { encodeBinary as encodeBinary_14, decodeBinary as decodeBinary_14, encodeJson as encodeJson_14, } from "../../../messages/toeic/mobile/ExecuteFulfillmentRequest";
import { encodeBinary as encodeBinary_15, decodeBinary as decodeBinary_15, encodeJson as encodeJson_15, } from "../../../messages/toeic/mobile/ExecuteFulfillmentResponse";
import { encodeBinary as encodeBinary_16, decodeBinary as decodeBinary_16, encodeJson as encodeJson_16, } from "../../../messages/toeic/mobile/SendEventTrackingRequest";
import { encodeBinary as encodeBinary_17, decodeBinary as decodeBinary_17, encodeJson as encodeJson_17, } from "../../../messages/toeic/mobile/SendEventTrackingResponse";
import { encodeBinary as encodeBinary_18, decodeBinary as decodeBinary_18, encodeJson as encodeJson_18, } from "../../../messages/toeic/mobile/OpenNewWindowRequest";
import { encodeBinary as encodeBinary_19, decodeBinary as decodeBinary_19, encodeJson as encodeJson_19, } from "../../../messages/toeic/mobile/OpenNewWindowResponse";
import { encodeBinary as encodeBinary_20, decodeBinary as decodeBinary_20, encodeJson as encodeJson_20, } from "../../../messages/toeic/mobile/OpenLinkRequest";
import { encodeBinary as encodeBinary_21, decodeBinary as decodeBinary_21, encodeJson as encodeJson_21, } from "../../../messages/toeic/mobile/OpenLinkResponse";
import { encodeBinary as encodeBinary_22, decodeBinary as decodeBinary_22, encodeJson as encodeJson_22, } from "../../../messages/toeic/mobile/GoToProductBoardRequest";
import { encodeBinary as encodeBinary_23, decodeBinary as decodeBinary_23, encodeJson as encodeJson_23, } from "../../../messages/toeic/mobile/GoToProductBoardResponse";
import { encodeBinary as encodeBinary_24, decodeBinary as decodeBinary_24, encodeJson as encodeJson_24, } from "../../../messages/toeic/mobile/GetAppInfoRequest";
import { encodeBinary as encodeBinary_25, decodeBinary as decodeBinary_25, encodeJson as encodeJson_25, } from "../../../messages/toeic/mobile/GetAppInfoResponse";
import { encodeBinary as encodeBinary_26, decodeBinary as decodeBinary_26, encodeJson as encodeJson_26, } from "../../../messages/toeic/mobile/SendHighlightEventRequest";
import { encodeBinary as encodeBinary_27, decodeBinary as decodeBinary_27, encodeJson as encodeJson_27, } from "../../../messages/toeic/mobile/SendHighlightEventResponse";
import { encodeBinary as encodeBinary_28, decodeBinary as decodeBinary_28, encodeJson as encodeJson_28, } from "../../../messages/toeic/mobile/GetABTestingExperimentRequest";
import { encodeBinary as encodeBinary_29, decodeBinary as decodeBinary_29, encodeJson as encodeJson_29, } from "../../../messages/toeic/mobile/GetABTestingExperimentResponse";
import { encodeBinary as encodeBinary_30, decodeBinary as decodeBinary_30, encodeJson as encodeJson_30, } from "../../../messages/toeic/mobile/GetNearbyCisIdsRequest";
import { encodeBinary as encodeBinary_31, decodeBinary as decodeBinary_31, encodeJson as encodeJson_31, } from "../../../messages/toeic/mobile/GetNearbyCisIdsResponse";
import { encodeBinary as encodeBinary_32, decodeBinary as decodeBinary_32, encodeJson as encodeJson_32, } from "../../../messages/toeic/mobile/TapStartDiagnosisButtonRequest";
import { encodeBinary as encodeBinary_33, decodeBinary as decodeBinary_33, encodeJson as encodeJson_33, } from "../../../messages/toeic/mobile/TapStartDiagnosisButtonResponse";
import { encodeBinary as encodeBinary_34, decodeBinary as decodeBinary_34, encodeJson as encodeJson_34, } from "../../../messages/toeic/mobile/NotifyWebviewIsReadyRequest";
import { encodeBinary as encodeBinary_35, decodeBinary as decodeBinary_35, encodeJson as encodeJson_35, } from "../../../messages/toeic/mobile/NotifyWebviewIsReadyResponse";
import { fromSingle, first, } from "../../../runtime/async/async-generator";
import { wrapRpcClientImpl, getDevtoolsConfig, } from "../../../runtime/client-devtools";
export const methodDescriptors = {
    completeCell: {
        methodName: "CompleteCell",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary,
            deserializeBinary: decodeBinary,
            serializeJson: (value) => JSON.stringify(encodeJson(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_1,
            deserializeBinary: decodeBinary_1,
            serializeJson: (value) => JSON.stringify(encodeJson_1(value)),
        },
    },
    shouldShowTutorial: {
        methodName: "ShouldShowTutorial",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_2,
            deserializeBinary: decodeBinary_2,
            serializeJson: (value) => JSON.stringify(encodeJson_2(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_3,
            deserializeBinary: decodeBinary_3,
            serializeJson: (value) => JSON.stringify(encodeJson_3(value)),
        },
    },
    confirmOrder: {
        methodName: "ConfirmOrder",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_4,
            deserializeBinary: decodeBinary_4,
            serializeJson: (value) => JSON.stringify(encodeJson_4(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_5,
            deserializeBinary: decodeBinary_5,
            serializeJson: (value) => JSON.stringify(encodeJson_5(value)),
        },
    },
    checkoutPayment: {
        methodName: "CheckoutPayment",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_6,
            deserializeBinary: decodeBinary_6,
            serializeJson: (value) => JSON.stringify(encodeJson_6(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_7,
            deserializeBinary: decodeBinary_7,
            serializeJson: (value) => JSON.stringify(encodeJson_7(value)),
        },
    },
    checkoutSubscriptionPayment: {
        methodName: "CheckoutSubscriptionPayment",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_8,
            deserializeBinary: decodeBinary_8,
            serializeJson: (value) => JSON.stringify(encodeJson_8(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_9,
            deserializeBinary: decodeBinary_9,
            serializeJson: (value) => JSON.stringify(encodeJson_9(value)),
        },
    },
    checkoutIamportPayment: {
        methodName: "CheckoutIamportPayment",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_10,
            deserializeBinary: decodeBinary_10,
            serializeJson: (value) => JSON.stringify(encodeJson_10(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_11,
            deserializeBinary: decodeBinary_11,
            serializeJson: (value) => JSON.stringify(encodeJson_11(value)),
        },
    },
    sendIamportPaymentResult: {
        methodName: "SendIamportPaymentResult",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_12,
            deserializeBinary: decodeBinary_12,
            serializeJson: (value) => JSON.stringify(encodeJson_12(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_13,
            deserializeBinary: decodeBinary_13,
            serializeJson: (value) => JSON.stringify(encodeJson_13(value)),
        },
    },
    executeFulfillment: {
        methodName: "ExecuteFulfillment",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_14,
            deserializeBinary: decodeBinary_14,
            serializeJson: (value) => JSON.stringify(encodeJson_14(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_15,
            deserializeBinary: decodeBinary_15,
            serializeJson: (value) => JSON.stringify(encodeJson_15(value)),
        },
    },
    sendEventTracking: {
        methodName: "SendEventTracking",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_16,
            deserializeBinary: decodeBinary_16,
            serializeJson: (value) => JSON.stringify(encodeJson_16(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_17,
            deserializeBinary: decodeBinary_17,
            serializeJson: (value) => JSON.stringify(encodeJson_17(value)),
        },
    },
    openNewWindow: {
        methodName: "OpenNewWindow",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_18,
            deserializeBinary: decodeBinary_18,
            serializeJson: (value) => JSON.stringify(encodeJson_18(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_19,
            deserializeBinary: decodeBinary_19,
            serializeJson: (value) => JSON.stringify(encodeJson_19(value)),
        },
    },
    openLink: {
        methodName: "OpenLink",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_20,
            deserializeBinary: decodeBinary_20,
            serializeJson: (value) => JSON.stringify(encodeJson_20(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_21,
            deserializeBinary: decodeBinary_21,
            serializeJson: (value) => JSON.stringify(encodeJson_21(value)),
        },
    },
    goToProductBoard: {
        methodName: "GoToProductBoard",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_22,
            deserializeBinary: decodeBinary_22,
            serializeJson: (value) => JSON.stringify(encodeJson_22(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_23,
            deserializeBinary: decodeBinary_23,
            serializeJson: (value) => JSON.stringify(encodeJson_23(value)),
        },
    },
    getAppInfo: {
        methodName: "GetAppInfo",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_24,
            deserializeBinary: decodeBinary_24,
            serializeJson: (value) => JSON.stringify(encodeJson_24(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_25,
            deserializeBinary: decodeBinary_25,
            serializeJson: (value) => JSON.stringify(encodeJson_25(value)),
        },
    },
    sendHighlightEvent: {
        methodName: "SendHighlightEvent",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_26,
            deserializeBinary: decodeBinary_26,
            serializeJson: (value) => JSON.stringify(encodeJson_26(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_27,
            deserializeBinary: decodeBinary_27,
            serializeJson: (value) => JSON.stringify(encodeJson_27(value)),
        },
    },
    getABTestingExperiment: {
        methodName: "GetABTestingExperiment",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_28,
            deserializeBinary: decodeBinary_28,
            serializeJson: (value) => JSON.stringify(encodeJson_28(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_29,
            deserializeBinary: decodeBinary_29,
            serializeJson: (value) => JSON.stringify(encodeJson_29(value)),
        },
    },
    getNearbyCisIds: {
        methodName: "GetNearbyCisIds",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_30,
            deserializeBinary: decodeBinary_30,
            serializeJson: (value) => JSON.stringify(encodeJson_30(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_31,
            deserializeBinary: decodeBinary_31,
            serializeJson: (value) => JSON.stringify(encodeJson_31(value)),
        },
    },
    tapStartDiagnosisButton: {
        methodName: "TapStartDiagnosisButton",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_32,
            deserializeBinary: decodeBinary_32,
            serializeJson: (value) => JSON.stringify(encodeJson_32(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_33,
            deserializeBinary: decodeBinary_33,
            serializeJson: (value) => JSON.stringify(encodeJson_33(value)),
        },
    },
    notifyWebviewIsReady: {
        methodName: "NotifyWebviewIsReady",
        service: { serviceName: "toeic.mobile.ToeicMobileService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_34,
            deserializeBinary: decodeBinary_34,
            serializeJson: (value) => JSON.stringify(encodeJson_34(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_35,
            deserializeBinary: decodeBinary_35,
            serializeJson: (value) => JSON.stringify(encodeJson_35(value)),
        },
    },
};
export class RpcError extends Error {
    constructor(trailer) {
        super();
        this.trailer = trailer;
    }
}
export function createServiceClient(rpcClientImpl, config) {
    var _a, _b;
    let _rpcClientImpl = rpcClientImpl;
    const responseOnly = (_a = config === null || config === void 0 ? void 0 : config.responseOnly) !== null && _a !== void 0 ? _a : true;
    const devtools = (_b = config === null || config === void 0 ? void 0 : config.devtools) !== null && _b !== void 0 ? _b : false;
    if (devtools) {
        const tags = devtools === true ? [] : devtools.tags;
        const devtoolsConfig = getDevtoolsConfig();
        _rpcClientImpl = wrapRpcClientImpl({ rpcClientImpl, devtoolsConfig, tags });
    }
    return Object.fromEntries(Object.entries(methodDescriptors).map(([camelRpcName, methodDescriptor]) => {
        const { requestStream, responseStream } = methodDescriptor;
        const rpcMethodImpl = _rpcClientImpl(methodDescriptor);
        const rpcMethodHandler = async (request, metadata) => {
            const reqAsyncGenerator = requestStream ? request : fromSingle(request);
            const rpcMethodResult = rpcMethodImpl(reqAsyncGenerator, metadata);
            const resAsyncGenerator = rpcMethodResult[0];
            const headerPromise = rpcMethodResult[1];
            const trailerPromise = rpcMethodResult[2];
            const [header, response] = await Promise.all([
                getHeaderBeforeTrailer(headerPromise, trailerPromise),
                responseStream ? resAsyncGenerator : first(resAsyncGenerator),
            ]);
            return responseOnly ? response : [response, header, trailerPromise];
        };
        return [camelRpcName, rpcMethodHandler];
    }));
}
function getHeaderBeforeTrailer(headerPromise, trailerPromise) {
    return Promise.race([
        headerPromise,
        trailerPromise.then(trailer => { throw new RpcError(trailer); }),
    ]);
}
