/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const TicketStatus = {
    UNUSED: 'UNUSED',
    USED: 'USED',
    EXPIRED: 'EXPIRED',
    REFUNDED: 'REFUNDED'
};
export function TicketStatusFromJSON(json) {
    return TicketStatusFromJSONTyped(json, false);
}
export function TicketStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TicketStatusToJSON(value) {
    return value;
}
