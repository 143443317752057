/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { IamportPgFromJSON, IamportPgToJSON, } from './IamportPg';
/**
 * Check if a given object implements the PaymentProvider interface.
 */
export function instanceOfPaymentProvider(value) {
    let isInstance = true;
    isInstance = isInstance && "iamportPgId" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function PaymentProviderFromJSON(json) {
    return PaymentProviderFromJSONTyped(json, false);
}
export function PaymentProviderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'iamportPgId': json['iamportPgId'],
        'iamportPg': !exists(json, 'iamportPg') ? undefined : IamportPgFromJSON(json['iamportPg']),
        'id': json['id'],
    };
}
export function PaymentProviderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'iamportPgId': value.iamportPgId,
        'iamportPg': IamportPgToJSON(value.iamportPg),
        'id': value.id,
    };
}
