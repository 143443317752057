/**
 * Check if a given object implements the ContentSummaryTitleAndContent interface.
 */
export function instanceOfContentSummaryTitleAndContent(value) {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "content" in value;
    return isInstance;
}
export function ContentSummaryTitleAndContentFromJSON(json) {
    return ContentSummaryTitleAndContentFromJSONTyped(json, false);
}
export function ContentSummaryTitleAndContentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': json['title'],
        'content': json['content'],
    };
}
export function ContentSummaryTitleAndContentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'content': value.content,
    };
}
