export var EXAM_VARIANT = {
    DIRECTION: "DIRECTION",
    PASSAGE: "PASSAGE",
    QUESTION: "QUESTION"
};
export var EXAM_PART_ARRAY = [
    "1",
    "2",
    "3",
    "4",
    "5"
];
// For non-production environment
export var SHORT_EXAM_INFO = {
    "1": [
        {
            variant: EXAM_VARIANT.DIRECTION,
            shouldWaitForAudioEnd: true
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            prepareTime: 3,
            responseTime: 3
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            prepareTime: 3,
            responseTime: 3
        }
    ],
    "2": [
        {
            variant: EXAM_VARIANT.DIRECTION,
            shouldWaitForAudioEnd: true
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            prepareTime: 3,
            responseTime: 3
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            prepareTime: 3,
            responseTime: 3
        }
    ],
    "3": [
        {
            variant: EXAM_VARIANT.DIRECTION,
            shouldWaitForAudioEnd: true
        },
        {
            variant: EXAM_VARIANT.PASSAGE,
            shouldWaitForAudioEnd: true
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            prepareTime: 3,
            responseTime: 3
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            prepareTime: 3,
            responseTime: 3
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            prepareTime: 3,
            responseTime: 3
        }
    ],
    "4": [
        {
            variant: EXAM_VARIANT.DIRECTION,
            shouldWaitForAudioEnd: true
        },
        {
            variant: EXAM_VARIANT.PASSAGE,
            shouldWaitForAudioEnd: true,
            prepareTime: 3
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            isPassageHidden: true,
            prepareTime: 3,
            responseTime: 3
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            isPassageHidden: true,
            prepareTime: 3,
            responseTime: 3
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            isPassageHidden: true,
            repeatCount: 2,
            prepareTime: 3,
            responseTime: 3
        }
    ],
    "5": [
        {
            variant: EXAM_VARIANT.DIRECTION,
            shouldWaitForAudioEnd: true
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            prepareTime: 3,
            responseTime: 3
        }
    ]
};
export var EXAM_INFO = {
    "1": [
        {
            variant: EXAM_VARIANT.DIRECTION,
            shouldWaitForAudioEnd: true
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            prepareTime: 45,
            responseTime: 45
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            prepareTime: 45,
            responseTime: 45
        }
    ],
    "2": [
        {
            variant: EXAM_VARIANT.DIRECTION,
            shouldWaitForAudioEnd: true
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            prepareTime: 45,
            responseTime: 30
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            prepareTime: 45,
            responseTime: 30
        }
    ],
    "3": [
        {
            variant: EXAM_VARIANT.DIRECTION,
            shouldWaitForAudioEnd: true
        },
        {
            variant: EXAM_VARIANT.PASSAGE,
            shouldWaitForAudioEnd: true
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            prepareTime: 3,
            responseTime: 15
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            prepareTime: 3,
            responseTime: 15
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            prepareTime: 3,
            responseTime: 30
        }
    ],
    "4": [
        {
            variant: EXAM_VARIANT.DIRECTION,
            shouldWaitForAudioEnd: true
        },
        {
            variant: EXAM_VARIANT.PASSAGE,
            shouldWaitForAudioEnd: true,
            prepareTime: 45
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            isPassageHidden: true,
            prepareTime: 3,
            responseTime: 15
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            isPassageHidden: true,
            prepareTime: 3,
            responseTime: 15
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            isPassageHidden: true,
            repeatCount: 2,
            prepareTime: 3,
            responseTime: 30
        }
    ],
    "5": [
        {
            variant: EXAM_VARIANT.DIRECTION,
            shouldWaitForAudioEnd: true
        },
        {
            variant: EXAM_VARIANT.QUESTION,
            shouldWaitForAudioEnd: true,
            prepareTime: 45,
            responseTime: 60
        }
    ]
};
export var EXAM_QUESTION_PER_PART = function() {
    var result = {};
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = EXAM_PART_ARRAY[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var part = _step.value;
            result[part] = SHORT_EXAM_INFO[part].filter(function(item) {
                return item.variant === EXAM_VARIANT.QUESTION;
            }).length;
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return result;
}();
export var EXAM_QUESTION_TOTAL_COUNT = Object.values(EXAM_QUESTION_PER_PART).reduce(function(acc, cur) {
    return acc + cur;
}, 0);
export var EXAM_QUESTION_NUMBERS = function() {
    var result = {};
    EXAM_PART_ARRAY.reduce(function(acc, cur) {
        var firstIndex = acc + 1;
        var lastIndex = firstIndex + EXAM_QUESTION_PER_PART[cur] - 1;
        result[cur] = Array(EXAM_QUESTION_PER_PART[cur]).fill(0).map(function(_, i) {
            return firstIndex + i;
        });
        return lastIndex;
    }, 0);
    return result;
}();
export var SHORT_EXAM_DIRECTIONS = {
    1: {
        title: "Read a text aloud",
        description: "In this part of the test, you will read aloud the text on the screen. You will have 45 seconds to prepare. Then you will have 45 seconds to read the text aloud.",
        audioSrc: "/assets/audio/short-direction.mp3"
    },
    2: {
        title: "Describe a picture",
        description: "In this part of the test, you will describe the picture on your screen in as much detail as you can. You will have 45 seconds to prepare your response. Then you will have 30 seconds to speak about the picture.",
        audioSrc: "/assets/audio/short-direction.mp3"
    },
    3: {
        title: "Respond to questions",
        description: "In this part of the test, you will answer three questions. You will have three seconds to prepare after you hear each question. You will have 15 seconds to respond to Questions 5 and 6 and 30 seconds to respond to Question 7.",
        audioSrc: "/assets/audio/short-direction.mp3"
    },
    4: {
        title: "Respond to questions using information provided",
        description: "In this part of the test, you will answer three questions based on the information provided. You will have 45 seconds to read the information before the questions begin. You will have three seconds to prepare and 15 seconds to respond to Questions 8 and 9. You will hear Question 10 two times. You will have three seconds to prepare and 30 seconds to respond to Question 10.",
        audioSrc: "/assets/audio/short-direction.mp3"
    },
    5: {
        title: "Express an opinion",
        description: "In this part of the test, you will give your opinion about a specific topic. Be sure to say as much as you can in the time allowed. You will have 45 seconds to prepare. Then you will have 60 seconds to speak.",
        audioSrc: "/assets/audio/short-direction.mp3"
    }
};
export var EXAM_DIRECTIONS = {
    1: {
        title: "Read a text aloud",
        description: "In this part of the test, you will read aloud the text on the screen. You will have 45 seconds to prepare. Then you will have 45 seconds to read the text aloud.",
        audioSrc: "/assets/audio/P1_direction.mp3"
    },
    2: {
        title: "Describe a picture",
        description: "In this part of the test, you will describe the picture on your screen in as much detail as you can. You will have 45 seconds to prepare your response. Then you will have 30 seconds to speak about the picture.",
        audioSrc: "/assets/audio/P2_direction.mp3"
    },
    3: {
        title: "Respond to questions",
        description: "In this part of the test, you will answer three questions. You will have three seconds to prepare after you hear each question. You will have 15 seconds to respond to Questions 5 and 6 and 30 seconds to respond to Question 7.",
        audioSrc: "/assets/audio/P3_direction.mp3"
    },
    4: {
        title: "Respond to questions using information provided",
        description: "In this part of the test, you will answer three questions based on the information provided. You will have 45 seconds to read the information before the questions begin. You will have three seconds to prepare and 15 seconds to respond to Questions 8 and 9. You will hear Question 10 two times. You will have three seconds to prepare and 30 seconds to respond to Question 10.",
        audioSrc: "/assets/audio/P4_direction.mp3"
    },
    5: {
        title: "Express an opinion",
        description: "In this part of the test, you will give your opinion about a specific topic. Be sure to say as much as you can in the time allowed. You will have 45 seconds to prepare. Then you will have 60 seconds to speak.",
        audioSrc: "/assets/audio/P5_direction.mp3"
    }
};
