/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { DiscountConfigFromJSON, DiscountConfigToJSON, } from './DiscountConfig';
import { PeriodFromJSON, PeriodToJSON, } from './Period';
/**
 * Check if a given object implements the SalesConfig interface.
 */
export function instanceOfSalesConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "salesPeriod" in value;
    return isInstance;
}
export function SalesConfigFromJSON(json) {
    return SalesConfigFromJSONTyped(json, false);
}
export function SalesConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'discountConfig': !exists(json, 'discountConfig') ? undefined : DiscountConfigFromJSON(json['discountConfig']),
        'salesPeriod': PeriodFromJSON(json['salesPeriod']),
    };
}
export function SalesConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'discountConfig': DiscountConfigToJSON(value.discountConfig),
        'salesPeriod': PeriodToJSON(value.salesPeriod),
    };
}
