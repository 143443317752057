export const num2name = {
    0: "COLOR_TYPE_UNSPECIFIED",
    1: "COLOR_TYPE_BLACK",
    2: "COLOR_TYPE_WHITE",
    3: "COLOR_TYPE_RED",
};
export const name2num = {
    COLOR_TYPE_UNSPECIFIED: 0,
    COLOR_TYPE_BLACK: 1,
    COLOR_TYPE_WHITE: 2,
    COLOR_TYPE_RED: 3,
};
