/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CreateReplyPlayEventResponseFromJSON, GetGrammarErrorCorrectionResponseFromJSON, UpdateReplyPlayEventRequestToJSON, } from '../models';
/**
 *
 */
export class ReplyV1Api extends runtime.BaseAPI {
    /**
     * 재생 진행률 세션을 시작합니다.
     */
    async createReplyPlayEventRaw(requestParameters, initOverrides) {
        if (requestParameters.replyId === null || requestParameters.replyId === undefined) {
            throw new runtime.RequiredError('replyId', 'Required parameter requestParameters.replyId was null or undefined when calling createReplyPlayEvent.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/reply/v1/{replyId}/play-event`.replace(`{${"replyId"}}`, encodeURIComponent(String(requestParameters.replyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateReplyPlayEventResponseFromJSON(jsonValue));
    }
    /**
     * 재생 진행률 세션을 시작합니다.
     */
    async createReplyPlayEvent(requestParameters, initOverrides) {
        const response = await this.createReplyPlayEventRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 문법교정 내용을 가져옵니다.
     */
    async getGrammarErrorCorrectionRaw(requestParameters, initOverrides) {
        if (requestParameters.replyId === null || requestParameters.replyId === undefined) {
            throw new runtime.RequiredError('replyId', 'Required parameter requestParameters.replyId was null or undefined when calling getGrammarErrorCorrection.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/reply/v1/{replyId}/grammar-error-correction`.replace(`{${"replyId"}}`, encodeURIComponent(String(requestParameters.replyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetGrammarErrorCorrectionResponseFromJSON(jsonValue));
    }
    /**
     * 문법교정 내용을 가져옵니다.
     */
    async getGrammarErrorCorrection(requestParameters, initOverrides) {
        const response = await this.getGrammarErrorCorrectionRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 재생 진행률을 업데이트 합니다.
     */
    async updateProgressRaw(requestParameters, initOverrides) {
        if (requestParameters.replyPlayEventId === null || requestParameters.replyPlayEventId === undefined) {
            throw new runtime.RequiredError('replyPlayEventId', 'Required parameter requestParameters.replyPlayEventId was null or undefined when calling updateProgress.');
        }
        if (requestParameters.updateReplyPlayEventRequest === null || requestParameters.updateReplyPlayEventRequest === undefined) {
            throw new runtime.RequiredError('updateReplyPlayEventRequest', 'Required parameter requestParameters.updateReplyPlayEventRequest was null or undefined when calling updateProgress.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/reply/v1/play-event/{replyPlayEventId}`.replace(`{${"replyPlayEventId"}}`, encodeURIComponent(String(requestParameters.replyPlayEventId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateReplyPlayEventRequestToJSON(requestParameters.updateReplyPlayEventRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 재생 진행률을 업데이트 합니다.
     */
    async updateProgress(requestParameters, initOverrides) {
        await this.updateProgressRaw(requestParameters, initOverrides);
    }
}
