// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../inside/payment/Payment";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../DecimalAmount";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../../google/protobuf/Timestamp";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "../../Coupon";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        payment: undefined,
        defaultGoodsAmount: undefined,
        optionGoodsAmount: undefined,
        totalGoodsAmount: undefined,
        baseDiscountAmount: undefined,
        couponDiscountAmount: undefined,
        totalChargeAmount: undefined,
        paymentSucceededAt: undefined,
        refundedAt: undefined,
        paymentBillId: "",
        createdAt: undefined,
        paymentRequestedAt: undefined,
        paymentAbortedAt: undefined,
        orderFulfilledAt: undefined,
        orderRefundRequestedAt: undefined,
        orderRefundedAt: undefined,
        orderCanceledAt: undefined,
        coupons: [],
        baseChargeAmount: undefined,
        totalDiscountAmount: undefined,
        refundAmount: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.payment !== undefined)
        result.payment = encodeJson_1(value.payment);
    if (value.defaultGoodsAmount !== undefined)
        result.defaultGoodsAmount = encodeJson_2(value.defaultGoodsAmount);
    if (value.optionGoodsAmount !== undefined)
        result.optionGoodsAmount = encodeJson_2(value.optionGoodsAmount);
    if (value.totalGoodsAmount !== undefined)
        result.totalGoodsAmount = encodeJson_2(value.totalGoodsAmount);
    if (value.baseDiscountAmount !== undefined)
        result.baseDiscountAmount = encodeJson_2(value.baseDiscountAmount);
    if (value.couponDiscountAmount !== undefined)
        result.couponDiscountAmount = encodeJson_2(value.couponDiscountAmount);
    if (value.totalChargeAmount !== undefined)
        result.totalChargeAmount = encodeJson_2(value.totalChargeAmount);
    if (value.paymentSucceededAt !== undefined)
        result.paymentSucceededAt = encodeJson_3(value.paymentSucceededAt);
    if (value.refundedAt !== undefined)
        result.refundedAt = encodeJson_3(value.refundedAt);
    if (value.paymentBillId !== undefined)
        result.paymentBillId = tsValueToJsonValueFns.string(value.paymentBillId);
    if (value.createdAt !== undefined)
        result.createdAt = encodeJson_3(value.createdAt);
    if (value.paymentRequestedAt !== undefined)
        result.paymentRequestedAt = encodeJson_3(value.paymentRequestedAt);
    if (value.paymentAbortedAt !== undefined)
        result.paymentAbortedAt = encodeJson_3(value.paymentAbortedAt);
    if (value.orderFulfilledAt !== undefined)
        result.orderFulfilledAt = encodeJson_3(value.orderFulfilledAt);
    if (value.orderRefundRequestedAt !== undefined)
        result.orderRefundRequestedAt = encodeJson_3(value.orderRefundRequestedAt);
    if (value.orderRefundedAt !== undefined)
        result.orderRefundedAt = encodeJson_3(value.orderRefundedAt);
    if (value.orderCanceledAt !== undefined)
        result.orderCanceledAt = encodeJson_3(value.orderCanceledAt);
    result.coupons = value.coupons.map(value => encodeJson_4(value));
    if (value.baseChargeAmount !== undefined)
        result.baseChargeAmount = encodeJson_2(value.baseChargeAmount);
    if (value.totalDiscountAmount !== undefined)
        result.totalDiscountAmount = encodeJson_2(value.totalDiscountAmount);
    if (value.refundAmount !== undefined)
        result.refundAmount = encodeJson_2(value.refundAmount);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.payment !== undefined)
        result.payment = decodeJson_1(value.payment);
    if (value.defaultGoodsAmount !== undefined)
        result.defaultGoodsAmount = decodeJson_2(value.defaultGoodsAmount);
    if (value.optionGoodsAmount !== undefined)
        result.optionGoodsAmount = decodeJson_2(value.optionGoodsAmount);
    if (value.totalGoodsAmount !== undefined)
        result.totalGoodsAmount = decodeJson_2(value.totalGoodsAmount);
    if (value.baseDiscountAmount !== undefined)
        result.baseDiscountAmount = decodeJson_2(value.baseDiscountAmount);
    if (value.couponDiscountAmount !== undefined)
        result.couponDiscountAmount = decodeJson_2(value.couponDiscountAmount);
    if (value.totalChargeAmount !== undefined)
        result.totalChargeAmount = decodeJson_2(value.totalChargeAmount);
    if (value.paymentSucceededAt !== undefined)
        result.paymentSucceededAt = decodeJson_3(value.paymentSucceededAt);
    if (value.refundedAt !== undefined)
        result.refundedAt = decodeJson_3(value.refundedAt);
    if (value.paymentBillId !== undefined)
        result.paymentBillId = jsonValueToTsValueFns.string(value.paymentBillId);
    if (value.createdAt !== undefined)
        result.createdAt = decodeJson_3(value.createdAt);
    if (value.paymentRequestedAt !== undefined)
        result.paymentRequestedAt = decodeJson_3(value.paymentRequestedAt);
    if (value.paymentAbortedAt !== undefined)
        result.paymentAbortedAt = decodeJson_3(value.paymentAbortedAt);
    if (value.orderFulfilledAt !== undefined)
        result.orderFulfilledAt = decodeJson_3(value.orderFulfilledAt);
    if (value.orderRefundRequestedAt !== undefined)
        result.orderRefundRequestedAt = decodeJson_3(value.orderRefundRequestedAt);
    if (value.orderRefundedAt !== undefined)
        result.orderRefundedAt = decodeJson_3(value.orderRefundedAt);
    if (value.orderCanceledAt !== undefined)
        result.orderCanceledAt = decodeJson_3(value.orderCanceledAt);
    result.coupons = (_b = (_a = value.coupons) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_4(value))) !== null && _b !== void 0 ? _b : [];
    if (value.baseChargeAmount !== undefined)
        result.baseChargeAmount = decodeJson_2(value.baseChargeAmount);
    if (value.totalDiscountAmount !== undefined)
        result.totalDiscountAmount = decodeJson_2(value.totalDiscountAmount);
    if (value.refundAmount !== undefined)
        result.refundAmount = decodeJson_2(value.refundAmount);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.payment !== undefined) {
        const tsValue = value.payment;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.defaultGoodsAmount !== undefined) {
        const tsValue = value.defaultGoodsAmount;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.optionGoodsAmount !== undefined) {
        const tsValue = value.optionGoodsAmount;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.totalGoodsAmount !== undefined) {
        const tsValue = value.totalGoodsAmount;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.baseDiscountAmount !== undefined) {
        const tsValue = value.baseDiscountAmount;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.couponDiscountAmount !== undefined) {
        const tsValue = value.couponDiscountAmount;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.totalChargeAmount !== undefined) {
        const tsValue = value.totalChargeAmount;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.paymentSucceededAt !== undefined) {
        const tsValue = value.paymentSucceededAt;
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.refundedAt !== undefined) {
        const tsValue = value.refundedAt;
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.paymentBillId !== undefined) {
        const tsValue = value.paymentBillId;
        result.push([10, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.createdAt !== undefined) {
        const tsValue = value.createdAt;
        result.push([11, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.paymentRequestedAt !== undefined) {
        const tsValue = value.paymentRequestedAt;
        result.push([12, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.paymentAbortedAt !== undefined) {
        const tsValue = value.paymentAbortedAt;
        result.push([13, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.orderFulfilledAt !== undefined) {
        const tsValue = value.orderFulfilledAt;
        result.push([14, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.orderRefundRequestedAt !== undefined) {
        const tsValue = value.orderRefundRequestedAt;
        result.push([15, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.orderRefundedAt !== undefined) {
        const tsValue = value.orderRefundedAt;
        result.push([16, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.orderCanceledAt !== undefined) {
        const tsValue = value.orderCanceledAt;
        result.push([17, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    for (const tsValue of value.coupons) {
        result.push([18, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    if (value.baseChargeAmount !== undefined) {
        const tsValue = value.baseChargeAmount;
        result.push([19, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.totalDiscountAmount !== undefined) {
        const tsValue = value.totalDiscountAmount;
        result.push([20, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.refundAmount !== undefined) {
        const tsValue = value.refundAmount;
        result.push([21, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.payment = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.defaultGoodsAmount = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.optionGoodsAmount = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.totalGoodsAmount = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.baseDiscountAmount = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.couponDiscountAmount = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.totalChargeAmount = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.paymentSucceededAt = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.refundedAt = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.paymentBillId = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAt = value;
    }
    field: {
        const wireValue = wireFields.get(12);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.paymentRequestedAt = value;
    }
    field: {
        const wireValue = wireFields.get(13);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.paymentAbortedAt = value;
    }
    field: {
        const wireValue = wireFields.get(14);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.orderFulfilledAt = value;
    }
    field: {
        const wireValue = wireFields.get(15);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.orderRefundRequestedAt = value;
    }
    field: {
        const wireValue = wireFields.get(16);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.orderRefundedAt = value;
    }
    field: {
        const wireValue = wireFields.get(17);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.orderCanceledAt = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 18).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.coupons = value;
    }
    field: {
        const wireValue = wireFields.get(19);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.baseChargeAmount = value;
    }
    field: {
        const wireValue = wireFields.get(20);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.totalDiscountAmount = value;
    }
    field: {
        const wireValue = wireFields.get(21);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.refundAmount = value;
    }
    return result;
}
