/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { ListVisibleLearningDomainsResponseFromJSON, } from '../models';
/**
 *
 */
export class LearningDomainV1Api extends runtime.BaseAPI {
    /**
     * 유저의 언어에서 노출가능한 학습 도메인을 가져옵니다.
     */
    async listVisibleLearningDomainsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listVisibleLearningDomains.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/learning-domain/v1/learning-domain/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListVisibleLearningDomainsResponseFromJSON(jsonValue));
    }
    /**
     * 유저의 언어에서 노출가능한 학습 도메인을 가져옵니다.
     */
    async listVisibleLearningDomains(requestParameters, initOverrides) {
        const response = await this.listVisibleLearningDomainsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
