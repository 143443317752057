export const num2name = {
    0: "LESSON_UNSPECIFIED",
    1: "LESSON_STARTER",
    2: "LESSON_INTRO",
    3: "LESSON_BASIC_LC",
    4: "LESSON_BASIC_RC",
    5: "LESSON_CONCEPT_LC",
    6: "LESSON_CONCEPT_RC",
    7: "LESSON_SOLVING_QUESTION_LC",
    8: "LESSON_SOLVING_QUESTION_RC",
    9: "LESSON_ADVANCED",
    10: "LESSON_CONCEPT_RC_VOCA_GRAMMAR",
    11: "LESSON_CONCEPT_RC_READING",
    12: "LESSON_SOLVING_QUESTION_P1_P2",
    13: "LESSON_SOLVING_QUESTION_P3_P4",
    14: "LESSON_SOLVING_QUESTION_P5",
    15: "LESSON_SOLVING_QUESTION_P6",
    16: "LESSON_SOLVING_QUESTION_P7",
};
export const name2num = {
    LESSON_UNSPECIFIED: 0,
    LESSON_STARTER: 1,
    LESSON_INTRO: 2,
    LESSON_BASIC_LC: 3,
    LESSON_BASIC_RC: 4,
    LESSON_CONCEPT_LC: 5,
    LESSON_CONCEPT_RC: 6,
    LESSON_SOLVING_QUESTION_LC: 7,
    LESSON_SOLVING_QUESTION_RC: 8,
    LESSON_ADVANCED: 9,
    LESSON_CONCEPT_RC_VOCA_GRAMMAR: 10,
    LESSON_CONCEPT_RC_READING: 11,
    LESSON_SOLVING_QUESTION_P1_P2: 12,
    LESSON_SOLVING_QUESTION_P3_P4: 13,
    LESSON_SOLVING_QUESTION_P5: 14,
    LESSON_SOLVING_QUESTION_P6: 15,
    LESSON_SOLVING_QUESTION_P7: 16,
};
