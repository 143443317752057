/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CreateTicketOrderRequestToJSON, ListTicketOrderResponseFromJSON, TicketOrderFromJSON, UpdateTicketOrderRequestToJSON, } from '../models';
/**
 *
 */
export class TicketOrderV1Api extends runtime.BaseAPI {
    /**
     * 주문을 생성합니다.
     */
    async createTicketOrderRaw(requestParameters, initOverrides) {
        if (requestParameters.createTicketOrderRequest === null || requestParameters.createTicketOrderRequest === undefined) {
            throw new runtime.RequiredError('createTicketOrderRequest', 'Required parameter requestParameters.createTicketOrderRequest was null or undefined when calling createTicketOrder.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/ticketOrder/v1/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTicketOrderRequestToJSON(requestParameters.createTicketOrderRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderFromJSON(jsonValue));
    }
    /**
     * 주문을 생성합니다.
     */
    async createTicketOrder(requestParameters, initOverrides) {
        const response = await this.createTicketOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주문을 조회합니다.
     */
    async getTicketOrderRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getTicketOrder.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/ticketOrder/v1/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderFromJSON(jsonValue));
    }
    /**
     * 주문을 조회합니다.
     */
    async getTicketOrder(requestParameters, initOverrides) {
        const response = await this.getTicketOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주문목록을 조회합니다.
     */
    async listTicketOrdersRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listTicketOrders.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/ticketOrder/v1/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListTicketOrderResponseFromJSON(jsonValue));
    }
    /**
     * 주문목록을 조회합니다.
     */
    async listTicketOrders(requestParameters, initOverrides) {
        const response = await this.listTicketOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주문을 업데이트합니다.
     */
    async updateTicketOrderRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateTicketOrder.');
        }
        if (requestParameters.updateTicketOrderRequest === null || requestParameters.updateTicketOrderRequest === undefined) {
            throw new runtime.RequiredError('updateTicketOrderRequest', 'Required parameter requestParameters.updateTicketOrderRequest was null or undefined when calling updateTicketOrder.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/ticketOrder/v1/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTicketOrderRequestToJSON(requestParameters.updateTicketOrderRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderFromJSON(jsonValue));
    }
    /**
     * 주문을 업데이트합니다.
     */
    async updateTicketOrder(requestParameters, initOverrides) {
        const response = await this.updateTicketOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
