/**
 * Check if a given object implements the AuthenticateWithGoogleAuthorizationCodeRequest interface.
 */
export function instanceOfAuthenticateWithGoogleAuthorizationCodeRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "authorizationCode" in value;
    return isInstance;
}
export function AuthenticateWithGoogleAuthorizationCodeRequestFromJSON(json) {
    return AuthenticateWithGoogleAuthorizationCodeRequestFromJSONTyped(json, false);
}
export function AuthenticateWithGoogleAuthorizationCodeRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'authorizationCode': json['authorizationCode'],
    };
}
export function AuthenticateWithGoogleAuthorizationCodeRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'authorizationCode': value.authorizationCode,
    };
}
