import React from 'react';

import {CommonBrowserServiceMethodImplMap} from '@app/api/browser-services';
import useMobileSafeArea from '@app/hooks/useMobileSafeArea';

const useBaseCommonBrowserService = (): Partial<CommonBrowserServiceMethodImplMap> => {
  const mobileSafeArea = useMobileSafeArea();

  const refresh = React.useCallback(() => {
    window.location.reload();
    return {};
  }, []);

  const refreshSafeArea = React.useCallback(() => {
    mobileSafeArea.refetch();
    return {};
  }, [mobileSafeArea]);

  return React.useMemo(() => {
    return {
      refresh,
      refreshSafeArea,
    };
  }, [refresh, refreshSafeArea]);
};

export default useBaseCommonBrowserService;
