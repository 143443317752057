// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./DecimalAmount";
import { name2num, num2name, } from "./(Coupon)/Type";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../google/protobuf/Timestamp";
import { name2num as name2num_1, num2name as num2name_1, } from "./(Coupon)/Status";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../TimeRange";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as Long, } from "../../runtime/Long";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        issuedCouponId: "0",
        couponId: "0",
        type: "TYPE_UNSPECIFIED",
        minPurchasePrice: undefined,
        maxDiscountAmount: undefined,
        canOverlap: false,
        registeredAt: undefined,
        status: "STATUS_UNSPECIFIED",
        validUseRange: undefined,
        name: "",
        discount: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.issuedCouponId !== undefined)
        result.issuedCouponId = tsValueToJsonValueFns.int64(value.issuedCouponId);
    if (value.couponId !== undefined)
        result.couponId = tsValueToJsonValueFns.int64(value.couponId);
    if (value.type !== undefined)
        result.type = tsValueToJsonValueFns.enum(value.type);
    if (value.minPurchasePrice !== undefined)
        result.minPurchasePrice = encodeJson_1(value.minPurchasePrice);
    if (value.maxDiscountAmount !== undefined)
        result.maxDiscountAmount = encodeJson_1(value.maxDiscountAmount);
    if (value.canOverlap !== undefined)
        result.canOverlap = tsValueToJsonValueFns.bool(value.canOverlap);
    if (value.registeredAt !== undefined)
        result.registeredAt = encodeJson_2(value.registeredAt);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    if (value.validUseRange !== undefined)
        result.validUseRange = encodeJson_3(value.validUseRange);
    if (value.name !== undefined)
        result.name = tsValueToJsonValueFns.string(value.name);
    switch ((_a = value.discount) === null || _a === void 0 ? void 0 : _a.field) {
        case "discountRate": {
            result.discountRate = encodeJson_1(value.discount.value);
            break;
        }
        case "discountAmount": {
            result.discountAmount = encodeJson_1(value.discount.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.issuedCouponId !== undefined)
        result.issuedCouponId = jsonValueToTsValueFns.int64(value.issuedCouponId);
    if (value.couponId !== undefined)
        result.couponId = jsonValueToTsValueFns.int64(value.couponId);
    if (value.type !== undefined)
        result.type = jsonValueToTsValueFns.enum(value.type);
    if (value.minPurchasePrice !== undefined)
        result.minPurchasePrice = decodeJson_1(value.minPurchasePrice);
    if (value.maxDiscountAmount !== undefined)
        result.maxDiscountAmount = decodeJson_1(value.maxDiscountAmount);
    if (value.canOverlap !== undefined)
        result.canOverlap = jsonValueToTsValueFns.bool(value.canOverlap);
    if (value.registeredAt !== undefined)
        result.registeredAt = decodeJson_2(value.registeredAt);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    if (value.validUseRange !== undefined)
        result.validUseRange = decodeJson_3(value.validUseRange);
    if (value.name !== undefined)
        result.name = jsonValueToTsValueFns.string(value.name);
    if (value.discountRate !== undefined)
        result.discount = { field: "discountRate", value: decodeJson_1(value.discountRate) };
    if (value.discountAmount !== undefined)
        result.discount = { field: "discountAmount", value: decodeJson_1(value.discountAmount) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.issuedCouponId !== undefined) {
        const tsValue = value.issuedCouponId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.couponId !== undefined) {
        const tsValue = value.couponId;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.type !== undefined) {
        const tsValue = value.type;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.minPurchasePrice !== undefined) {
        const tsValue = value.minPurchasePrice;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.maxDiscountAmount !== undefined) {
        const tsValue = value.maxDiscountAmount;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.canOverlap !== undefined) {
        const tsValue = value.canOverlap;
        result.push([8, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.registeredAt !== undefined) {
        const tsValue = value.registeredAt;
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([10, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.validUseRange !== undefined) {
        const tsValue = value.validUseRange;
        result.push([11, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.name !== undefined) {
        const tsValue = value.name;
        result.push([12, tsValueToWireValueFns.string(tsValue)]);
    }
    switch ((_a = value.discount) === null || _a === void 0 ? void 0 : _a.field) {
        case "discountRate": {
            const tsValue = value.discount.value;
            result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
        case "discountAmount": {
            const tsValue = value.discount.value;
            result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    discount: new Set([4, 5]),
};
const oneofFieldNamesMap = {
    discount: new Map([
        [4, "discountRate"],
        [5, "discountAmount"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.issuedCouponId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.couponId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.type = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.minPurchasePrice = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.maxDiscountAmount = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.canOverlap = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.registeredAt = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.validUseRange = value;
    }
    field: {
        const wireValue = wireFields.get(12);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.name = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.discount;
        const oneofFieldNames = oneofFieldNamesMap.discount;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [4](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
            [5](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.discount = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
