/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionResultDetailCategoryFromJSON, QuestionResultDetailCategoryToJSON, } from './QuestionResultDetailCategory';
/**
 * Check if a given object implements the QuestionResultDetail interface.
 */
export function instanceOfQuestionResultDetail(value) {
    let isInstance = true;
    isInstance = isInstance && "averageProficiencyOfSameLevelCandidates" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "proficiency" in value;
    return isInstance;
}
export function QuestionResultDetailFromJSON(json) {
    return QuestionResultDetailFromJSONTyped(json, false);
}
export function QuestionResultDetailFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'averageProficiencyOfSameLevelCandidates': json['averageProficiencyOfSameLevelCandidates'],
        'category': QuestionResultDetailCategoryFromJSON(json['category']),
        'proficiency': json['proficiency'],
    };
}
export function QuestionResultDetailToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'averageProficiencyOfSameLevelCandidates': value.averageProficiencyOfSameLevelCandidates,
        'category': QuestionResultDetailCategoryToJSON(value.category),
        'proficiency': value.proficiency,
    };
}
