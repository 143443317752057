import {getAppBridge} from '@santa-web/gen/ssp/app-bridge';

export const isAppBridgeAvailable = () => {
  try {
    getAppBridge();
    return true;
  } catch {
    return false;
  }
};
