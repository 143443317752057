/**
 * Check if a given object implements the UserAgreementItem interface.
 */
export function instanceOfUserAgreementItem(value) {
    let isInstance = true;
    isInstance = isInstance && "agreed" in value;
    isInstance = isInstance && "chosenAt" in value;
    return isInstance;
}
export function UserAgreementItemFromJSON(json) {
    return UserAgreementItemFromJSONTyped(json, false);
}
export function UserAgreementItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'agreed': json['agreed'],
        'chosenAt': (new Date(json['chosenAt'])),
    };
}
export function UserAgreementItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'agreed': value.agreed,
        'chosenAt': (value.chosenAt.toISOString()),
    };
}
