import { KakaoRegistrationInfoFromJSON, KakaoRegistrationInfoToJSON, } from './KakaoRegistrationInfo';
/**
 * Check if a given object implements the LoginWithKakaoRequest interface.
 */
export function instanceOfLoginWithKakaoRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "kakaoRegistrationInfo" in value;
    return isInstance;
}
export function LoginWithKakaoRequestFromJSON(json) {
    return LoginWithKakaoRequestFromJSONTyped(json, false);
}
export function LoginWithKakaoRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'kakaoRegistrationInfo': KakaoRegistrationInfoFromJSON(json['kakaoRegistrationInfo']),
    };
}
export function LoginWithKakaoRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'kakaoRegistrationInfo': KakaoRegistrationInfoToJSON(value.kakaoRegistrationInfo),
    };
}
