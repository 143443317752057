export const num2name = {
    0: "COLOR_UNSPECIFIED",
    1: "COLOR_BRAND",
    2: "COLOR_RIIID_RED",
    3: "COLOR_RIIID_PURPLE",
    4: "COLOR_RIIID_YELLOW",
    5: "COLOR_RIIID_GREEN",
};
export const name2num = {
    COLOR_UNSPECIFIED: 0,
    COLOR_BRAND: 1,
    COLOR_RIIID_RED: 2,
    COLOR_RIIID_PURPLE: 3,
    COLOR_RIIID_YELLOW: 4,
    COLOR_RIIID_GREEN: 5,
};
