/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { StripeProductBoardFromJSON, } from '../models';
/**
 *
 */
export class StripeProductBoardV1Api extends runtime.BaseAPI {
    /**
     */
    async getStripeSubscriptionProductBoardRaw(requestParameters, initOverrides) {
        if (requestParameters.boardCode === null || requestParameters.boardCode === undefined) {
            throw new runtime.RequiredError('boardCode', 'Required parameter requestParameters.boardCode was null or undefined when calling getStripeSubscriptionProductBoard.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/commerce/v1/stripe/product-board/{boardCode}`.replace(`{${"boardCode"}}`, encodeURIComponent(String(requestParameters.boardCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => StripeProductBoardFromJSON(jsonValue));
    }
    /**
     */
    async getStripeSubscriptionProductBoard(requestParameters, initOverrides) {
        const response = await this.getStripeSubscriptionProductBoardRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
