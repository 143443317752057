export const num2name = {
    0: "VOCABULARY_UNSPECIFIED",
    1: "VOCABULARY_BASIC_BASIC",
    2: "VOCABULARY_BASIC_LC",
    3: "VOCABULARY_BASIC_RC",
    4: "VOCABULARY_BASIC_PART1",
    5: "VOCABULARY_ADVANCED_700",
    6: "VOCABULARY_ADVANCED_800",
    7: "VOCABULARY_ADVANCED_900",
};
export const name2num = {
    VOCABULARY_UNSPECIFIED: 0,
    VOCABULARY_BASIC_BASIC: 1,
    VOCABULARY_BASIC_LC: 2,
    VOCABULARY_BASIC_RC: 3,
    VOCABULARY_BASIC_PART1: 4,
    VOCABULARY_ADVANCED_700: 5,
    VOCABULARY_ADVANCED_800: 6,
    VOCABULARY_ADVANCED_900: 7,
};
