// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        name: "",
        propertyJson: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.name !== undefined)
        result.name = tsValueToJsonValueFns.string(value.name);
    if (value.propertyJson !== undefined)
        result.propertyJson = tsValueToJsonValueFns.string(value.propertyJson);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.name !== undefined)
        result.name = jsonValueToTsValueFns.string(value.name);
    if (value.propertyJson !== undefined)
        result.propertyJson = jsonValueToTsValueFns.string(value.propertyJson);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.name !== undefined) {
        const tsValue = value.name;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.propertyJson !== undefined) {
        const tsValue = value.propertyJson;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.name = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.propertyJson = value;
    }
    return result;
}
