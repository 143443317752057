import { KakaoRegistrationInfoFromJSON, KakaoRegistrationInfoToJSON, } from './KakaoRegistrationInfo';
/**
 * Check if a given object implements the CheckUserExistsWithKakaoRequest interface.
 */
export function instanceOfCheckUserExistsWithKakaoRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "registrationInfo" in value;
    return isInstance;
}
export function CheckUserExistsWithKakaoRequestFromJSON(json) {
    return CheckUserExistsWithKakaoRequestFromJSONTyped(json, false);
}
export function CheckUserExistsWithKakaoRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'registrationInfo': KakaoRegistrationInfoFromJSON(json['registrationInfo']),
    };
}
export function CheckUserExistsWithKakaoRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'registrationInfo': KakaoRegistrationInfoToJSON(value.registrationInfo),
    };
}
