/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { DiscountCouponTypeFromJSON, DiscountCouponTypeToJSON, } from './DiscountCouponType';
import { PriceFromJSON, PriceToJSON, } from './Price';
/**
 * Check if a given object implements the DiscountCouponConfig interface.
 */
export function instanceOfDiscountCouponConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "minPurchasePrice" in value;
    return isInstance;
}
export function DiscountCouponConfigFromJSON(json) {
    return DiscountCouponConfigFromJSONTyped(json, false);
}
export function DiscountCouponConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'maximumAmount': !exists(json, 'maximumAmount') ? undefined : PriceFromJSON(json['maximumAmount']),
        'id': json['id'],
        'type': DiscountCouponTypeFromJSON(json['type']),
        'value': json['value'],
        'minPurchasePrice': PriceFromJSON(json['minPurchasePrice']),
    };
}
export function DiscountCouponConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'maximumAmount': PriceToJSON(value.maximumAmount),
        'id': value.id,
        'type': DiscountCouponTypeToJSON(value.type),
        'value': value.value,
        'minPurchasePrice': PriceToJSON(value.minPurchasePrice),
    };
}
