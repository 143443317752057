export const num2name = {
    0: "UNKNOWN",
    1: "PENDING",
    2: "PAID",
    3: "OVERPAID",
    4: "CANCELED",
};
export const name2num = {
    UNKNOWN: 0,
    PENDING: 1,
    PAID: 2,
    OVERPAID: 3,
    CANCELED: 4,
};
