/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DeliverableFromJSON, DeliverableToJSON, } from './Deliverable';
import { DisplayConfigFromJSON, DisplayConfigToJSON, } from './DisplayConfig';
import { SalesConfigFromJSON, SalesConfigToJSON, } from './SalesConfig';
import { StripeBillingConfigFromJSON, StripeBillingConfigToJSON, } from './StripeBillingConfig';
/**
 * Check if a given object implements the StripeProduct interface.
 */
export function instanceOfStripeProduct(value) {
    let isInstance = true;
    isInstance = isInstance && "displayConfig" in value;
    isInstance = isInstance && "billingConfig" in value;
    isInstance = isInstance && "salesConfig" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function StripeProductFromJSON(json) {
    return StripeProductFromJSONTyped(json, false);
}
export function StripeProductFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayConfig': DisplayConfigFromJSON(json['displayConfig']),
        'billingConfig': StripeBillingConfigFromJSON(json['billingConfig']),
        'salesConfig': SalesConfigFromJSON(json['salesConfig']),
        'deliverable': !exists(json, 'deliverable') ? undefined : DeliverableFromJSON(json['deliverable']),
        'id': json['id'],
    };
}
export function StripeProductToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayConfig': DisplayConfigToJSON(value.displayConfig),
        'billingConfig': StripeBillingConfigToJSON(value.billingConfig),
        'salesConfig': SalesConfigToJSON(value.salesConfig),
        'deliverable': DeliverableToJSON(value.deliverable),
        'id': value.id,
    };
}
