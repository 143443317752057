/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionResultFromJSON, QuestionResultToJSON, } from './QuestionResult';
import { ToeicSpeakingLevelFromJSON, ToeicSpeakingLevelToJSON, } from './ToeicSpeakingLevel';
/**
 * Check if a given object implements the ExamResult interface.
 */
export function instanceOfExamResult(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "score" in value;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "questionResults" in value;
    isInstance = isInstance && "analysisVersion" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function ExamResultFromJSON(json) {
    return ExamResultFromJSONTyped(json, false);
}
export function ExamResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'score': json['score'],
        'level': ToeicSpeakingLevelFromJSON(json['level']),
        'questionResults': (json['questionResults'].map(QuestionResultFromJSON)),
        'analysisVersion': json['analysisVersion'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function ExamResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': (value.createdAt.toISOString()),
        'score': value.score,
        'level': ToeicSpeakingLevelToJSON(value.level),
        'questionResults': (value.questionResults.map(QuestionResultToJSON)),
        'analysisVersion': value.analysisVersion,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
