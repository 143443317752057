// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./QuestionSet";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./LessonSet";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./Lesson";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./Question";
import { encodeJson as encodeJson_5, decodeJson as decodeJson_5, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "./Vocabulary";
import { encodeJson as encodeJson_6, decodeJson as decodeJson_6, encodeBinary as encodeBinary_6, decodeBinary as decodeBinary_6, } from "./ToeicSpeakingPart";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        attachedResourceIds: [],
        content: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    result.attachedResourceIds = value.attachedResourceIds.map(value => tsValueToJsonValueFns.string(value));
    switch ((_a = value.content) === null || _a === void 0 ? void 0 : _a.field) {
        case "questionSet": {
            result.questionSet = encodeJson_1(value.content.value);
            break;
        }
        case "lessonSet": {
            result.lessonSet = encodeJson_2(value.content.value);
            break;
        }
        case "lesson": {
            result.lesson = encodeJson_3(value.content.value);
            break;
        }
        case "question": {
            result.question = encodeJson_4(value.content.value);
            break;
        }
        case "voca": {
            result.voca = encodeJson_5(value.content.value);
            break;
        }
        case "toeicSpeakingPart": {
            result.toeicSpeakingPart = encodeJson_6(value.content.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    result.attachedResourceIds = (_b = (_a = value.attachedResourceIds) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.string(value))) !== null && _b !== void 0 ? _b : [];
    if (value.questionSet !== undefined)
        result.content = { field: "questionSet", value: decodeJson_1(value.questionSet) };
    if (value.lessonSet !== undefined)
        result.content = { field: "lessonSet", value: decodeJson_2(value.lessonSet) };
    if (value.lesson !== undefined)
        result.content = { field: "lesson", value: decodeJson_3(value.lesson) };
    if (value.question !== undefined)
        result.content = { field: "question", value: decodeJson_4(value.question) };
    if (value.voca !== undefined)
        result.content = { field: "voca", value: decodeJson_5(value.voca) };
    if (value.toeicSpeakingPart !== undefined)
        result.content = { field: "toeicSpeakingPart", value: decodeJson_6(value.toeicSpeakingPart) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    for (const tsValue of value.attachedResourceIds) {
        result.push([6, tsValueToWireValueFns.string(tsValue)]);
    }
    switch ((_a = value.content) === null || _a === void 0 ? void 0 : _a.field) {
        case "questionSet": {
            const tsValue = value.content.value;
            result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
        case "lessonSet": {
            const tsValue = value.content.value;
            result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
        case "lesson": {
            const tsValue = value.content.value;
            result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
            break;
        }
        case "question": {
            const tsValue = value.content.value;
            result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
            break;
        }
        case "voca": {
            const tsValue = value.content.value;
            result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
            break;
        }
        case "toeicSpeakingPart": {
            const tsValue = value.content.value;
            result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_6(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    content: new Set([1, 2, 3, 4, 5, 7]),
};
const oneofFieldNamesMap = {
    content: new Map([
        [1, "questionSet"],
        [2, "lessonSet"],
        [3, "lesson"],
        [4, "question"],
        [5, "voca"],
        [7, "toeicSpeakingPart"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 6).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValueToTsValueFns.string(wireValue)).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.attachedResourceIds = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.content;
        const oneofFieldNames = oneofFieldNamesMap.content;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [1](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
            [2](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
            [3](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined; },
            [4](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined; },
            [5](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined; },
            [7](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_6(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.content = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
