export const num2name = {
    0: "AUTHORITY_TYPE_UNSPECIFIED",
    1: "COURSE_CELL",
    5: "SELF_LEARNING",
    6: "MY_NOTE_QUIZ",
    7: "SELF_LEARNING_HIERARCHY",
    100: "DOWNLOAD_RESOURCE",
    102: "REQUEST_GIFT_CODE",
    103: "REQUEST_DELIVERY",
};
export const name2num = {
    AUTHORITY_TYPE_UNSPECIFIED: 0,
    COURSE_CELL: 1,
    SELF_LEARNING: 5,
    MY_NOTE_QUIZ: 6,
    SELF_LEARNING_HIERARCHY: 7,
    DOWNLOAD_RESOURCE: 100,
    REQUEST_GIFT_CODE: 102,
    REQUEST_DELIVERY: 103,
};
