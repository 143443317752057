import { ContentInteractionStateWithContentFromJSON, ContentInteractionStateWithContentToJSON, } from './ContentInteractionStateWithContent';
/**
 * Check if a given object implements the CreateContentInteractionStatesResponse interface.
 */
export function instanceOfCreateContentInteractionStatesResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    return isInstance;
}
export function CreateContentInteractionStatesResponseFromJSON(json) {
    return CreateContentInteractionStatesResponseFromJSONTyped(json, false);
}
export function CreateContentInteractionStatesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'created': (json['created'].map(ContentInteractionStateWithContentFromJSON)),
    };
}
export function CreateContentInteractionStatesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'created': (value.created.map(ContentInteractionStateWithContentToJSON)),
    };
}
