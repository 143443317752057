/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Period interface.
 */
export function instanceOfPeriod(value) {
    let isInstance = true;
    isInstance = isInstance && "startInclusive" in value;
    isInstance = isInstance && "endExclusive" in value;
    return isInstance;
}
export function PeriodFromJSON(json) {
    return PeriodFromJSONTyped(json, false);
}
export function PeriodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'startInclusive': (new Date(json['startInclusive'])),
        'endExclusive': (new Date(json['endExclusive'])),
    };
}
export function PeriodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'startInclusive': (value.startInclusive.toISOString()),
        'endExclusive': (value.endExclusive.toISOString()),
    };
}
