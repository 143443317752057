/* tslint:disable */
/* eslint-disable */
/**
 * 학습 사이클에 속한 학습 셀의 종류.
 *
 * - **QUESTION**: 문제
 * - **VOCABULARY**: 어휘
 * - **REVIEW**: 복습 퀴즈 (구 쪽지시험)
 * - **DIAGNOSIS**: 진단고사
 * - **LESSON**: 강의 (더 이상 새로 생성되지 않음)
 * - **MOCK_EXAM**: 추천 모의고사 (TOEIC 이외에는 제공되지 않음)
 * @export
 */
export const LearningCycleCellType = {
    QUESTION: 'QUESTION',
    VOCABULARY: 'VOCABULARY',
    REVIEW: 'REVIEW',
    DIAGNOSIS: 'DIAGNOSIS',
    LESSON: 'LESSON',
    MOCK_EXAM: 'MOCK_EXAM'
};
export function LearningCycleCellTypeFromJSON(json) {
    return LearningCycleCellTypeFromJSONTyped(json, false);
}
export function LearningCycleCellTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LearningCycleCellTypeToJSON(value) {
    return value;
}
