/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ExamineeFromJSON, TypeformSurveyWebhookRequestToJSON, } from '../models';
/**
 *
 */
export class ExamineeV1Api extends runtime.BaseAPI {
    /**
     * Examinee 조회. 조회에는 현재 인증된 유저의 authId가 사용된다. 현재 인증된 유저에 대한 examinee가 없는 경우 새로 생성된다. * Command와 query를 분리하는 방안을 고려했지만 일단 하나로 통합하기로 결정함(20221103).
     */
    async getMeRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/examinee/v1/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExamineeFromJSON(jsonValue));
    }
    /**
     * Examinee 조회. 조회에는 현재 인증된 유저의 authId가 사용된다. 현재 인증된 유저에 대한 examinee가 없는 경우 새로 생성된다. * Command와 query를 분리하는 방안을 고려했지만 일단 하나로 통합하기로 결정함(20221103).
     */
    async getMe(initOverrides) {
        const response = await this.getMeRaw(initOverrides);
        return await response.value();
    }
    /**
     * Typeform 설문 완료시 호출되는 webhook.
     */
    async typeformSurveyWebhookRaw(requestParameters, initOverrides) {
        if (requestParameters.typeformSurveyWebhookRequest === null || requestParameters.typeformSurveyWebhookRequest === undefined) {
            throw new runtime.RequiredError('typeformSurveyWebhookRequest', 'Required parameter requestParameters.typeformSurveyWebhookRequest was null or undefined when calling typeformSurveyWebhook.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/examinee/v1/webhook/typeform`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TypeformSurveyWebhookRequestToJSON(requestParameters.typeformSurveyWebhookRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Typeform 설문 완료시 호출되는 webhook.
     */
    async typeformSurveyWebhook(requestParameters, initOverrides) {
        await this.typeformSurveyWebhookRaw(requestParameters, initOverrides);
    }
}
