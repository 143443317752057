/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetDiagnosisReportResponseFromJSON, } from '../models';
/**
 *
 */
export class DiagnosisReportV1Api extends runtime.BaseAPI {
    /**
     * 현재 사용자의 선택된 학습 도메인에 대한 진단고사 결과 리포트를 반환한다.
     */
    async getDiagnosisReportRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/analytics/v1/diagnosis-report/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetDiagnosisReportResponseFromJSON(jsonValue));
    }
    /**
     * 현재 사용자의 선택된 학습 도메인에 대한 진단고사 결과 리포트를 반환한다.
     */
    async getDiagnosisReport(requestParameters, initOverrides) {
        const response = await this.getDiagnosisReportRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
