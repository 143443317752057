/**
 * Check if a given object implements the GetMyReferralInfoResponse interface.
 */
export function instanceOfGetMyReferralInfoResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "referralCode" in value;
    isInstance = isInstance && "referredCount" in value;
    return isInstance;
}
export function GetMyReferralInfoResponseFromJSON(json) {
    return GetMyReferralInfoResponseFromJSONTyped(json, false);
}
export function GetMyReferralInfoResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'referralCode': json['referralCode'],
        'referredCount': json['referredCount'],
    };
}
export function GetMyReferralInfoResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'referralCode': value.referralCode,
        'referredCount': value.referredCount,
    };
}
