/* tslint:disable */
/* eslint-disable */
/**
 * AI Block이 생성된 맥락에 대한 종류. 어떤 종류인지에 따라 AI Block의 배경 이미지가 변경된다.
 *
 * - **INCREASE**: 상승
 * - **DECREASE**: 하락
 * - **INFORMATION**: 정보
 * @export
 */
export const AIBlockType = {
    INCREASE: 'INCREASE',
    DECREASE: 'DECREASE',
    INFORMATION: 'INFORMATION'
};
export function AIBlockTypeFromJSON(json) {
    return AIBlockTypeFromJSONTyped(json, false);
}
export function AIBlockTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function AIBlockTypeToJSON(value) {
    return value;
}
