/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HiddenFromJSON, HiddenToJSON, } from './Hidden';
/**
 * Check if a given object implements the FormResponse interface.
 */
export function instanceOfFormResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "hidden" in value;
    isInstance = isInstance && "submittedAt" in value;
    isInstance = isInstance && "formId" in value;
    isInstance = isInstance && "landedAt" in value;
    isInstance = isInstance && "token" in value;
    return isInstance;
}
export function FormResponseFromJSON(json) {
    return FormResponseFromJSONTyped(json, false);
}
export function FormResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hidden': HiddenFromJSON(json['hidden']),
        'submittedAt': json['submitted_at'],
        'formId': json['form_id'],
        'landedAt': json['landed_at'],
        'token': json['token'],
    };
}
export function FormResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hidden': HiddenToJSON(value.hidden),
        'submitted_at': value.submittedAt,
        'form_id': value.formId,
        'landed_at': value.landedAt,
        'token': value.token,
    };
}
