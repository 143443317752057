/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { LearningCellStateFromJSON, LearningCellStateToJSON, } from './LearningCellState';
import { LearningCycleCellProgressInfoFromJSON, LearningCycleCellProgressInfoToJSON, } from './LearningCycleCellProgressInfo';
import { LearningCycleCellTypeFromJSON, LearningCycleCellTypeToJSON, } from './LearningCycleCellType';
/**
 * Check if a given object implements the LearningCycleCellInfo interface.
 */
export function instanceOfLearningCycleCellInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "tagList" in value;
    isInstance = isInstance && "tagTitle" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "learningCellState" in value;
    return isInstance;
}
export function LearningCycleCellInfoFromJSON(json) {
    return LearningCycleCellInfoFromJSONTyped(json, false);
}
export function LearningCycleCellInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tagList': json['tagList'],
        'cellLength': !exists(json, 'cellLength') ? undefined : json['cellLength'],
        'tagTitle': json['tagTitle'],
        'learningCellId': !exists(json, 'learningCellId') ? undefined : json['learningCellId'],
        'progressInfo': !exists(json, 'progressInfo') ? undefined : LearningCycleCellProgressInfoFromJSON(json['progressInfo']),
        'title': json['title'],
        'type': LearningCycleCellTypeFromJSON(json['type']),
        'learningCellState': LearningCellStateFromJSON(json['learningCellState']),
    };
}
export function LearningCycleCellInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tagList': value.tagList,
        'cellLength': value.cellLength,
        'tagTitle': value.tagTitle,
        'learningCellId': value.learningCellId,
        'progressInfo': LearningCycleCellProgressInfoToJSON(value.progressInfo),
        'title': value.title,
        'type': LearningCycleCellTypeToJSON(value.type),
        'learningCellState': LearningCellStateToJSON(value.learningCellState),
    };
}
