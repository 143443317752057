import { EmailPasswordRegistrationInfoFromJSON, EmailPasswordRegistrationInfoToJSON, } from './EmailPasswordRegistrationInfo';
/**
 * Check if a given object implements the LoginWithEmailPasswordRequest interface.
 */
export function instanceOfLoginWithEmailPasswordRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "emailPasswordRegistrationInfo" in value;
    return isInstance;
}
export function LoginWithEmailPasswordRequestFromJSON(json) {
    return LoginWithEmailPasswordRequestFromJSONTyped(json, false);
}
export function LoginWithEmailPasswordRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'emailPasswordRegistrationInfo': EmailPasswordRegistrationInfoFromJSON(json['emailPasswordRegistrationInfo']),
    };
}
export function LoginWithEmailPasswordRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'emailPasswordRegistrationInfo': EmailPasswordRegistrationInfoToJSON(value.emailPasswordRegistrationInfo),
    };
}
