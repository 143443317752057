/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ToeicSpeakingLevel = {
    ADVANCED_HIGH: 'ADVANCED_HIGH',
    ADVANCED_MID: 'ADVANCED_MID',
    ADVANCED_LOW: 'ADVANCED_LOW',
    INTERMEDIATE_HIGH: 'INTERMEDIATE_HIGH',
    INTERMEDIATE_MID_3: 'INTERMEDIATE_MID_3',
    INTERMEDIATE_MID_2: 'INTERMEDIATE_MID_2',
    INTERMEDIATE_MID_1: 'INTERMEDIATE_MID_1',
    INTERMEDIATE_LOW: 'INTERMEDIATE_LOW',
    NOVICE_HIGH: 'NOVICE_HIGH',
    NOVICE_MID: 'NOVICE_MID',
    NOVICE_LOW: 'NOVICE_LOW'
};
export function ToeicSpeakingLevelFromJSON(json) {
    return ToeicSpeakingLevelFromJSONTyped(json, false);
}
export function ToeicSpeakingLevelFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ToeicSpeakingLevelToJSON(value) {
    return value;
}
