/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CurrencyFromJSON, CurrencyToJSON, } from './Currency';
/**
 * Check if a given object implements the Price interface.
 */
export function instanceOfPrice(value) {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;
    return isInstance;
}
export function PriceFromJSON(json) {
    return PriceFromJSONTyped(json, false);
}
export function PriceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'amount': json['amount'],
        'currency': CurrencyFromJSON(json['currency']),
    };
}
export function PriceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'amount': value.amount,
        'currency': CurrencyToJSON(value.currency),
    };
}
