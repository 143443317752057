// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(Goods)/GoodsDisplayConfig";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../authority/AuthorityConfig";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../DecimalAmount";
import { name2num, num2name, } from "../../inside/payment/PaymentCurrency";
import { name2num as name2num_1, num2name as num2name_1, } from "./(Goods)/CalculatePriceMethod";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        displayConfig: undefined,
        authorityConfig: undefined,
        calculateDaily: false,
        priceAmount: undefined,
        currency: "UNSET",
        calculatePriceMethod: "CALCULATE_PRICE_METHOD_UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.displayConfig !== undefined)
        result.displayConfig = encodeJson_1(value.displayConfig);
    if (value.authorityConfig !== undefined)
        result.authorityConfig = encodeJson_2(value.authorityConfig);
    if (value.calculateDaily !== undefined)
        result.calculateDaily = tsValueToJsonValueFns.bool(value.calculateDaily);
    if (value.priceAmount !== undefined)
        result.priceAmount = encodeJson_3(value.priceAmount);
    if (value.currency !== undefined)
        result.currency = tsValueToJsonValueFns.enum(value.currency);
    if (value.calculatePriceMethod !== undefined)
        result.calculatePriceMethod = tsValueToJsonValueFns.enum(value.calculatePriceMethod);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.displayConfig !== undefined)
        result.displayConfig = decodeJson_1(value.displayConfig);
    if (value.authorityConfig !== undefined)
        result.authorityConfig = decodeJson_2(value.authorityConfig);
    if (value.calculateDaily !== undefined)
        result.calculateDaily = jsonValueToTsValueFns.bool(value.calculateDaily);
    if (value.priceAmount !== undefined)
        result.priceAmount = decodeJson_3(value.priceAmount);
    if (value.currency !== undefined)
        result.currency = jsonValueToTsValueFns.enum(value.currency);
    if (value.calculatePriceMethod !== undefined)
        result.calculatePriceMethod = jsonValueToTsValueFns.enum(value.calculatePriceMethod);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.displayConfig !== undefined) {
        const tsValue = value.displayConfig;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.authorityConfig !== undefined) {
        const tsValue = value.authorityConfig;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.calculateDaily !== undefined) {
        const tsValue = value.calculateDaily;
        result.push([4, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.priceAmount !== undefined) {
        const tsValue = value.priceAmount;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.currency !== undefined) {
        const tsValue = value.currency;
        result.push([6, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.calculatePriceMethod !== undefined) {
        const tsValue = value.calculatePriceMethod;
        result.push([7, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.displayConfig = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.authorityConfig = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.calculateDaily = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.priceAmount = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.currency = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.calculatePriceMethod = value;
    }
    return result;
}
