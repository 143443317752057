export const num2name = {
    0: "AUTHORITY_LIMIT_UNSPECIFIED",
    1: "COUNT_PER_DAY",
    2: "COUNT_OVER_DURATION",
    3: "UNLIMITED_DURING_DURATION",
    4: "COUNT_PER_DAY_OVER_LIFETIME",
    5: "COUNT_OVER_LIFETIME",
};
export const name2num = {
    AUTHORITY_LIMIT_UNSPECIFIED: 0,
    COUNT_PER_DAY: 1,
    COUNT_OVER_DURATION: 2,
    UNLIMITED_DURING_DURATION: 3,
    COUNT_PER_DAY_OVER_LIFETIME: 4,
    COUNT_OVER_LIFETIME: 5,
};
