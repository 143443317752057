/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetMyUserConfigResponseFromJSON, UpdateMyUserConfigRequestToJSON, UpdateMyUserConfigResponseFromJSON, } from '../models';
/**
 *
 */
export class UserConfigV1Api extends runtime.BaseAPI {
    /**
     * User의 config를 가져옵니다.
     */
    async getMyUserConfigRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/user/v1/user-config/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetMyUserConfigResponseFromJSON(jsonValue));
    }
    /**
     * User의 config를 가져옵니다.
     */
    async getMyUserConfig(requestParameters, initOverrides) {
        const response = await this.getMyUserConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * User의 config를 변경합니다.
     */
    async updateMyUserConfigRaw(requestParameters, initOverrides) {
        if (requestParameters.updateMyUserConfigRequest === null || requestParameters.updateMyUserConfigRequest === undefined) {
            throw new runtime.RequiredError('updateMyUserConfigRequest', 'Required parameter requestParameters.updateMyUserConfigRequest was null or undefined when calling updateMyUserConfig.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/user-config/me`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMyUserConfigRequestToJSON(requestParameters.updateMyUserConfigRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateMyUserConfigResponseFromJSON(jsonValue));
    }
    /**
     * User의 config를 변경합니다.
     */
    async updateMyUserConfig(requestParameters, initOverrides) {
        const response = await this.updateMyUserConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
