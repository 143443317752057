/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CouponFromJSON, } from '../models';
/**
 *
 */
export class CouponV1Api extends runtime.BaseAPI {
    /**
     * 응시권 교환 쿠폰을 조회합니다.
     */
    async getExchangeCouponRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getExchangeCoupon.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/coupon/v1/exchange/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CouponFromJSON(jsonValue));
    }
    /**
     * 응시권 교환 쿠폰을 조회합니다.
     */
    async getExchangeCoupon(requestParameters, initOverrides) {
        const response = await this.getExchangeCouponRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 쿠폰 목록을 조회합니다.
     */
    async listCouponsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }
        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/coupon/v1/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CouponFromJSON));
    }
    /**
     * 쿠폰 목록을 조회합니다.
     */
    async listCoupons(requestParameters = {}, initOverrides) {
        const response = await this.listCouponsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 쿠폰을 등록합니다.
     */
    async registerCouponRaw(requestParameters, initOverrides) {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code', 'Required parameter requestParameters.code was null or undefined when calling registerCoupon.');
        }
        const queryParameters = {};
        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/coupon/v1/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CouponFromJSON(jsonValue));
    }
    /**
     * 쿠폰을 등록합니다.
     */
    async registerCoupon(requestParameters, initOverrides) {
        const response = await this.registerCouponRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 응시권 교환 쿠폰을 사용합니다.
     */
    async useExchangeCouponRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling useExchangeCoupon.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/coupon/v1/exchange/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CouponFromJSON(jsonValue));
    }
    /**
     * 응시권 교환 쿠폰을 사용합니다.
     */
    async useExchangeCoupon(requestParameters, initOverrides) {
        const response = await this.useExchangeCouponRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
