import { UserTokenFromJSON, UserTokenToJSON, } from './UserToken';
/**
 * Check if a given object implements the AuthenticateWithGoogleAuthorizationCodeResponse interface.
 */
export function instanceOfAuthenticateWithGoogleAuthorizationCodeResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "userToken" in value;
    return isInstance;
}
export function AuthenticateWithGoogleAuthorizationCodeResponseFromJSON(json) {
    return AuthenticateWithGoogleAuthorizationCodeResponseFromJSONTyped(json, false);
}
export function AuthenticateWithGoogleAuthorizationCodeResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userToken': UserTokenFromJSON(json['userToken']),
    };
}
export function AuthenticateWithGoogleAuthorizationCodeResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userToken': UserTokenToJSON(value.userToken),
    };
}
