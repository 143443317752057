import { UserTokenFromJSON, UserTokenToJSON, } from './UserToken';
/**
 * Check if a given object implements the CommonLoginResponse interface.
 */
export function instanceOfCommonLoginResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "userToken" in value;
    return isInstance;
}
export function CommonLoginResponseFromJSON(json) {
    return CommonLoginResponseFromJSONTyped(json, false);
}
export function CommonLoginResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userToken': UserTokenFromJSON(json['userToken']),
    };
}
export function CommonLoginResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userToken': UserTokenToJSON(value.userToken),
    };
}
