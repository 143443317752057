/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ImageSetFromJSON, ImageSetToJSON, } from './ImageSet';
import { ThumbnailFgImageAlignmentFromJSON, ThumbnailFgImageAlignmentToJSON, } from './ThumbnailFgImageAlignment';
import { TimerConfigFromJSON, TimerConfigToJSON, } from './TimerConfig';
/**
 * Check if a given object implements the DisplayConfig interface.
 */
export function instanceOfDisplayConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "cautionLink" in value;
    isInstance = isInstance && "detailImageSets" in value;
    isInstance = isInstance && "thumbnailBgImageSet" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "thumbnailFgImageAlignment" in value;
    isInstance = isInstance && "thumbnailFgImageSet" in value;
    return isInstance;
}
export function DisplayConfigFromJSON(json) {
    return DisplayConfigFromJSONTyped(json, false);
}
export function DisplayConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'timerConfig': !exists(json, 'timerConfig') ? undefined : TimerConfigFromJSON(json['timerConfig']),
        'description': json['description'],
        'cautionLink': json['cautionLink'],
        'detailImageSets': (json['detailImageSets'].map(ImageSetFromJSON)),
        'thumbnailBgImageSet': ImageSetFromJSON(json['thumbnailBgImageSet']),
        'title': json['title'],
        'thumbnailFgImageAlignment': ThumbnailFgImageAlignmentFromJSON(json['thumbnailFgImageAlignment']),
        'thumbnailFgImageSet': ImageSetFromJSON(json['thumbnailFgImageSet']),
    };
}
export function DisplayConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'timerConfig': TimerConfigToJSON(value.timerConfig),
        'description': value.description,
        'cautionLink': value.cautionLink,
        'detailImageSets': (value.detailImageSets.map(ImageSetToJSON)),
        'thumbnailBgImageSet': ImageSetToJSON(value.thumbnailBgImageSet),
        'title': value.title,
        'thumbnailFgImageAlignment': ThumbnailFgImageAlignmentToJSON(value.thumbnailFgImageAlignment),
        'thumbnailFgImageSet': ImageSetToJSON(value.thumbnailFgImageSet),
    };
}
