/* tslint:disable */
/* eslint-disable */
/**
 * - **READY**: 유저가 아직 Onboarding을 시작하지 않은 상태
 * - **ONGOING**: 유저가 하나 이상의 Onboarding 질문에 답변했으나 모든 Onboarding 질문에 답변하지는 않은 상태
 * - **DONE**: 유저가 모든 Onboarding 질문에 답변한 상태
 * @export
 */
export const UserOnboardingStatus = {
    READY: 'READY',
    ONGOING: 'ONGOING',
    DONE: 'DONE'
};
export function UserOnboardingStatusFromJSON(json) {
    return UserOnboardingStatusFromJSONTyped(json, false);
}
export function UserOnboardingStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UserOnboardingStatusToJSON(value) {
    return value;
}
