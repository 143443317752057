import {TFunction} from 'react-i18next';

import {Language as _Language} from '@santa-web/gen/open-api/service';

export type Language = Lowercase<keyof typeof _Language>;
export const LANGUAGES = Object.keys(_Language).map(language => language.toLowerCase()) as Array<Language>;

// TODO: country =/= language, but they cannot be distinguishable now
export const COUNTRIES = {
  ko: 'KR',
  en: 'US',
  ja: 'JP',
  vi: 'VN',
  zh: 'TW',
  th: 'TH',
} as const;
export type Country = typeof COUNTRIES[Language];

export type Translation = TFunction<'translation'>;
