export const num2name = {
    0: "UNSPECIFIED_COUNTRY",
    1: "COUNTRY_KR",
    2: "COUNTRY_JP",
    3: "COUNTRY_US",
    4: "COUNTRY_BR",
    5: "COUNTRY_CO",
    6: "COUNTRY_VN",
    7: "COUNTRY_TW",
    8: "COUNTRY_TH",
};
export const name2num = {
    UNSPECIFIED_COUNTRY: 0,
    COUNTRY_KR: 1,
    COUNTRY_JP: 2,
    COUNTRY_US: 3,
    COUNTRY_BR: 4,
    COUNTRY_CO: 5,
    COUNTRY_VN: 6,
    COUNTRY_TW: 7,
    COUNTRY_TH: 8,
};
