import { EmailRegistrationInfoFromJSON, EmailRegistrationInfoToJSON, } from './EmailRegistrationInfo';
/**
 * Check if a given object implements the CheckUserExistsWithEmailRequest interface.
 */
export function instanceOfCheckUserExistsWithEmailRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "registrationInfo" in value;
    return isInstance;
}
export function CheckUserExistsWithEmailRequestFromJSON(json) {
    return CheckUserExistsWithEmailRequestFromJSONTyped(json, false);
}
export function CheckUserExistsWithEmailRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'registrationInfo': EmailRegistrationInfoFromJSON(json['registrationInfo']),
    };
}
export function CheckUserExistsWithEmailRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'registrationInfo': EmailRegistrationInfoToJSON(value.registrationInfo),
    };
}
