export const num2name = {
    0: "DURATION_TYPE_UNSPECIFIED",
    1: "DYNAMIC",
    2: "FIXED",
    3: "DYNAMIC_MONTHLY",
    4: "FIXED_MONTHLY",
};
export const name2num = {
    DURATION_TYPE_UNSPECIFIED: 0,
    DYNAMIC: 1,
    FIXED: 2,
    DYNAMIC_MONTHLY: 3,
    FIXED_MONTHLY: 4,
};
