// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../../../toeic/DecimalAmount";
import { WireType, } from "../../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        totalGoodsAmount: undefined,
        baseDiscountAmount: undefined,
        baseChargeAmount: undefined,
        totalDiscountAmount: undefined,
        totalChargeAmount: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.totalGoodsAmount !== undefined)
        result.totalGoodsAmount = encodeJson_1(value.totalGoodsAmount);
    if (value.baseDiscountAmount !== undefined)
        result.baseDiscountAmount = encodeJson_1(value.baseDiscountAmount);
    if (value.baseChargeAmount !== undefined)
        result.baseChargeAmount = encodeJson_1(value.baseChargeAmount);
    if (value.totalDiscountAmount !== undefined)
        result.totalDiscountAmount = encodeJson_1(value.totalDiscountAmount);
    if (value.totalChargeAmount !== undefined)
        result.totalChargeAmount = encodeJson_1(value.totalChargeAmount);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.totalGoodsAmount !== undefined)
        result.totalGoodsAmount = decodeJson_1(value.totalGoodsAmount);
    if (value.baseDiscountAmount !== undefined)
        result.baseDiscountAmount = decodeJson_1(value.baseDiscountAmount);
    if (value.baseChargeAmount !== undefined)
        result.baseChargeAmount = decodeJson_1(value.baseChargeAmount);
    if (value.totalDiscountAmount !== undefined)
        result.totalDiscountAmount = decodeJson_1(value.totalDiscountAmount);
    if (value.totalChargeAmount !== undefined)
        result.totalChargeAmount = decodeJson_1(value.totalChargeAmount);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.totalGoodsAmount !== undefined) {
        const tsValue = value.totalGoodsAmount;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.baseDiscountAmount !== undefined) {
        const tsValue = value.baseDiscountAmount;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.baseChargeAmount !== undefined) {
        const tsValue = value.baseChargeAmount;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.totalDiscountAmount !== undefined) {
        const tsValue = value.totalDiscountAmount;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.totalChargeAmount !== undefined) {
        const tsValue = value.totalChargeAmount;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.totalGoodsAmount = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.baseDiscountAmount = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.baseChargeAmount = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.totalDiscountAmount = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.totalChargeAmount = value;
    }
    return result;
}
