/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ParagraphDiffFromJSON, ParagraphDiffToJSON, } from './ParagraphDiff';
import { TipFromJSON, TipToJSON, } from './Tip';
/**
 * Check if a given object implements the GrammarErrorCorrection interface.
 */
export function instanceOfGrammarErrorCorrection(value) {
    let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "explanation" in value;
    isInstance = isInstance && "tips" in value;
    return isInstance;
}
export function GrammarErrorCorrectionFromJSON(json) {
    return GrammarErrorCorrectionFromJSONTyped(json, false);
}
export function GrammarErrorCorrectionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'from': ParagraphDiffFromJSON(json['from']),
        'to': ParagraphDiffFromJSON(json['to']),
        'explanation': json['explanation'],
        'tips': (json['tips'].map(TipFromJSON)),
    };
}
export function GrammarErrorCorrectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'from': ParagraphDiffToJSON(value.from),
        'to': ParagraphDiffToJSON(value.to),
        'explanation': value.explanation,
        'tips': (value.tips.map(TipToJSON)),
    };
}
