/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetContentStatisticsResponseFromJSON, } from '../models';
/**
 *
 */
export class ContentStatisticsV1Api extends runtime.BaseAPI {
    /**
     * 컨텐츠의 상호작용 통계를 조회한다.
     */
    async getContentStatisticsRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling getContentStatistics.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning-x/v1/statistics/{contentInteractionStateId}`.replace(`{${"contentInteractionStateId"}}`, encodeURIComponent(String(requestParameters.contentInteractionStateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetContentStatisticsResponseFromJSON(jsonValue));
    }
    /**
     * 컨텐츠의 상호작용 통계를 조회한다.
     */
    async getContentStatistics(requestParameters, initOverrides) {
        const response = await this.getContentStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
