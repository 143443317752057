export const num2name = {
    0: "ORDER_STATUS_UNSPECIFIED",
    1: "ORDER_CREATED",
    2: "ORDER_CONFIGURED",
    3: "ORDER_CANCELED",
    4: "PAYMENT_REQUESTED",
    5: "PAYMENT_SUCCEEDED",
    6: "ORDER_FULFILLED",
    7: "REFUND_REQUESTED",
    8: "ORDER_REFUNDED",
    9: "PAYMENT_ABORTED",
};
export const name2num = {
    ORDER_STATUS_UNSPECIFIED: 0,
    ORDER_CREATED: 1,
    ORDER_CONFIGURED: 2,
    ORDER_CANCELED: 3,
    PAYMENT_REQUESTED: 4,
    PAYMENT_SUCCEEDED: 5,
    ORDER_FULFILLED: 6,
    REFUND_REQUESTED: 7,
    ORDER_REFUNDED: 8,
    PAYMENT_ABORTED: 9,
};
