/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { AuthenticateWithGoogleAuthorizationCodeRequestToJSON, AuthenticateWithGoogleAuthorizationCodeResponseFromJSON, CheckUserExistsWithAppleRequestToJSON, CheckUserExistsWithEmailRequestToJSON, CheckUserExistsWithGoogleRequestToJSON, CheckUserExistsWithKakaoRequestToJSON, CheckUserExistsWithLineRequestToJSON, CommonCheckUserExistsResponseFromJSON, CommonLoginResponseFromJSON, CommonSignupResponseFromJSON, CreateAnonymousUserRequestToJSON, CreateAnonymousUserResponseFromJSON, LoginWithAppleRequestToJSON, LoginWithEmailPasswordRequestToJSON, LoginWithGoogleRequestToJSON, LoginWithKakaoRequestToJSON, LoginWithLineRequestToJSON, RefreshTokenRequestToJSON, RefreshTokenResponseFromJSON, ResetPasswordRequestToJSON, SignupWithAppleRequestToJSON, SignupWithEmailPasswordRequestToJSON, SignupWithGoogleRequestToJSON, SignupWithKakaoRequestToJSON, SignupWithLineRequestToJSON, ValidateResetPasswordTokenResponseFromJSON, WithdrawRequestToJSON, } from '../models';
/**
 *
 */
export class AuthV1Api extends runtime.BaseAPI {
    /**
     * Google OAuth 정보를 바탕으로 로그인을 시도하고, 가입 정보가 없을 경우 PENDING 상태로 가입을 진행합니다.\\ 두 경우 모두 UserToken을 반환합니다.\\ OAuth authorization code flow를 따릅니다.
     */
    async authenticateWithGoogleAuthorizationCodeRaw(requestParameters, initOverrides) {
        if (requestParameters.authenticateWithGoogleAuthorizationCodeRequest === null || requestParameters.authenticateWithGoogleAuthorizationCodeRequest === undefined) {
            throw new runtime.RequiredError('authenticateWithGoogleAuthorizationCodeRequest', 'Required parameter requestParameters.authenticateWithGoogleAuthorizationCodeRequest was null or undefined when calling authenticateWithGoogleAuthorizationCode.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/google/authorization-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticateWithGoogleAuthorizationCodeRequestToJSON(requestParameters.authenticateWithGoogleAuthorizationCodeRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticateWithGoogleAuthorizationCodeResponseFromJSON(jsonValue));
    }
    /**
     * Google OAuth 정보를 바탕으로 로그인을 시도하고, 가입 정보가 없을 경우 PENDING 상태로 가입을 진행합니다.\\ 두 경우 모두 UserToken을 반환합니다.\\ OAuth authorization code flow를 따릅니다.
     */
    async authenticateWithGoogleAuthorizationCode(requestParameters, initOverrides) {
        const response = await this.authenticateWithGoogleAuthorizationCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 해당 Apple 계정으로 가입된 사용자가 있는지를 확인합니다.
     */
    async checkUserExistsWithAppleRaw(requestParameters, initOverrides) {
        if (requestParameters.checkUserExistsWithAppleRequest === null || requestParameters.checkUserExistsWithAppleRequest === undefined) {
            throw new runtime.RequiredError('checkUserExistsWithAppleRequest', 'Required parameter requestParameters.checkUserExistsWithAppleRequest was null or undefined when calling checkUserExistsWithApple.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/apple/check`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckUserExistsWithAppleRequestToJSON(requestParameters.checkUserExistsWithAppleRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonCheckUserExistsResponseFromJSON(jsonValue));
    }
    /**
     * 해당 Apple 계정으로 가입된 사용자가 있는지를 확인합니다.
     */
    async checkUserExistsWithApple(requestParameters, initOverrides) {
        const response = await this.checkUserExistsWithAppleRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 해당 email 계정으로 가입된 사용자가 있는지를 확인합니다.
     */
    async checkUserExistsWithEmailRaw(requestParameters, initOverrides) {
        if (requestParameters.checkUserExistsWithEmailRequest === null || requestParameters.checkUserExistsWithEmailRequest === undefined) {
            throw new runtime.RequiredError('checkUserExistsWithEmailRequest', 'Required parameter requestParameters.checkUserExistsWithEmailRequest was null or undefined when calling checkUserExistsWithEmail.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/email/check`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckUserExistsWithEmailRequestToJSON(requestParameters.checkUserExistsWithEmailRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonCheckUserExistsResponseFromJSON(jsonValue));
    }
    /**
     * 해당 email 계정으로 가입된 사용자가 있는지를 확인합니다.
     */
    async checkUserExistsWithEmail(requestParameters, initOverrides) {
        const response = await this.checkUserExistsWithEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 해당 Google 계정으로 가입된 사용자가 있는지를 확인합니다.\\ OAuth implicit grant flow를 따릅니다.
     */
    async checkUserExistsWithGoogleRaw(requestParameters, initOverrides) {
        if (requestParameters.checkUserExistsWithGoogleRequest === null || requestParameters.checkUserExistsWithGoogleRequest === undefined) {
            throw new runtime.RequiredError('checkUserExistsWithGoogleRequest', 'Required parameter requestParameters.checkUserExistsWithGoogleRequest was null or undefined when calling checkUserExistsWithGoogle.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/google/check`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckUserExistsWithGoogleRequestToJSON(requestParameters.checkUserExistsWithGoogleRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonCheckUserExistsResponseFromJSON(jsonValue));
    }
    /**
     * 해당 Google 계정으로 가입된 사용자가 있는지를 확인합니다.\\ OAuth implicit grant flow를 따릅니다.
     */
    async checkUserExistsWithGoogle(requestParameters, initOverrides) {
        const response = await this.checkUserExistsWithGoogleRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 해당 카카오 계정으로 가입된 사용자가 있는지를 확인합니다.
     */
    async checkUserExistsWithKakaoRaw(requestParameters, initOverrides) {
        if (requestParameters.checkUserExistsWithKakaoRequest === null || requestParameters.checkUserExistsWithKakaoRequest === undefined) {
            throw new runtime.RequiredError('checkUserExistsWithKakaoRequest', 'Required parameter requestParameters.checkUserExistsWithKakaoRequest was null or undefined when calling checkUserExistsWithKakao.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/kakao/check`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckUserExistsWithKakaoRequestToJSON(requestParameters.checkUserExistsWithKakaoRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonCheckUserExistsResponseFromJSON(jsonValue));
    }
    /**
     * 해당 카카오 계정으로 가입된 사용자가 있는지를 확인합니다.
     */
    async checkUserExistsWithKakao(requestParameters, initOverrides) {
        const response = await this.checkUserExistsWithKakaoRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 해당 Line 계정으로 가입된 사용자가 있는지를 확인합니다.
     */
    async checkUserExistsWithLineRaw(requestParameters, initOverrides) {
        if (requestParameters.checkUserExistsWithLineRequest === null || requestParameters.checkUserExistsWithLineRequest === undefined) {
            throw new runtime.RequiredError('checkUserExistsWithLineRequest', 'Required parameter requestParameters.checkUserExistsWithLineRequest was null or undefined when calling checkUserExistsWithLine.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/line/check`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckUserExistsWithLineRequestToJSON(requestParameters.checkUserExistsWithLineRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonCheckUserExistsResponseFromJSON(jsonValue));
    }
    /**
     * 해당 Line 계정으로 가입된 사용자가 있는지를 확인합니다.
     */
    async checkUserExistsWithLine(requestParameters, initOverrides) {
        const response = await this.checkUserExistsWithLineRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * user-agent header와 locale code를 기반으로 익명 사용자를 생성하고 UserToken을 반환합니다.
     */
    async createAnonymousUserRaw(requestParameters, initOverrides) {
        if (requestParameters.createAnonymousUserRequest === null || requestParameters.createAnonymousUserRequest === undefined) {
            throw new runtime.RequiredError('createAnonymousUserRequest', 'Required parameter requestParameters.createAnonymousUserRequest was null or undefined when calling createAnonymousUser.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAnonymousUserRequestToJSON(requestParameters.createAnonymousUserRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAnonymousUserResponseFromJSON(jsonValue));
    }
    /**
     * user-agent header와 locale code를 기반으로 익명 사용자를 생성하고 UserToken을 반환합니다.
     */
    async createAnonymousUser(requestParameters, initOverrides) {
        const response = await this.createAnonymousUserRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Apple OAuth 정보를 바탕으로 로그인을 시도하고 UserToken을 반환합니다.
     */
    async loginWithAppleRaw(requestParameters, initOverrides) {
        if (requestParameters.loginWithAppleRequest === null || requestParameters.loginWithAppleRequest === undefined) {
            throw new runtime.RequiredError('loginWithAppleRequest', 'Required parameter requestParameters.loginWithAppleRequest was null or undefined when calling loginWithApple.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/apple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginWithAppleRequestToJSON(requestParameters.loginWithAppleRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonLoginResponseFromJSON(jsonValue));
    }
    /**
     * Apple OAuth 정보를 바탕으로 로그인을 시도하고 UserToken을 반환합니다.
     */
    async loginWithApple(requestParameters, initOverrides) {
        const response = await this.loginWithAppleRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 이메일, 비밀번호를 바탕으로 로그인을 시도하고 UserToken을 반환합니다.
     */
    async loginWithEmailPasswordRaw(requestParameters, initOverrides) {
        if (requestParameters.loginWithEmailPasswordRequest === null || requestParameters.loginWithEmailPasswordRequest === undefined) {
            throw new runtime.RequiredError('loginWithEmailPasswordRequest', 'Required parameter requestParameters.loginWithEmailPasswordRequest was null or undefined when calling loginWithEmailPassword.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginWithEmailPasswordRequestToJSON(requestParameters.loginWithEmailPasswordRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonLoginResponseFromJSON(jsonValue));
    }
    /**
     * 이메일, 비밀번호를 바탕으로 로그인을 시도하고 UserToken을 반환합니다.
     */
    async loginWithEmailPassword(requestParameters, initOverrides) {
        const response = await this.loginWithEmailPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Google OAuth 정보를 바탕으로 로그인을 시도하고 UserToken을 반환합니다.
     */
    async loginWithGoogleRaw(requestParameters, initOverrides) {
        if (requestParameters.loginWithGoogleRequest === null || requestParameters.loginWithGoogleRequest === undefined) {
            throw new runtime.RequiredError('loginWithGoogleRequest', 'Required parameter requestParameters.loginWithGoogleRequest was null or undefined when calling loginWithGoogle.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/google`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginWithGoogleRequestToJSON(requestParameters.loginWithGoogleRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonLoginResponseFromJSON(jsonValue));
    }
    /**
     * Google OAuth 정보를 바탕으로 로그인을 시도하고 UserToken을 반환합니다.
     */
    async loginWithGoogle(requestParameters, initOverrides) {
        const response = await this.loginWithGoogleRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 카카오 OAuth 정보를 바탕으로 로그인을 시도하고 UserToken을 반환합니다.
     */
    async loginWithKakaoRaw(requestParameters, initOverrides) {
        if (requestParameters.loginWithKakaoRequest === null || requestParameters.loginWithKakaoRequest === undefined) {
            throw new runtime.RequiredError('loginWithKakaoRequest', 'Required parameter requestParameters.loginWithKakaoRequest was null or undefined when calling loginWithKakao.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/kakao`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginWithKakaoRequestToJSON(requestParameters.loginWithKakaoRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonLoginResponseFromJSON(jsonValue));
    }
    /**
     * 카카오 OAuth 정보를 바탕으로 로그인을 시도하고 UserToken을 반환합니다.
     */
    async loginWithKakao(requestParameters, initOverrides) {
        const response = await this.loginWithKakaoRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Line OAuth 정보를 바탕으로 로그인을 시도하고 UserToken을 반환합니다.
     */
    async loginWithLineRaw(requestParameters, initOverrides) {
        if (requestParameters.loginWithLineRequest === null || requestParameters.loginWithLineRequest === undefined) {
            throw new runtime.RequiredError('loginWithLineRequest', 'Required parameter requestParameters.loginWithLineRequest was null or undefined when calling loginWithLine.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/line`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginWithLineRequestToJSON(requestParameters.loginWithLineRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonLoginResponseFromJSON(jsonValue));
    }
    /**
     * Line OAuth 정보를 바탕으로 로그인을 시도하고 UserToken을 반환합니다.
     */
    async loginWithLine(requestParameters, initOverrides) {
        const response = await this.loginWithLineRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * UserToken.accessToken 을 갱신합니다.
     */
    async refreshTokenRaw(requestParameters, initOverrides) {
        if (requestParameters.refreshTokenRequest === null || requestParameters.refreshTokenRequest === undefined) {
            throw new runtime.RequiredError('refreshTokenRequest', 'Required parameter requestParameters.refreshTokenRequest was null or undefined when calling refreshToken.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenRequestToJSON(requestParameters.refreshTokenRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshTokenResponseFromJSON(jsonValue));
    }
    /**
     * UserToken.accessToken 을 갱신합니다.
     */
    async refreshToken(requestParameters, initOverrides) {
        const response = await this.refreshTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 비밀번호 재설정을 위한 이메일을 전송합니다.
     */
    async requestResetPasswordEmailRaw(requestParameters, initOverrides) {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email', 'Required parameter requestParameters.email was null or undefined when calling requestResetPasswordEmail.');
        }
        const queryParameters = {};
        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/user/v1/auth/reset-password`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 비밀번호 재설정을 위한 이메일을 전송합니다.
     */
    async requestResetPasswordEmail(requestParameters, initOverrides) {
        await this.requestResetPasswordEmailRaw(requestParameters, initOverrides);
    }
    /**
     * 이메일의 토큰을 가지고 비밀번호를 재설정합니다.
     */
    async resetPasswordRaw(requestParameters, initOverrides) {
        if (requestParameters.resetPasswordRequest === null || requestParameters.resetPasswordRequest === undefined) {
            throw new runtime.RequiredError('resetPasswordRequest', 'Required parameter requestParameters.resetPasswordRequest was null or undefined when calling resetPassword.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 이메일의 토큰을 가지고 비밀번호를 재설정합니다.
     */
    async resetPassword(requestParameters, initOverrides) {
        await this.resetPasswordRaw(requestParameters, initOverrides);
    }
    /**
     * Apple OAuth 정보를 바탕으로 기존 익명 사용자 정보를 업데이트하고 새로운 UserToken을 반환합니다.
     */
    async signupWithAppleRaw(requestParameters, initOverrides) {
        if (requestParameters.signupWithAppleRequest === null || requestParameters.signupWithAppleRequest === undefined) {
            throw new runtime.RequiredError('signupWithAppleRequest', 'Required parameter requestParameters.signupWithAppleRequest was null or undefined when calling signupWithApple.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/apple`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SignupWithAppleRequestToJSON(requestParameters.signupWithAppleRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonSignupResponseFromJSON(jsonValue));
    }
    /**
     * Apple OAuth 정보를 바탕으로 기존 익명 사용자 정보를 업데이트하고 새로운 UserToken을 반환합니다.
     */
    async signupWithApple(requestParameters, initOverrides) {
        const response = await this.signupWithAppleRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 이메일, 비밀번호를 바탕으로 기존 익명 사용자 정보를 업데이트하고 새로운 UserToken을 반환합니다.
     */
    async signupWithEmailPasswordRaw(requestParameters, initOverrides) {
        if (requestParameters.signupWithEmailPasswordRequest === null || requestParameters.signupWithEmailPasswordRequest === undefined) {
            throw new runtime.RequiredError('signupWithEmailPasswordRequest', 'Required parameter requestParameters.signupWithEmailPasswordRequest was null or undefined when calling signupWithEmailPassword.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SignupWithEmailPasswordRequestToJSON(requestParameters.signupWithEmailPasswordRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonSignupResponseFromJSON(jsonValue));
    }
    /**
     * 이메일, 비밀번호를 바탕으로 기존 익명 사용자 정보를 업데이트하고 새로운 UserToken을 반환합니다.
     */
    async signupWithEmailPassword(requestParameters, initOverrides) {
        const response = await this.signupWithEmailPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Google OAuth 정보를 바탕으로 기존 익명 사용자 정보를 업데이트하고 새로운 UserToken을 반환합니다.\\ OAuth implicit grant flow를 따릅니다.
     */
    async signupWithGoogleRaw(requestParameters, initOverrides) {
        if (requestParameters.signupWithGoogleRequest === null || requestParameters.signupWithGoogleRequest === undefined) {
            throw new runtime.RequiredError('signupWithGoogleRequest', 'Required parameter requestParameters.signupWithGoogleRequest was null or undefined when calling signupWithGoogle.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/google`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SignupWithGoogleRequestToJSON(requestParameters.signupWithGoogleRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonSignupResponseFromJSON(jsonValue));
    }
    /**
     * Google OAuth 정보를 바탕으로 기존 익명 사용자 정보를 업데이트하고 새로운 UserToken을 반환합니다.\\ OAuth implicit grant flow를 따릅니다.
     */
    async signupWithGoogle(requestParameters, initOverrides) {
        const response = await this.signupWithGoogleRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 카카오 OAuth 정보를 바탕으로 기존 익명 사용자 정보를 업데이트하고 새로운 UserToken을 반환합니다.
     */
    async signupWithKakaoRaw(requestParameters, initOverrides) {
        if (requestParameters.signupWithKakaoRequest === null || requestParameters.signupWithKakaoRequest === undefined) {
            throw new runtime.RequiredError('signupWithKakaoRequest', 'Required parameter requestParameters.signupWithKakaoRequest was null or undefined when calling signupWithKakao.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/kakao`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SignupWithKakaoRequestToJSON(requestParameters.signupWithKakaoRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonSignupResponseFromJSON(jsonValue));
    }
    /**
     * 카카오 OAuth 정보를 바탕으로 기존 익명 사용자 정보를 업데이트하고 새로운 UserToken을 반환합니다.
     */
    async signupWithKakao(requestParameters, initOverrides) {
        const response = await this.signupWithKakaoRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Line OAuth 정보를 바탕으로 기존 익명 사용자 정보를 업데이트하고 새로운 UserToken을 반환합니다.
     */
    async signupWithLineRaw(requestParameters, initOverrides) {
        if (requestParameters.signupWithLineRequest === null || requestParameters.signupWithLineRequest === undefined) {
            throw new runtime.RequiredError('signupWithLineRequest', 'Required parameter requestParameters.signupWithLineRequest was null or undefined when calling signupWithLine.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/line`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SignupWithLineRequestToJSON(requestParameters.signupWithLineRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CommonSignupResponseFromJSON(jsonValue));
    }
    /**
     * Line OAuth 정보를 바탕으로 기존 익명 사용자 정보를 업데이트하고 새로운 UserToken을 반환합니다.
     */
    async signupWithLine(requestParameters, initOverrides) {
        const response = await this.signupWithLineRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 비밀번호 재설정 토큰이 유효한지 확인합니다.
     */
    async validateResetPasswordTokenRaw(requestParameters, initOverrides) {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token', 'Required parameter requestParameters.token was null or undefined when calling validateResetPasswordToken.');
        }
        const queryParameters = {};
        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/user/v1/auth/reset-password/validate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateResetPasswordTokenResponseFromJSON(jsonValue));
    }
    /**
     * 비밀번호 재설정 토큰이 유효한지 확인합니다.
     */
    async validateResetPasswordToken(requestParameters, initOverrides) {
        const response = await this.validateResetPasswordTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 회원 탈퇴를 진행합니다.
     */
    async withdrawRaw(requestParameters, initOverrides) {
        if (requestParameters.withdrawRequest === null || requestParameters.withdrawRequest === undefined) {
            throw new runtime.RequiredError('withdrawRequest', 'Required parameter requestParameters.withdrawRequest was null or undefined when calling withdraw.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/auth/withdraw`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawRequestToJSON(requestParameters.withdrawRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 회원 탈퇴를 진행합니다.
     */
    async withdraw(requestParameters, initOverrides) {
        await this.withdrawRaw(requestParameters, initOverrides);
    }
}
