import { LearningCycleReportFromJSON, LearningCycleReportToJSON, } from './LearningCycleReport';
/**
 * Check if a given object implements the GetLearningCycleReportResponse interface.
 */
export function instanceOfGetLearningCycleReportResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "learningCycleReport" in value;
    return isInstance;
}
export function GetLearningCycleReportResponseFromJSON(json) {
    return GetLearningCycleReportResponseFromJSONTyped(json, false);
}
export function GetLearningCycleReportResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningCycleReport': LearningCycleReportFromJSON(json['LearningCycleReport']),
    };
}
export function GetLearningCycleReportResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'LearningCycleReport': LearningCycleReportToJSON(value.learningCycleReport),
    };
}
