/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { LearningCellResponseFromJSON, UpdateLearningCellRequestToJSON, } from '../models';
/**
 *
 */
export class LearningCellV1Api extends runtime.BaseAPI {
    /**
     * `LearningCell`을 가져옵니다.
     * LearningCell을 가져옵니다.
     */
    async getLearningCellRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getLearningCell.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/learning-cells/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => LearningCellResponseFromJSON(jsonValue));
    }
    /**
     * `LearningCell`을 가져옵니다.
     * LearningCell을 가져옵니다.
     */
    async getLearningCell(requestParameters, initOverrides) {
        const response = await this.getLearningCellRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * `LearningCell`을 업데이트 합니다.
     * LearningCell을 업데이트 합니다.
     */
    async updateLearningCellRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateLearningCell.');
        }
        if (requestParameters.updateLearningCellRequest === null || requestParameters.updateLearningCellRequest === undefined) {
            throw new runtime.RequiredError('updateLearningCellRequest', 'Required parameter requestParameters.updateLearningCellRequest was null or undefined when calling updateLearningCell.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/content-learning/v1/learning-cells/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLearningCellRequestToJSON(requestParameters.updateLearningCellRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => LearningCellResponseFromJSON(jsonValue));
    }
    /**
     * `LearningCell`을 업데이트 합니다.
     * LearningCell을 업데이트 합니다.
     */
    async updateLearningCell(requestParameters, initOverrides) {
        const response = await this.updateLearningCellRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
