import * as ds from "@riiid/design-system";
export var createTypographyTheme = function() {
    return {
        variantMapping: {
            h1: "h1",
            h2: "h2",
            h3: "h3",
            h4: "h4",
            h5: "h5",
            body1: "p",
            body2: "p",
            body3: "p",
            caption1: "span",
            caption2: "span",
            caption3: "span",
            point: "span"
        },
        variants: {
            h1: {
                fontSize: ds.FONT_SIZE_H1,
                letterSpacing: ds.LETTER_SPACING_H1,
                lineHeight: ds.LINE_HEIGHT_H1
            },
            h2: {
                fontSize: ds.FONT_SIZE_H2,
                letterSpacing: ds.LETTER_SPACING_H2,
                lineHeight: ds.LINE_HEIGHT_H2
            },
            h3: {
                fontSize: ds.FONT_SIZE_H3,
                letterSpacing: ds.LETTER_SPACING_H3,
                lineHeight: ds.LINE_HEIGHT_H3
            },
            h4: {
                fontSize: ds.FONT_SIZE_H4,
                letterSpacing: ds.LETTER_SPACING_H4,
                lineHeight: ds.LINE_HEIGHT_H4
            },
            h5: {
                fontSize: ds.FONT_SIZE_H5,
                letterSpacing: ds.LETTER_SPACING_H5,
                lineHeight: ds.LINE_HEIGHT_H5
            },
            body1: {
                fontSize: ds.FONT_SIZE_BODY1,
                letterSpacing: ds.LETTER_SPACING_BODY1,
                lineHeight: ds.LINE_HEIGHT_BODY1
            },
            body2: {
                fontSize: ds.FONT_SIZE_BODY2,
                letterSpacing: ds.LETTER_SPACING_BODY2,
                lineHeight: ds.LINE_HEIGHT_BODY2
            },
            body3: {
                fontSize: ds.FONT_SIZE_BODY3,
                letterSpacing: ds.LETTER_SPACING_BODY3,
                lineHeight: ds.LINE_HEIGHT_BODY3
            },
            caption1: {
                fontSize: ds.FONT_SIZE_CAPTION1,
                letterSpacing: ds.LETTER_SPACING_CAPTION1,
                lineHeight: ds.LINE_HEIGHT_CAPTION1
            },
            caption2: {
                fontSize: ds.FONT_SIZE_CAPTION2,
                letterSpacing: ds.LETTER_SPACING_CAPTION2,
                lineHeight: ds.LINE_HEIGHT_CAPTION2
            },
            caption3: {
                fontSize: ds.FONT_SIZE_CAPTION3,
                letterSpacing: ds.LETTER_SPACING_CAPTION3,
                lineHeight: ds.LINE_HEIGHT_CAPTION3
            },
            point: {
                fontSize: ds.FONT_SIZE_POINT,
                letterSpacing: ds.LETTER_SPACING_POINT,
                lineHeight: ds.LINE_HEIGHT_POINT
            }
        }
    };
};
