/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { AIBlockTypeFromJSON, AIBlockTypeToJSON, } from './AIBlockType';
/**
 * Check if a given object implements the AIBlock interface.
 */
export function instanceOfAIBlock(value) {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function AIBlockFromJSON(json) {
    return AIBlockFromJSONTyped(json, false);
}
export function AIBlockFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'webLink': !exists(json, 'webLink') ? undefined : json['webLink'],
        'description': json['description'],
        'id': json['id'],
        'type': AIBlockTypeFromJSON(json['type']),
        'mobileLink': !exists(json, 'mobileLink') ? undefined : json['mobileLink'],
    };
}
export function AIBlockToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'webLink': value.webLink,
        'description': value.description,
        'id': value.id,
        'type': AIBlockTypeToJSON(value.type),
        'mobileLink': value.mobileLink,
    };
}
