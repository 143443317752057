/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { VocaStateVocaOngoingStepFromJSON, VocaStateVocaOngoingStepToJSON, } from './VocaStateVocaOngoingStep';
/**
 * Check if a given object implements the VocaStateSelfReport interface.
 */
export function instanceOfVocaStateSelfReport(value) {
    let isInstance = true;
    isInstance = isInstance && "vocaOngoingStep" in value;
    return isInstance;
}
export function VocaStateSelfReportFromJSON(json) {
    return VocaStateSelfReportFromJSONTyped(json, false);
}
export function VocaStateSelfReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isKnowledge': !exists(json, 'isKnowledge') ? undefined : json['isKnowledge'],
        'vocaOngoingStep': VocaStateVocaOngoingStepFromJSON(json['vocaOngoingStep']),
    };
}
export function VocaStateSelfReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isKnowledge': value.isKnowledge,
        'vocaOngoingStep': VocaStateVocaOngoingStepToJSON(value.vocaOngoingStep),
    };
}
