/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { LearningCellConfigOneOfCaseFromJSON, LearningCellConfigOneOfCaseToJSON, } from './LearningCellConfigOneOfCase';
import { LearningCellConfigQuestionSetFromJSON, LearningCellConfigQuestionSetToJSON, } from './LearningCellConfigQuestionSet';
import { LearningCellConfigQuestionStreamFromJSON, LearningCellConfigQuestionStreamToJSON, } from './LearningCellConfigQuestionStream';
import { LearningCellConfigVocaFromJSON, LearningCellConfigVocaToJSON, } from './LearningCellConfigVoca';
/**
 * Check if a given object implements the LearningCellConfigOneOf interface.
 */
export function instanceOfLearningCellConfigOneOf(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function LearningCellConfigOneOfFromJSON(json) {
    return LearningCellConfigOneOfFromJSONTyped(json, false);
}
export function LearningCellConfigOneOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'voca': !exists(json, 'voca') ? undefined : LearningCellConfigVocaFromJSON(json['voca']),
        'oneOfCase': LearningCellConfigOneOfCaseFromJSON(json['oneOfCase']),
        'questionSet': !exists(json, 'questionSet') ? undefined : LearningCellConfigQuestionSetFromJSON(json['questionSet']),
        'lesson': !exists(json, 'lesson') ? undefined : json['lesson'],
        'questionStream': !exists(json, 'questionStream') ? undefined : LearningCellConfigQuestionStreamFromJSON(json['questionStream']),
    };
}
export function LearningCellConfigOneOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'voca': LearningCellConfigVocaToJSON(value.voca),
        'oneOfCase': LearningCellConfigOneOfCaseToJSON(value.oneOfCase),
        'questionSet': LearningCellConfigQuestionSetToJSON(value.questionSet),
        'lesson': value.lesson,
        'questionStream': LearningCellConfigQuestionStreamToJSON(value.questionStream),
    };
}
