// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../../google/protobuf/Timestamp";
import { WireType, } from "../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        createdAt: undefined,
        canceledAt: undefined,
        paymentRequestedAt: undefined,
        paymentFailedAt: undefined,
        paymentSucceededAt: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.createdAt !== undefined)
        result.createdAt = encodeJson_1(value.createdAt);
    if (value.canceledAt !== undefined)
        result.canceledAt = encodeJson_1(value.canceledAt);
    if (value.paymentRequestedAt !== undefined)
        result.paymentRequestedAt = encodeJson_1(value.paymentRequestedAt);
    if (value.paymentFailedAt !== undefined)
        result.paymentFailedAt = encodeJson_1(value.paymentFailedAt);
    if (value.paymentSucceededAt !== undefined)
        result.paymentSucceededAt = encodeJson_1(value.paymentSucceededAt);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.createdAt !== undefined)
        result.createdAt = decodeJson_1(value.createdAt);
    if (value.canceledAt !== undefined)
        result.canceledAt = decodeJson_1(value.canceledAt);
    if (value.paymentRequestedAt !== undefined)
        result.paymentRequestedAt = decodeJson_1(value.paymentRequestedAt);
    if (value.paymentFailedAt !== undefined)
        result.paymentFailedAt = decodeJson_1(value.paymentFailedAt);
    if (value.paymentSucceededAt !== undefined)
        result.paymentSucceededAt = decodeJson_1(value.paymentSucceededAt);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.createdAt !== undefined) {
        const tsValue = value.createdAt;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.canceledAt !== undefined) {
        const tsValue = value.canceledAt;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.paymentRequestedAt !== undefined) {
        const tsValue = value.paymentRequestedAt;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.paymentFailedAt !== undefined) {
        const tsValue = value.paymentFailedAt;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.paymentSucceededAt !== undefined) {
        const tsValue = value.paymentSucceededAt;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAt = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.canceledAt = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.paymentRequestedAt = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.paymentFailedAt = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.paymentSucceededAt = value;
    }
    return result;
}
