/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const StripeCurrency = {
    JPY: 'JPY',
    KRW: 'KRW',
    USD: 'USD'
};
export function StripeCurrencyFromJSON(json) {
    return StripeCurrencyFromJSONTyped(json, false);
}
export function StripeCurrencyFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function StripeCurrencyToJSON(value) {
    return value;
}
