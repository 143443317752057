export const num2name = {
    0: "UNSPECIFIED_LANGUAGE",
    1: "LANGUAGE_KO",
    2: "LANGUAGE_JA",
    3: "LANGUAGE_EN",
    4: "LANGUAGE_PT",
    5: "LANGUAGE_ES",
    6: "LANGUAGE_VI",
    7: "LANGUAGE_ZH",
    8: "LANGUAGE_TH",
};
export const name2num = {
    UNSPECIFIED_LANGUAGE: 0,
    LANGUAGE_KO: 1,
    LANGUAGE_JA: 2,
    LANGUAGE_EN: 3,
    LANGUAGE_PT: 4,
    LANGUAGE_ES: 5,
    LANGUAGE_VI: 6,
    LANGUAGE_ZH: 7,
    LANGUAGE_TH: 8,
};
