// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./VoiceResourceIdsByAccent";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        text: "",
        voiceResourceId: "",
        translation: "",
        voiceResourceIdsByAccent: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.text !== undefined)
        result.text = tsValueToJsonValueFns.string(value.text);
    if (value.voiceResourceId !== undefined)
        result.voiceResourceId = tsValueToJsonValueFns.string(value.voiceResourceId);
    if (value.translation !== undefined)
        result.translation = tsValueToJsonValueFns.string(value.translation);
    if (value.voiceResourceIdsByAccent !== undefined)
        result.voiceResourceIdsByAccent = encodeJson_1(value.voiceResourceIdsByAccent);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.text !== undefined)
        result.text = jsonValueToTsValueFns.string(value.text);
    if (value.voiceResourceId !== undefined)
        result.voiceResourceId = jsonValueToTsValueFns.string(value.voiceResourceId);
    if (value.translation !== undefined)
        result.translation = jsonValueToTsValueFns.string(value.translation);
    if (value.voiceResourceIdsByAccent !== undefined)
        result.voiceResourceIdsByAccent = decodeJson_1(value.voiceResourceIdsByAccent);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.text !== undefined) {
        const tsValue = value.text;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.voiceResourceId !== undefined) {
        const tsValue = value.voiceResourceId;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.translation !== undefined) {
        const tsValue = value.translation;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.voiceResourceIdsByAccent !== undefined) {
        const tsValue = value.voiceResourceIdsByAccent;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.text = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.voiceResourceId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.translation = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.voiceResourceIdsByAccent = value;
    }
    return result;
}
