export const num2name = {
    0: "UNKNOWN",
    1: "IAMPORT",
    2: "TOSS",
    3: "STRIPE",
    4: "APP_STORE",
    5: "PLAY_STORE",
    6: "MANUAL",
};
export const name2num = {
    UNKNOWN: 0,
    IAMPORT: 1,
    TOSS: 2,
    STRIPE: 3,
    APP_STORE: 4,
    PLAY_STORE: 5,
    MANUAL: 6,
};
