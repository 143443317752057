export const num2name = {
    0: "USER_SEG_BY_PAYMENT_UNSPECIFIED",
    1: "USER_SEG_BY_PAYMENT_PAID",
    2: "USER_SEG_BY_PAYMENT_UNPAID_AND_HAVE_PAID",
    3: "USER_SEG_BY_PAYMENT_UNPAID_AND_NEVER_PAID_AND_WITHIN_3DAYS_OF_REGISTRATION",
    4: "USER_SEG_BY_PAYMENT_UNPAID_AND_NEVER_PAID_AND_AFTER_3DAYS_OF_REGISTRATION",
    5: "USER_SEG_BY_PAYMENT_ANONYMOUS",
};
export const name2num = {
    USER_SEG_BY_PAYMENT_UNSPECIFIED: 0,
    USER_SEG_BY_PAYMENT_PAID: 1,
    USER_SEG_BY_PAYMENT_UNPAID_AND_HAVE_PAID: 2,
    USER_SEG_BY_PAYMENT_UNPAID_AND_NEVER_PAID_AND_WITHIN_3DAYS_OF_REGISTRATION: 3,
    USER_SEG_BY_PAYMENT_UNPAID_AND_NEVER_PAID_AND_AFTER_3DAYS_OF_REGISTRATION: 4,
    USER_SEG_BY_PAYMENT_ANONYMOUS: 5,
};
