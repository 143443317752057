import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
import { LearningCycleInfoFromJSON, LearningCycleInfoToJSON, } from './LearningCycleInfo';
/**
 * Check if a given object implements the ListLearningCycleInfosResponse interface.
 */
export function instanceOfListLearningCycleInfosResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "learningCycleInfos" in value;
    isInstance = isInstance && "page" in value;
    return isInstance;
}
export function ListLearningCycleInfosResponseFromJSON(json) {
    return ListLearningCycleInfosResponseFromJSONTyped(json, false);
}
export function ListLearningCycleInfosResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningCycleInfos': (json['learningCycleInfos'].map(LearningCycleInfoFromJSON)),
        'page': CursorPageFromJSON(json['page']),
    };
}
export function ListLearningCycleInfosResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningCycleInfos': (value.learningCycleInfos.map(LearningCycleInfoToJSON)),
        'page': CursorPageToJSON(value.page),
    };
}
