/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CreateExamRequestToJSON, GetExamResponseFromJSON, GetReplyDownloadUrlResponseFromJSON, GetReplyUploadUrlResponseFromJSON, ListExamsResponseFromJSON, } from '../models';
/**
 *
 */
export class ExamV1Api extends runtime.BaseAPI {
    /**
     * 새 시험 생성. 응시자가 문제 세트를 선택한다.
     */
    async createExamRaw(requestParameters, initOverrides) {
        if (requestParameters.createExamRequest === null || requestParameters.createExamRequest === undefined) {
            throw new runtime.RequiredError('createExamRequest', 'Required parameter requestParameters.createExamRequest was null or undefined when calling createExam.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/exam/v1/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExamRequestToJSON(requestParameters.createExamRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamResponseFromJSON(jsonValue));
    }
    /**
     * 새 시험 생성. 응시자가 문제 세트를 선택한다.
     */
    async createExam(requestParameters, initOverrides) {
        const response = await this.createExamRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 시험 조회
     */
    async getExamRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getExam.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/exam/v1/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamResponseFromJSON(jsonValue));
    }
    /**
     * 시험 조회
     */
    async getExam(requestParameters, initOverrides) {
        const response = await this.getExamRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Exam의 reply을 다운로드할 수 있는 presigned url을 조회.
     */
    async getReplyDownloadUrlRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getReplyDownloadUrl.');
        }
        if (requestParameters.replyId === null || requestParameters.replyId === undefined) {
            throw new runtime.RequiredError('replyId', 'Required parameter requestParameters.replyId was null or undefined when calling getReplyDownloadUrl.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/exam/v1/{id}/reply/{replyId}/download-url/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"replyId"}}`, encodeURIComponent(String(requestParameters.replyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetReplyDownloadUrlResponseFromJSON(jsonValue));
    }
    /**
     * Exam의 reply을 다운로드할 수 있는 presigned url을 조회.
     */
    async getReplyDownloadUrl(requestParameters, initOverrides) {
        const response = await this.getReplyDownloadUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 응답을 업로드하기 위한 url을 조회한다.
     */
    async getReplyUploadUrlRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getReplyUploadUrl.');
        }
        if (requestParameters.replyId === null || requestParameters.replyId === undefined) {
            throw new runtime.RequiredError('replyId', 'Required parameter requestParameters.replyId was null or undefined when calling getReplyUploadUrl.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/exam/v1/{id}/reply/{replyId}/upload-url/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"replyId"}}`, encodeURIComponent(String(requestParameters.replyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetReplyUploadUrlResponseFromJSON(jsonValue));
    }
    /**
     * 응답을 업로드하기 위한 url을 조회한다.
     */
    async getReplyUploadUrl(requestParameters, initOverrides) {
        const response = await this.getReplyUploadUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 시험 목록을 조회합니다.
     */
    async listExamsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listExams.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/exam/v1/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListExamsResponseFromJSON(jsonValue));
    }
    /**
     * 시험 목록을 조회합니다.
     */
    async listExams(requestParameters, initOverrides) {
        const response = await this.listExamsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 전체 시험에 대한 재분석 요청. 2023/06/14 정책 기준 최신 버전이 아닌 경우에만 재분석 가능
     */
    async reanalyzeExamRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling reanalyzeExam.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/exam/v1/{id}/reanalyze`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamResponseFromJSON(jsonValue));
    }
    /**
     * 전체 시험에 대한 재분석 요청. 2023/06/14 정책 기준 최신 버전이 아닌 경우에만 재분석 가능
     */
    async reanalyzeExam(requestParameters, initOverrides) {
        const response = await this.reanalyzeExamRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 응답을 업로드했음을 알린다.
     */
    async setReplyUploadedRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling setReplyUploaded.');
        }
        if (requestParameters.replyId === null || requestParameters.replyId === undefined) {
            throw new runtime.RequiredError('replyId', 'Required parameter requestParameters.replyId was null or undefined when calling setReplyUploaded.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/exam/v1/{id}/reply/{replyId}/set-uploaded`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"replyId"}}`, encodeURIComponent(String(requestParameters.replyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 응답을 업로드했음을 알린다.
     */
    async setReplyUploaded(requestParameters, initOverrides) {
        await this.setReplyUploadedRaw(requestParameters, initOverrides);
    }
}
