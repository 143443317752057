/**
 * Check if a given object implements the SummarizeContentInteractionStatesResponse interface.
 */
export function instanceOfSummarizeContentInteractionStatesResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "totalElapsedTimeMs" in value;
    isInstance = isInstance && "count" in value;
    return isInstance;
}
export function SummarizeContentInteractionStatesResponseFromJSON(json) {
    return SummarizeContentInteractionStatesResponseFromJSONTyped(json, false);
}
export function SummarizeContentInteractionStatesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalElapsedTimeMs': json['totalElapsedTimeMs'],
        'count': json['count'],
    };
}
export function SummarizeContentInteractionStatesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totalElapsedTimeMs': value.totalElapsedTimeMs,
        'count': value.count,
    };
}
