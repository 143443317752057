import { DiagnosisDisplayConfigFromJSON, DiagnosisDisplayConfigToJSON, } from './DiagnosisDisplayConfig';
/**
 * Check if a given object implements the DiagnosisConfig interface.
 */
export function instanceOfDiagnosisConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "displayConfig" in value;
    isInstance = isInstance && "lcAvailable" in value;
    isInstance = isInstance && "lcRequired" in value;
    return isInstance;
}
export function DiagnosisConfigFromJSON(json) {
    return DiagnosisConfigFromJSONTyped(json, false);
}
export function DiagnosisConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayConfig': DiagnosisDisplayConfigFromJSON(json['displayConfig']),
        'lcAvailable': json['lcAvailable'],
        'lcRequired': json['lcRequired'],
    };
}
export function DiagnosisConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayConfig': DiagnosisDisplayConfigToJSON(value.displayConfig),
        'lcAvailable': value.lcAvailable,
        'lcRequired': value.lcRequired,
    };
}
