// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../runtime/json/scalar";
import { default as serialize, } from "../runtime/wire/serialize";
import { tsValueToWireValueFns, unpackFns, } from "../runtime/wire/scalar";
import { default as deserialize, } from "../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        values: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    result.values = value.values.map(value => tsValueToJsonValueFns.double(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    result.values = (_b = (_a = value.values) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.double(value))) !== null && _b !== void 0 ? _b : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    for (const tsValue of value.values) {
        result.push([1, tsValueToWireValueFns.double(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.double(wireValues));
        if (!value.length)
            break collection;
        result.values = value;
    }
    return result;
}
