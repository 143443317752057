/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const GTelpUserSegment = {
    UNKNOWN: 'UNKNOWN'
};
export function GTelpUserSegmentFromJSON(json) {
    return GTelpUserSegmentFromJSONTyped(json, false);
}
export function GTelpUserSegmentFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function GTelpUserSegmentToJSON(value) {
    return value;
}
