/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreateUnderlineRequestToJSON, CreateUnderlineResponseFromJSON, GetUnderlineResponseFromJSON, } from '../models';
/**
 *
 */
export class UnderlineV1Api extends runtime.BaseAPI {
    /**
     */
    async createUnderlineRaw(requestParameters, initOverrides) {
        if (requestParameters.createUnderlineRequest === null || requestParameters.createUnderlineRequest === undefined) {
            throw new runtime.RequiredError('createUnderlineRequest', 'Required parameter requestParameters.createUnderlineRequest was null or undefined when calling createUnderline.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/content-learning/v1/underline/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUnderlineRequestToJSON(requestParameters.createUnderlineRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUnderlineResponseFromJSON(jsonValue));
    }
    /**
     */
    async createUnderline(requestParameters, initOverrides) {
        const response = await this.createUnderlineRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getUnderlineRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling getUnderline.');
        }
        const queryParameters = {};
        if (requestParameters.contentInteractionStateId !== undefined) {
            queryParameters['contentInteractionStateId'] = requestParameters.contentInteractionStateId;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/underline/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetUnderlineResponseFromJSON(jsonValue));
    }
    /**
     */
    async getUnderline(requestParameters, initOverrides) {
        const response = await this.getUnderlineRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
