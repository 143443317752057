/**
 * Check if a given object implements the EstimatedTagProficiency interface.
 */
export function instanceOfEstimatedTagProficiency(value) {
    let isInstance = true;
    isInstance = isInstance && "tagName" in value;
    isInstance = isInstance && "proficiency" in value;
    isInstance = isInstance && "translatedTagName" in value;
    return isInstance;
}
export function EstimatedTagProficiencyFromJSON(json) {
    return EstimatedTagProficiencyFromJSONTyped(json, false);
}
export function EstimatedTagProficiencyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tagName': json['tagName'],
        'proficiency': json['proficiency'],
        'translatedTagName': json['translatedTagName'],
    };
}
export function EstimatedTagProficiencyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tagName': value.tagName,
        'proficiency': value.proficiency,
        'translatedTagName': value.translatedTagName,
    };
}
