/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CurrencyFromJSON, CurrencyToJSON, } from './Currency';
/**
 * Check if a given object implements the TicketProductAmountData interface.
 */
export function instanceOfTicketProductAmountData(value) {
    let isInstance = true;
    isInstance = isInstance && "defaultAmount" in value;
    isInstance = isInstance && "chargeAmount" in value;
    isInstance = isInstance && "currency" in value;
    return isInstance;
}
export function TicketProductAmountDataFromJSON(json) {
    return TicketProductAmountDataFromJSONTyped(json, false);
}
export function TicketProductAmountDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'defaultAmount': json['defaultAmount'],
        'discountAmount': !exists(json, 'discountAmount') ? undefined : json['discountAmount'],
        'chargeAmount': json['chargeAmount'],
        'currency': CurrencyFromJSON(json['currency']),
    };
}
export function TicketProductAmountDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'defaultAmount': value.defaultAmount,
        'discountAmount': value.discountAmount,
        'chargeAmount': value.chargeAmount,
        'currency': CurrencyToJSON(value.currency),
    };
}
