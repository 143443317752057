import i18n from '@app/i18n';

declare global {
  interface Window {
    __THEME_TOGGLE_HANDLER: (e: KeyboardEvent) => void;
    __LOCALE_SETTING_SHOW_HANDLER: (e: KeyboardEvent) => void;
  }
}

export const registerThemeToggleEvent = () => {
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.code === 'KeyM') {
      const root = document.documentElement;

      const dataColorScheme = root.getAttribute('data-color-scheme');

      switch (dataColorScheme) {
        case 'light':
          root.setAttribute('data-color-scheme', 'dark');
          alert('Current color theme is dark theme');
          break;
        case 'dark':
          root.removeAttribute('data-color-scheme');
          alert('Current color theme is unset (device theme)');
          break;
        default:
          root.setAttribute('data-color-scheme', 'light');
          alert('Current color theme is light theme');
          break;
      }
    }
  };
  document.removeEventListener('keydown', window.__THEME_TOGGLE_HANDLER);
  document.addEventListener('keydown', onKeyDown);
  window.__THEME_TOGGLE_HANDLER = onKeyDown;
};

export const registerShowLocaleSettingEvent = () => {
  const SUPPORTED_LOCALES = ['ko-KR', 'en-US', 'ja-JP', 'th-TH', 'vi-VN', 'zh-TW'];
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.shiftKey && e.ctrlKey && e.code === 'KeyL') {
      const locale = prompt(`Supported locales: ${SUPPORTED_LOCALES.join(', ')}`);

      if (locale === null || !SUPPORTED_LOCALES.includes(locale)) {
        alert(`"${locale}" is not supported`);
        return;
      }

      i18n.changeLanguage(locale);
    }
  };

  document.removeEventListener('keydown', window.__LOCALE_SETTING_SHOW_HANDLER);
  document.addEventListener('keydown', onKeyDown);
  window.__LOCALE_SETTING_SHOW_HANDLER = onKeyDown;
};
