/**
 * Check if a given object implements the LearningCellConfigQuestionSet interface.
 */
export function instanceOfLearningCellConfigQuestionSet(value) {
    let isInstance = true;
    isInstance = isInstance && "isExam" in value;
    return isInstance;
}
export function LearningCellConfigQuestionSetFromJSON(json) {
    return LearningCellConfigQuestionSetFromJSONTyped(json, false);
}
export function LearningCellConfigQuestionSetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isExam': json['isExam'],
    };
}
export function LearningCellConfigQuestionSetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isExam': value.isExam,
    };
}
