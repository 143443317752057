import { DiagnosisReportFromJSON, DiagnosisReportToJSON, } from './DiagnosisReport';
/**
 * Check if a given object implements the GetDiagnosisReportResponse interface.
 */
export function instanceOfGetDiagnosisReportResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "diagnosisReport" in value;
    return isInstance;
}
export function GetDiagnosisReportResponseFromJSON(json) {
    return GetDiagnosisReportResponseFromJSONTyped(json, false);
}
export function GetDiagnosisReportResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'diagnosisReport': DiagnosisReportFromJSON(json['diagnosisReport']),
    };
}
export function GetDiagnosisReportResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'diagnosisReport': DiagnosisReportToJSON(value.diagnosisReport),
    };
}
