export const num2name = {
    0: "PRICE_EXPRESSION_METHOD_UNSPECIFIED",
    1: "PRICE_EXPRESSION_METHOD_TOTAL_AMOUNT",
    2: "PRICE_EXPRESSION_METHOD_PER_MONTH",
};
export const name2num = {
    PRICE_EXPRESSION_METHOD_UNSPECIFIED: 0,
    PRICE_EXPRESSION_METHOD_TOTAL_AMOUNT: 1,
    PRICE_EXPRESSION_METHOD_PER_MONTH: 2,
};
