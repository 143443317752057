/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { DiscountCouponConfigFromJSON, DiscountCouponConfigToJSON, } from './DiscountCouponConfig';
import { ExchangeCouponConfigFromJSON, ExchangeCouponConfigToJSON, } from './ExchangeCouponConfig';
import { OneOfCouponConfigCaseFromJSON, OneOfCouponConfigCaseToJSON, } from './OneOfCouponConfigCase';
/**
 * Check if a given object implements the OneOfCouponConfig interface.
 */
export function instanceOfOneOfCouponConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function OneOfCouponConfigFromJSON(json) {
    return OneOfCouponConfigFromJSONTyped(json, false);
}
export function OneOfCouponConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'exchangeCouponConfig': !exists(json, 'exchangeCouponConfig') ? undefined : ExchangeCouponConfigFromJSON(json['exchangeCouponConfig']),
        'oneOfCase': OneOfCouponConfigCaseFromJSON(json['oneOfCase']),
        'discountCouponConfig': !exists(json, 'discountCouponConfig') ? undefined : DiscountCouponConfigFromJSON(json['discountCouponConfig']),
    };
}
export function OneOfCouponConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'exchangeCouponConfig': ExchangeCouponConfigToJSON(value.exchangeCouponConfig),
        'oneOfCase': OneOfCouponConfigCaseToJSON(value.oneOfCase),
        'discountCouponConfig': DiscountCouponConfigToJSON(value.discountCouponConfig),
    };
}
