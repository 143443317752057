/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const QuestionStateDetailsOneOfCase = {
    OBJECTIVE: 'OBJECTIVE',
    SUBJECTIVE: 'SUBJECTIVE',
    TRUE_FALSE: 'TRUE_FALSE'
};
export function QuestionStateDetailsOneOfCaseFromJSON(json) {
    return QuestionStateDetailsOneOfCaseFromJSONTyped(json, false);
}
export function QuestionStateDetailsOneOfCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function QuestionStateDetailsOneOfCaseToJSON(value) {
    return value;
}
