/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const ElementInteractionStateDetailsOneOfCase = {
    QUESTION: 'QUESTION',
    LESSON: 'LESSON',
    VOCA: 'VOCA'
};
export function ElementInteractionStateDetailsOneOfCaseFromJSON(json) {
    return ElementInteractionStateDetailsOneOfCaseFromJSONTyped(json, false);
}
export function ElementInteractionStateDetailsOneOfCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ElementInteractionStateDetailsOneOfCaseToJSON(value) {
    return value;
}
