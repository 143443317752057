import { EstimatedTagProficiencyWithTargetFromJSON, EstimatedTagProficiencyWithTargetToJSON, } from './EstimatedTagProficiencyWithTarget';
/**
 * Check if a given object implements the EstimatedTagProficienciesWithTarget interface.
 */
export function instanceOfEstimatedTagProficienciesWithTarget(value) {
    let isInstance = true;
    isInstance = isInstance && "estimationAndTargetPairs" in value;
    isInstance = isInstance && "targetScore" in value;
    return isInstance;
}
export function EstimatedTagProficienciesWithTargetFromJSON(json) {
    return EstimatedTagProficienciesWithTargetFromJSONTyped(json, false);
}
export function EstimatedTagProficienciesWithTargetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'estimationAndTargetPairs': (json['estimationAndTargetPairs'].map(EstimatedTagProficiencyWithTargetFromJSON)),
        'targetScore': json['targetScore'],
    };
}
export function EstimatedTagProficienciesWithTargetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'estimationAndTargetPairs': (value.estimationAndTargetPairs.map(EstimatedTagProficiencyWithTargetToJSON)),
        'targetScore': value.targetScore,
    };
}
