import {atomWithDefault} from 'jotai/utils';
import Cookies from 'js-cookie';

import {
  ListVisibleLearningDomainsResponseLearningDomain as LearningDomain,
  LearningDomainKey,
} from '@santa-web/gen/open-api/service';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';
import {SantaBootFailureError} from '@app/utils/error';

import santaAuthorizedOpenapiServicesAtom from './santa-authorized-openapi-services';

export const learningDomainsAtom = atomWithDefault<Promise<LearningDomain[]>>(async get => {
  const {LearningDomainService} = await get(santaAuthorizedOpenapiServicesAtom);
  const {learningDomains} = await LearningDomainService.listVisibleLearningDomains({size: 100});

  return learningDomains;
});

const learningDomainAtom = atomWithDefault<Promise<LearningDomain>>(async get => {
  // NOTE: Webview 환경에서는 TOEIC 키를 가진 learningDomain을 찾아 쓰지 않고 모바일에서 제공받은 값을 검증 없이 사용하기 때문에 learningDomains가 필요하지 않음
  // NOTE: 원칙적으로는 ↑ 이지만 도메인 확장 도중에는 키를 이용해 땜빵 로직을 작성해야 하는 경우가 있어 일단 Webview 환경에서도 불러 쓰도록 함
  const learningDomains = await get(learningDomainsAtom);

  if (isAppBridgeAvailable()) {
    /**
     * Webview 환경에서는 모바일로부터 쿠키에 주입받은 learningDomain ID를 사용한다. [문서](https://www.notion.so/riiid/Learning-domain-dcf0cc3e09bb49278e4d8940c677273a?pvs=4) 참조.
     */
    const learningDomainIdRaw = Cookies.get('riiid-learning-domain');
    if (learningDomainIdRaw == null) {
      throw new SantaLearningDomainIdError('riiid-learning-domain cookie is not set for webview');
    }
    const learningDomainId = Number(learningDomainIdRaw);
    if (!Number.isSafeInteger(learningDomainId)) {
      throw new SantaLearningDomainIdError(
        `riiid-learning-domain cookie is invalid: ${learningDomainId} (type: ${typeof learningDomainId})`
      );
    }
    const learningDomain = learningDomains.find(({id}) => id === learningDomainId);
    if (learningDomain == null) {
      throw new SantaLearningDomainIdError(
        `given riiid-learning-domain is not in visible learning domains: ${learningDomainId} (type: ${typeof learningDomainId})`
      );
    }
    return learningDomain;
  } else {
    // PC Web 환경에서는 TOEIC key를 가진 learningDomain ID를 사용한다.
    const toeic = learningDomains.find(({key}) => key === LearningDomainKey.TOEIC);
    if (toeic == null) {
      throw new SantaToeicLearningDomainNotFoundError('TOEIC learning domain is not found');
    }
    return toeic;
  }
});

export class SantaLearningDomainIdError extends SantaBootFailureError {
  constructor(message: string) {
    super(message, learningDomainAtom);
    this.name = 'SantaLearningDomainError';
  }
}

export class SantaToeicLearningDomainNotFoundError extends SantaBootFailureError {
  constructor(message: string) {
    super(message, learningDomainAtom);
    this.name = 'SantaToeicLearningDomainNotFoundError';
  }
}

export default learningDomainAtom;

learningDomainAtom.debugPrivate = true;
