import {toeicSpeaking} from '@santa-web/service-common';
const {config: toeicSpeakingConfig} = toeicSpeaking;

export type Config = {
  env: 'development' | 'production' | 'staging';
  commitHash: string;
  buildTimestamp: number;
  domainServer: string;
  domainHttpServer: string;
  sentryDsn: string;
  iamportCode: string;
  kakaoKey: string;
  googleClientId: string;
  appleAppId: string;
  lineClientId: string;
  lineClientSecret: string;
  gtmId: string;
  gtmEnv: string;
  gtmAuth: string;
  metaPixelId: string;
  santaDomain: string;
  brazeApiKey: string;
  brazeEndPoint: string;
  enableProdDevtools: string;
  toeicSpeakingDomainServer: string;
  toeicSpeakingDomain: string;
  toeicSpeakingEmailFormId: string;
  santaSystemCheckServer: string;

  // libs
  jqueryUrl: string;
  iamportUrl: string;
  kakaoUrl: string;
  googleUrl: string;
  appleUrl: string;
  lineTokenUrl: string;
  lineAuthUrl: string;
  deepLinkUrl: string;
  toeicProductBoardDeepLinkUrl: string;
};

const config: Config = (() => {
  const common = {
    env: (process.env.NOW_ENV || 'development') as 'development' | 'production' | 'staging',
    buildTimestamp: +process.env.BUILD_TIMESTAMP!,
    commitHash: process.env.COMMIT_SHA!,
    sentryDsn: 'https://cc209e9e17804434961dd354135c8e23@o314078.ingest.sentry.io/5662474',
    jqueryUrl: '/js/jquery-1.12.4.min.js',
    iamportUrl: '/js/iamport.payment-1.1.5.js',
    kakaoUrl: 'https://developers.kakao.com/sdk/js/kakao.js',
    googleUrl: 'https://apis.google.com/js/api:client.js',
    appleUrl: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
    lineTokenUrl: 'https://api.line.me/oauth2/v2.1/token',
    lineAuthUrl: 'https://access.line.me/oauth2/v2.1/authorize',
    gtmId: 'GTM-M756DGC',
    enableProdDevtools: process.env.ENABLE_PROD_DEVTOOLS ?? '',
    toeicSpeakingEmailFormId: 'yA7wgw5e',
    santaSystemCheckServer: 'https://system-check.aitutorsanta.com',
    toeicProductBoardDeepLinkUrl: 'riiidaitutor://toeic/product-board',
  };

  if (common.env === 'production') {
    return {
      ...common,
      domainServer: 'https://toeic.prod.riiid.cloud',
      domainHttpServer: 'https://toeic-http-api.prod.riiid.cloud',
      appleAppId: 'com.santatoeic',
      googleClientId: '672939403159-lqte9vcr3gmleb0bub3a21plcbpoi52m.apps.googleusercontent.com',
      kakaoKey: 'b4bfeb709a31f44def1c29dbc05d7ecb',
      lineClientId: '1655674896',
      lineClientSecret: 'ba22d640f2d81640761333bdd55c8e70',
      gtmEnv: 'env-8',
      gtmAuth: 'PVQUuACrCIOTnZ89Ytgz4w',
      metaPixelId: '1677778775623678',
      iamportCode: 'imp29741373',
      santaDomain: 'https://aitutorsanta.com',
      deepLinkUrl: 'https://riiidtutor.onelink.me/rfl2/toeic',
      brazeApiKey: '81d43fbb-9fad-4374-a11d-8073b3d9a4b4',
      brazeEndPoint: 'sdk.iad-05.braze.com',
      toeicSpeakingDomainServer: toeicSpeakingConfig.production.server,
      toeicSpeakingDomain: 'https://toeic-speaking.aitutorsanta.com',
    };
  } else if (common.env === 'staging') {
    return {
      ...common,
      domainServer: 'https://toeic.stg.riiid.cloud',
      domainHttpServer: 'https://toeic-http-api.stg.riiid.cloud',
      kakaoKey: 'bf4da2051d74566a3d6c6599443f1c4f',
      googleClientId: '672939403159-itkeue33k6orn8aujlrocjqto973obn7.apps.googleusercontent.com',
      appleAppId: 'com.santatoeic.stg',
      lineClientId: '1655638116',
      lineClientSecret: '3b8cd03482f1ad8a4827b7db75af6b21',
      gtmEnv: 'env-7',
      gtmAuth: '2bss9vshsBQrCmJA7qRK5g',
      metaPixelId: '496749692582527',
      iamportCode: 'imp51745287',
      santaDomain: 'https://dev.vida-web.riiid.app',
      // TODO: replace with url for stg.
      deepLinkUrl: 'https://riiidtutor.onelink.me/rfl2/toeic',
      brazeApiKey: 'a8bb8200-f95c-4039-a5a8-1c19d334e244',
      brazeEndPoint: 'sdk.iad-05.braze.com',
      toeicSpeakingDomainServer: toeicSpeakingConfig.staging.server,
      toeicSpeakingDomain: 'https://santa-toeic-speaking-web-stg.vercel.app',
    };
  } else {
    common.env = 'development';
    return {
      ...common,
      domainServer: 'https://toeic.dev.riiid.cloud',
      domainHttpServer: 'https://toeic-http-api.dev.riiid.cloud',
      kakaoKey: 'b98ce9890acbf0c6a9ccae67ad4394f8',
      googleClientId: '672939403159-sef4dl1ojj37vih7bunqdl6pm391odvp.apps.googleusercontent.com',
      appleAppId: 'com.santatoeic.dev',
      lineClientId: '1655610047',
      lineClientSecret: '004d95709aa68a92e8e07ab286e14d39',
      gtmEnv: 'env-6',
      gtmAuth: 'zYTxs-F_BHaw9hVwPJnYBw',
      metaPixelId: '573845817888310',
      iamportCode: 'imp68442593',
      santaDomain: 'https://dev.vida-web.riiid.app',
      // TODO: replace with url for dev.
      deepLinkUrl: 'https://riiidtutor.onelink.me/rfl2/toeic',
      brazeApiKey: '7edd492c-8793-4359-8e7f-298ced4f5d19',
      brazeEndPoint: 'sdk.iad-05.braze.com',
      toeicSpeakingDomainServer: toeicSpeakingConfig.development.server,
      toeicSpeakingDomain: process.env.NOW_ENV
        ? 'https://santa-toeic-speaking-web-dev.vercel.app'
        : 'http://localhost:5173',
    };
  }
})();

export default config;
