/**
 * Check if a given object implements the ResetPasswordRequest interface.
 */
export function instanceOfResetPasswordRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "token" in value;
    return isInstance;
}
export function ResetPasswordRequestFromJSON(json) {
    return ResetPasswordRequestFromJSONTyped(json, false);
}
export function ResetPasswordRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'password': json['password'],
        'token': json['token'],
    };
}
export function ResetPasswordRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'password': value.password,
        'token': value.token,
    };
}
