import { LineRegistrationInfoFromJSON, LineRegistrationInfoToJSON, } from './LineRegistrationInfo';
/**
 * Check if a given object implements the CheckUserExistsWithLineRequest interface.
 */
export function instanceOfCheckUserExistsWithLineRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "registrationInfo" in value;
    return isInstance;
}
export function CheckUserExistsWithLineRequestFromJSON(json) {
    return CheckUserExistsWithLineRequestFromJSONTyped(json, false);
}
export function CheckUserExistsWithLineRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'registrationInfo': LineRegistrationInfoFromJSON(json['registrationInfo']),
    };
}
export function CheckUserExistsWithLineRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'registrationInfo': LineRegistrationInfoToJSON(value.registrationInfo),
    };
}
