/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { QuestionStateDetailsOneOfCaseFromJSON, QuestionStateDetailsOneOfCaseToJSON, } from './QuestionStateDetailsOneOfCase';
import { QuestionStateObjectiveFromJSON, QuestionStateObjectiveToJSON, } from './QuestionStateObjective';
import { QuestionStateSubjectiveFromJSON, QuestionStateSubjectiveToJSON, } from './QuestionStateSubjective';
import { QuestionStateTrueFalseFromJSON, QuestionStateTrueFalseToJSON, } from './QuestionStateTrueFalse';
/**
 * Check if a given object implements the QuestionStateOneOfDetails interface.
 */
export function instanceOfQuestionStateOneOfDetails(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function QuestionStateOneOfDetailsFromJSON(json) {
    return QuestionStateOneOfDetailsFromJSONTyped(json, false);
}
export function QuestionStateOneOfDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'oneOfCase': QuestionStateDetailsOneOfCaseFromJSON(json['oneOfCase']),
        'subjective': !exists(json, 'subjective') ? undefined : QuestionStateSubjectiveFromJSON(json['subjective']),
        'trueFalse': !exists(json, 'trueFalse') ? undefined : QuestionStateTrueFalseFromJSON(json['trueFalse']),
        'objective': !exists(json, 'objective') ? undefined : QuestionStateObjectiveFromJSON(json['objective']),
    };
}
export function QuestionStateOneOfDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'oneOfCase': QuestionStateDetailsOneOfCaseToJSON(value.oneOfCase),
        'subjective': QuestionStateSubjectiveToJSON(value.subjective),
        'trueFalse': QuestionStateTrueFalseToJSON(value.trueFalse),
        'objective': QuestionStateObjectiveToJSON(value.objective),
    };
}
