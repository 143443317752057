/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CouponFromJSON, CouponToJSON, } from './Coupon';
import { TicketGroupSourceOneOfCaseFromJSON, TicketGroupSourceOneOfCaseToJSON, } from './TicketGroupSourceOneOfCase';
import { TicketOrderFromJSON, TicketOrderToJSON, } from './TicketOrder';
/**
 * Check if a given object implements the TicketGroupSource interface.
 */
export function instanceOfTicketGroupSource(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function TicketGroupSourceFromJSON(json) {
    return TicketGroupSourceFromJSONTyped(json, false);
}
export function TicketGroupSourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'exchangeCoupon': !exists(json, 'exchangeCoupon') ? undefined : CouponFromJSON(json['exchangeCoupon']),
        'oneOfCase': TicketGroupSourceOneOfCaseFromJSON(json['oneOfCase']),
        'ticketOrder': !exists(json, 'ticketOrder') ? undefined : TicketOrderFromJSON(json['ticketOrder']),
    };
}
export function TicketGroupSourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'exchangeCoupon': CouponToJSON(value.exchangeCoupon),
        'oneOfCase': TicketGroupSourceOneOfCaseToJSON(value.oneOfCase),
        'ticketOrder': TicketOrderToJSON(value.ticketOrder),
    };
}
