/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const TagGroupProficiencyReportType = {
    SKILL: 'SKILL',
    SUBJECT: 'SUBJECT',
    PRONOUNCE: 'PRONOUNCE',
    WEAK_CHAPTER: 'WEAK_CHAPTER'
};
export function TagGroupProficiencyReportTypeFromJSON(json) {
    return TagGroupProficiencyReportTypeFromJSONTyped(json, false);
}
export function TagGroupProficiencyReportTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TagGroupProficiencyReportTypeToJSON(value) {
    return value;
}
