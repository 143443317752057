import { LearningCellExtraInfoOneOfFromJSON, LearningCellExtraInfoOneOfToJSON, } from './LearningCellExtraInfoOneOf';
/**
 * Check if a given object implements the LearningCellExtraInfo interface.
 */
export function instanceOfLearningCellExtraInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    return isInstance;
}
export function LearningCellExtraInfoFromJSON(json) {
    return LearningCellExtraInfoFromJSONTyped(json, false);
}
export function LearningCellExtraInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'value': LearningCellExtraInfoOneOfFromJSON(json['value']),
    };
}
export function LearningCellExtraInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'value': LearningCellExtraInfoOneOfToJSON(value.value),
    };
}
