// @ts-nocheck
import { name2num, num2name, } from "./ErrorCode";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { default as Long, } from "../../../../runtime/Long";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        code: "ERROR_CODE_UNKNOWN",
        message: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.code !== undefined)
        result.code = tsValueToJsonValueFns.enum(value.code);
    if (value.message !== undefined)
        result.message = tsValueToJsonValueFns.string(value.message);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.code !== undefined)
        result.code = jsonValueToTsValueFns.enum(value.code);
    if (value.message !== undefined)
        result.message = jsonValueToTsValueFns.string(value.message);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.code !== undefined) {
        const tsValue = value.code;
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.message !== undefined) {
        const tsValue = value.message;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.code = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.message = value;
    }
    return result;
}
