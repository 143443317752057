import React from 'react';
import {use100vh} from 'react-div-100vh';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_E} from '@riiid/design-system';

import {Button, SinglePageLayout, SystemErrorLayout} from '@santa-web/service-ui';
import MobileSafeAreaColor from '@app/components/MobileSafeAreaColor';

const SystemCheckView: React.FC<{reference: string}> = ({reference}) => {
  const viewportHeight = use100vh();
  const {t} = useTranslation();

  return (
    <div
      css={css`
        width: 100%;
        height: ${viewportHeight ? `${viewportHeight}px` : '100vh'};
      `}>
      <MobileSafeAreaColor bottom={{color: COLOR_SANTA_E}} />
      <SinglePageLayout isError>
        <SystemErrorLayout
          icon="warning"
          description={
            <p
              css={css`
                white-space: pre-line;
              `}>
              {t('system_check_title')}
            </p>
          }
          button={
            <a
              href={reference}
              css={css`
                text-decoration: none;
              `}>
              <Button variant="box-line">{t('system_check_button')}</Button>
            </a>
          }
        />
      </SinglePageLayout>
    </div>
  );
};

export default SystemCheckView;
