/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CouponStatusFromJSON, CouponStatusToJSON, } from './CouponStatus';
import { OneOfCouponConfigFromJSON, OneOfCouponConfigToJSON, } from './OneOfCouponConfig';
import { PeriodFromJSON, PeriodToJSON, } from './Period';
/**
 * Check if a given object implements the Coupon interface.
 */
export function instanceOfCoupon(value) {
    let isInstance = true;
    isInstance = isInstance && "validUsePeriod" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "registeredAt" in value;
    isInstance = isInstance && "oneOfCouponConfig" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function CouponFromJSON(json) {
    return CouponFromJSONTyped(json, false);
}
export function CouponFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'validUsePeriod': PeriodFromJSON(json['validUsePeriod']),
        'name': json['name'],
        'registeredAt': (new Date(json['registeredAt'])),
        'oneOfCouponConfig': OneOfCouponConfigFromJSON(json['oneOfCouponConfig']),
        'status': CouponStatusFromJSON(json['status']),
    };
}
export function CouponToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'validUsePeriod': PeriodToJSON(value.validUsePeriod),
        'name': value.name,
        'registeredAt': (value.registeredAt.toISOString()),
        'oneOfCouponConfig': OneOfCouponConfigToJSON(value.oneOfCouponConfig),
        'status': CouponStatusToJSON(value.status),
    };
}
