/**
 * Check if a given object implements the ImageSet interface.
 */
export function instanceOfImageSet(value) {
    let isInstance = true;
    isInstance = isInstance && "imageX1Id" in value;
    isInstance = isInstance && "imageX3Id" in value;
    isInstance = isInstance && "imageX2Id" in value;
    isInstance = isInstance && "imageUrlX3" in value;
    isInstance = isInstance && "imageUrlX2" in value;
    isInstance = isInstance && "imageUrlX1" in value;
    return isInstance;
}
export function ImageSetFromJSON(json) {
    return ImageSetFromJSONTyped(json, false);
}
export function ImageSetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'imageX1Id': json['imageX1Id'],
        'imageX3Id': json['imageX3Id'],
        'imageX2Id': json['imageX2Id'],
        'imageUrlX3': json['imageUrlX3'],
        'imageUrlX2': json['imageUrlX2'],
        'imageUrlX1': json['imageUrlX1'],
    };
}
export function ImageSetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'imageX1Id': value.imageX1Id,
        'imageX3Id': value.imageX3Id,
        'imageX2Id': value.imageX2Id,
        'imageUrlX3': value.imageUrlX3,
        'imageUrlX2': value.imageUrlX2,
        'imageUrlX1': value.imageUrlX1,
    };
}
