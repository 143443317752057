import {ConversionTypes} from '@app/hooks/naver-log-analytics-script/useNaverLogAnalyticsConversionScript';

const NAVER_KEY = 's_4aba992506f2';
const DOMAIN = 'aitutorsanta.com';

export const generateCommonScript = () => `
  window.wcs_add={};
  wcs_add["wa"] = "${NAVER_KEY}";
  if (!_nasa) var _nasa={};
  if(window.wcs){
    wcs.inflow("${DOMAIN}");
    wcs_do(_nasa);
  }
`;

export const generateConversionScript = (type: ConversionTypes, value: number) => `
  var _nasa={};
  if(window.wcs) _nasa["cnv"] = wcs.cnv("${type}","${value}");
  
  ${generateCommonScript()}
`;

export const executeScript = (plainStrScript: string) => {
  const naverSDK = document.createElement('script');
  let isScriptLoaded = false;

  naverSDK.type = 'text/javascript';
  naverSDK.src = '//wcs.naver.net/wcslog.js';
  document.body.appendChild(naverSDK);

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `
    (function() {
      ${plainStrScript}
    })()
  `;

  naverSDK.onload = () => {
    document.body.appendChild(script);
    isScriptLoaded = true;
  };

  return {
    removeScripts: () => {
      document.body.removeChild(naverSDK);
      naverSDK.onload = null;
      isScriptLoaded && document.body.removeChild(script);
    },
  };
};
