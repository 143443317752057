export const num2name = {
    0: "IAMPORT_PG_UNSPECIFIED",
    1: "IAMPORT_PG_KAKAO_PAY",
    2: "IAMPORT_PG_NAVER_PAY",
    3: "IAMPORT_PG_KCP",
};
export const name2num = {
    IAMPORT_PG_UNSPECIFIED: 0,
    IAMPORT_PG_KAKAO_PAY: 1,
    IAMPORT_PG_NAVER_PAY: 2,
    IAMPORT_PG_KCP: 3,
};
