export const num2name = {
    0: "RENEWAL_INTERVAL_UNSPECIFIED",
    1: "RENEWAL_INTERVAL_WEEK_1",
    2: "RENEWAL_INTERVAL_MONTH_1",
    3: "RENEWAL_INTERVAL_MONTH_3",
    4: "RENEWAL_INTERVAL_MONTH_6",
    5: "RENEWAL_INTERVAL_YEAR_1",
};
export const name2num = {
    RENEWAL_INTERVAL_UNSPECIFIED: 0,
    RENEWAL_INTERVAL_WEEK_1: 1,
    RENEWAL_INTERVAL_MONTH_1: 2,
    RENEWAL_INTERVAL_MONTH_3: 3,
    RENEWAL_INTERVAL_MONTH_6: 4,
    RENEWAL_INTERVAL_YEAR_1: 5,
};
