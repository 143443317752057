/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const UserDiagnosisStatus = {
    READY: 'READY',
    ONGOING: 'ONGOING',
    DONE: 'DONE'
};
export function UserDiagnosisStatusFromJSON(json) {
    return UserDiagnosisStatusFromJSONTyped(json, false);
}
export function UserDiagnosisStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UserDiagnosisStatusToJSON(value) {
    return value;
}
