/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ContentSummaryOneOfCaseFromJSON, ContentSummaryOneOfCaseToJSON, } from './ContentSummaryOneOfCase';
import { ContentSummaryTitleAndContentFromJSON, ContentSummaryTitleAndContentToJSON, } from './ContentSummaryTitleAndContent';
/**
 * Check if a given object implements the OneOfContentSummary interface.
 */
export function instanceOfOneOfContentSummary(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function OneOfContentSummaryFromJSON(json) {
    return OneOfContentSummaryFromJSONTyped(json, false);
}
export function OneOfContentSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'oneOfCase': ContentSummaryOneOfCaseFromJSON(json['oneOfCase']),
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'titleAndContent': !exists(json, 'titleAndContent') ? undefined : ContentSummaryTitleAndContentFromJSON(json['titleAndContent']),
    };
}
export function OneOfContentSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'oneOfCase': ContentSummaryOneOfCaseToJSON(value.oneOfCase),
        'imageUrl': value.imageUrl,
        'text': value.text,
        'titleAndContent': ContentSummaryTitleAndContentToJSON(value.titleAndContent),
    };
}
