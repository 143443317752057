/**
 * Check if a given object implements the CreateStripeCheckoutUrlResponse interface.
 */
export function instanceOfCreateStripeCheckoutUrlResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "checkoutUrl" in value;
    return isInstance;
}
export function CreateStripeCheckoutUrlResponseFromJSON(json) {
    return CreateStripeCheckoutUrlResponseFromJSONTyped(json, false);
}
export function CreateStripeCheckoutUrlResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'checkoutUrl': json['checkoutUrl'],
    };
}
export function CreateStripeCheckoutUrlResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'checkoutUrl': value.checkoutUrl,
    };
}
