/**
 * Check if a given object implements the CreateOrGetChatRoomRequest interface.
 */
export function instanceOfCreateOrGetChatRoomRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "contentInteractionStateId" in value;
    return isInstance;
}
export function CreateOrGetChatRoomRequestFromJSON(json) {
    return CreateOrGetChatRoomRequestFromJSONTyped(json, false);
}
export function CreateOrGetChatRoomRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentInteractionStateId': json['contentInteractionStateId'],
    };
}
export function CreateOrGetChatRoomRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentInteractionStateId': value.contentInteractionStateId,
    };
}
