/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const SkillAnalyticsReportCase = {
    ELAPSED_TIME: 'ELAPSED_TIME',
    TAG_GROUP_PROFICIENCY: 'TAG_GROUP_PROFICIENCY'
};
export function SkillAnalyticsReportCaseFromJSON(json) {
    return SkillAnalyticsReportCaseFromJSONTyped(json, false);
}
export function SkillAnalyticsReportCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SkillAnalyticsReportCaseToJSON(value) {
    return value;
}
