import {useRouter} from 'next/router';
import React from 'react';

import config from '@app/config';
import * as naverScript from '@app/hooks/naver-log-analytics-script/naver-script';

const conversionPaths = ['/order/result', '/webview/order/result', '/diagnosis-report', '/onboarding'];

const useNaverLogAnalyticsCommonScript = () => {
  const router = useRouter();

  React.useEffect(() => {
    // production 환경에서만 동작
    if (config.env !== 'production') {
      return;
    }

    // conversion 페이지는 `common + conversion` 스크립트를 각 페이지에서 적용
    if (conversionPaths.includes(router.pathname)) {
      return;
    }

    const {removeScripts} = naverScript.executeScript(naverScript.generateCommonScript());

    return removeScripts;
  }, [router.pathname]);
};

export default useNaverLogAnalyticsCommonScript;
