/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { LineRegistrationInfoFromJSON, LineRegistrationInfoToJSON, } from './LineRegistrationInfo';
import { SignupRequestAgreementInfoFromJSON, SignupRequestAgreementInfoToJSON, } from './SignupRequestAgreementInfo';
import { UserPersonalInfoFromJSON, UserPersonalInfoToJSON, } from './UserPersonalInfo';
/**
 * Check if a given object implements the SignupWithLineRequest interface.
 */
export function instanceOfSignupWithLineRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "agreementInfo" in value;
    isInstance = isInstance && "lineRegistrationInfo" in value;
    return isInstance;
}
export function SignupWithLineRequestFromJSON(json) {
    return SignupWithLineRequestFromJSONTyped(json, false);
}
export function SignupWithLineRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'personalInfo': !exists(json, 'personalInfo') ? undefined : UserPersonalInfoFromJSON(json['personalInfo']),
        'agreementInfo': SignupRequestAgreementInfoFromJSON(json['agreementInfo']),
        'lineRegistrationInfo': LineRegistrationInfoFromJSON(json['lineRegistrationInfo']),
    };
}
export function SignupWithLineRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'personalInfo': UserPersonalInfoToJSON(value.personalInfo),
        'agreementInfo': SignupRequestAgreementInfoToJSON(value.agreementInfo),
        'lineRegistrationInfo': LineRegistrationInfoToJSON(value.lineRegistrationInfo),
    };
}
