/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ChatMessageFeedbackFromJSON, ChatMessageFeedbackToJSON, } from './ChatMessageFeedback';
import { ChatMessageRoleFromJSON, ChatMessageRoleToJSON, } from './ChatMessageRole';
import { ChatMessageStatusFromJSON, ChatMessageStatusToJSON, } from './ChatMessageStatus';
/**
 * Check if a given object implements the ChatMessage interface.
 */
export function instanceOfChatMessage(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function ChatMessageFromJSON(json) {
    return ChatMessageFromJSONTyped(json, false);
}
export function ChatMessageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'feedback': !exists(json, 'feedback') ? undefined : ChatMessageFeedbackFromJSON(json['feedback']),
        'createdAt': (new Date(json['createdAt'])),
        'role': ChatMessageRoleFromJSON(json['role']),
        'id': json['id'],
        'content': json['content'],
        'status': ChatMessageStatusFromJSON(json['status']),
    };
}
export function ChatMessageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'feedback': ChatMessageFeedbackToJSON(value.feedback),
        'createdAt': (value.createdAt.toISOString()),
        'role': ChatMessageRoleToJSON(value.role),
        'id': value.id,
        'content': value.content,
        'status': ChatMessageStatusToJSON(value.status),
    };
}
