/**
 * Check if a given object implements the UpdateMyTargetExamDateRequest interface.
 */
export function instanceOfUpdateMyTargetExamDateRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "targetExamDate" in value;
    return isInstance;
}
export function UpdateMyTargetExamDateRequestFromJSON(json) {
    return UpdateMyTargetExamDateRequestFromJSONTyped(json, false);
}
export function UpdateMyTargetExamDateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'targetExamDate': (new Date(json['targetExamDate'])),
    };
}
export function UpdateMyTargetExamDateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'targetExamDate': (value.targetExamDate.toISOString().substr(0, 10)),
    };
}
