// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        major: 0,
        minor: 0,
        patch: 0,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.major !== undefined)
        result.major = tsValueToJsonValueFns.int32(value.major);
    if (value.minor !== undefined)
        result.minor = tsValueToJsonValueFns.int32(value.minor);
    if (value.patch !== undefined)
        result.patch = tsValueToJsonValueFns.int32(value.patch);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.major !== undefined)
        result.major = jsonValueToTsValueFns.int32(value.major);
    if (value.minor !== undefined)
        result.minor = jsonValueToTsValueFns.int32(value.minor);
    if (value.patch !== undefined)
        result.patch = jsonValueToTsValueFns.int32(value.patch);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.major !== undefined) {
        const tsValue = value.major;
        result.push([1, tsValueToWireValueFns.int32(tsValue)]);
    }
    if (value.minor !== undefined) {
        const tsValue = value.minor;
        result.push([2, tsValueToWireValueFns.int32(tsValue)]);
    }
    if (value.patch !== undefined) {
        const tsValue = value.patch;
        result.push([3, tsValueToWireValueFns.int32(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.major = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.minor = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.patch = value;
    }
    return result;
}
