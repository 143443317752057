import { useMedia } from "react-use";
var COLOR_THEMES = [
    "light",
    "dark"
];
export default function useColorTheme() {
    var customColorTheme = typeof window !== "undefined" && document.documentElement.dataset.colorScheme;
    if (customColorTheme && !COLOR_THEMES.includes(customColorTheme)) {
        throw new Error("Invalid color theme has been set");
    }
    var systemColorTheme = useMedia("(prefers-color-scheme: dark)") ? "dark" : "light";
    return customColorTheme || systemColorTheme;
}
