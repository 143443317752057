// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        originalTransactionId: "",
        transactionId: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.originalTransactionId !== undefined)
        result.originalTransactionId = tsValueToJsonValueFns.string(value.originalTransactionId);
    if (value.transactionId !== undefined)
        result.transactionId = tsValueToJsonValueFns.string(value.transactionId);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.originalTransactionId !== undefined)
        result.originalTransactionId = jsonValueToTsValueFns.string(value.originalTransactionId);
    if (value.transactionId !== undefined)
        result.transactionId = jsonValueToTsValueFns.string(value.transactionId);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.originalTransactionId !== undefined) {
        const tsValue = value.originalTransactionId;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.transactionId !== undefined) {
        const tsValue = value.transactionId;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.originalTransactionId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.transactionId = value;
    }
    return result;
}
