import React from 'react';
import {useMount} from 'react-use';
import {css} from '@emotion/react';

import {registerCommonBrowserService, registerToeicBrowserPreviewService} from '@app/api/browser-services';
import config from '@app/config';
import useBaseCommonBrowserService from '@app/hooks/useBaseCommonBrowserService';

type UnhandledRejection = {
  reason: string;
  id: number;
};

const ThrowUnhandledRejection = () => {
  const baseCommonBrowserService = useBaseCommonBrowserService();

  const idRef = React.useRef(0);

  const [errors, setErrors] = React.useState<Array<UnhandledRejection>>([]);

  const appendError = React.useCallback(
    (error: UnhandledRejection) => setErrors(errors => errors.concat(error)),
    [setErrors]
  );
  const removeError = React.useCallback(
    (id: number) => setErrors(errors => errors.filter(error => error.id !== id)),
    [setErrors]
  );

  useMount(() => {
    // FIXME: Implement it gracefully
    registerCommonBrowserService(baseCommonBrowserService);
    registerToeicBrowserPreviewService({});

    const showError = config.env !== 'production';

    if (!showError) {
      return;
    }

    // @TODO: show toast
    const listener = (e: PromiseRejectionEvent) => {
      console.error(e.reason);
      appendError({
        reason: e.reason.toString(),
        id: idRef.current++,
      });
    };

    window.addEventListener('unhandledrejection', listener);

    return () => {
      window.removeEventListener('unhandledrejection', listener);
    };
  });

  if (errors.length === 0) {
    return null;
  }

  return (
    <div
      css={css`
        z-index: 999999999;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
        display: flex;
        flex-direction: column;
      `}>
      {errors.map(({reason, id}) => (
        <div
          key={id}
          onClick={() => removeError(id)}
          css={css`
            margin: 4px;
            padding: 12px;
            border-radius: 4px;
            font-size: 18px;
            background-color: red;
            color: white;
          `}>
          {reason}
        </div>
      ))}
    </div>
  );
};

export default ThrowUnhandledRejection;
