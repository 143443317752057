export const num2name = {
    0: "UNSET_PAYMENT_METHOD",
    1: "CARD",
    2: "CASH",
    3: "INAPP",
};
export const name2num = {
    UNSET_PAYMENT_METHOD: 0,
    CARD: 1,
    CASH: 2,
    INAPP: 3,
};
