/**
 * Check if a given object implements the ElapsedTimeReportItem interface.
 */
export function instanceOfElapsedTimeReportItem(value) {
    let isInstance = true;
    isInstance = isInstance && "recommendedElapsedTime" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "elapsedTime" in value;
    return isInstance;
}
export function ElapsedTimeReportItemFromJSON(json) {
    return ElapsedTimeReportItemFromJSONTyped(json, false);
}
export function ElapsedTimeReportItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'recommendedElapsedTime': json['recommendedElapsedTime'],
        'name': json['name'],
        'elapsedTime': json['elapsedTime'],
    };
}
export function ElapsedTimeReportItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'recommendedElapsedTime': value.recommendedElapsedTime,
        'name': value.name,
        'elapsedTime': value.elapsedTime,
    };
}
