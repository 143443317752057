// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(CISHighlightInfo)/ContentBodyIdentifier";
import { name2num, num2name, } from "./(CISHighlightInfo)/Color";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as Long, } from "../../runtime/Long";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        contentInteractionStateId: "0",
        contentBodyIdentifier: undefined,
        color: "COLOR_UNSPECIFIED",
        displayInfoJson: "",
        previewStr: "",
        createdAt: undefined,
        updatedAt: undefined,
        versionIdIsRecent: false,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.contentInteractionStateId !== undefined)
        result.contentInteractionStateId = tsValueToJsonValueFns.int64(value.contentInteractionStateId);
    if (value.contentBodyIdentifier !== undefined)
        result.contentBodyIdentifier = encodeJson_1(value.contentBodyIdentifier);
    if (value.color !== undefined)
        result.color = tsValueToJsonValueFns.enum(value.color);
    if (value.displayInfoJson !== undefined)
        result.displayInfoJson = tsValueToJsonValueFns.string(value.displayInfoJson);
    if (value.previewStr !== undefined)
        result.previewStr = tsValueToJsonValueFns.string(value.previewStr);
    if (value.createdAt !== undefined)
        result.createdAt = encodeJson_2(value.createdAt);
    if (value.updatedAt !== undefined)
        result.updatedAt = encodeJson_2(value.updatedAt);
    if (value.versionIdIsRecent !== undefined)
        result.versionIdIsRecent = tsValueToJsonValueFns.bool(value.versionIdIsRecent);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.contentInteractionStateId !== undefined)
        result.contentInteractionStateId = jsonValueToTsValueFns.int64(value.contentInteractionStateId);
    if (value.contentBodyIdentifier !== undefined)
        result.contentBodyIdentifier = decodeJson_1(value.contentBodyIdentifier);
    if (value.color !== undefined)
        result.color = jsonValueToTsValueFns.enum(value.color);
    if (value.displayInfoJson !== undefined)
        result.displayInfoJson = jsonValueToTsValueFns.string(value.displayInfoJson);
    if (value.previewStr !== undefined)
        result.previewStr = jsonValueToTsValueFns.string(value.previewStr);
    if (value.createdAt !== undefined)
        result.createdAt = decodeJson_2(value.createdAt);
    if (value.updatedAt !== undefined)
        result.updatedAt = decodeJson_2(value.updatedAt);
    if (value.versionIdIsRecent !== undefined)
        result.versionIdIsRecent = jsonValueToTsValueFns.bool(value.versionIdIsRecent);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.contentInteractionStateId !== undefined) {
        const tsValue = value.contentInteractionStateId;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.contentBodyIdentifier !== undefined) {
        const tsValue = value.contentBodyIdentifier;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.color !== undefined) {
        const tsValue = value.color;
        result.push([4, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.displayInfoJson !== undefined) {
        const tsValue = value.displayInfoJson;
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.previewStr !== undefined) {
        const tsValue = value.previewStr;
        result.push([6, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.createdAt !== undefined) {
        const tsValue = value.createdAt;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.updatedAt !== undefined) {
        const tsValue = value.updatedAt;
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.versionIdIsRecent !== undefined) {
        const tsValue = value.versionIdIsRecent;
        result.push([9, tsValueToWireValueFns.bool(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.contentInteractionStateId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.contentBodyIdentifier = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.color = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.displayInfoJson = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.previewStr = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAt = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.updatedAt = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.versionIdIsRecent = value;
    }
    return result;
}
