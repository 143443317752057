/**
 * Check if a given object implements the AppleRegistrationInfo interface.
 */
export function instanceOfAppleRegistrationInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "redirectUri" in value;
    isInstance = isInstance && "identityToken" in value;
    isInstance = isInstance && "authorizationCode" in value;
    return isInstance;
}
export function AppleRegistrationInfoFromJSON(json) {
    return AppleRegistrationInfoFromJSONTyped(json, false);
}
export function AppleRegistrationInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'redirectUri': json['redirectUri'],
        'identityToken': json['identityToken'],
        'authorizationCode': json['authorizationCode'],
    };
}
export function AppleRegistrationInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'redirectUri': value.redirectUri,
        'identityToken': value.identityToken,
        'authorizationCode': value.authorizationCode,
    };
}
