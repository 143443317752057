/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { UserDiagnosisStatusFromJSON, UserDiagnosisStatusToJSON, } from './UserDiagnosisStatus';
/**
 * Check if a given object implements the UserDiagnosisInfo interface.
 */
export function instanceOfUserDiagnosisInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function UserDiagnosisInfoFromJSON(json) {
    return UserDiagnosisInfoFromJSONTyped(json, false);
}
export function UserDiagnosisInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'completedAt': !exists(json, 'completedAt') ? undefined : (new Date(json['completedAt'])),
        'status': UserDiagnosisStatusFromJSON(json['status']),
    };
}
export function UserDiagnosisInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'completedAt': value.completedAt === undefined ? undefined : (value.completedAt.toISOString()),
        'status': UserDiagnosisStatusToJSON(value.status),
    };
}
