/* tslint:disable */
/* eslint-disable */
/**
 * 어휘 학습 단게를 나타낸다.
 * @export
 */
export const VocaStateVocaOngoingStep = {
    STEP1: 'STEP1',
    STEP2: 'STEP2'
};
export function VocaStateVocaOngoingStepFromJSON(json) {
    return VocaStateVocaOngoingStepFromJSONTyped(json, false);
}
export function VocaStateVocaOngoingStepFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function VocaStateVocaOngoingStepToJSON(value) {
    return value;
}
