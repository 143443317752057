export const num2name = {
    0: "REFERRED_UNSPECIFIED",
    1: "REFERRED_SELF_LEARNING_QUESTION",
    2: "REFERRED_SELF_LEARNING_LESSON",
    3: "REFERRED_SELF_LEARNING_VOCA",
    4: "REFERRED_SELF_LEARNING_QUESTION_WEAKNESS_BLOCK",
};
export const name2num = {
    REFERRED_UNSPECIFIED: 0,
    REFERRED_SELF_LEARNING_QUESTION: 1,
    REFERRED_SELF_LEARNING_LESSON: 2,
    REFERRED_SELF_LEARNING_VOCA: 3,
    REFERRED_SELF_LEARNING_QUESTION_WEAKNESS_BLOCK: 4,
};
