/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { QuestionResultDetailFromJSON, QuestionResultDetailToJSON, } from './QuestionResultDetail';
/**
 * Check if a given object implements the QuestionResult interface.
 */
export function instanceOfQuestionResult(value) {
    let isInstance = true;
    isInstance = isInstance && "score" in value;
    isInstance = isInstance && "maxScore" in value;
    return isInstance;
}
export function QuestionResultFromJSON(json) {
    return QuestionResultFromJSONTyped(json, false);
}
export function QuestionResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'score': json['score'],
        'transcription': !exists(json, 'transcription') ? undefined : json['transcription'],
        'details': !exists(json, 'details') ? undefined : (json['details'].map(QuestionResultDetailFromJSON)),
        'maxScore': json['maxScore'],
    };
}
export function QuestionResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'score': value.score,
        'transcription': value.transcription,
        'details': value.details === undefined ? undefined : (value.details.map(QuestionResultDetailToJSON)),
        'maxScore': value.maxScore,
    };
}
