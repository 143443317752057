/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const ThumbnailFgImageAlignment = {
    LEFT: 'LEFT',
    CENTER: 'CENTER'
};
export function ThumbnailFgImageAlignmentFromJSON(json) {
    return ThumbnailFgImageAlignmentFromJSONTyped(json, false);
}
export function ThumbnailFgImageAlignmentFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ThumbnailFgImageAlignmentToJSON(value) {
    return value;
}
