import { LearningCycleFromJSON, LearningCycleToJSON, } from './LearningCycle';
/**
 * Check if a given object implements the GetLearningCycleResponse interface.
 */
export function instanceOfGetLearningCycleResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "learningCycle" in value;
    return isInstance;
}
export function GetLearningCycleResponseFromJSON(json) {
    return GetLearningCycleResponseFromJSONTyped(json, false);
}
export function GetLearningCycleResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningCycle': LearningCycleFromJSON(json['learningCycle']),
    };
}
export function GetLearningCycleResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningCycle': LearningCycleToJSON(value.learningCycle),
    };
}
