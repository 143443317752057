import {useQuery} from '@tanstack/react-query';

import {useMobileServices} from '@app/api/mobile-services';

const useMobileSafeArea = () => {
  const {CommonMobileService} = useMobileServices();
  return useQuery(['useMobileSafeArea'], async () => {
    try {
      const {value} = await CommonMobileService.getSafeArea({});
      return value ?? null;
    } catch {
      return null;
    }
  });
};

export default useMobileSafeArea;
