/* tslint:disable */
/* eslint-disable */
/**
 * - **CREATED**: 구독이 생성된 상태.
 * - **ACTIVE**: 구독이 정상적으로 활성화되어 있는 상태. 유저에게 권한이 부여됨.
 * - **CANCELED**: 유저가 구독을 취소하여 더이상 갱신되지 않는 상태. 현재 이용 기간이 만료될 때 까지는 권한 이용 가능.
 * - **EXPIRED**: 유저가 구독을 취소하였거나 갱신에 실패하였으며 구독 생명 주기가 완전히 종료되어 다시 갱신될 수 없는 상태.
 * - **RENEW_FAILED**: 구독 갱신 실패
 * @export
 */
export const StripeSubscriptionStatus = {
    CREATED: 'CREATED',
    ACTIVE: 'ACTIVE',
    CANCELED: 'CANCELED',
    EXPIRED: 'EXPIRED',
    RENEW_FAILED: 'RENEW_FAILED'
};
export function StripeSubscriptionStatusFromJSON(json) {
    return StripeSubscriptionStatusFromJSONTyped(json, false);
}
export function StripeSubscriptionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function StripeSubscriptionStatusToJSON(value) {
    return value;
}
