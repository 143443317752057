/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const ContentSummaryOneOfCase = {
    TEXT: 'TEXT',
    IMAGE_URL: 'IMAGE_URL',
    TITLE_AND_CONTENT: 'TITLE_AND_CONTENT'
};
export function ContentSummaryOneOfCaseFromJSON(json) {
    return ContentSummaryOneOfCaseFromJSONTyped(json, false);
}
export function ContentSummaryOneOfCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ContentSummaryOneOfCaseToJSON(value) {
    return value;
}
