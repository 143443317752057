import { StripeSubscriptionStatusFromJSON, StripeSubscriptionStatusToJSON, } from './StripeSubscriptionStatus';
import { StripeSubscriptionStripeProductFromJSON, StripeSubscriptionStripeProductToJSON, } from './StripeSubscriptionStripeProduct';
/**
 * Check if a given object implements the StripeSubscriptionListItem interface.
 */
export function instanceOfStripeSubscriptionListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "expireAt" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function StripeSubscriptionListItemFromJSON(json) {
    return StripeSubscriptionListItemFromJSONTyped(json, false);
}
export function StripeSubscriptionListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'product': StripeSubscriptionStripeProductFromJSON(json['product']),
        'id': json['id'],
        'expireAt': (new Date(json['expireAt'])),
        'status': StripeSubscriptionStatusFromJSON(json['status']),
    };
}
export function StripeSubscriptionListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': (value.createdAt.toISOString()),
        'product': StripeSubscriptionStripeProductToJSON(value.product),
        'id': value.id,
        'expireAt': (value.expireAt.toISOString()),
        'status': StripeSubscriptionStatusToJSON(value.status),
    };
}
