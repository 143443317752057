export const num2name = {
    0: "SUBSCRIPTION_ORDER_STATUS_UNSPECIFIED",
    1: "SUBSCRIPTION_ORDER_STATUS_CREATED",
    2: "SUBSCRIPTION_ORDER_STATUS_CONFIGURED",
    3: "SUBSCRIPTION_ORDER_STATUS_CANCELED",
    4: "SUBSCRIPTION_ORDER_STATUS_PAYMENT_REQUESTED",
    5: "SUBSCRIPTION_ORDER_STATUS_PAYMENT_FAILED",
    6: "SUBSCRIPTION_ORDER_STATUS_PAYMENT_SUCCEEDED",
};
export const name2num = {
    SUBSCRIPTION_ORDER_STATUS_UNSPECIFIED: 0,
    SUBSCRIPTION_ORDER_STATUS_CREATED: 1,
    SUBSCRIPTION_ORDER_STATUS_CONFIGURED: 2,
    SUBSCRIPTION_ORDER_STATUS_CANCELED: 3,
    SUBSCRIPTION_ORDER_STATUS_PAYMENT_REQUESTED: 4,
    SUBSCRIPTION_ORDER_STATUS_PAYMENT_FAILED: 5,
    SUBSCRIPTION_ORDER_STATUS_PAYMENT_SUCCEEDED: 6,
};
