/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetLearningCycleReportResponseFromJSON, } from '../models';
/**
 *
 */
export class LearningCycleReportV1Api extends runtime.BaseAPI {
    /**
     * 대상 학습 사이클에 대한 추천학습 사이클 리포트를 조회한다.
     * 추천학습 사이클 리포트를 조회한다.
     */
    async getLearningCycleReportRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCycleId === null || requestParameters.learningCycleId === undefined) {
            throw new runtime.RequiredError('learningCycleId', 'Required parameter requestParameters.learningCycleId was null or undefined when calling getLearningCycleReport.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/analytics/v1/learning-cycle-report/{learningCycleId}`.replace(`{${"learningCycleId"}}`, encodeURIComponent(String(requestParameters.learningCycleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetLearningCycleReportResponseFromJSON(jsonValue));
    }
    /**
     * 대상 학습 사이클에 대한 추천학습 사이클 리포트를 조회한다.
     * 추천학습 사이클 리포트를 조회한다.
     */
    async getLearningCycleReport(requestParameters, initOverrides) {
        const response = await this.getLearningCycleReportRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
