// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Timestamp";
import { name2num, num2name, } from "./PaymentCurrency";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        itemName: "",
        storeSubscriptionId: "",
        subscriptionTransactionId: "",
        orderId: "0",
        version: "0",
        expiredAt: undefined,
        sellingPrice: "",
        paidAmount: "",
        subscriptionPaymentId: "0",
        currency: "UNSET",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.itemName !== undefined)
        result.itemName = tsValueToJsonValueFns.string(value.itemName);
    if (value.storeSubscriptionId !== undefined)
        result.storeSubscriptionId = tsValueToJsonValueFns.string(value.storeSubscriptionId);
    if (value.subscriptionTransactionId !== undefined)
        result.subscriptionTransactionId = tsValueToJsonValueFns.string(value.subscriptionTransactionId);
    if (value.orderId !== undefined)
        result.orderId = tsValueToJsonValueFns.int64(value.orderId);
    if (value.version !== undefined)
        result.version = tsValueToJsonValueFns.int64(value.version);
    if (value.expiredAt !== undefined)
        result.expiredAt = encodeJson_1(value.expiredAt);
    if (value.sellingPrice !== undefined)
        result.sellingPrice = tsValueToJsonValueFns.string(value.sellingPrice);
    if (value.paidAmount !== undefined)
        result.paidAmount = tsValueToJsonValueFns.string(value.paidAmount);
    if (value.subscriptionPaymentId !== undefined)
        result.subscriptionPaymentId = tsValueToJsonValueFns.int64(value.subscriptionPaymentId);
    if (value.currency !== undefined)
        result.currency = tsValueToJsonValueFns.enum(value.currency);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.itemName !== undefined)
        result.itemName = jsonValueToTsValueFns.string(value.itemName);
    if (value.storeSubscriptionId !== undefined)
        result.storeSubscriptionId = jsonValueToTsValueFns.string(value.storeSubscriptionId);
    if (value.subscriptionTransactionId !== undefined)
        result.subscriptionTransactionId = jsonValueToTsValueFns.string(value.subscriptionTransactionId);
    if (value.orderId !== undefined)
        result.orderId = jsonValueToTsValueFns.int64(value.orderId);
    if (value.version !== undefined)
        result.version = jsonValueToTsValueFns.int64(value.version);
    if (value.expiredAt !== undefined)
        result.expiredAt = decodeJson_1(value.expiredAt);
    if (value.sellingPrice !== undefined)
        result.sellingPrice = jsonValueToTsValueFns.string(value.sellingPrice);
    if (value.paidAmount !== undefined)
        result.paidAmount = jsonValueToTsValueFns.string(value.paidAmount);
    if (value.subscriptionPaymentId !== undefined)
        result.subscriptionPaymentId = jsonValueToTsValueFns.int64(value.subscriptionPaymentId);
    if (value.currency !== undefined)
        result.currency = jsonValueToTsValueFns.enum(value.currency);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.itemName !== undefined) {
        const tsValue = value.itemName;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.storeSubscriptionId !== undefined) {
        const tsValue = value.storeSubscriptionId;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.subscriptionTransactionId !== undefined) {
        const tsValue = value.subscriptionTransactionId;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.orderId !== undefined) {
        const tsValue = value.orderId;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.version !== undefined) {
        const tsValue = value.version;
        result.push([5, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.expiredAt !== undefined) {
        const tsValue = value.expiredAt;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.sellingPrice !== undefined) {
        const tsValue = value.sellingPrice;
        result.push([7, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.paidAmount !== undefined) {
        const tsValue = value.paidAmount;
        result.push([8, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.subscriptionPaymentId !== undefined) {
        const tsValue = value.subscriptionPaymentId;
        result.push([9, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.currency !== undefined) {
        const tsValue = value.currency;
        result.push([10, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.itemName = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.storeSubscriptionId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.subscriptionTransactionId = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.orderId = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.version = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.expiredAt = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.sellingPrice = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.paidAmount = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.subscriptionPaymentId = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.currency = value;
    }
    return result;
}
