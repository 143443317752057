/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CanPurchaseTicketProductResponseFromJSON, TicketProductFromJSON, } from '../models';
/**
 *
 */
export class TicketProductV1Api extends runtime.BaseAPI {
    /**
     * 티켓 상품을 구매할 수 있는지 확인합니다.
     */
    async canPurchaseTicketProductRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling canPurchaseTicketProduct.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/ticketProduct/v1/{id}/availability`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CanPurchaseTicketProductResponseFromJSON(jsonValue));
    }
    /**
     * 티켓 상품을 구매할 수 있는지 확인합니다.
     */
    async canPurchaseTicketProduct(requestParameters, initOverrides) {
        const response = await this.canPurchaseTicketProductRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 티켓 상품을 조회합니다.
     */
    async getTicketProductRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getTicketProduct.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/ticketProduct/v1/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketProductFromJSON(jsonValue));
    }
    /**
     * 티켓 상품을 조회합니다.
     */
    async getTicketProduct(requestParameters, initOverrides) {
        const response = await this.getTicketProductRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
