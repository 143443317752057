import { ExamStatus } from "@santa-web/gen/open-api/toeic-speaking";
import { EXAM_PART_ARRAY, EXAM_QUESTION_NUMBERS } from "../constants";
import { ToeicSpeakingError, TOEIC_SPEAKING_ERROR } from "./error";
export var isLastQuestionOfExam = function(questionNumber) {
    var lastPart = EXAM_PART_ARRAY[EXAM_PART_ARRAY.length - 1];
    return isLastQuestionOfPart(questionNumber, lastPart);
};
export var isLastQuestionOfPart = function(questionNumber, part) {
    if (part) {
        var lastQuestionNumber = EXAM_QUESTION_NUMBERS[part];
        return questionNumber === lastQuestionNumber[lastQuestionNumber.length - 1];
    }
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = EXAM_PART_ARRAY[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var _$part = _step.value;
            var currentPartQuestions = EXAM_QUESTION_NUMBERS[_$part];
            if (currentPartQuestions.includes(questionNumber)) {
                return questionNumber === currentPartQuestions[currentPartQuestions.length - 1];
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    throw new Error("invalid question number");
};
export var getQuestionNumberFromIndex = function(part, partQuestionIndex) {
    return EXAM_QUESTION_NUMBERS[part][partQuestionIndex];
};
export var getIndexFromQuestionNumber = function(questionNumber) {
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = EXAM_PART_ARRAY[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var part = _step.value;
            if (EXAM_QUESTION_NUMBERS[part].includes(questionNumber)) {
                return questionNumber - EXAM_QUESTION_NUMBERS[part][0];
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    throw new ToeicSpeakingError(TOEIC_SPEAKING_ERROR.CLIENT.INVALID_QUESTION_NUMBER);
};
export var getPartFromQuestionNumber = function(questionNumber) {
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = EXAM_PART_ARRAY[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var part = _step.value;
            if (EXAM_QUESTION_NUMBERS[part].includes(questionNumber)) return part;
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    throw new ToeicSpeakingError(TOEIC_SPEAKING_ERROR.CLIENT.INVALID_QUESTION_NUMBER);
};
export var getNumberRangeString = function(numbers) {
    if (numbers.length === 0) return "";
    if (numbers.length === 1) return numbers[0].toString();
    return "".concat(numbers[0], "~").concat(numbers[numbers.length - 1]);
};
export var getIsExamFinished = function(exam) {
    var status = exam.progress.status;
    return ExamStatus.FINISHED === status || ExamStatus.ANALYZED === status || ExamStatus.ANALYZE_FAILED === status;
};
export var getIsTriedExamAnalysis = function(exam) {
    var status = exam.progress.status;
    return ExamStatus.ANALYZED === status || ExamStatus.ANALYZE_FAILED === status;
};
