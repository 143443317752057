import { LearningCellReportFromJSON, LearningCellReportToJSON, } from './LearningCellReport';
/**
 * Check if a given object implements the GetLearningCellReportResponse interface.
 */
export function instanceOfGetLearningCellReportResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "learningCellReport" in value;
    return isInstance;
}
export function GetLearningCellReportResponseFromJSON(json) {
    return GetLearningCellReportResponseFromJSONTyped(json, false);
}
export function GetLearningCellReportResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningCellReport': LearningCellReportFromJSON(json['learningCellReport']),
    };
}
export function GetLearningCellReportResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningCellReport': LearningCellReportToJSON(value.learningCellReport),
    };
}
