import { CorrectnessStatisticFromJSON, CorrectnessStatisticToJSON, } from './CorrectnessStatistic';
/**
 * Check if a given object implements the ElementStatistics interface.
 */
export function instanceOfElementStatistics(value) {
    let isInstance = true;
    isInstance = isInstance && "correctnessStatisticByScoreRange" in value;
    return isInstance;
}
export function ElementStatisticsFromJSON(json) {
    return ElementStatisticsFromJSONTyped(json, false);
}
export function ElementStatisticsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'correctnessStatisticByScoreRange': (json['correctnessStatisticByScoreRange'].map(CorrectnessStatisticFromJSON)),
    };
}
export function ElementStatisticsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'correctnessStatisticByScoreRange': (value.correctnessStatisticByScoreRange.map(CorrectnessStatisticToJSON)),
    };
}
