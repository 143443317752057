import {atomWithDefault} from 'jotai/utils';
import {Configuration, ConfigurationParameters, DevicePlatform} from '@santa-web/gen/open-api/service';
import {BrowserStorage} from '@santa-web/service-common';
import {OpenAPIServices, createOpenAPIServices} from '@app/api/openapi';
import santaSystemCheckStatusAtom from '@app/atoms/core/santa-system-check-status';
import config from '@app/config';
import {SantaBootFailureError, SantaFetchError, SantaResponseError} from '@app/utils/error';

export const santaUnauthorizedOpenapiServicesConfParamsAtom = atomWithDefault<ConfigurationParameters>(() => {
  return {
    basePath: config.domainHttpServer,
    headers: {
      'riiid-app-version': '0.0.0',
      'riiid-device-platform': DevicePlatform.WEB,
      'riiid-device-model': globalThis.navigator.userAgent,
    },
    // 에러 관련 middleware. 스펙은 [여기](https://www.notion.so/riiid/Santa-app-19087f3ddccc4baf8f7282367c28579b?pvs=4) 참고
    middleware: [
      {
        onError: async ({error}) => {
          if (error instanceof Error) {
            throw new SantaFetchError(error);
          }
          throw error;
        },
        post: async ({response, init: request}) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          }
          throw await SantaResponseError.fromResponse(response, request);
        },
      },
    ],
  };
});

/**
 * @description Authorization header를 주입해주지 않는 OpenAPI 서비스. 시스템 점검중일 시 에러를 던짐.
 */
const santaUnauthorizedOpenapiServicesAtom = atomWithDefault<Promise<OpenAPIServices>>(async get => {
  const browserStorage = new BrowserStorage<{DEBUG_MODE_IS_UNDER_SYSTEM_CHECK: boolean}>(sessionStorage);
  /**
   * @description [디버그 모드 reference](https://www.notion.so/riiid/ed4da607b731478f838bbc29db02b11f?pvs=4)
   */
  const isDebugMode = browserStorage.getItem('DEBUG_MODE_IS_UNDER_SYSTEM_CHECK');
  const sysChkStats = await get(santaSystemCheckStatusAtom);
  const shouldBlockBoot = isDebugMode ?? sysChkStats.isUnderSystemCheck;
  if (shouldBlockBoot) {
    throw new SantaUnderSystemCheckError(sysChkStats.systemCheckReferenceLink);
  }
  return createOpenAPIServices(new Configuration(get(santaUnauthorizedOpenapiServicesConfParamsAtom)));
});

export class SantaUnderSystemCheckError extends SantaBootFailureError {
  constructor(public reference: string) {
    super('UNDER_SYSTEM_CHECK', santaUnauthorizedOpenapiServicesAtom);
    this.name = 'SantaUnderSystemCheckError';
  }
}

export default santaUnauthorizedOpenapiServicesAtom;

santaUnauthorizedOpenapiServicesConfParamsAtom.debugPrivate = true;
santaUnauthorizedOpenapiServicesAtom.debugPrivate = true;
