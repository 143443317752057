import { createContext, useContext } from "react";
import { getDefaultTheme } from "../theme";
var defaultThemeCache = null;
var useTheme = function() {
    var theme = useContext(ThemeContext);
    if (theme == null) {
        if (defaultThemeCache == null) {
            defaultThemeCache = getDefaultTheme();
        }
        return defaultThemeCache;
    }
    return theme;
};
export var ThemeContext = createContext(null);
export default useTheme;
