export const num2name = {
    0: "VIRTUAL_EXAM_UNSPECIFIED",
    1: "VIRTUAL_EXAM_TOEIC_FULL",
    2: "VIRTUAL_EXAM_TOEIC_HALF",
    3: "VIRTUAL_EXAM_TOEIC_MINI",
};
export const name2num = {
    VIRTUAL_EXAM_UNSPECIFIED: 0,
    VIRTUAL_EXAM_TOEIC_FULL: 1,
    VIRTUAL_EXAM_TOEIC_HALF: 2,
    VIRTUAL_EXAM_TOEIC_MINI: 3,
};
