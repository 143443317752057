// @ts-nocheck
import { name2num, num2name, } from "./(SelfCardCategory)/Lesson";
import { name2num as name2num_1, num2name as num2name_1, } from "./(SelfCardCategory)/Vocabulary";
import { name2num as name2num_2, num2name as num2name_2, } from "./(SelfCardCategory)/VirtualExam";
import { name2num as name2num_3, num2name as num2name_3, } from "./(SelfCardCategory)/LessonV2";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { default as Long, } from "../../runtime/Long";
import { default as deserialize, } from "../../runtime/wire/deserialize";
import { unpackFns, } from "../../runtime/wire/scalar";
export function getDefaultValue() {
    return {
        lesson: [],
        vocabulary: [],
        virtualExam: [],
        lessonV2: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    result.lesson = value.lesson.map(value => tsValueToJsonValueFns.enum(value));
    result.vocabulary = value.vocabulary.map(value => tsValueToJsonValueFns.enum(value));
    result.virtualExam = value.virtualExam.map(value => tsValueToJsonValueFns.enum(value));
    result.lessonV2 = value.lessonV2.map(value => tsValueToJsonValueFns.enum(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const result = getDefaultValue();
    result.lesson = (_b = (_a = value.lesson) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _b !== void 0 ? _b : [];
    result.vocabulary = (_d = (_c = value.vocabulary) === null || _c === void 0 ? void 0 : _c.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _d !== void 0 ? _d : [];
    result.virtualExam = (_f = (_e = value.virtualExam) === null || _e === void 0 ? void 0 : _e.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _f !== void 0 ? _f : [];
    result.lessonV2 = (_h = (_g = value.lessonV2) === null || _g === void 0 ? void 0 : _g.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _h !== void 0 ? _h : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    for (const tsValue of value.lesson) {
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    for (const tsValue of value.vocabulary) {
        result.push([3, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    for (const tsValue of value.virtualExam) {
        result.push([4, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
    }
    for (const tsValue of value.lessonV2) {
        result.push([5, { type: WireType.Varint, value: new Long(name2num_3[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name[num]);
        if (!value.length)
            break collection;
        result.lesson = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 3).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_1[num]);
        if (!value.length)
            break collection;
        result.vocabulary = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_2[num]);
        if (!value.length)
            break collection;
        result.virtualExam = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_3[num]);
        if (!value.length)
            break collection;
        result.lessonV2 = value;
    }
    return result;
}
