// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(CheckoutSubscriptionPaymentResponse)/AppStore";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(CheckoutSubscriptionPaymentResponse)/PlayStore";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(CheckoutSubscriptionPaymentResponse)/Error";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        result: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    switch ((_a = value.result) === null || _a === void 0 ? void 0 : _a.field) {
        case "appStore": {
            result.appStore = encodeJson_1(value.result.value);
            break;
        }
        case "playStore": {
            result.playStore = encodeJson_2(value.result.value);
            break;
        }
        case "error": {
            result.error = encodeJson_3(value.result.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.appStore !== undefined)
        result.result = { field: "appStore", value: decodeJson_1(value.appStore) };
    if (value.playStore !== undefined)
        result.result = { field: "playStore", value: decodeJson_2(value.playStore) };
    if (value.error !== undefined)
        result.result = { field: "error", value: decodeJson_3(value.error) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    switch ((_a = value.result) === null || _a === void 0 ? void 0 : _a.field) {
        case "appStore": {
            const tsValue = value.result.value;
            result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
        case "playStore": {
            const tsValue = value.result.value;
            result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
        case "error": {
            const tsValue = value.result.value;
            result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    result: new Set([1, 2, 3]),
};
const oneofFieldNamesMap = {
    result: new Map([
        [1, "appStore"],
        [2, "playStore"],
        [3, "error"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.result;
        const oneofFieldNames = oneofFieldNamesMap.result;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [1](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
            [2](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
            [3](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.result = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
