/**
 * Check if a given object implements the Duration interface.
 */
export function instanceOfDuration(value) {
    let isInstance = true;
    isInstance = isInstance && "seconds" in value;
    isInstance = isInstance && "nanos" in value;
    return isInstance;
}
export function DurationFromJSON(json) {
    return DurationFromJSONTyped(json, false);
}
export function DurationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'seconds': json['seconds'],
        'nanos': json['nanos'],
    };
}
export function DurationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'seconds': value.seconds,
        'nanos': value.nanos,
    };
}
