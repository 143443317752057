/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const TimerBgColor = {
    BLACK: 'BLACK',
    WHITE: 'WHITE',
    RED: 'RED'
};
export function TimerBgColorFromJSON(json) {
    return TimerBgColorFromJSONTyped(json, false);
}
export function TimerBgColorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TimerBgColorToJSON(value) {
    return value;
}
