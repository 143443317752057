/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { PaymentInfoFromJSON, PaymentInfoToJSON, } from './PaymentInfo';
import { TicketGroupFromJSON, TicketGroupToJSON, } from './TicketGroup';
import { TicketOrderStatusFromJSON, TicketOrderStatusToJSON, } from './TicketOrderStatus';
import { TicketProductFromJSON, TicketProductToJSON, } from './TicketProduct';
/**
 * Check if a given object implements the TicketOrder interface.
 */
export function instanceOfTicketOrder(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "paymentInfo" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function TicketOrderFromJSON(json) {
    return TicketOrderFromJSONTyped(json, false);
}
export function TicketOrderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'product': TicketProductFromJSON(json['product']),
        'issuedTicketGroup': !exists(json, 'issuedTicketGroup') ? undefined : TicketGroupFromJSON(json['issuedTicketGroup']),
        'billId': !exists(json, 'billId') ? undefined : json['billId'],
        'id': json['id'],
        'paymentInfo': PaymentInfoFromJSON(json['paymentInfo']),
        'status': TicketOrderStatusFromJSON(json['status']),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function TicketOrderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': (value.createdAt.toISOString()),
        'product': TicketProductToJSON(value.product),
        'issuedTicketGroup': TicketGroupToJSON(value.issuedTicketGroup),
        'billId': value.billId,
        'id': value.id,
        'paymentInfo': PaymentInfoToJSON(value.paymentInfo),
        'status': TicketOrderStatusToJSON(value.status),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
