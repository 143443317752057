/* tslint:disable */
/* eslint-disable */
/**
 * - **AVAILABLE**: 사용자가 상품을 구매할 수 있는 상태.
 * - **UNAVAILABLE_ALREADY_SUBSCRIBED**: 만료되지 않은 구독이 있어서 상품을 구매할 수 없는 상태.
 * - **UNAVAILABLE_HAS_AUTHORITY**: 어떠한 권한이라도 존재를 하는 경우. 이건 해당 상품이 제공하는 권한이 아닌 경우도 해당한다.
 * - **UNAVAILABLE_HAS_FULFILLMENT**: 아직 사용하지 않은 구매 내역(fulfillment)이 존재하는 경우
 * @export
 */
export const StripeCheckoutAvailability = {
    AVAILABLE: 'AVAILABLE',
    UNAVAILABLE_ALREADY_SUBSCRIBED: 'UNAVAILABLE_ALREADY_SUBSCRIBED',
    UNAVAILABLE_HAS_AUTHORITY: 'UNAVAILABLE_HAS_AUTHORITY',
    UNAVAILABLE_HAS_FULFILLMENT: 'UNAVAILABLE_HAS_FULFILLMENT'
};
export function StripeCheckoutAvailabilityFromJSON(json) {
    return StripeCheckoutAvailabilityFromJSONTyped(json, false);
}
export function StripeCheckoutAvailabilityFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function StripeCheckoutAvailabilityToJSON(value) {
    return value;
}
