/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreateStripeCustomerPortalResponseFromJSON, ListStripeSubscriptionsResponseFromJSON, StripeSubscriptionFromJSON, } from '../models';
/**
 *
 */
export class StripeSubscriptionV1Api extends runtime.BaseAPI {
    /**
     * 현재 유저에 대한 customer portal url을 요청합니다.
     */
    async createStripeCustomerPortalRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/commerce/v1/stripe/subscription/customer-portal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateStripeCustomerPortalResponseFromJSON(jsonValue));
    }
    /**
     * 현재 유저에 대한 customer portal url을 요청합니다.
     */
    async createStripeCustomerPortal(requestParameters, initOverrides) {
        const response = await this.createStripeCustomerPortalRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 구독을 조회합니다.
     */
    async getStripeSubscriptionRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getStripeSubscription.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/commerce/v1/stripe/subscription/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => StripeSubscriptionFromJSON(jsonValue));
    }
    /**
     * 구독을 조회합니다.
     */
    async getStripeSubscription(requestParameters, initOverrides) {
        const response = await this.getStripeSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 구독한 상품 리스트를 조회합니다.
     */
    async listStripeSubscriptionsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listStripeSubscriptions.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/commerce/v1/stripe/subscription/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListStripeSubscriptionsResponseFromJSON(jsonValue));
    }
    /**
     * 구독한 상품 리스트를 조회합니다.
     */
    async listStripeSubscriptions(requestParameters, initOverrides) {
        const response = await this.listStripeSubscriptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
