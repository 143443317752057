/**
 * Check if a given object implements the DiagnosisDisplayConfig interface.
 */
export function instanceOfDiagnosisDisplayConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "titleText" in value;
    isInstance = isInstance && "descriptionText" in value;
    return isInstance;
}
export function DiagnosisDisplayConfigFromJSON(json) {
    return DiagnosisDisplayConfigFromJSONTyped(json, false);
}
export function DiagnosisDisplayConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'titleText': json['titleText'],
        'descriptionText': json['descriptionText'],
    };
}
export function DiagnosisDisplayConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'titleText': value.titleText,
        'descriptionText': value.descriptionText,
    };
}
