export const num2name = {
    0: "SUBSCRIPTION_RENEWAL_INTERVAL_UNSPECIFIED",
    1: "SUBSCRIPTION_RENEWAL_INTERVAL_WEEK_1",
    2: "SUBSCRIPTION_RENEWAL_INTERVAL_MONTH_1",
    3: "SUBSCRIPTION_RENEWAL_INTERVAL_MONTH_3",
    4: "SUBSCRIPTION_RENEWAL_INTERVAL_MONTH_6",
    5: "SUBSCRIPTION_RENEWAL_INTERVAL_YEAR_1",
};
export const name2num = {
    SUBSCRIPTION_RENEWAL_INTERVAL_UNSPECIFIED: 0,
    SUBSCRIPTION_RENEWAL_INTERVAL_WEEK_1: 1,
    SUBSCRIPTION_RENEWAL_INTERVAL_MONTH_1: 2,
    SUBSCRIPTION_RENEWAL_INTERVAL_MONTH_3: 3,
    SUBSCRIPTION_RENEWAL_INTERVAL_MONTH_6: 4,
    SUBSCRIPTION_RENEWAL_INTERVAL_YEAR_1: 5,
};
