/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EnvFromJSON, EnvToJSON, } from './Env';
/**
 * Check if a given object implements the Hidden interface.
 */
export function instanceOfHidden(value) {
    let isInstance = true;
    isInstance = isInstance && "examineeId" in value;
    isInstance = isInstance && "env" in value;
    return isInstance;
}
export function HiddenFromJSON(json) {
    return HiddenFromJSONTyped(json, false);
}
export function HiddenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'examineeId': json['examinee_id'],
        'env': EnvFromJSON(json['env']),
    };
}
export function HiddenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'examinee_id': value.examineeId,
        'env': EnvToJSON(value.env),
    };
}
