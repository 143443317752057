// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./Domain";
import { name2num, num2name, } from "./PaymentType";
import { name2num as name2num_1, num2name as num2name_1, } from "./PaymentMethod";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../google/protobuf/Timestamp";
import { name2num as name2num_2, num2name as num2name_2, } from "./IamportPg";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        domain: undefined,
        paymentType: "UNKNOWN",
        method: "UNSET_PAYMENT_METHOD",
        iamportPgId: "",
        playStorePackageName: "",
        createdAt: undefined,
        playStoreProjectId: "",
        iamportPg: "IAMPORT_PG_UNSPECIFIED",
        iamportIdentificationCode: "",
        playStoreSubscriptionName: "",
        appStoreBundleId: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.domain !== undefined)
        result.domain = encodeJson_1(value.domain);
    if (value.paymentType !== undefined)
        result.paymentType = tsValueToJsonValueFns.enum(value.paymentType);
    if (value.method !== undefined)
        result.method = tsValueToJsonValueFns.enum(value.method);
    if (value.iamportPgId !== undefined)
        result.iamportPgId = tsValueToJsonValueFns.string(value.iamportPgId);
    if (value.playStorePackageName !== undefined)
        result.playStorePackageName = tsValueToJsonValueFns.string(value.playStorePackageName);
    if (value.createdAt !== undefined)
        result.createdAt = encodeJson_2(value.createdAt);
    if (value.playStoreProjectId !== undefined)
        result.playStoreProjectId = tsValueToJsonValueFns.string(value.playStoreProjectId);
    if (value.iamportPg !== undefined)
        result.iamportPg = tsValueToJsonValueFns.enum(value.iamportPg);
    if (value.iamportIdentificationCode !== undefined)
        result.iamportIdentificationCode = tsValueToJsonValueFns.string(value.iamportIdentificationCode);
    if (value.playStoreSubscriptionName !== undefined)
        result.playStoreSubscriptionName = tsValueToJsonValueFns.string(value.playStoreSubscriptionName);
    if (value.appStoreBundleId !== undefined)
        result.appStoreBundleId = tsValueToJsonValueFns.string(value.appStoreBundleId);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.domain !== undefined)
        result.domain = decodeJson_1(value.domain);
    if (value.paymentType !== undefined)
        result.paymentType = jsonValueToTsValueFns.enum(value.paymentType);
    if (value.method !== undefined)
        result.method = jsonValueToTsValueFns.enum(value.method);
    if (value.iamportPgId !== undefined)
        result.iamportPgId = jsonValueToTsValueFns.string(value.iamportPgId);
    if (value.playStorePackageName !== undefined)
        result.playStorePackageName = jsonValueToTsValueFns.string(value.playStorePackageName);
    if (value.createdAt !== undefined)
        result.createdAt = decodeJson_2(value.createdAt);
    if (value.playStoreProjectId !== undefined)
        result.playStoreProjectId = jsonValueToTsValueFns.string(value.playStoreProjectId);
    if (value.iamportPg !== undefined)
        result.iamportPg = jsonValueToTsValueFns.enum(value.iamportPg);
    if (value.iamportIdentificationCode !== undefined)
        result.iamportIdentificationCode = jsonValueToTsValueFns.string(value.iamportIdentificationCode);
    if (value.playStoreSubscriptionName !== undefined)
        result.playStoreSubscriptionName = jsonValueToTsValueFns.string(value.playStoreSubscriptionName);
    if (value.appStoreBundleId !== undefined)
        result.appStoreBundleId = jsonValueToTsValueFns.string(value.appStoreBundleId);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.domain !== undefined) {
        const tsValue = value.domain;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.paymentType !== undefined) {
        const tsValue = value.paymentType;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.method !== undefined) {
        const tsValue = value.method;
        result.push([5, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.iamportPgId !== undefined) {
        const tsValue = value.iamportPgId;
        result.push([7, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.playStorePackageName !== undefined) {
        const tsValue = value.playStorePackageName;
        result.push([8, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.createdAt !== undefined) {
        const tsValue = value.createdAt;
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.playStoreProjectId !== undefined) {
        const tsValue = value.playStoreProjectId;
        result.push([10, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.iamportPg !== undefined) {
        const tsValue = value.iamportPg;
        result.push([11, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
    }
    if (value.iamportIdentificationCode !== undefined) {
        const tsValue = value.iamportIdentificationCode;
        result.push([12, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.playStoreSubscriptionName !== undefined) {
        const tsValue = value.playStoreSubscriptionName;
        result.push([13, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.appStoreBundleId !== undefined) {
        const tsValue = value.appStoreBundleId;
        result.push([14, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.domain = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.paymentType = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.method = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.iamportPgId = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.playStorePackageName = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAt = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.playStoreProjectId = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_2[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.iamportPg = value;
    }
    field: {
        const wireValue = wireFields.get(12);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.iamportIdentificationCode = value;
    }
    field: {
        const wireValue = wireFields.get(13);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.playStoreSubscriptionName = value;
    }
    field: {
        const wireValue = wireFields.get(14);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.appStoreBundleId = value;
    }
    return result;
}
