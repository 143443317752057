/**
 * Check if a given object implements the CreateStripeCheckoutUrlRequest interface.
 */
export function instanceOfCreateStripeCheckoutUrlRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "successPath" in value;
    isInstance = isInstance && "cancelPath" in value;
    return isInstance;
}
export function CreateStripeCheckoutUrlRequestFromJSON(json) {
    return CreateStripeCheckoutUrlRequestFromJSONTyped(json, false);
}
export function CreateStripeCheckoutUrlRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'productId': json['productId'],
        'successPath': json['successPath'],
        'cancelPath': json['cancelPath'],
    };
}
export function CreateStripeCheckoutUrlRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'productId': value.productId,
        'successPath': value.successPath,
        'cancelPath': value.cancelPath,
    };
}
