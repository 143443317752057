import {useCallback} from 'react';
import TagManager from 'react-gtm-module';
import {useAtomValue} from 'jotai';

import {useMobileServices} from '@app/api/mobile-services';
import tokenManagerAtom from '@app/atoms/core/token-manager';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

// GA4 Event Spreadsheet
// https://docs.google.com/spreadsheets/d/188CIQE8ZjhnSEN_xGNX7icFe_TEPTQ8tUA9X0TNtEwQ/edit#gid=0

export const usePushEvent = () => {
  const {ToeicMobileService} = useMobileServices();
  const tokenManager = useAtomValue(tokenManagerAtom);

  const pushEvent = useCallback(
    async <T extends Record<string, unknown>>(eventName: string, payload?: T) => {
      if (isAppBridgeAvailable()) {
        // With the app bridge, send the GA4 event to native app.
        await ToeicMobileService.sendEventTracking({name: eventName, propertyJson: JSON.stringify(payload ?? {})});
      } else {
        const userId = (await tokenManager.getToken())?.authId;

        TagManager.dataLayer({
          dataLayer: {event: eventName, payload, userId},
        });
      }
    },
    [ToeicMobileService, tokenManager]
  );

  return pushEvent;
};

export const usePushScreenViewEvent = () => {
  const {ToeicMobileService} = useMobileServices();

  const pushScreenViewEvent = useCallback(
    async (screenName: string, payload: Record<string, unknown>) => {
      /**
       * NOTE: In PC web(=not webview), it does not need to send 'screen_view' event,
       * because 'page_view' event is triggered in PC web when history changes.
       * see: https://analytics.google.com/analytics/web/#/p152900420/reports/dashboard?params=_u..nav%3Dmaui%26_u..comparisons%3D%5B%7B%22name%22:%22All%20Users%22,%22filters%22:%5B%7B%22isCaseSensitive%22:true,%22expression%22:%220%22,%22fieldName%22:%22audience%22%7D%5D%7D%5D%26_r..dimension-value%3D%7B%22dimension%22:%22eventName%22,%22value%22:%22page_view%22%7D&r=events-overview-page-view&collectionId=life-cycle
       */
      if (isAppBridgeAvailable()) {
        await ToeicMobileService.sendEventTracking({
          name: 'screen_view',
          propertyJson: JSON.stringify({
            ...payload,
            screen_name: screenName,
            screen_class: screenName,
          }),
        });
      }
    },
    [ToeicMobileService]
  );

  return pushScreenViewEvent;
};
