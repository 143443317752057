/* tslint:disable */
/* eslint-disable */
import { mapValues } from '../runtime';
import { ElementStatisticsFromJSON, ElementStatisticsToJSON, } from './ElementStatistics';
/**
 * Check if a given object implements the ContentStatistics interface.
 */
export function instanceOfContentStatistics(value) {
    let isInstance = true;
    isInstance = isInstance && "elementStatisticsMap" in value;
    return isInstance;
}
export function ContentStatisticsFromJSON(json) {
    return ContentStatisticsFromJSONTyped(json, false);
}
export function ContentStatisticsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'elementStatisticsMap': (mapValues(json['elementStatisticsMap'], ElementStatisticsFromJSON)),
    };
}
export function ContentStatisticsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'elementStatisticsMap': (mapValues(value.elementStatisticsMap, ElementStatisticsToJSON)),
    };
}
