import { SkillAnalyticsFromJSON, SkillAnalyticsToJSON, } from './SkillAnalytics';
/**
 * Check if a given object implements the GetSkillAnalyticsResponse interface.
 */
export function instanceOfGetSkillAnalyticsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "skillAnalytics" in value;
    return isInstance;
}
export function GetSkillAnalyticsResponseFromJSON(json) {
    return GetSkillAnalyticsResponseFromJSONTyped(json, false);
}
export function GetSkillAnalyticsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'skillAnalytics': SkillAnalyticsFromJSON(json['skillAnalytics']),
    };
}
export function GetSkillAnalyticsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'skillAnalytics': SkillAnalyticsToJSON(value.skillAnalytics),
    };
}
