export var config = {
    production: {
        server: "https://toeic-speaking-api.prod.riiid.cloud"
    },
    staging: {
        server: "https://toeic-speaking-api.stg.riiid.cloud"
    },
    development: {
        server: "https://toeic-speaking-api.dev.riiid.cloud"
    }
};
