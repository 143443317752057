import React from 'react';
import {css, Global} from '@emotion/react';

import {ButtonColorVariant, ButtonVariant, useTheme} from '@santa-web/service-ui';

// Default value is hardcoded to follow Button component's default value
// So if the Button component's default value changed, this component's default value should be changed too
interface StyleType {
  color?: string;
  buttonVariant?: {
    colorVariant?: ButtonColorVariant;
    variant?: ButtonVariant;
    isDisabled?: boolean;
  };
}

interface Props {
  top?: StyleType;
  right?: StyleType;
  bottom?: StyleType;
  left?: StyleType;
}

const MobileSafeAreaColor = ({top, right, bottom, left}: Props) => {
  const buttonColors = useTheme().button.colorVariants;
  const [topColor, rightColor, bottomColor, leftColor] = React.useMemo(() => {
    const directions = [top, right, bottom, left];
    return directions.map(direction => {
      if (direction?.color) {
        return direction.color;
      }
      if (direction?.buttonVariant) {
        const {disabled, root} =
          buttonColors[direction.buttonVariant.variant || 'solid'][direction.buttonVariant.colorVariant || 'black'];
        return direction.buttonVariant.isDisabled ? (disabled?.background as string) : (root?.background as string);
      }
    });
  }, [top, right, bottom, left, buttonColors]);

  return (
    <Global
      styles={css`
        .mobile-safe-area {
          ${topColor ? `border-top-color: ${topColor} !important` : ''};
          ${rightColor ? `border-right-color: ${rightColor} !important` : ''};
          ${bottomColor ? `border-bottom-color: ${bottomColor} !important` : ''};
          ${leftColor ? `border-left-color: ${leftColor} !important` : ''};
        }
      `}
    />
  );
};

export default React.memo(MobileSafeAreaColor);
