// @ts-nocheck
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../UInt32List";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../Snippet";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        choices: [],
        choiceTranslations: [],
        answers: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    result.choices = value.choices.map(value => encodeJson_1(value));
    result.choiceTranslations = value.choiceTranslations.map(value => encodeJson_1(value));
    switch ((_a = value.answers) === null || _a === void 0 ? void 0 : _a.field) {
        case "orAnswers": {
            result.orAnswers = encodeJson_2(value.answers.value);
            break;
        }
        case "andAnswers": {
            result.andAnswers = encodeJson_2(value.answers.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    result.choices = (_b = (_a = value.choices) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    result.choiceTranslations = (_d = (_c = value.choiceTranslations) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_1(value))) !== null && _d !== void 0 ? _d : [];
    if (value.orAnswers !== undefined)
        result.answers = { field: "orAnswers", value: decodeJson_2(value.orAnswers) };
    if (value.andAnswers !== undefined)
        result.answers = { field: "andAnswers", value: decodeJson_2(value.andAnswers) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    for (const tsValue of value.choices) {
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.choiceTranslations) {
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    switch ((_a = value.answers) === null || _a === void 0 ? void 0 : _a.field) {
        case "orAnswers": {
            const tsValue = value.answers.value;
            result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
        case "andAnswers": {
            const tsValue = value.answers.value;
            result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    answers: new Set([2, 3]),
};
const oneofFieldNamesMap = {
    answers: new Map([
        [2, "orAnswers"],
        [3, "andAnswers"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.choices = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.choiceTranslations = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.answers;
        const oneofFieldNames = oneofFieldNamesMap.answers;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [2](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
            [3](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.answers = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
