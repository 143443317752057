/**
 * Check if a given object implements the LearningCellReportCorrectnessInfo interface.
 */
export function instanceOfLearningCellReportCorrectnessInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "correctCount" in value;
    isInstance = isInstance && "totalCount" in value;
    return isInstance;
}
export function LearningCellReportCorrectnessInfoFromJSON(json) {
    return LearningCellReportCorrectnessInfoFromJSONTyped(json, false);
}
export function LearningCellReportCorrectnessInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'correctCount': json['correctCount'],
        'totalCount': json['totalCount'],
    };
}
export function LearningCellReportCorrectnessInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'correctCount': value.correctCount,
        'totalCount': value.totalCount,
    };
}
