import config from '@app/config';

// https://developers.facebook.com/docs/meta-pixel/reference
type StandardEventType =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'CompleteRegistration'
  | 'Contact'
  | 'CustomizeProduct'
  | 'Donate'
  | 'FindLocation'
  | 'InitiateCheckout'
  | 'Lead'
  | 'Purchase'
  | 'Schedule'
  | 'Search'
  | 'StartTrial'
  | 'SubmitApplication'
  | 'Subscribe'
  | 'ViewContent';

interface StandardEventProps {
  content_category?: string;
  content_ids?: (number | string)[];
  content_name?: string;
  content_type?: string;
  contents?: Record<string, unknown>[];
  currency?: string;
  num_items?: number;
  predicted_ltv?: number;
  search_string?: string;
  status?: boolean;
  value?: number;
}

export interface WindowWithFbq extends Window {
  fbq?: (variant: string, eventName: string, props?: any) => Promise<void>;
}

const windowWithFbq: WindowWithFbq | undefined = typeof window !== 'undefined' ? window : undefined;

class MetaPixel {
  private static isMetaPixelActive(): boolean {
    return windowWithFbq?.fbq != null;
  }

  private static async fbq(variant: string, eventName: string, props?: any): Promise<void> {
    if (!this.isMetaPixelActive()) {
      await this.initialize();
    }
    await windowWithFbq?.fbq?.(variant, eventName, props);
  }

  static async initialize() {
    const id = config.metaPixelId;
    await new Promise((resolve, reject) => {
      if (!document) {
        reject(new Error('Document was not defined'));
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      // https://developers.facebook.com/docs/meta-pixel/get-started
      script.innerHTML = `!(function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", ${id});
      fbq("track", "PageView");`;
      script.async = true;
      script.onload = () => {
        resolve(script);
      };
      script.onerror = msg => {
        reject(new Error(`Error in loading script: ${msg}`));
      };
      script.onabort = msg => {
        reject(new Error(`Script loading aborted: ${msg}`));
      };
      document.head.appendChild(script);
    });
  }

  private static async trackStandardEvent(eventName: StandardEventType, props?: StandardEventProps): Promise<void> {
    await this.fbq('track', eventName, props);
  }

  // 아래 표준 이벤트 함수들은 on demand로 추가

  static async trackAddToCardEvent(
    props?: Pick<
      StandardEventProps,
      'content_ids' | 'content_name' | 'content_type' | 'contents' | 'currency' | 'value'
    >
  ) {
    await this.trackStandardEvent('AddToCart', props);
  }

  static async trackCompleteRegistrationEvent(
    props?: Pick<StandardEventProps, 'content_name' | 'currency' | 'status' | 'value'>
  ) {
    await this.trackStandardEvent('CompleteRegistration', props);
  }

  static async trackInitiateCheckoutEvent(
    props?: Pick<
      StandardEventProps,
      'content_category' | 'content_ids' | 'contents' | 'currency' | 'num_items' | 'value'
    >
  ) {
    await this.trackStandardEvent('InitiateCheckout', props);
  }

  static async trackPurchaseEvent(
    props?: Pick<
      StandardEventProps,
      'content_ids' | 'content_name' | 'content_type' | 'contents' | 'currency' | 'num_items' | 'value'
    >
  ) {
    await this.trackStandardEvent('Purchase', props);
  }

  static async trackViewContentEvent(
    props?: Pick<
      StandardEventProps,
      'content_ids' | 'content_category' | 'content_name' | 'content_type' | 'contents' | 'currency' | 'value'
    >
  ) {
    await this.trackStandardEvent('ViewContent', props);
  }
}

export default MetaPixel;
