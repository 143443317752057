/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { UserAgreementItemFromJSON, UserAgreementItemToJSON, } from './UserAgreementItem';
/**
 * Check if a given object implements the UserAgreement interface.
 */
export function instanceOfUserAgreement(value) {
    let isInstance = true;
    return isInstance;
}
export function UserAgreementFromJSON(json) {
    return UserAgreementFromJSONTyped(json, false);
}
export function UserAgreementFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'marketing': !exists(json, 'marketing') ? undefined : UserAgreementItemFromJSON(json['marketing']),
        'push': !exists(json, 'push') ? undefined : UserAgreementItemFromJSON(json['push']),
    };
}
export function UserAgreementToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'marketing': UserAgreementItemToJSON(value.marketing),
        'push': UserAgreementItemToJSON(value.push),
    };
}
