/* tslint:disable */
/* eslint-disable */
/**
 * 정답 여부 혹은 "모름" 선택 여부
 *
 * - **CORRECT**: 사용자의 답안이 기재되어 있고, 해당 답안이 정답임.
 * - **INCORRECT**: 사용자의 답안이 기재되어 있지 않거나, 기재되어 있으나 정답이 아니고 "모름"을 선택하지 않음.
 * - **DOES_NOT_KNOW**: [Objective] 문제에 대해 사용자의 답안이 "모름"으로 선택되어 있음.
 * @export
 */
export const QuestionStateAnswerState = {
    CORRECT: 'CORRECT',
    INCORRECT: 'INCORRECT',
    DOES_NOT_KNOW: 'DOES_NOT_KNOW'
};
export function QuestionStateAnswerStateFromJSON(json) {
    return QuestionStateAnswerStateFromJSONTyped(json, false);
}
export function QuestionStateAnswerStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function QuestionStateAnswerStateToJSON(value) {
    return value;
}
