/**
 * Check if a given object implements the CorrectnessStatistic interface.
 */
export function instanceOfCorrectnessStatistic(value) {
    let isInstance = true;
    isInstance = isInstance && "minScore" in value;
    isInstance = isInstance && "correctCount" in value;
    isInstance = isInstance && "incorrectCount" in value;
    return isInstance;
}
export function CorrectnessStatisticFromJSON(json) {
    return CorrectnessStatisticFromJSONTyped(json, false);
}
export function CorrectnessStatisticFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'minScore': json['minScore'],
        'correctCount': json['correctCount'],
        'incorrectCount': json['incorrectCount'],
    };
}
export function CorrectnessStatisticToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'minScore': value.minScore,
        'correctCount': value.correctCount,
        'incorrectCount': value.incorrectCount,
    };
}
