import { EstimatedScoreFromJSON, EstimatedScoreToJSON, } from './EstimatedScore';
import { LearningDomainKeyFromJSON, LearningDomainKeyToJSON, } from './LearningDomainKey';
import { OneOfSkillAnalyticsReportFromJSON, OneOfSkillAnalyticsReportToJSON, } from './OneOfSkillAnalyticsReport';
/**
 * Check if a given object implements the SkillAnalytics interface.
 */
export function instanceOfSkillAnalytics(value) {
    let isInstance = true;
    isInstance = isInstance && "reports" in value;
    isInstance = isInstance && "estimatedScore" in value;
    isInstance = isInstance && "learningDomainKey" in value;
    return isInstance;
}
export function SkillAnalyticsFromJSON(json) {
    return SkillAnalyticsFromJSONTyped(json, false);
}
export function SkillAnalyticsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'reports': (json['reports'].map(OneOfSkillAnalyticsReportFromJSON)),
        'estimatedScore': EstimatedScoreFromJSON(json['estimatedScore']),
        'learningDomainKey': LearningDomainKeyFromJSON(json['learningDomainKey']),
    };
}
export function SkillAnalyticsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'reports': (value.reports.map(OneOfSkillAnalyticsReportToJSON)),
        'estimatedScore': EstimatedScoreToJSON(value.estimatedScore),
        'learningDomainKey': LearningDomainKeyToJSON(value.learningDomainKey),
    };
}
