export const num2name = {
    0: "CREATE",
    1: "UPDATE",
    2: "DELETE",
};
export const name2num = {
    CREATE: 0,
    UPDATE: 1,
    DELETE: 2,
};
