// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../google/protobuf/Timestamp";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../ToeicResourceDesignAsset";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../ToeicResourceImageSet";
import { name2num, num2name, } from "./(DisplayConfig)/ThumbnailContentImageAlignment";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./(DisplayConfig)/TimerConfig";
import { name2num as name2num_1, num2name as num2name_1, } from "./(DisplayConfig)/PriceExpressionMethod";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as Long, } from "../../../../runtime/Long";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        name: "",
        description: "",
        displayStart: undefined,
        displayEnd: undefined,
        saleStart: undefined,
        saleEnd: undefined,
        detailPage: [],
        isTextBlack: false,
        orderPageTopImage: undefined,
        calculatePricePerDurationDays: "0",
        thumbnailBackgroundImage: undefined,
        thumbnailContentImage: undefined,
        thumbnailContentImageAlignment: "THUMBNAIL_CONTENT_IMAGE_ALIGNMENT_UNSPECIFIED",
        timerConfig: undefined,
        priceExpressionMethod: "PRICE_EXPRESSION_METHOD_UNSPECIFIED",
        policyLink: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.name !== undefined)
        result.name = tsValueToJsonValueFns.string(value.name);
    if (value.description !== undefined)
        result.description = tsValueToJsonValueFns.string(value.description);
    if (value.displayStart !== undefined)
        result.displayStart = encodeJson_1(value.displayStart);
    if (value.displayEnd !== undefined)
        result.displayEnd = encodeJson_1(value.displayEnd);
    if (value.saleStart !== undefined)
        result.saleStart = encodeJson_1(value.saleStart);
    if (value.saleEnd !== undefined)
        result.saleEnd = encodeJson_1(value.saleEnd);
    result.detailPage = value.detailPage.map(value => encodeJson_2(value));
    if (value.isTextBlack !== undefined)
        result.isTextBlack = tsValueToJsonValueFns.bool(value.isTextBlack);
    if (value.orderPageTopImage !== undefined)
        result.orderPageTopImage = encodeJson_3(value.orderPageTopImage);
    if (value.calculatePricePerDurationDays !== undefined)
        result.calculatePricePerDurationDays = tsValueToJsonValueFns.int64(value.calculatePricePerDurationDays);
    if (value.thumbnailBackgroundImage !== undefined)
        result.thumbnailBackgroundImage = encodeJson_3(value.thumbnailBackgroundImage);
    if (value.thumbnailContentImage !== undefined)
        result.thumbnailContentImage = encodeJson_3(value.thumbnailContentImage);
    if (value.thumbnailContentImageAlignment !== undefined)
        result.thumbnailContentImageAlignment = tsValueToJsonValueFns.enum(value.thumbnailContentImageAlignment);
    if (value.timerConfig !== undefined)
        result.timerConfig = encodeJson_4(value.timerConfig);
    if (value.priceExpressionMethod !== undefined)
        result.priceExpressionMethod = tsValueToJsonValueFns.enum(value.priceExpressionMethod);
    if (value.policyLink !== undefined)
        result.policyLink = tsValueToJsonValueFns.string(value.policyLink);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.name !== undefined)
        result.name = jsonValueToTsValueFns.string(value.name);
    if (value.description !== undefined)
        result.description = jsonValueToTsValueFns.string(value.description);
    if (value.displayStart !== undefined)
        result.displayStart = decodeJson_1(value.displayStart);
    if (value.displayEnd !== undefined)
        result.displayEnd = decodeJson_1(value.displayEnd);
    if (value.saleStart !== undefined)
        result.saleStart = decodeJson_1(value.saleStart);
    if (value.saleEnd !== undefined)
        result.saleEnd = decodeJson_1(value.saleEnd);
    result.detailPage = (_b = (_a = value.detailPage) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_2(value))) !== null && _b !== void 0 ? _b : [];
    if (value.isTextBlack !== undefined)
        result.isTextBlack = jsonValueToTsValueFns.bool(value.isTextBlack);
    if (value.orderPageTopImage !== undefined)
        result.orderPageTopImage = decodeJson_3(value.orderPageTopImage);
    if (value.calculatePricePerDurationDays !== undefined)
        result.calculatePricePerDurationDays = jsonValueToTsValueFns.int64(value.calculatePricePerDurationDays);
    if (value.thumbnailBackgroundImage !== undefined)
        result.thumbnailBackgroundImage = decodeJson_3(value.thumbnailBackgroundImage);
    if (value.thumbnailContentImage !== undefined)
        result.thumbnailContentImage = decodeJson_3(value.thumbnailContentImage);
    if (value.thumbnailContentImageAlignment !== undefined)
        result.thumbnailContentImageAlignment = jsonValueToTsValueFns.enum(value.thumbnailContentImageAlignment);
    if (value.timerConfig !== undefined)
        result.timerConfig = decodeJson_4(value.timerConfig);
    if (value.priceExpressionMethod !== undefined)
        result.priceExpressionMethod = jsonValueToTsValueFns.enum(value.priceExpressionMethod);
    if (value.policyLink !== undefined)
        result.policyLink = jsonValueToTsValueFns.string(value.policyLink);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.name !== undefined) {
        const tsValue = value.name;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.description !== undefined) {
        const tsValue = value.description;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.displayStart !== undefined) {
        const tsValue = value.displayStart;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.displayEnd !== undefined) {
        const tsValue = value.displayEnd;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.saleStart !== undefined) {
        const tsValue = value.saleStart;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.saleEnd !== undefined) {
        const tsValue = value.saleEnd;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.detailPage) {
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.isTextBlack !== undefined) {
        const tsValue = value.isTextBlack;
        result.push([10, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.orderPageTopImage !== undefined) {
        const tsValue = value.orderPageTopImage;
        result.push([11, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.calculatePricePerDurationDays !== undefined) {
        const tsValue = value.calculatePricePerDurationDays;
        result.push([12, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.thumbnailBackgroundImage !== undefined) {
        const tsValue = value.thumbnailBackgroundImage;
        result.push([13, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.thumbnailContentImage !== undefined) {
        const tsValue = value.thumbnailContentImage;
        result.push([14, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.thumbnailContentImageAlignment !== undefined) {
        const tsValue = value.thumbnailContentImageAlignment;
        result.push([15, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.timerConfig !== undefined) {
        const tsValue = value.timerConfig;
        result.push([16, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    if (value.priceExpressionMethod !== undefined) {
        const tsValue = value.priceExpressionMethod;
        result.push([17, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.policyLink !== undefined) {
        const tsValue = value.policyLink;
        result.push([18, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.name = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.description = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.displayStart = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.displayEnd = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.saleStart = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.saleEnd = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 9).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.detailPage = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isTextBlack = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.orderPageTopImage = value;
    }
    field: {
        const wireValue = wireFields.get(12);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.calculatePricePerDurationDays = value;
    }
    field: {
        const wireValue = wireFields.get(13);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.thumbnailBackgroundImage = value;
    }
    field: {
        const wireValue = wireFields.get(14);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.thumbnailContentImage = value;
    }
    field: {
        const wireValue = wireFields.get(15);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.thumbnailContentImageAlignment = value;
    }
    field: {
        const wireValue = wireFields.get(16);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.timerConfig = value;
    }
    field: {
        const wireValue = wireFields.get(17);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.priceExpressionMethod = value;
    }
    field: {
        const wireValue = wireFields.get(18);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.policyLink = value;
    }
    return result;
}
