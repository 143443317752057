/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetMyReferralInfoResponseFromJSON, } from '../models';
/**
 *
 */
export class ReferralV1Api extends runtime.BaseAPI {
    /**
     * 사용자의 추천 코드와 추천한 사용자 수를 가져옵니다.
     */
    async getMyReferralInfoRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/user/v1/referral/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetMyReferralInfoResponseFromJSON(jsonValue));
    }
    /**
     * 사용자의 추천 코드와 추천한 사용자 수를 가져옵니다.
     */
    async getMyReferralInfo(requestParameters, initOverrides) {
        const response = await this.getMyReferralInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
