export const num2name = {
    0: "STATUS_UNSPECIFIED",
    1: "STATUS_REGISTERED",
    2: "STATUS_USED",
    3: "STATUS_EXPIRED",
};
export const name2num = {
    STATUS_UNSPECIFIED: 0,
    STATUS_REGISTERED: 1,
    STATUS_USED: 2,
    STATUS_EXPIRED: 3,
};
