/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const LearningDomainKey = {
    TOEIC: 'TOEIC',
    G_TELP: 'G_TELP'
};
export function LearningDomainKeyFromJSON(json) {
    return LearningDomainKeyFromJSONTyped(json, false);
}
export function LearningDomainKeyFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LearningDomainKeyToJSON(value) {
    return value;
}
