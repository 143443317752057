// @ts-nocheck
import { name2num, num2name, } from "./(VocaPoS)/PoS";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./VocaExample";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./VoiceResourceIdsByAccent";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { default as Long, } from "../../runtime/Long";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        pos: "NOUN",
        meaning: "",
        posImageResourceIds: [],
        examples: [],
        voiceResourceIdsByAccent: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.pos !== undefined)
        result.pos = tsValueToJsonValueFns.enum(value.pos);
    if (value.meaning !== undefined)
        result.meaning = tsValueToJsonValueFns.string(value.meaning);
    result.posImageResourceIds = value.posImageResourceIds.map(value => tsValueToJsonValueFns.string(value));
    result.examples = value.examples.map(value => encodeJson_1(value));
    if (value.voiceResourceIdsByAccent !== undefined)
        result.voiceResourceIdsByAccent = encodeJson_2(value.voiceResourceIdsByAccent);
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    if (value.pos !== undefined)
        result.pos = jsonValueToTsValueFns.enum(value.pos);
    if (value.meaning !== undefined)
        result.meaning = jsonValueToTsValueFns.string(value.meaning);
    result.posImageResourceIds = (_b = (_a = value.posImageResourceIds) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.string(value))) !== null && _b !== void 0 ? _b : [];
    result.examples = (_d = (_c = value.examples) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_1(value))) !== null && _d !== void 0 ? _d : [];
    if (value.voiceResourceIdsByAccent !== undefined)
        result.voiceResourceIdsByAccent = decodeJson_2(value.voiceResourceIdsByAccent);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.pos !== undefined) {
        const tsValue = value.pos;
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.meaning !== undefined) {
        const tsValue = value.meaning;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.posImageResourceIds) {
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.examples) {
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.voiceResourceIdsByAccent !== undefined) {
        const tsValue = value.voiceResourceIdsByAccent;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.pos = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.meaning = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 3).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValueToTsValueFns.string(wireValue)).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.posImageResourceIds = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.examples = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.voiceResourceIdsByAccent = value;
    }
    return result;
}
