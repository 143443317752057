/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CompleteAllContentInteractionStatesResponseFromJSON, ContentInteractionStateWithContentFromJSON, CreateContentInteractionStatesResponseFromJSON, ListContentInteractionStatesResponseFromJSON, SummarizeContentInteractionStatesResponseFromJSON, UpdateContentInteractionStateRequestToJSON, } from '../models';
/**
 *
 */
export class ContentInteractionStateV1Api extends runtime.BaseAPI {
    /**
     * 대상 `LearningCell`의 완료 처리되지 않은 `ContentInteractionState`를 모두 완료 처리한다.
     */
    async completeAllContentInteractionStatesRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling completeAllContentInteractionStates.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/learning-cells/{learningCellId}/content-interaction-states/complete`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CompleteAllContentInteractionStatesResponseFromJSON(jsonValue));
    }
    /**
     * 대상 `LearningCell`의 완료 처리되지 않은 `ContentInteractionState`를 모두 완료 처리한다.
     */
    async completeAllContentInteractionStates(requestParameters, initOverrides) {
        const response = await this.completeAllContentInteractionStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주어진 ID에 대한 `ContentInteractionState`를 완료 처리한다.\\ 시작 처리가 되면 `ContentInteractionState.completedAtServer`이 서버 시각 기준으로 매겨진다.\\ `ContentInteractionState`가 완료처리 되면 `updateContentInteractionState` 요청으로 수정이 불가능해진다.
     */
    async completeContentInteractionStateRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling completeContentInteractionState.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/content-interaction-states/{contentInteractionStateId}/complete`.replace(`{${"contentInteractionStateId"}}`, encodeURIComponent(String(requestParameters.contentInteractionStateId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentInteractionStateWithContentFromJSON(jsonValue));
    }
    /**
     * 주어진 ID에 대한 `ContentInteractionState`를 완료 처리한다.\\ 시작 처리가 되면 `ContentInteractionState.completedAtServer`이 서버 시각 기준으로 매겨진다.\\ `ContentInteractionState`가 완료처리 되면 `updateContentInteractionState` 요청으로 수정이 불가능해진다.
     */
    async completeContentInteractionState(requestParameters, initOverrides) {
        const response = await this.completeContentInteractionStateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주어진 ID에 대한 `LearningCell`에 새로운 `ContentInteractionState`들을 생성해 배정한다.
     */
    async createContentInteractionStatesRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling createContentInteractionStates.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/learning-cells/{learningCellId}/content-interaction-states`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateContentInteractionStatesResponseFromJSON(jsonValue));
    }
    /**
     * 주어진 ID에 대한 `LearningCell`에 새로운 `ContentInteractionState`들을 생성해 배정한다.
     */
    async createContentInteractionStates(requestParameters, initOverrides) {
        const response = await this.createContentInteractionStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주어진 ID에 대한 `ContentInteractionState`를 조회한다.
     */
    async getContentInteractionStateRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling getContentInteractionState.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/content-interaction-states/{contentInteractionStateId}`.replace(`{${"contentInteractionStateId"}}`, encodeURIComponent(String(requestParameters.contentInteractionStateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentInteractionStateWithContentFromJSON(jsonValue));
    }
    /**
     * 주어진 ID에 대한 `ContentInteractionState`를 조회한다.
     */
    async getContentInteractionState(requestParameters, initOverrides) {
        const response = await this.getContentInteractionStateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 대상 `LearningCell`의 `ContentInteractionState`들을 조회한다.\\ 응답으로 오는 `ContentInteractionState`는 ID의 오름차순으로 정렬된다.
     */
    async listContentInteractionStatesRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling listContentInteractionStates.');
        }
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listContentInteractionStates.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.isCompletedCondition !== undefined) {
            queryParameters['isCompletedCondition'] = requestParameters.isCompletedCondition;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/learning-cells/{learningCellId}/content-interaction-states`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListContentInteractionStatesResponseFromJSON(jsonValue));
    }
    /**
     * 대상 `LearningCell`의 `ContentInteractionState`들을 조회한다.\\ 응답으로 오는 `ContentInteractionState`는 ID의 오름차순으로 정렬된다.
     */
    async listContentInteractionStates(requestParameters, initOverrides) {
        const response = await this.listContentInteractionStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주어진 ID에 대한 `ContentInteractionState`를 시작 처리한다.\\ 시작 처리가 되면 `ContentInteractionState.startedAtServer`이 서버 시각 기준으로 매겨진다.\\ `ContentInteractionState`가 시작처리 되어야만 `updateContentInteractionState` 요청으로 수정이 가능하다.
     */
    async startContentInteractionStateRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling startContentInteractionState.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/content-interaction-states/{contentInteractionStateId}/start`.replace(`{${"contentInteractionStateId"}}`, encodeURIComponent(String(requestParameters.contentInteractionStateId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentInteractionStateWithContentFromJSON(jsonValue));
    }
    /**
     * 주어진 ID에 대한 `ContentInteractionState`를 시작 처리한다.\\ 시작 처리가 되면 `ContentInteractionState.startedAtServer`이 서버 시각 기준으로 매겨진다.\\ `ContentInteractionState`가 시작처리 되어야만 `updateContentInteractionState` 요청으로 수정이 가능하다.
     */
    async startContentInteractionState(requestParameters, initOverrides) {
        const response = await this.startContentInteractionStateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 대상 `LearningCell`의 `ContentInteractionState`에 대한 요약 정보를 조회
     */
    async summarizeContentInteractionStatesRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling summarizeContentInteractionStates.');
        }
        const queryParameters = {};
        if (requestParameters.isCompletedCondition !== undefined) {
            queryParameters['isCompletedCondition'] = requestParameters.isCompletedCondition;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/learning-cells/{learningCellId}/content-interaction-states/summarize`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SummarizeContentInteractionStatesResponseFromJSON(jsonValue));
    }
    /**
     * 대상 `LearningCell`의 `ContentInteractionState`에 대한 요약 정보를 조회
     */
    async summarizeContentInteractionStates(requestParameters, initOverrides) {
        const response = await this.summarizeContentInteractionStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 요청 payload의 `contentInteractionState`를 `contentInteractionStateId`가 가리키는 `ContentInteractionState`에 덮어쓴다.\\ 단, `@UpdatableProperties` 어노테이션이 붙지 않은 속성은 수정할 수 없다.\\ `timestampClient`는 서버에서 `ContentInteractionState`를 수정할 때 같이 DB에 저장되며,\\ 이는 이후에 서버에 도착하는 요청이 이미 서버에 도착하여 처리된 요청보다 이전 시점에 클라이언트에서 보냈는지 판별할 때 사용된다.\\ \\ 또한 다음과 같은 경우에는 `updateContentInteractionState` 요청에 의한 수정이 이루어지지 않는다. 1. 요청의 `timestampClient`가 서버에 기록된 `timestampClient`보다 이전 시점인 경우 2. 대상 `ContentInteractionState`가 이미 완료 처리된 경우, `isBookmarked` 이외의 값은 수정되지 않는다.
     */
    async updateContentInteractionStateRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling updateContentInteractionState.');
        }
        if (requestParameters.updateContentInteractionStateRequest === null || requestParameters.updateContentInteractionStateRequest === undefined) {
            throw new runtime.RequiredError('updateContentInteractionStateRequest', 'Required parameter requestParameters.updateContentInteractionStateRequest was null or undefined when calling updateContentInteractionState.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/content-learning/v1/content-interaction-states/{contentInteractionStateId}`.replace(`{${"contentInteractionStateId"}}`, encodeURIComponent(String(requestParameters.contentInteractionStateId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateContentInteractionStateRequestToJSON(requestParameters.updateContentInteractionStateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentInteractionStateWithContentFromJSON(jsonValue));
    }
    /**
     * 요청 payload의 `contentInteractionState`를 `contentInteractionStateId`가 가리키는 `ContentInteractionState`에 덮어쓴다.\\ 단, `@UpdatableProperties` 어노테이션이 붙지 않은 속성은 수정할 수 없다.\\ `timestampClient`는 서버에서 `ContentInteractionState`를 수정할 때 같이 DB에 저장되며,\\ 이는 이후에 서버에 도착하는 요청이 이미 서버에 도착하여 처리된 요청보다 이전 시점에 클라이언트에서 보냈는지 판별할 때 사용된다.\\ \\ 또한 다음과 같은 경우에는 `updateContentInteractionState` 요청에 의한 수정이 이루어지지 않는다. 1. 요청의 `timestampClient`가 서버에 기록된 `timestampClient`보다 이전 시점인 경우 2. 대상 `ContentInteractionState`가 이미 완료 처리된 경우, `isBookmarked` 이외의 값은 수정되지 않는다.
     */
    async updateContentInteractionState(requestParameters, initOverrides) {
        const response = await this.updateContentInteractionStateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 대상 `ContentInteractionState`의 `isBookmarked` 속성을 요청한 값으로 수정한다. 학습 기록 화면에서 북마크 여부를 수정하기 위한 API이다.
     * `ContentInteractionState`의 `isBookmarked` 속성을 수정한다.
     */
    async updateContentInteractionStateIsBookmarkedRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling updateContentInteractionStateIsBookmarked.');
        }
        if (requestParameters.newValue === null || requestParameters.newValue === undefined) {
            throw new runtime.RequiredError('newValue', 'Required parameter requestParameters.newValue was null or undefined when calling updateContentInteractionStateIsBookmarked.');
        }
        const queryParameters = {};
        if (requestParameters.newValue !== undefined) {
            queryParameters['newValue'] = requestParameters.newValue;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/content-interaction-states/{contentInteractionStateId}/is-bookmarked`.replace(`{${"contentInteractionStateId"}}`, encodeURIComponent(String(requestParameters.contentInteractionStateId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentInteractionStateWithContentFromJSON(jsonValue));
    }
    /**
     * 대상 `ContentInteractionState`의 `isBookmarked` 속성을 요청한 값으로 수정한다. 학습 기록 화면에서 북마크 여부를 수정하기 위한 API이다.
     * `ContentInteractionState`의 `isBookmarked` 속성을 수정한다.
     */
    async updateContentInteractionStateIsBookmarked(requestParameters, initOverrides) {
        const response = await this.updateContentInteractionStateIsBookmarkedRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
