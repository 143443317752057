const serviceMethodTable = {};
export function getServiceMethod(methodName) {
    return serviceMethodTable[methodName];
}
export function registerServiceMethodImpl(methodDescriptors, methodImplMap) {
    Object.entries(methodDescriptors).forEach(([methodName, methodDescriptor]) => {
        const methodImpl = methodImplMap[methodName];
        if (methodImpl) {
            const { service, methodName, requestStream, responseStream, requestType, responseType } = methodDescriptor;
            if (requestStream || responseStream)
                throw new Error('Stream request/response is not supported');
            serviceMethodTable[`${service.serviceName}.${methodName}`] = async (encodedReq) => {
                const decodedReq = requestType.deserializeBinary(Buffer.from(encodedReq, 'base64'));
                const decodedRes = await methodImpl(decodedReq);
                return Buffer.from(responseType.serializeBinary(decodedRes)).toString('base64');
            };
        }
    });
}
