import { ContentInteractionStateWithContentFromJSON, ContentInteractionStateWithContentToJSON, } from './ContentInteractionStateWithContent';
/**
 * Check if a given object implements the CompleteAllContentInteractionStatesResponse interface.
 */
export function instanceOfCompleteAllContentInteractionStatesResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "updated" in value;
    return isInstance;
}
export function CompleteAllContentInteractionStatesResponseFromJSON(json) {
    return CompleteAllContentInteractionStatesResponseFromJSONTyped(json, false);
}
export function CompleteAllContentInteractionStatesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'updated': (json['updated'].map(ContentInteractionStateWithContentFromJSON)),
    };
}
export function CompleteAllContentInteractionStatesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'updated': (value.updated.map(ContentInteractionStateWithContentToJSON)),
    };
}
