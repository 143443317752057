import { AppleRegistrationInfoFromJSON, AppleRegistrationInfoToJSON, } from './AppleRegistrationInfo';
/**
 * Check if a given object implements the CheckUserExistsWithAppleRequest interface.
 */
export function instanceOfCheckUserExistsWithAppleRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "registrationInfo" in value;
    return isInstance;
}
export function CheckUserExistsWithAppleRequestFromJSON(json) {
    return CheckUserExistsWithAppleRequestFromJSONTyped(json, false);
}
export function CheckUserExistsWithAppleRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'registrationInfo': AppleRegistrationInfoFromJSON(json['registrationInfo']),
    };
}
export function CheckUserExistsWithAppleRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'registrationInfo': AppleRegistrationInfoToJSON(value.registrationInfo),
    };
}
