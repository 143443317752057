export const num2name = {
    0: "PLATFORM_UNSPECIFIED",
    1: "PLATFORM_ANDROID",
    2: "PLATFORM_IOS",
};
export const name2num = {
    PLATFORM_UNSPECIFIED: 0,
    PLATFORM_ANDROID: 1,
    PLATFORM_IOS: 2,
};
