// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(OrderData)/AmountData";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../../../inside/payment/SubscriptionPayment";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        amountData: undefined,
        subscriptionPayment: undefined,
        paymentBillId: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.amountData !== undefined)
        result.amountData = encodeJson_1(value.amountData);
    if (value.subscriptionPayment !== undefined)
        result.subscriptionPayment = encodeJson_2(value.subscriptionPayment);
    if (value.paymentBillId !== undefined)
        result.paymentBillId = tsValueToJsonValueFns.string(value.paymentBillId);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.amountData !== undefined)
        result.amountData = decodeJson_1(value.amountData);
    if (value.subscriptionPayment !== undefined)
        result.subscriptionPayment = decodeJson_2(value.subscriptionPayment);
    if (value.paymentBillId !== undefined)
        result.paymentBillId = jsonValueToTsValueFns.string(value.paymentBillId);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.amountData !== undefined) {
        const tsValue = value.amountData;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.subscriptionPayment !== undefined) {
        const tsValue = value.subscriptionPayment;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.paymentBillId !== undefined) {
        const tsValue = value.paymentBillId;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.amountData = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.subscriptionPayment = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.paymentBillId = value;
    }
    return result;
}
