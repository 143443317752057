import {createBrowserServiceClient} from '@santa-web/gen/ssp/client';
import {registerServiceMethodImpl} from '@santa-web/gen/ssp/server';
import {MethodImplMap} from '@santa-web/gen/ssp/server/ServiceMethod';
import {
  createServiceClient as createCommonBrowserService,
  methodDescriptors as commonBrowserServiceMethods,
} from '@santa-web/gen/ssp/services/inside/app/CommonBrowserService';
import {
  createServiceClient as createToeicBrowserPreviewService,
  methodDescriptors as commonToeicBrowserPreviewMethods,
} from '@santa-web/gen/ssp/services/toeic/browser/ToeicBrowserPreviewService';

export const createBrowserServices = (iframe: HTMLIFrameElement) => {
  const browserServiceClient = createBrowserServiceClient({iframe});
  return {
    CommonBrowserService: createCommonBrowserService(browserServiceClient, {devtools: true}),
    ToeicBrowserPreviewService: createToeicBrowserPreviewService(browserServiceClient, {devtools: true}),
  };
};

export type CommonBrowserServiceMethodImplMap = MethodImplMap<typeof commonBrowserServiceMethods>;
export type ToeicBrowserPreviewMethodImplMap = MethodImplMap<typeof commonToeicBrowserPreviewMethods>;

function noOp() {
  return {};
}

let methodsBeforeOverrideMap: CommonBrowserServiceMethodImplMap = {
  onBackPressed: noOp,
  onDeviceStateChanged: noOp,
  refresh: noOp,
  refreshSafeArea: noOp,
};

export const overrideCommonBrowserService = (methodImplMap: Partial<CommonBrowserServiceMethodImplMap>) => {
  const currentOverrideBeforeMethods = {...methodsBeforeOverrideMap};
  registerCommonBrowserService({...methodsBeforeOverrideMap, ...methodImplMap});

  return {
    revoke: () => {
      registerCommonBrowserService({...currentOverrideBeforeMethods});
    },
  };
};

export const registerCommonBrowserService = (methodImplMap: Partial<CommonBrowserServiceMethodImplMap>) => {
  const noOpsMap: CommonBrowserServiceMethodImplMap = {
    onBackPressed: noOp,
    onDeviceStateChanged: noOp,
    refresh: noOp,
    refreshSafeArea: noOp,
  };

  methodsBeforeOverrideMap = {...noOpsMap, ...methodImplMap};

  registerServiceMethodImpl(commonBrowserServiceMethods, {...noOpsMap, ...methodImplMap});
};

export const registerToeicBrowserPreviewService = (methodImplMap: Partial<ToeicBrowserPreviewMethodImplMap>) => {
  const noOpsMap: ToeicBrowserPreviewMethodImplMap = {
    previewContent: noOp,
    sendTouchEndEvent: noOp,
  };
  registerServiceMethodImpl(commonToeicBrowserPreviewMethods, {...noOpsMap, ...methodImplMap});
};
