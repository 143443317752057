/* tslint:disable */
/* eslint-disable */
/**
 * 학습 사이클의 종류.
 *
 * - **DIAGNOSIS**: 진단고사
 * - **COURSE_LEARNING**: 추천학습
 * @export
 */
export const LearningCycleType = {
    DIAGNOSIS: 'DIAGNOSIS',
    COURSE_LEARNING: 'COURSE_LEARNING'
};
export function LearningCycleTypeFromJSON(json) {
    return LearningCycleTypeFromJSONTyped(json, false);
}
export function LearningCycleTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LearningCycleTypeToJSON(value) {
    return value;
}
