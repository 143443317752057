/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
import { TicketOrderFromJSON, TicketOrderToJSON, } from './TicketOrder';
/**
 * Check if a given object implements the ListTicketOrderResponse interface.
 */
export function instanceOfListTicketOrderResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "orders" in value;
    isInstance = isInstance && "page" in value;
    return isInstance;
}
export function ListTicketOrderResponseFromJSON(json) {
    return ListTicketOrderResponseFromJSONTyped(json, false);
}
export function ListTicketOrderResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'orders': (json['orders'].map(TicketOrderFromJSON)),
        'page': CursorPageFromJSON(json['page']),
    };
}
export function ListTicketOrderResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'orders': (value.orders.map(TicketOrderToJSON)),
        'page': CursorPageToJSON(value.page),
    };
}
