// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./ToeicResourceImageSet";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        asset: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    switch ((_a = value.asset) === null || _a === void 0 ? void 0 : _a.field) {
        case "imageSet": {
            result.imageSet = encodeJson_1(value.asset.value);
            break;
        }
        case "youtubeUrl": {
            result.youtubeUrl = tsValueToJsonValueFns.string(value.asset.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.imageSet !== undefined)
        result.asset = { field: "imageSet", value: decodeJson_1(value.imageSet) };
    if (value.youtubeUrl !== undefined)
        result.asset = { field: "youtubeUrl", value: jsonValueToTsValueFns.string(value.youtubeUrl) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    switch ((_a = value.asset) === null || _a === void 0 ? void 0 : _a.field) {
        case "imageSet": {
            const tsValue = value.asset.value;
            result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
        case "youtubeUrl": {
            const tsValue = value.asset.value;
            result.push([2, tsValueToWireValueFns.string(tsValue)]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    asset: new Set([1, 2]),
};
const oneofFieldNamesMap = {
    asset: new Map([
        [1, "imageSet"],
        [2, "youtubeUrl"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.asset;
        const oneofFieldNames = oneofFieldNamesMap.asset;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [1](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
            [2](wireValue) { return wireValueToTsValueFns.string(wireValue); },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.asset = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
