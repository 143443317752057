import React from 'react';

import {useMobileServices} from '@app/api/mobile-services';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

const NotificationOnMount: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {ToeicMobileService} = useMobileServices();
  React.useEffect(() => {
    if (isAppBridgeAvailable()) {
      ToeicMobileService.notifyWebviewIsReady({});
    }
  }, [ToeicMobileService]);
  return <>{children}</>;
};

export default React.memo(NotificationOnMount);
