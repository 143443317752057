/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DisplayConfigFromJSON, DisplayConfigToJSON, } from './DisplayConfig';
import { PaymentProviderFromJSON, PaymentProviderToJSON, } from './PaymentProvider';
import { SalesConfigFromJSON, SalesConfigToJSON, } from './SalesConfig';
import { TicketProductAmountDataFromJSON, TicketProductAmountDataToJSON, } from './TicketProductAmountData';
import { TicketProductBillingConfigFromJSON, TicketProductBillingConfigToJSON, } from './TicketProductBillingConfig';
/**
 * Check if a given object implements the TicketProduct interface.
 */
export function instanceOfTicketProduct(value) {
    let isInstance = true;
    isInstance = isInstance && "ticketAmount" in value;
    isInstance = isInstance && "displayConfig" in value;
    isInstance = isInstance && "amountData" in value;
    isInstance = isInstance && "billingConfig" in value;
    isInstance = isInstance && "salesConfig" in value;
    isInstance = isInstance && "ticketValidDays" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "paymentProviders" in value;
    return isInstance;
}
export function TicketProductFromJSON(json) {
    return TicketProductFromJSONTyped(json, false);
}
export function TicketProductFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketAmount': json['ticketAmount'],
        'displayConfig': DisplayConfigFromJSON(json['displayConfig']),
        'amountData': TicketProductAmountDataFromJSON(json['amountData']),
        'billingConfig': TicketProductBillingConfigFromJSON(json['billingConfig']),
        'salesConfig': SalesConfigFromJSON(json['salesConfig']),
        'ticketValidDays': json['ticketValidDays'],
        'id': json['id'],
        'paymentProviders': (json['paymentProviders'].map(PaymentProviderFromJSON)),
    };
}
export function TicketProductToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ticketAmount': value.ticketAmount,
        'displayConfig': DisplayConfigToJSON(value.displayConfig),
        'amountData': TicketProductAmountDataToJSON(value.amountData),
        'billingConfig': TicketProductBillingConfigToJSON(value.billingConfig),
        'salesConfig': SalesConfigToJSON(value.salesConfig),
        'ticketValidDays': value.ticketValidDays,
        'id': value.id,
        'paymentProviders': (value.paymentProviders.map(PaymentProviderToJSON)),
    };
}
