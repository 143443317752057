function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { allToeicSpeakingGrades, ToeicSpeakingGrade } from "../types/grade";
var _obj;
var maxScoreMap = (_obj = {}, _define_property(_obj, ToeicSpeakingGrade.NL, 20), _define_property(_obj, ToeicSpeakingGrade.NM, 50), _define_property(_obj, ToeicSpeakingGrade.NH, 80), _define_property(_obj, ToeicSpeakingGrade.IL, 100), _define_property(_obj, ToeicSpeakingGrade.IM1, 110), _define_property(_obj, ToeicSpeakingGrade.IM2, 120), _define_property(_obj, ToeicSpeakingGrade.IM3, 130), _define_property(_obj, ToeicSpeakingGrade.IH, 150), _define_property(_obj, ToeicSpeakingGrade.AL, 170), _define_property(_obj, ToeicSpeakingGrade.AM, 190), _define_property(_obj, ToeicSpeakingGrade.AH, 200), _obj);
export function convertToeicSpeakingScoreToGrade(score) {
    return allToeicSpeakingGrades.find(function(grade) {
        return score <= maxScoreMap[grade];
    }) || ToeicSpeakingGrade.AH;
}
var _obj1;
var textMap = (_obj1 = {}, _define_property(_obj1, ToeicSpeakingGrade.NL, "Novice Low"), _define_property(_obj1, ToeicSpeakingGrade.NM, "Novice Mid"), _define_property(_obj1, ToeicSpeakingGrade.NH, "Novice High"), _define_property(_obj1, ToeicSpeakingGrade.IL, "Intermediate Low"), _define_property(_obj1, ToeicSpeakingGrade.IM1, "Intermediate Mid 1"), _define_property(_obj1, ToeicSpeakingGrade.IM2, "Intermediate Mid 2"), _define_property(_obj1, ToeicSpeakingGrade.IM3, "Intermediate Mid 3"), _define_property(_obj1, ToeicSpeakingGrade.IH, "Intermediate High"), _define_property(_obj1, ToeicSpeakingGrade.AL, "Advanced Low"), _define_property(_obj1, ToeicSpeakingGrade.AM, "Advanced Mid"), _define_property(_obj1, ToeicSpeakingGrade.AH, "Advanced High"), _obj1);
export function convertToeicSpeakingGradeToText(grade) {
    return textMap[grade];
}
