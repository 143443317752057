/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ExamProgressFromJSON, ExamProgressToJSON, } from './ExamProgress';
import { ExamResultFromJSON, ExamResultToJSON, } from './ExamResult';
import { ReplyFromJSON, ReplyToJSON, } from './Reply';
/**
 * Check if a given object implements the Exam interface.
 */
export function instanceOfExam(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "questionSetId" in value;
    isInstance = isInstance && "partContentUrls" in value;
    isInstance = isInstance && "replies" in value;
    isInstance = isInstance && "progress" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function ExamFromJSON(json) {
    return ExamFromJSONTyped(json, false);
}
export function ExamFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'result': !exists(json, 'result') ? undefined : ExamResultFromJSON(json['result']),
        'createdAt': (new Date(json['createdAt'])),
        'questionSetId': json['questionSetId'],
        'partContentUrls': json['partContentUrls'],
        'replies': (json['replies'].map(ReplyFromJSON)),
        'progress': ExamProgressFromJSON(json['progress']),
        'id': json['id'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function ExamToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'result': ExamResultToJSON(value.result),
        'createdAt': (value.createdAt.toISOString()),
        'questionSetId': value.questionSetId,
        'partContentUrls': value.partContentUrls,
        'replies': (value.replies.map(ReplyToJSON)),
        'progress': ExamProgressToJSON(value.progress),
        'id': value.id,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
