import { CreateUnderlineInfoFromJSON, CreateUnderlineInfoToJSON, } from './CreateUnderlineInfo';
/**
 * Check if a given object implements the CreateUnderlineRequest interface.
 */
export function instanceOfCreateUnderlineRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "contentInteractionStateId" in value;
    isInstance = isInstance && "createUnderlineInfo" in value;
    return isInstance;
}
export function CreateUnderlineRequestFromJSON(json) {
    return CreateUnderlineRequestFromJSONTyped(json, false);
}
export function CreateUnderlineRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentInteractionStateId': json['contentInteractionStateId'],
        'createUnderlineInfo': (json['createUnderlineInfo'].map(CreateUnderlineInfoFromJSON)),
    };
}
export function CreateUnderlineRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentInteractionStateId': value.contentInteractionStateId,
        'createUnderlineInfo': (value.createUnderlineInfo.map(CreateUnderlineInfoToJSON)),
    };
}
