import { WithdrawReasonTypeFromJSON, WithdrawReasonTypeToJSON, } from './WithdrawReasonType';
/**
 * Check if a given object implements the WithdrawRequest interface.
 */
export function instanceOfWithdrawRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "reasonType" in value;
    return isInstance;
}
export function WithdrawRequestFromJSON(json) {
    return WithdrawRequestFromJSONTyped(json, false);
}
export function WithdrawRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'reason': json['reason'],
        'reasonType': WithdrawReasonTypeFromJSON(json['reasonType']),
    };
}
export function WithdrawRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'reason': value.reason,
        'reasonType': WithdrawReasonTypeToJSON(value.reasonType),
    };
}
