/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ElementInteractionStateOneOfDetailsFromJSON, ElementInteractionStateOneOfDetailsToJSON, } from './ElementInteractionStateOneOfDetails';
/**
 * Check if a given object implements the ElementInteractionState interface.
 */
export function instanceOfElementInteractionState(value) {
    let isInstance = true;
    isInstance = isInstance && "details" in value;
    isInstance = isInstance && "contentElementId" in value;
    isInstance = isInstance && "elapsedTimeMs" in value;
    return isInstance;
}
export function ElementInteractionStateFromJSON(json) {
    return ElementInteractionStateFromJSONTyped(json, false);
}
export function ElementInteractionStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'details': ElementInteractionStateOneOfDetailsFromJSON(json['details']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contentElementId': json['contentElementId'],
        'elapsedTimeMs': json['elapsedTimeMs'],
    };
}
export function ElementInteractionStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'details': ElementInteractionStateOneOfDetailsToJSON(value.details),
        'id': value.id,
        'contentElementId': value.contentElementId,
        'elapsedTimeMs': value.elapsedTimeMs,
    };
}
