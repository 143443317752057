/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const WithdrawReasonType = {
    UNKNOWN: 'UNKNOWN',
    UNUSEFUL: 'UNUSEFUL',
    TIRED: 'TIRED'
};
export function WithdrawReasonTypeFromJSON(json) {
    return WithdrawReasonTypeFromJSONTyped(json, false);
}
export function WithdrawReasonTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function WithdrawReasonTypeToJSON(value) {
    return value;
}
