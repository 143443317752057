/**
 * Check if a given object implements the RecurringConfig interface.
 */
export function instanceOfRecurringConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "intervalInDays" in value;
    return isInstance;
}
export function RecurringConfigFromJSON(json) {
    return RecurringConfigFromJSONTyped(json, false);
}
export function RecurringConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'intervalInDays': json['intervalInDays'],
    };
}
export function RecurringConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'intervalInDays': value.intervalInDays,
    };
}
