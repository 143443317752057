/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const LearningCellConfigOneOfCase = {
    QUESTION_SET: 'QUESTION_SET',
    QUESTION_STREAM: 'QUESTION_STREAM',
    LESSON: 'LESSON',
    VOCA: 'VOCA'
};
export function LearningCellConfigOneOfCaseFromJSON(json) {
    return LearningCellConfigOneOfCaseFromJSONTyped(json, false);
}
export function LearningCellConfigOneOfCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LearningCellConfigOneOfCaseToJSON(value) {
    return value;
}
