/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { StripeProductFromJSON, } from '../models';
/**
 *
 */
export class StripeProductV1Api extends runtime.BaseAPI {
    /**
     * id에 해당하는 product를 조회합니다.
     */
    async getStripeProductRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getStripeProduct.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/commerce/v1/stripe/product/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => StripeProductFromJSON(jsonValue));
    }
    /**
     * id에 해당하는 product를 조회합니다.
     */
    async getStripeProduct(requestParameters, initOverrides) {
        const response = await this.getStripeProductRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
