import {useRouter} from 'next/router';
import React from 'react';

import {usePushScreenViewEvent} from '@app/api/google-tag-manager';

const LogScreenViewEvent = ({children}: {children?: React.ReactNode}) => {
  const pushScreenViewEvent = usePushScreenViewEvent();
  const router = useRouter();

  React.useEffect(() => {
    const payload = {
      ...router.query,
      page_path: router.asPath.split('?')[0],
    };

    pushScreenViewEvent(router.pathname, payload);
  }, [pushScreenViewEvent, router.asPath, router.pathname, router.query]);

  return <>{children}</>;
};

export default LogScreenViewEvent;
