/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetMeResponseFromJSON, UpdateMyAgreementRequestToJSON, UpdateMyAgreementResponseFromJSON, UpdateMyPersonalInfoRequestToJSON, UpdateMyPersonalInfoResponseFromJSON, } from '../models';
/**
 *
 */
export class UserV1Api extends runtime.BaseAPI {
    /**
     * 자신의 유저 정보를 가져옵니다.
     */
    async getMeRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/user/v1/user/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetMeResponseFromJSON(jsonValue));
    }
    /**
     * 자신의 유저 정보를 가져옵니다.
     */
    async getMe(requestParameters, initOverrides) {
        const response = await this.getMeRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * User의 동의 여부를 변경합니다.
     */
    async updateMyAgreementRaw(requestParameters, initOverrides) {
        if (requestParameters.updateMyAgreementRequest === null || requestParameters.updateMyAgreementRequest === undefined) {
            throw new runtime.RequiredError('updateMyAgreementRequest', 'Required parameter requestParameters.updateMyAgreementRequest was null or undefined when calling updateMyAgreement.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/user/me/agreement`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMyAgreementRequestToJSON(requestParameters.updateMyAgreementRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateMyAgreementResponseFromJSON(jsonValue));
    }
    /**
     * User의 동의 여부를 변경합니다.
     */
    async updateMyAgreement(requestParameters, initOverrides) {
        const response = await this.updateMyAgreementRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * User의 개인정보를 변경합니다.
     */
    async updateMyPersonalInfoRaw(requestParameters, initOverrides) {
        if (requestParameters.updateMyPersonalInfoRequest === null || requestParameters.updateMyPersonalInfoRequest === undefined) {
            throw new runtime.RequiredError('updateMyPersonalInfoRequest', 'Required parameter requestParameters.updateMyPersonalInfoRequest was null or undefined when calling updateMyPersonalInfo.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/user/me/personal-info`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMyPersonalInfoRequestToJSON(requestParameters.updateMyPersonalInfoRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateMyPersonalInfoResponseFromJSON(jsonValue));
    }
    /**
     * User의 개인정보를 변경합니다.
     */
    async updateMyPersonalInfo(requestParameters, initOverrides) {
        const response = await this.updateMyPersonalInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
