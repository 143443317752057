import { StripeCurrencyFromJSON, StripeCurrencyToJSON, } from './StripeCurrency';
/**
 * Check if a given object implements the StripePrice interface.
 */
export function instanceOfStripePrice(value) {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;
    return isInstance;
}
export function StripePriceFromJSON(json) {
    return StripePriceFromJSONTyped(json, false);
}
export function StripePriceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'amount': json['amount'],
        'currency': StripeCurrencyFromJSON(json['currency']),
    };
}
export function StripePriceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'amount': value.amount,
        'currency': StripeCurrencyToJSON(value.currency),
    };
}
