/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CheckoutTicketOrderRequestToJSON, CompleteIamportTicketOrderRequestToJSON, TicketOrderFromJSON, } from '../models';
/**
 *
 */
export class CheckoutV1Api extends runtime.BaseAPI {
    /**
     * 주문 결제를 시작합니다.
     */
    async checkoutTicketOrderRaw(requestParameters, initOverrides) {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId', 'Required parameter requestParameters.orderId was null or undefined when calling checkoutTicketOrder.');
        }
        if (requestParameters.checkoutTicketOrderRequest === null || requestParameters.checkoutTicketOrderRequest === undefined) {
            throw new runtime.RequiredError('checkoutTicketOrderRequest', 'Required parameter requestParameters.checkoutTicketOrderRequest was null or undefined when calling checkoutTicketOrder.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/checkout/v1/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckoutTicketOrderRequestToJSON(requestParameters.checkoutTicketOrderRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderFromJSON(jsonValue));
    }
    /**
     * 주문 결제를 시작합니다.
     */
    async checkoutTicketOrder(requestParameters, initOverrides) {
        const response = await this.checkoutTicketOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주문을 완료합니다. iamportUid가 null인 경우 결제에 실패한 것으로 간주합니다.
     */
    async completeIamportTicketOrderRaw(requestParameters, initOverrides) {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId', 'Required parameter requestParameters.orderId was null or undefined when calling completeIamportTicketOrder.');
        }
        if (requestParameters.completeIamportTicketOrderRequest === null || requestParameters.completeIamportTicketOrderRequest === undefined) {
            throw new runtime.RequiredError('completeIamportTicketOrderRequest', 'Required parameter requestParameters.completeIamportTicketOrderRequest was null or undefined when calling completeIamportTicketOrder.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/checkout/v1/{orderId}/complete`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteIamportTicketOrderRequestToJSON(requestParameters.completeIamportTicketOrderRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderFromJSON(jsonValue));
    }
    /**
     * 주문을 완료합니다. iamportUid가 null인 경우 결제에 실패한 것으로 간주합니다.
     */
    async completeIamportTicketOrder(requestParameters, initOverrides) {
        const response = await this.completeIamportTicketOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
