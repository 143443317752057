/* tslint:disable */
/* eslint-disable */
/**
 * - **CREATED**: 구독 상품이 선택되었고 구독 주문이 생성된 상태.
 * - **PAYMENT_FAILED**: 결제에 실패한 상태.
 * - **PAYMENT_SUCCEEDED**: 정상적으로 결제된 후 권한이 부여되었으며 자동으로 갱신되는 상태.
 * - **REVOKED**: 만료됨
 * @export
 */
export const StripeSubscriptionOrderStatus = {
    CREATED: 'CREATED',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    PAYMENT_SUCCEEDED: 'PAYMENT_SUCCEEDED',
    REVOKED: 'REVOKED'
};
export function StripeSubscriptionOrderStatusFromJSON(json) {
    return StripeSubscriptionOrderStatusFromJSONTyped(json, false);
}
export function StripeSubscriptionOrderStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function StripeSubscriptionOrderStatusToJSON(value) {
    return value;
}
