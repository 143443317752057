import { LearningCellFromJSON, LearningCellToJSON, } from './LearningCell';
/**
 * Check if a given object implements the LearningCellResponse interface.
 */
export function instanceOfLearningCellResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "learningCell" in value;
    return isInstance;
}
export function LearningCellResponseFromJSON(json) {
    return LearningCellResponseFromJSONTyped(json, false);
}
export function LearningCellResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningCell': LearningCellFromJSON(json['learningCell']),
    };
}
export function LearningCellResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningCell': LearningCellToJSON(value.learningCell),
    };
}
