import {atomWithDefault} from 'jotai/utils';
import config from '@app/config';
import i18n from '@app/i18n';
import * as I18nUtils from '@app/i18n/utils';

export type SantaSystemCheckStatus = {
  isUnderSystemCheck: boolean;
  systemCheckReferenceLink: string;
};

/**
 * @description 시스템 점검 여부를 확인하는 API를 호출하는 async atom\
 * [관련 스펙 문서](https://www.notion.so/riiid/aea219be381f4c3f9e0455dfec47569d?pvs=4) 참조
 */
const santaSystemCheckStatusAtom = atomWithDefault<Promise<SantaSystemCheckStatus>>(async () => {
  const env = {
    development: 'dev',
    staging: 'stg',
    production: 'prod',
  }[config.env];
  const country = I18nUtils.getCountry(i18n.language).toLowerCase();
  const response = await fetch(`${config.santaSystemCheckServer}/${country}/${env}.json`);
  return response.json();
});

export default santaSystemCheckStatusAtom;

santaSystemCheckStatusAtom.debugPrivate = true;
