import { DurationFromJSON, DurationToJSON, } from './Duration';
import { EstimatedScoreDifferenceFromJSON, EstimatedScoreDifferenceToJSON, } from './EstimatedScoreDifference';
import { EstimatedTagProficienciesWithTargetFromJSON, EstimatedTagProficienciesWithTargetToJSON, } from './EstimatedTagProficienciesWithTarget';
import { EstimatedTagProficiencyWithPreviousFromJSON, EstimatedTagProficiencyWithPreviousToJSON, } from './EstimatedTagProficiencyWithPrevious';
import { LearningCycleConsumedCISCountsFromJSON, LearningCycleConsumedCISCountsToJSON, } from './LearningCycleConsumedCISCounts';
/**
 * Check if a given object implements the LearningCycleReport interface.
 */
export function instanceOfLearningCycleReport(value) {
    let isInstance = true;
    isInstance = isInstance && "estimatedScoreDifference" in value;
    isInstance = isInstance && "ordinalNumber" in value;
    isInstance = isInstance && "totalElapsedTime" in value;
    isInstance = isInstance && "consumedCisCounts" in value;
    isInstance = isInstance && "weakChapterInformation" in value;
    isInstance = isInstance && "mostIncreasedSkillInformation" in value;
    return isInstance;
}
export function LearningCycleReportFromJSON(json) {
    return LearningCycleReportFromJSONTyped(json, false);
}
export function LearningCycleReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'estimatedScoreDifference': EstimatedScoreDifferenceFromJSON(json['estimatedScoreDifference']),
        'ordinalNumber': json['ordinalNumber'],
        'totalElapsedTime': DurationFromJSON(json['totalElapsedTime']),
        'consumedCisCounts': LearningCycleConsumedCISCountsFromJSON(json['consumedCisCounts']),
        'weakChapterInformation': EstimatedTagProficienciesWithTargetFromJSON(json['weakChapterInformation']),
        'mostIncreasedSkillInformation': EstimatedTagProficiencyWithPreviousFromJSON(json['mostIncreasedSkillInformation']),
    };
}
export function LearningCycleReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'estimatedScoreDifference': EstimatedScoreDifferenceToJSON(value.estimatedScoreDifference),
        'ordinalNumber': value.ordinalNumber,
        'totalElapsedTime': DurationToJSON(value.totalElapsedTime),
        'consumedCisCounts': LearningCycleConsumedCISCountsToJSON(value.consumedCisCounts),
        'weakChapterInformation': EstimatedTagProficienciesWithTargetToJSON(value.weakChapterInformation),
        'mostIncreasedSkillInformation': EstimatedTagProficiencyWithPreviousToJSON(value.mostIncreasedSkillInformation),
    };
}
