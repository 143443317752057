/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetValueByTypeAndKeyResponseFromJSON, } from '../models';
/**
 *
 */
export class KeyValueStoreV1Api extends runtime.BaseAPI {
    /**
     * 변수로 받은 `type` 및 `key`에 대하여 DB에 저장한 `value`를 조회한다. 해당 API는 실험적인 임시 기능 구현을 할 때 백엔드 리소스 없이 구현할 목적으로 사용할 수 있다.  see: [임시적인 실험용 기능들(ABTest등)을 위한 Common한 BE Storage를 구현하고 이에 대한 Read API를 제공합니다.] (https://www.notion.so/riiid/ABTest-Common-BE-Storage-Read-API-1a12f44a71f04584aa3d7f4aa29b8d57)
     * 주어진 `type` 및 `key`에 대한 `value`를 조회한다.
     */
    async getValueByTypeAndKeyRaw(requestParameters, initOverrides) {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling getValueByTypeAndKey.');
        }
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key', 'Required parameter requestParameters.key was null or undefined when calling getValueByTypeAndKey.');
        }
        const queryParameters = {};
        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }
        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/pilot/v1/key-value-store/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetValueByTypeAndKeyResponseFromJSON(jsonValue));
    }
    /**
     * 변수로 받은 `type` 및 `key`에 대하여 DB에 저장한 `value`를 조회한다. 해당 API는 실험적인 임시 기능 구현을 할 때 백엔드 리소스 없이 구현할 목적으로 사용할 수 있다.  see: [임시적인 실험용 기능들(ABTest등)을 위한 Common한 BE Storage를 구현하고 이에 대한 Read API를 제공합니다.] (https://www.notion.so/riiid/ABTest-Common-BE-Storage-Read-API-1a12f44a71f04584aa3d7f4aa29b8d57)
     * 주어진 `type` 및 `key`에 대한 `value`를 조회한다.
     */
    async getValueByTypeAndKey(requestParameters, initOverrides) {
        const response = await this.getValueByTypeAndKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
