import { DiscountTypeFromJSON, DiscountTypeToJSON, } from './DiscountType';
/**
 * Check if a given object implements the DiscountConfig interface.
 */
export function instanceOfDiscountConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "value" in value;
    return isInstance;
}
export function DiscountConfigFromJSON(json) {
    return DiscountConfigFromJSONTyped(json, false);
}
export function DiscountConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': DiscountTypeFromJSON(json['type']),
        'value': json['value'],
    };
}
export function DiscountConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': DiscountTypeToJSON(value.type),
        'value': value.value,
    };
}
