import { EstimatedScoreFromJSON, EstimatedScoreToJSON, } from './EstimatedScore';
import { EstimatedTagProficienciesWithTargetFromJSON, EstimatedTagProficienciesWithTargetToJSON, } from './EstimatedTagProficienciesWithTarget';
import { LearningDomainKeyFromJSON, LearningDomainKeyToJSON, } from './LearningDomainKey';
import { OneOfUserSegmentFromJSON, OneOfUserSegmentToJSON, } from './OneOfUserSegment';
/**
 * Check if a given object implements the DiagnosisReport interface.
 */
export function instanceOfDiagnosisReport(value) {
    let isInstance = true;
    isInstance = isInstance && "skillInformation" in value;
    isInstance = isInstance && "estimatedScore" in value;
    isInstance = isInstance && "weakChapterInformation" in value;
    isInstance = isInstance && "learningDomainKey" in value;
    isInstance = isInstance && "userSegment" in value;
    return isInstance;
}
export function DiagnosisReportFromJSON(json) {
    return DiagnosisReportFromJSONTyped(json, false);
}
export function DiagnosisReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'skillInformation': EstimatedTagProficienciesWithTargetFromJSON(json['skillInformation']),
        'estimatedScore': EstimatedScoreFromJSON(json['estimatedScore']),
        'weakChapterInformation': EstimatedTagProficienciesWithTargetFromJSON(json['weakChapterInformation']),
        'learningDomainKey': LearningDomainKeyFromJSON(json['learningDomainKey']),
        'userSegment': OneOfUserSegmentFromJSON(json['userSegment']),
    };
}
export function DiagnosisReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'skillInformation': EstimatedTagProficienciesWithTargetToJSON(value.skillInformation),
        'estimatedScore': EstimatedScoreToJSON(value.estimatedScore),
        'weakChapterInformation': EstimatedTagProficienciesWithTargetToJSON(value.weakChapterInformation),
        'learningDomainKey': LearningDomainKeyToJSON(value.learningDomainKey),
        'userSegment': OneOfUserSegmentToJSON(value.userSegment),
    };
}
