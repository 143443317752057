// @ts-nocheck
import { name2num, num2name, } from "./DiscountType";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../DecimalAmount";
import { name2num as name2num_1, num2name as num2name_1, } from "../../(Coupon)/Type";
import { name2num as name2num_2, num2name as num2name_2, } from "../../UserSegByPayment";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./StockConfig";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { default as Long, } from "../../../../runtime/Long";
import { tsValueToWireValueFns, wireValueToTsValueFns, unpackFns, } from "../../../../runtime/wire/scalar";
import { encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../google/protobuf/Int64Value";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        discountType: "DISCOUNT_TYPE_UNSPECIFIED",
        discountAmount: undefined,
        limited: false,
        stockCount: undefined,
        internal: false,
        availableCouponTypes: [],
        canPurchaseOnce: false,
        availableUserSegByPayments: [],
        stockConfig: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.discountType !== undefined)
        result.discountType = tsValueToJsonValueFns.enum(value.discountType);
    if (value.discountAmount !== undefined)
        result.discountAmount = encodeJson_1(value.discountAmount);
    if (value.limited !== undefined)
        result.limited = tsValueToJsonValueFns.bool(value.limited);
    if (value.stockCount !== undefined)
        result.stockCount = value.stockCount;
    if (value.internal !== undefined)
        result.internal = tsValueToJsonValueFns.bool(value.internal);
    result.availableCouponTypes = value.availableCouponTypes.map(value => tsValueToJsonValueFns.enum(value));
    if (value.canPurchaseOnce !== undefined)
        result.canPurchaseOnce = tsValueToJsonValueFns.bool(value.canPurchaseOnce);
    result.availableUserSegByPayments = value.availableUserSegByPayments.map(value => tsValueToJsonValueFns.enum(value));
    if (value.stockConfig !== undefined)
        result.stockConfig = encodeJson_2(value.stockConfig);
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    if (value.discountType !== undefined)
        result.discountType = jsonValueToTsValueFns.enum(value.discountType);
    if (value.discountAmount !== undefined)
        result.discountAmount = decodeJson_1(value.discountAmount);
    if (value.limited !== undefined)
        result.limited = jsonValueToTsValueFns.bool(value.limited);
    if (value.stockCount !== undefined)
        result.stockCount = value.stockCount;
    if (value.internal !== undefined)
        result.internal = jsonValueToTsValueFns.bool(value.internal);
    result.availableCouponTypes = (_b = (_a = value.availableCouponTypes) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _b !== void 0 ? _b : [];
    if (value.canPurchaseOnce !== undefined)
        result.canPurchaseOnce = jsonValueToTsValueFns.bool(value.canPurchaseOnce);
    result.availableUserSegByPayments = (_d = (_c = value.availableUserSegByPayments) === null || _c === void 0 ? void 0 : _c.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _d !== void 0 ? _d : [];
    if (value.stockConfig !== undefined)
        result.stockConfig = decodeJson_2(value.stockConfig);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.discountType !== undefined) {
        const tsValue = value.discountType;
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.discountAmount !== undefined) {
        const tsValue = value.discountAmount;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.limited !== undefined) {
        const tsValue = value.limited;
        result.push([3, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.stockCount !== undefined) {
        const tsValue = value.stockCount;
        result.push([4, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
    }
    if (value.internal !== undefined) {
        const tsValue = value.internal;
        result.push([5, tsValueToWireValueFns.bool(tsValue)]);
    }
    for (const tsValue of value.availableCouponTypes) {
        result.push([6, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.canPurchaseOnce !== undefined) {
        const tsValue = value.canPurchaseOnce;
        result.push([7, tsValueToWireValueFns.bool(tsValue)]);
    }
    for (const tsValue of value.availableUserSegByPayments) {
        result.push([8, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
    }
    if (value.stockConfig !== undefined) {
        const tsValue = value.stockConfig;
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.discountType = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.discountAmount = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.limited = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.stockCount = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.internal = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 6).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_1[num]);
        if (!value.length)
            break collection;
        result.availableCouponTypes = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.canPurchaseOnce = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 8).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_2[num]);
        if (!value.length)
            break collection;
        result.availableUserSegByPayments = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.stockConfig = value;
    }
    return result;
}
