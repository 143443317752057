import { EstimatedTagProficiencyFromJSON, EstimatedTagProficiencyToJSON, } from './EstimatedTagProficiency';
/**
 * Check if a given object implements the EstimatedTagProficiencyWithPrevious interface.
 */
export function instanceOfEstimatedTagProficiencyWithPrevious(value) {
    let isInstance = true;
    isInstance = isInstance && "previousTagProficiency" in value;
    isInstance = isInstance && "estimatedTagProficiency" in value;
    return isInstance;
}
export function EstimatedTagProficiencyWithPreviousFromJSON(json) {
    return EstimatedTagProficiencyWithPreviousFromJSONTyped(json, false);
}
export function EstimatedTagProficiencyWithPreviousFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'previousTagProficiency': json['previousTagProficiency'],
        'estimatedTagProficiency': EstimatedTagProficiencyFromJSON(json['estimatedTagProficiency']),
    };
}
export function EstimatedTagProficiencyWithPreviousToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'previousTagProficiency': value.previousTagProficiency,
        'estimatedTagProficiency': EstimatedTagProficiencyToJSON(value.estimatedTagProficiency),
    };
}
