import { StripeBillingConfigFromJSON, StripeBillingConfigToJSON, } from './StripeBillingConfig';
/**
 * Check if a given object implements the StripeSubscriptionStripeProduct interface.
 */
export function instanceOfStripeSubscriptionStripeProduct(value) {
    let isInstance = true;
    isInstance = isInstance && "billingConfig" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
export function StripeSubscriptionStripeProductFromJSON(json) {
    return StripeSubscriptionStripeProductFromJSONTyped(json, false);
}
export function StripeSubscriptionStripeProductFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'billingConfig': StripeBillingConfigFromJSON(json['billingConfig']),
        'name': json['name'],
    };
}
export function StripeSubscriptionStripeProductToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'billingConfig': StripeBillingConfigToJSON(value.billingConfig),
        'name': value.name,
    };
}
