/**
 * Check if a given object implements the UnderlineInfo interface.
 */
export function instanceOfUnderlineInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "displayInfo" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function UnderlineInfoFromJSON(json) {
    return UnderlineInfoFromJSONTyped(json, false);
}
export function UnderlineInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayInfo': json['displayInfo'],
        'id': json['id'],
    };
}
export function UnderlineInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayInfo': value.displayInfo,
        'id': value.id,
    };
}
