// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./Header";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./Passage";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./Question";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./Explanation";
import { encodeJson as encodeJson_5, decodeJson as decodeJson_5, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "./Snippet";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        headers: [],
        passages: [],
        questions: [],
        explanations: [],
        description: undefined,
        passageTranslations: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    result.headers = value.headers.map(value => encodeJson_1(value));
    result.passages = value.passages.map(value => encodeJson_2(value));
    result.questions = value.questions.map(value => encodeJson_3(value));
    result.explanations = value.explanations.map(value => encodeJson_4(value));
    if (value.description !== undefined)
        result.description = encodeJson_5(value.description);
    result.passageTranslations = value.passageTranslations.map(value => encodeJson_2(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const result = getDefaultValue();
    result.headers = (_b = (_a = value.headers) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    result.passages = (_d = (_c = value.passages) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_2(value))) !== null && _d !== void 0 ? _d : [];
    result.questions = (_f = (_e = value.questions) === null || _e === void 0 ? void 0 : _e.map((value) => decodeJson_3(value))) !== null && _f !== void 0 ? _f : [];
    result.explanations = (_h = (_g = value.explanations) === null || _g === void 0 ? void 0 : _g.map((value) => decodeJson_4(value))) !== null && _h !== void 0 ? _h : [];
    if (value.description !== undefined)
        result.description = decodeJson_5(value.description);
    result.passageTranslations = (_k = (_j = value.passageTranslations) === null || _j === void 0 ? void 0 : _j.map((value) => decodeJson_2(value))) !== null && _k !== void 0 ? _k : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    for (const tsValue of value.headers) {
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.passages) {
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    for (const tsValue of value.questions) {
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    for (const tsValue of value.explanations) {
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    if (value.description !== undefined) {
        const tsValue = value.description;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
    }
    for (const tsValue of value.passageTranslations) {
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.headers = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 2).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.passages = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 3).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.questions = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.explanations = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.description = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 7).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.passageTranslations = value;
    }
    return result;
}
