// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(PreviewContentResponse)/Value";
import { name2num, num2name, } from "./(PreviewContentResponse)/Error";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        res: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    switch ((_a = value.res) === null || _a === void 0 ? void 0 : _a.field) {
        case "value": {
            result.value = encodeJson_1(value.res.value);
            break;
        }
        case "error": {
            result.error = tsValueToJsonValueFns.enum(value.res.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.value !== undefined)
        result.res = { field: "value", value: decodeJson_1(value.value) };
    if (value.error !== undefined)
        result.res = { field: "error", value: jsonValueToTsValueFns.enum(value.error) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    switch ((_a = value.res) === null || _a === void 0 ? void 0 : _a.field) {
        case "value": {
            const tsValue = value.res.value;
            result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
        case "error": {
            const tsValue = value.res.value;
            result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    res: new Set([1, 2]),
};
const oneofFieldNamesMap = {
    res: new Map([
        [1, "value"],
        [2, "error"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.res;
        const oneofFieldNames = oneofFieldNamesMap.res;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [1](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
            [2](wireValue) { return wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.res = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
