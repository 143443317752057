/**
 * Check if a given object implements the LearningCellReportExtraInfoCourseLearning interface.
 */
export function instanceOfLearningCellReportExtraInfoCourseLearning(value) {
    let isInstance = true;
    isInstance = isInstance && "ordinalNumber" in value;
    isInstance = isInstance && "learningCycleId" in value;
    return isInstance;
}
export function LearningCellReportExtraInfoCourseLearningFromJSON(json) {
    return LearningCellReportExtraInfoCourseLearningFromJSONTyped(json, false);
}
export function LearningCellReportExtraInfoCourseLearningFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ordinalNumber': json['ordinalNumber'],
        'learningCycleId': json['learningCycleId'],
    };
}
export function LearningCellReportExtraInfoCourseLearningToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ordinalNumber': value.ordinalNumber,
        'learningCycleId': value.learningCycleId,
    };
}
