export const num2name = {
    0: "UNSET",
    1: "KRW",
    2: "USD",
    3: "JPY",
    4: "TWD",
    5: "VND",
    6: "THB",
};
export const name2num = {
    UNSET: 0,
    KRW: 1,
    USD: 2,
    JPY: 3,
    TWD: 4,
    VND: 5,
    THB: 6,
};
