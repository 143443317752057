/**
 * Check if a given object implements the CursorPage interface.
 */
export function instanceOfCursorPage(value) {
    let isInstance = true;
    isInstance = isInstance && "cursor" in value;
    isInstance = isInstance && "size" in value;
    return isInstance;
}
export function CursorPageFromJSON(json) {
    return CursorPageFromJSONTyped(json, false);
}
export function CursorPageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cursor': json['cursor'],
        'size': json['size'],
    };
}
export function CursorPageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cursor': value.cursor,
        'size': value.size,
    };
}
