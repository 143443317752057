import React from 'react';
import {QueryClient} from '@tanstack/query-core';
import {QueryClientProvider as _QueryClientProvider} from '@tanstack/react-query';

const QueryClientProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const queryClient = React.useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          useErrorBoundary: true,
        },
      },
    });
  }, []);

  return <_QueryClientProvider client={queryClient}>{children}</_QueryClientProvider>;
};

export default React.memo(QueryClientProvider);
