export const num2name = {
    0: "CALCULATE_PRICE_METHOD_UNSPECIFIED",
    1: "CALCULATE_PRICE_METHOD_DAILY",
    2: "CALCULATE_PRICE_METHOD_MONTHLY",
};
export const name2num = {
    CALCULATE_PRICE_METHOD_UNSPECIFIED: 0,
    CALCULATE_PRICE_METHOD_DAILY: 1,
    CALCULATE_PRICE_METHOD_MONTHLY: 2,
};
