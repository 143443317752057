// @ts-nocheck
import { name2num, num2name, } from "./PaymentCurrency";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        itemName: "",
        storeProductId: "",
        orderId: "0",
        version: "0",
        sellingPrice: "",
        couponAmount: "",
        paidAmount: "",
        paymentId: "0",
        purchasePrice: "",
        refundAmount: "",
        paybackAmount: "",
        currency: "UNSET",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.itemName !== undefined)
        result.itemName = tsValueToJsonValueFns.string(value.itemName);
    if (value.storeProductId !== undefined)
        result.storeProductId = tsValueToJsonValueFns.string(value.storeProductId);
    if (value.orderId !== undefined)
        result.orderId = tsValueToJsonValueFns.int64(value.orderId);
    if (value.version !== undefined)
        result.version = tsValueToJsonValueFns.int64(value.version);
    if (value.sellingPrice !== undefined)
        result.sellingPrice = tsValueToJsonValueFns.string(value.sellingPrice);
    if (value.couponAmount !== undefined)
        result.couponAmount = tsValueToJsonValueFns.string(value.couponAmount);
    if (value.paidAmount !== undefined)
        result.paidAmount = tsValueToJsonValueFns.string(value.paidAmount);
    if (value.paymentId !== undefined)
        result.paymentId = tsValueToJsonValueFns.int64(value.paymentId);
    if (value.purchasePrice !== undefined)
        result.purchasePrice = tsValueToJsonValueFns.string(value.purchasePrice);
    if (value.refundAmount !== undefined)
        result.refundAmount = tsValueToJsonValueFns.string(value.refundAmount);
    if (value.paybackAmount !== undefined)
        result.paybackAmount = tsValueToJsonValueFns.string(value.paybackAmount);
    if (value.currency !== undefined)
        result.currency = tsValueToJsonValueFns.enum(value.currency);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.itemName !== undefined)
        result.itemName = jsonValueToTsValueFns.string(value.itemName);
    if (value.storeProductId !== undefined)
        result.storeProductId = jsonValueToTsValueFns.string(value.storeProductId);
    if (value.orderId !== undefined)
        result.orderId = jsonValueToTsValueFns.int64(value.orderId);
    if (value.version !== undefined)
        result.version = jsonValueToTsValueFns.int64(value.version);
    if (value.sellingPrice !== undefined)
        result.sellingPrice = jsonValueToTsValueFns.string(value.sellingPrice);
    if (value.couponAmount !== undefined)
        result.couponAmount = jsonValueToTsValueFns.string(value.couponAmount);
    if (value.paidAmount !== undefined)
        result.paidAmount = jsonValueToTsValueFns.string(value.paidAmount);
    if (value.paymentId !== undefined)
        result.paymentId = jsonValueToTsValueFns.int64(value.paymentId);
    if (value.purchasePrice !== undefined)
        result.purchasePrice = jsonValueToTsValueFns.string(value.purchasePrice);
    if (value.refundAmount !== undefined)
        result.refundAmount = jsonValueToTsValueFns.string(value.refundAmount);
    if (value.paybackAmount !== undefined)
        result.paybackAmount = jsonValueToTsValueFns.string(value.paybackAmount);
    if (value.currency !== undefined)
        result.currency = jsonValueToTsValueFns.enum(value.currency);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.itemName !== undefined) {
        const tsValue = value.itemName;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.storeProductId !== undefined) {
        const tsValue = value.storeProductId;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.orderId !== undefined) {
        const tsValue = value.orderId;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.version !== undefined) {
        const tsValue = value.version;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.sellingPrice !== undefined) {
        const tsValue = value.sellingPrice;
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.couponAmount !== undefined) {
        const tsValue = value.couponAmount;
        result.push([6, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.paidAmount !== undefined) {
        const tsValue = value.paidAmount;
        result.push([7, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.paymentId !== undefined) {
        const tsValue = value.paymentId;
        result.push([8, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.purchasePrice !== undefined) {
        const tsValue = value.purchasePrice;
        result.push([9, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.refundAmount !== undefined) {
        const tsValue = value.refundAmount;
        result.push([10, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.paybackAmount !== undefined) {
        const tsValue = value.paybackAmount;
        result.push([11, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.currency !== undefined) {
        const tsValue = value.currency;
        result.push([12, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.itemName = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.storeProductId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.orderId = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.version = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.sellingPrice = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.couponAmount = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.paidAmount = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.paymentId = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.purchasePrice = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.refundAmount = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.paybackAmount = value;
    }
    field: {
        const wireValue = wireFields.get(12);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.currency = value;
    }
    return result;
}
