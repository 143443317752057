/**
 * Check if a given object implements the CommonCheckUserExistsResponse interface.
 */
export function instanceOfCommonCheckUserExistsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "isExisting" in value;
    return isInstance;
}
export function CommonCheckUserExistsResponseFromJSON(json) {
    return CommonCheckUserExistsResponseFromJSONTyped(json, false);
}
export function CommonCheckUserExistsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isExisting': json['isExisting'],
    };
}
export function CommonCheckUserExistsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isExisting': value.isExisting,
    };
}
