import { UserProfileFromJSON, UserProfileToJSON, } from './UserProfile';
/**
 * Check if a given object implements the UpdateMyTargetExamDateResponse interface.
 */
export function instanceOfUpdateMyTargetExamDateResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "profile" in value;
    return isInstance;
}
export function UpdateMyTargetExamDateResponseFromJSON(json) {
    return UpdateMyTargetExamDateResponseFromJSONTyped(json, false);
}
export function UpdateMyTargetExamDateResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'profile': UserProfileFromJSON(json['profile']),
    };
}
export function UpdateMyTargetExamDateResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'profile': UserProfileToJSON(value.profile),
    };
}
