import { UnderlineInfoFromJSON, UnderlineInfoToJSON, } from './UnderlineInfo';
/**
 * Check if a given object implements the Underline interface.
 */
export function instanceOfUnderline(value) {
    let isInstance = true;
    isInstance = isInstance && "contentInteractionStateId" in value;
    isInstance = isInstance && "underlineInfos" in value;
    return isInstance;
}
export function UnderlineFromJSON(json) {
    return UnderlineFromJSONTyped(json, false);
}
export function UnderlineFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentInteractionStateId': json['contentInteractionStateId'],
        'underlineInfos': (json['underlineInfos'].map(UnderlineInfoFromJSON)),
    };
}
export function UnderlineToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentInteractionStateId': value.contentInteractionStateId,
        'underlineInfos': (value.underlineInfos.map(UnderlineInfoToJSON)),
    };
}
