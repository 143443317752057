/* tslint:disable */
/* eslint-disable */
/**
 * 진단고사 문제 콘텐츠 옵션. 해당 옵션에 맞춰 진단고사 문제가 추천된다.
 * ([[DX] Usecase 정의서 - 진단고사](https://www.notion.so/riiid/2-c896589996f84538b6c287010402569a))
 *
 * - **RC**: RC만 추천
 * - **LC_RC**: LC와 RC 둘 다 추천
 * @export
 */
export const DiagnosisContentOption = {
    RC: 'RC',
    LC_RC: 'LC_RC'
};
export function DiagnosisContentOptionFromJSON(json) {
    return DiagnosisContentOptionFromJSONTyped(json, false);
}
export function DiagnosisContentOptionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function DiagnosisContentOptionToJSON(value) {
    return value;
}
