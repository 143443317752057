import { DiagnosisContentOptionFromJSON, DiagnosisContentOptionToJSON, } from './DiagnosisContentOption';
/**
 * Check if a given object implements the StartDiagnosisResponse interface.
 */
export function instanceOfStartDiagnosisResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "learningCellId" in value;
    isInstance = isInstance && "contentOption" in value;
    return isInstance;
}
export function StartDiagnosisResponseFromJSON(json) {
    return StartDiagnosisResponseFromJSONTyped(json, false);
}
export function StartDiagnosisResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningCellId': json['learningCellId'],
        'contentOption': DiagnosisContentOptionFromJSON(json['ContentOption']),
    };
}
export function StartDiagnosisResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningCellId': value.learningCellId,
        'ContentOption': DiagnosisContentOptionToJSON(value.contentOption),
    };
}
