// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./google/protobuf/Timestamp";
import { WireType, } from "../runtime/wire/index";
import { default as serialize, } from "../runtime/wire/serialize";
import { default as deserialize, } from "../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        from: undefined,
        to: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.from !== undefined)
        result.from = encodeJson_1(value.from);
    if (value.to !== undefined)
        result.to = encodeJson_1(value.to);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.from !== undefined)
        result.from = decodeJson_1(value.from);
    if (value.to !== undefined)
        result.to = decodeJson_1(value.to);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.from !== undefined) {
        const tsValue = value.from;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.to !== undefined) {
        const tsValue = value.to;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.from = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.to = value;
    }
    return result;
}
