/* tslint:disable */
/* eslint-disable */
/**
 * Santa - Toeic Speaking
 * 인증이 필요한 API에서 access token이 없는 경우 401로 내려준다. 인증이 필요한 API에서 access token에 해당하는 유저가 익명유저인 경우 403으로 내려준다.
 *
 * The version of the OpenAPI document: 2023.12.4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const QuestionResultDetailCategory = {
    PRONUNCIATION: 'PRONUNCIATION',
    INTONATION_STRESS: 'INTONATION_STRESS',
    GRAMMAR_VOCABULARY: 'GRAMMAR_VOCABULARY',
    COHESION: 'COHESION',
    RELEVANCE_COMPLETENESS: 'RELEVANCE_COMPLETENESS'
};
export function QuestionResultDetailCategoryFromJSON(json) {
    return QuestionResultDetailCategoryFromJSONTyped(json, false);
}
export function QuestionResultDetailCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function QuestionResultDetailCategoryToJSON(value) {
    return value;
}
