import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
import { ListVisibleLearningDomainsResponseLearningDomainFromJSON, ListVisibleLearningDomainsResponseLearningDomainToJSON, } from './ListVisibleLearningDomainsResponseLearningDomain';
/**
 * Check if a given object implements the ListVisibleLearningDomainsResponse interface.
 */
export function instanceOfListVisibleLearningDomainsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "learningDomains" in value;
    isInstance = isInstance && "page" in value;
    return isInstance;
}
export function ListVisibleLearningDomainsResponseFromJSON(json) {
    return ListVisibleLearningDomainsResponseFromJSONTyped(json, false);
}
export function ListVisibleLearningDomainsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningDomains': (json['learningDomains'].map(ListVisibleLearningDomainsResponseLearningDomainFromJSON)),
        'page': CursorPageFromJSON(json['page']),
    };
}
export function ListVisibleLearningDomainsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningDomains': (value.learningDomains.map(ListVisibleLearningDomainsResponseLearningDomainToJSON)),
        'page': CursorPageToJSON(value.page),
    };
}
