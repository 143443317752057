export const num2name = {
    0: "TESTPREP_SECOND_PAGE_UI_USAGE",
    1: "TESTPREP_SECOND_PAGE_UI_PASSAGE_EXPLANATION",
    2: "TESTPREP_REPLAY_AUDIO",
    3: "TESTPREP_SENTENCE_COMPLETION",
    4: "TESTPREP_HIGHLIGHT_USAGE",
    5: "TESTPREP_SWITCHABLE_ACTION",
};
export const name2num = {
    TESTPREP_SECOND_PAGE_UI_USAGE: 0,
    TESTPREP_SECOND_PAGE_UI_PASSAGE_EXPLANATION: 1,
    TESTPREP_REPLAY_AUDIO: 2,
    TESTPREP_SENTENCE_COMPLETION: 3,
    TESTPREP_HIGHLIGHT_USAGE: 4,
    TESTPREP_SWITCHABLE_ACTION: 5,
};
