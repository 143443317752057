export const num2name = {
    0: "PRODUCT_CATEGORY_UNSPECIFIED",
    1: "BASIC",
    2: "CUSTOM",
    3: "EVENT",
    4: "TOEIC2_MIGRATION",
    5: "ZERO_PAYMENT",
};
export const name2num = {
    PRODUCT_CATEGORY_UNSPECIFIED: 0,
    BASIC: 1,
    CUSTOM: 2,
    EVENT: 3,
    TOEIC2_MIGRATION: 4,
    ZERO_PAYMENT: 5,
};
