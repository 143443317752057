/* tslint:disable */
/* eslint-disable */
/**
 * - **NONE**: 정상적인 상태
 * - **ENQUEUED**: AI 서버로부터 응답을 기다리는 상태
 * - **ERROR**: AI 서버로부터 응답을 받지 못한 상태로, 메시지를 재전송하거나 삭제해야 하는 상태
 * - **DELETED**: 삭제된 메시지 (UI에서 보여선 안됨)
 * @export
 */
export const ChatMessageStatus = {
    NONE: 'NONE',
    ENQUEUED: 'ENQUEUED',
    ERROR: 'ERROR',
    DELETED: 'DELETED'
};
export function ChatMessageStatusFromJSON(json) {
    return ChatMessageStatusFromJSONTyped(json, false);
}
export function ChatMessageStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ChatMessageStatusToJSON(value) {
    return value;
}
