/* tslint:disable */
/* eslint-disable */
/**
 * - **THUMBS_UP**: Thumbs Up
 * - **THUMBS_DOWN**: Thumbs Down
 * @export
 */
export const ChatMessageFeedback = {
    UP: 'THUMBS_UP',
    DOWN: 'THUMBS_DOWN'
};
export function ChatMessageFeedbackFromJSON(json) {
    return ChatMessageFeedbackFromJSONTyped(json, false);
}
export function ChatMessageFeedbackFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ChatMessageFeedbackToJSON(value) {
    return value;
}
