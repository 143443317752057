import React from 'react';
import {use100vh} from 'react-div-100vh';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {Button, SinglePageLayout, SystemErrorLayout} from '@santa-web/service-ui';
import {useMobileServices} from '@app/api/mobile-services';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

interface FallbackProps {
  resetError: () => void;
}

const SentryErrorBoundaryFallback = ({resetError}: FallbackProps) => {
  const viewportHeight = use100vh();
  const {t} = useTranslation();
  const {CommonMobileService} = useMobileServices();

  const handleErrorButtonClick = React.useCallback(async () => {
    const isWebview = isAppBridgeAvailable();
    if (isWebview) {
      await CommonMobileService.exit({});
      return;
    }
    resetError();
  }, [CommonMobileService, resetError]);

  const button = (
    <Button variant="box-line" onClick={handleErrorButtonClick}>
      {t('error_page_service_error_button')}
    </Button>
  );

  return (
    <div
      css={css`
        width: 100%;
        height: ${viewportHeight ? `${viewportHeight}px` : '100vh'};
      `}>
      <SinglePageLayout isError>
        <SystemErrorLayout
          title={t('error_page_service_error_title')}
          description={t('error_page_service_error_description')}
          button={button}
        />
      </SinglePageLayout>
    </div>
  );
};

export default SentryErrorBoundaryFallback;
