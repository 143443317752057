import { LearningCellConfigOneOfFromJSON, LearningCellConfigOneOfToJSON, } from './LearningCellConfigOneOf';
/**
 * Check if a given object implements the LearningCellConfig interface.
 */
export function instanceOfLearningCellConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    return isInstance;
}
export function LearningCellConfigFromJSON(json) {
    return LearningCellConfigFromJSONTyped(json, false);
}
export function LearningCellConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'value': LearningCellConfigOneOfFromJSON(json['value']),
    };
}
export function LearningCellConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'value': LearningCellConfigOneOfToJSON(value.value),
    };
}
