import { RecurringConfigFromJSON, RecurringConfigToJSON, } from './RecurringConfig';
import { StripePriceFromJSON, StripePriceToJSON, } from './StripePrice';
/**
 * Check if a given object implements the StripeBillingConfig interface.
 */
export function instanceOfStripeBillingConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "recurringConfig" in value;
    return isInstance;
}
export function StripeBillingConfigFromJSON(json) {
    return StripeBillingConfigFromJSONTyped(json, false);
}
export function StripeBillingConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'price': StripePriceFromJSON(json['price']),
        'recurringConfig': RecurringConfigFromJSON(json['recurringConfig']),
    };
}
export function StripeBillingConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'price': StripePriceToJSON(value.price),
        'recurringConfig': RecurringConfigToJSON(value.recurringConfig),
    };
}
