import UserAgentParser from 'ua-parser-js';

import UserAgent from '@app/types/user-agent';

export const UNKNOWN = 'UNKNOWN';
// https://github.com/faisalman/ua-parser-js/blob/master/src/ua-parser.js
export const getUserAgent = (): UserAgent => {
  const uaParserInstance = new UserAgentParser(window.navigator.userAgent);
  const osInformation = uaParserInstance.getOS();
  const device = uaParserInstance.getDevice();
  const deviceType = device.type === 'mobile' ? 'mobile' : device.type === 'tablet' ? 'tablet' : 'etc';
  const browser = uaParserInstance.getBrowser();
  return {
    os: {
      name: osInformation.name ?? 'UNKNOWN',
      version: osInformation.version ?? 'UNKNOWN',
    },
    browser: {
      name: browser.name ?? 'UNKNOWN',
      version: browser.version ?? 'UNKNOWN',
    },
    device: {
      type: deviceType,
    },
  };
};

export const getOsName = (userAgent: UserAgent): string => userAgent.os.name;
export const getBrowserName = (userAgent: UserAgent): string => userAgent.browser.name;
export const isIos = (userAgent: UserAgent): boolean => getOsName(userAgent).toLowerCase().startsWith('ios');
export const isAndroid = (userAgent: UserAgent): boolean => getOsName(userAgent).toLowerCase().startsWith('android');

export const isChrome = (userAgent: UserAgent): boolean => getBrowserName(userAgent).toLowerCase() === 'chrome';
export const isSafari = (userAgent: UserAgent): boolean => getBrowserName(userAgent).toLowerCase() === 'safari';

export const getIsMobileOs = (userAgent: UserAgent): boolean => {
  return isIos(userAgent) || isAndroid(userAgent);
};
