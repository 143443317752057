export var getToeicSpeakingExamineeQueryKey = function() {
    return [
        "getToeicSpeakingExaminee"
    ];
};
export var getToeicSpeakingExamQueryKey = function(examId) {
    return [
        "getToeicSpeakingExam",
        examId
    ];
};
export var getToeicSpeakingExamListQueryKey = function() {
    return [
        "getToeicSpeakingExamList"
    ];
};
export var getToeicSpeakingQuestionSetListQueryKey = function() {
    return [
        "getToeicSpeakingQuestionSetList"
    ];
};
export var getToeicSpeakingExchangeCouponQueryKey = function(id) {
    return [
        "getToeicSpeakingExchangeCouponQuery",
        id
    ];
};
export var getToeicSpeakingCouponListQueryKey = function(filter) {
    return [
        "getToeicSpeakingCouponList",
        filter
    ];
};
export var getToeicSpeakingTicketGroupSourcesQueryKey = function() {
    return [
        "getToeicSpeakingTicketGroupSources"
    ];
};
export var getToeicSpeakingTicketListQueryKey = function(filter) {
    return [
        "getToeicSpeakingTicketList",
        filter
    ];
};
export var getToeicSpeakingPurchasableTicketCountQueryKey = function() {
    return [
        "getToeicSpeakingPurchasableTicketCountQueryKey"
    ];
};
export var getToeicSpeakingProductBoardQueryKey = function(boardCode) {
    return [
        "getToeicSpeakingProductBoard",
        boardCode
    ];
};
export var getToeicSpeakingTicketProductQueryKey = function(productId) {
    return [
        "getToeicSpeakingTicketProductQueryKey",
        productId
    ];
};
export var getToeicSpeakingCanPurchaseProductQueryKey = function(productId) {
    return [
        "getToeicSpeakingCanPurchaseProduct",
        productId
    ];
};
export var getToeicSpeakingTicketOrderListQueryKey = function() {
    return [
        "getToeicSpeakingTicketOrderList"
    ];
};
export var getToeicSpeakingTicketOrderQueryKey = function(orderId) {
    return [
        "getToeicSpeakingTicketOrder",
        orderId
    ];
};
export var getToeicSpeakingMarketingBannerListQueryKey = function() {
    return [
        "getToeicSpeakingMarketingBannerList"
    ];
};
export var getToeicSpeakingExamWithContentsQueryKey = function(examId) {
    return [
        "getToeicSpeakingExamWithContents",
        examId
    ];
};
export var getToeicSpeakingExamPartContentsQueryKey = function(examId) {
    return [
        "getToeicSpeakingExamPartContentsQueryKey",
        examId
    ];
};
