// @ts-nocheck
import { name2num, num2name, } from "./Domain";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./LanguageTag";
import { name2num as name2num_1, num2name as num2name_1, } from "./ContentStatus";
import { name2num as name2num_2, num2name as num2name_2, } from "./ContentType";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./ContentTag";
import { name2num as name2num_3, num2name as num2name_3, } from "./Country";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../google/protobuf/Timestamp";
import { name2num as name2num_4, num2name as num2name_4, } from "./ContentUploadValidationStatus";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, unpackFns, } from "../../runtime/wire/scalar";
import { default as Long, } from "../../runtime/Long";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        contentId: "",
        versionId: "0",
        domain: "UNSPECIFIED_DOMAIN",
        languageTag: undefined,
        status: "UNSPECIFIED_CONTENT_STATUS",
        contentType: "UNSPECIFIED_CONTENT_TYPE",
        tags: [],
        regions: [],
        createTime: undefined,
        initialCreateTime: undefined,
        updateTime: undefined,
        contentUrl: "",
        contentUploadValidationStatus: "UNSPECIFIED_CONTENT_UPLOAD_VALIDATION_STATUS",
        createdBy: "",
        updatedBy: "",
        reviewedBy: "",
        isReviewed: false,
        displayName: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.contentId !== undefined)
        result.contentId = tsValueToJsonValueFns.string(value.contentId);
    if (value.versionId !== undefined)
        result.versionId = tsValueToJsonValueFns.uint64(value.versionId);
    if (value.domain !== undefined)
        result.domain = tsValueToJsonValueFns.enum(value.domain);
    if (value.languageTag !== undefined)
        result.languageTag = encodeJson_1(value.languageTag);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    if (value.contentType !== undefined)
        result.contentType = tsValueToJsonValueFns.enum(value.contentType);
    result.tags = value.tags.map(value => encodeJson_2(value));
    result.regions = value.regions.map(value => tsValueToJsonValueFns.enum(value));
    if (value.createTime !== undefined)
        result.createTime = encodeJson_3(value.createTime);
    if (value.initialCreateTime !== undefined)
        result.initialCreateTime = encodeJson_3(value.initialCreateTime);
    if (value.updateTime !== undefined)
        result.updateTime = encodeJson_3(value.updateTime);
    if (value.contentUrl !== undefined)
        result.contentUrl = tsValueToJsonValueFns.string(value.contentUrl);
    if (value.contentUploadValidationStatus !== undefined)
        result.contentUploadValidationStatus = tsValueToJsonValueFns.enum(value.contentUploadValidationStatus);
    if (value.createdBy !== undefined)
        result.createdBy = tsValueToJsonValueFns.string(value.createdBy);
    if (value.updatedBy !== undefined)
        result.updatedBy = tsValueToJsonValueFns.string(value.updatedBy);
    if (value.reviewedBy !== undefined)
        result.reviewedBy = tsValueToJsonValueFns.string(value.reviewedBy);
    if (value.isReviewed !== undefined)
        result.isReviewed = tsValueToJsonValueFns.bool(value.isReviewed);
    if (value.displayName !== undefined)
        result.displayName = tsValueToJsonValueFns.string(value.displayName);
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    if (value.contentId !== undefined)
        result.contentId = jsonValueToTsValueFns.string(value.contentId);
    if (value.versionId !== undefined)
        result.versionId = jsonValueToTsValueFns.uint64(value.versionId);
    if (value.domain !== undefined)
        result.domain = jsonValueToTsValueFns.enum(value.domain);
    if (value.languageTag !== undefined)
        result.languageTag = decodeJson_1(value.languageTag);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    if (value.contentType !== undefined)
        result.contentType = jsonValueToTsValueFns.enum(value.contentType);
    result.tags = (_b = (_a = value.tags) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_2(value))) !== null && _b !== void 0 ? _b : [];
    result.regions = (_d = (_c = value.regions) === null || _c === void 0 ? void 0 : _c.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _d !== void 0 ? _d : [];
    if (value.createTime !== undefined)
        result.createTime = decodeJson_3(value.createTime);
    if (value.initialCreateTime !== undefined)
        result.initialCreateTime = decodeJson_3(value.initialCreateTime);
    if (value.updateTime !== undefined)
        result.updateTime = decodeJson_3(value.updateTime);
    if (value.contentUrl !== undefined)
        result.contentUrl = jsonValueToTsValueFns.string(value.contentUrl);
    if (value.contentUploadValidationStatus !== undefined)
        result.contentUploadValidationStatus = jsonValueToTsValueFns.enum(value.contentUploadValidationStatus);
    if (value.createdBy !== undefined)
        result.createdBy = jsonValueToTsValueFns.string(value.createdBy);
    if (value.updatedBy !== undefined)
        result.updatedBy = jsonValueToTsValueFns.string(value.updatedBy);
    if (value.reviewedBy !== undefined)
        result.reviewedBy = jsonValueToTsValueFns.string(value.reviewedBy);
    if (value.isReviewed !== undefined)
        result.isReviewed = jsonValueToTsValueFns.bool(value.isReviewed);
    if (value.displayName !== undefined)
        result.displayName = jsonValueToTsValueFns.string(value.displayName);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.contentId !== undefined) {
        const tsValue = value.contentId;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.versionId !== undefined) {
        const tsValue = value.versionId;
        result.push([2, tsValueToWireValueFns.uint64(tsValue)]);
    }
    if (value.domain !== undefined) {
        const tsValue = value.domain;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.languageTag !== undefined) {
        const tsValue = value.languageTag;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([5, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.contentType !== undefined) {
        const tsValue = value.contentType;
        result.push([6, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
    }
    for (const tsValue of value.tags) {
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    for (const tsValue of value.regions) {
        result.push([8, { type: WireType.Varint, value: new Long(name2num_3[tsValue]) }]);
    }
    if (value.createTime !== undefined) {
        const tsValue = value.createTime;
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.initialCreateTime !== undefined) {
        const tsValue = value.initialCreateTime;
        result.push([10, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.updateTime !== undefined) {
        const tsValue = value.updateTime;
        result.push([11, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.contentUrl !== undefined) {
        const tsValue = value.contentUrl;
        result.push([12, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.contentUploadValidationStatus !== undefined) {
        const tsValue = value.contentUploadValidationStatus;
        result.push([13, { type: WireType.Varint, value: new Long(name2num_4[tsValue]) }]);
    }
    if (value.createdBy !== undefined) {
        const tsValue = value.createdBy;
        result.push([14, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.updatedBy !== undefined) {
        const tsValue = value.updatedBy;
        result.push([15, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.reviewedBy !== undefined) {
        const tsValue = value.reviewedBy;
        result.push([16, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.isReviewed !== undefined) {
        const tsValue = value.isReviewed;
        result.push([17, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.displayName !== undefined) {
        const tsValue = value.displayName;
        result.push([18, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.contentId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.uint64(wireValue);
        if (value === undefined)
            break field;
        result.versionId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.domain = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.languageTag = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_2[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.contentType = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 7).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.tags = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 8).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_3[num]);
        if (!value.length)
            break collection;
        result.regions = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createTime = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.initialCreateTime = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.updateTime = value;
    }
    field: {
        const wireValue = wireFields.get(12);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.contentUrl = value;
    }
    field: {
        const wireValue = wireFields.get(13);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_4[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.contentUploadValidationStatus = value;
    }
    field: {
        const wireValue = wireFields.get(14);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.createdBy = value;
    }
    field: {
        const wireValue = wireFields.get(15);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.updatedBy = value;
    }
    field: {
        const wireValue = wireFields.get(16);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.reviewedBy = value;
    }
    field: {
        const wireValue = wireFields.get(17);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isReviewed = value;
    }
    field: {
        const wireValue = wireFields.get(18);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.displayName = value;
    }
    return result;
}
